import React from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import PPKTeamTable from "../Table/PPKTeamTable";
const PPKTeamsModal = ({ show, teams, match, closeModal }) => {
  return (
    <Modal isOpen={show} scrollable={true} className="custom-modal w-706">
      <ModalHeader toggle={closeModal} tag="h4" className="text-capitalize">
        Match {match}
      </ModalHeader>

      <ModalBody className="events">
        <PPKTeamTable teams={teams} />
      </ModalBody>
    </Modal>
  );
};

export default PPKTeamsModal;
