import React, { Component } from "react";

import { Row, Col, Card, CardBody, FormGroup, Button } from "reactstrap";
import { AvForm } from "availity-reactstrap-validation";
import Breadcrumbs from "../../components/Common/Breadcrumb";

import { Link, withRouter } from "react-router-dom";
import Loader from "../../components/Common/Loader";

import { toast } from "react-toastify";
import toastrOptions from "../../helpers/toastr-options/toastr-options";
import { addBanCountry } from "../../services/complaint_user_api_helper";
import { getCountries, getStates } from "../../services/config_api_helper";

import { Multiselect } from "multiselect-react-dropdown";

import whiteCrossIcon from "../../assets/images/white-cross-icon.svg";
import { SUCCESS_MESSAGES } from "../../helpers/StringConstant";
class BanLocation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      editMode: false,
      errorMsg: null,

      countries: [],
      state: [],
      country: null,
      isDisabled: true,
      selectedCountryList: [],
      addMoreCountry: [],
      selectedStateList: [],
      addMoreState: [],
    };
    this.myRef = React.createRef();
  }

  componentDidMount() {
    this.getCountryList();
  }

  async getCountryList() {
    await getCountries().then((res) => {
      this.setState({ countries: res });
    });
  }
  async getStateList(id) {
    await getStates(id).then((res) => {
      this.setState({ state: res });
    });
  }
  async handleValidSubmit(event, values) {
    event.preventDefault();
    if (this.state.addMoreState.length === 0) {
      let model = {
        location_id: this.state.addMoreCountry,
        location_type: "country",
      };
      this.showLoader(true);
      this.setState({ errorMsg: null });
      await addBanCountry(model).then(
        (_) => {
          this.showLoader(false);

          if (_ != undefined) {
            toast.success(SUCCESS_MESSAGES.addedSuccess, toastrOptions);
            this.goToListing();
          }
        },
        (err) => {
          this.showLoader(false);
        }
      );
    } else {
      let model = {
        location_id: this.state.addMoreState,
        location_type: "state",
      };
      this.showLoader(true);
      this.setState({ errorMsg: null });
      await addBanCountry(model).then(
        (_) => {
          if (_ != undefined) {
            toast.success(SUCCESS_MESSAGES.addedSuccess, toastrOptions);
            this.goToListing();
          }
          this.showLoader(false);
        },
        (err) => {
          this.showLoader(false);
        }
      );
    }
  }

  goToListing() {
    this.props.history.push("/locationLists");
  }

  showLoader(isLoad) {
    this.setState({
      isLoading: isLoad,
    });
  }

  checkMultiSelect = (selectedList, selectedItem) => {
    this.setState({ selectedCountryList: selectedItem.id });
    this.getStateList(selectedItem.id);
    this.setState({
      addMoreCountry: Array.isArray(selectedList)
        ? selectedList.map((x) => x.id)
        : [],
      isDisabled: false,
    });
  };
  onRemove = (selectedList, removedItem) => {
    this.setState({
      addMoreCountry: Array.isArray(selectedList)
        ? selectedList.map((x) => x.id)
        : [],
      state: [],
      addMoreState: [],
    });
    this.myRef.current.resetSelectedValues();
  };
  checkMultiSelectState = (selectedList, selectedItem) => {
    this.setState({ selectedStateList: selectedItem.id });
    this.setState({
      addMoreState: Array.isArray(selectedList)
        ? selectedList.map((x) => x.id)
        : [],
    });
  };
  onRemoveState = (selectedList, removedItem) => {
    this.setState({
      addMoreState: Array.isArray(selectedList)
        ? selectedList.map((x) => x.id)
        : [],
    });
  };
  render() {
    return (
      <React.Fragment>
        <Loader showLoader={this.state.isLoading} />
        <div className="page-content">
          <Breadcrumbs breadcrumbItem={" Ban location"} />
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <Row className="mb-4">
                    <Col>
                      <p>
                        <Link to="/locationLists">
                          <i className="mdi mdi-arrow-left"></i> back
                        </Link>
                      </p>
                    </Col>
                  </Row>
                  <Row className="ban-location-row">
                    <Col className="col-lg-8 col-md-8 col-sm-12">
                      <AvForm
                        onValidSubmit={(e, v) => {
                          this.handleValidSubmit(e, v);
                        }}
                      >
                        <div className="mb-3">
                          <label>Country*</label>
                          <Multiselect
                            placeholder="Select country"
                            options={this.state.countries}
                            displayValue="name"
                            onSelect={this.checkMultiSelect}
                            onRemove={this.onRemove}
                            selectionLimit={1}
                            customCloseIcon={
                              <img
                                alt="white-cross-icon"
                                className="whiteCrossIcon"
                                src={whiteCrossIcon}
                              />
                            }
                          />
                        </div>
                        <div className="mb-3 select-state">
                          <label>State</label>
                          <Multiselect
                            placeholder="Select state"
                            options={this.state.state}
                            displayValue="name"
                            onSelect={this.checkMultiSelectState}
                            onRemove={this.onRemoveState}
                            customCloseIcon={
                              <img
                                alt="white-cross-icon"
                                className="whiteCrossIcon"
                                src={whiteCrossIcon}
                              />
                            }
                            ref={this.myRef}
                          />
                        </div>
                        <FormGroup className="mt-4">
                          <div>
                            <Button
                              type="submit"
                              color="primary"
                              className="ms-1"
                              disabled={
                                this.state.isDisabled
                                  ? this.state.isDisabled
                                  : this.state.isLoading
                              }
                            >
                              Submit
                            </Button>
                          </div>
                        </FormGroup>
                      </AvForm>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(BanLocation);
