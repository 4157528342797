import React from "react";
import { appendSuffixToTournamentPosition } from "../../../helpers/util";
import { useState } from "react";
import { Button } from "reactstrap";
import PrizeListModal from "../Modal/PrizeListModal";
const PrizeList = ({ prize }) => {
  const [showMore, setShowMore] = useState(false);
  const [showMorePrice, setShowMorePriceData] = useState([]);
  const handleShowMore = (showMorePriceData) => {
    setShowMore(true);
    setShowMorePriceData(showMorePriceData);
  };
  const handleClose = () => {
    setShowMore(false);
    setShowMorePriceData([]);
  };
  return (
    <>
      {prize
        .sort((first, second) => {
          return first.position > second.position ? 1 : -1;
        })
        .slice(0, 3)
        .map((priceList, id) => {
          return (
            <p key={id} className="mb-0">
              {priceList.position}
              {appendSuffixToTournamentPosition(priceList.position)} prize - $
              {priceList.price}
            </p>
          );
        })}
      {prize.length > 3 ? (
        <Button
          onClick={() => handleShowMore(prize)}
          variant="link"
          className="btn btn-primary btn-sm view-button theme-color mt-2"
        >
          View more
        </Button>
      ) : null}
      <PrizeListModal
        show={showMore}
        prizelist={showMorePrice}
        closeModal={handleClose}
      />
    </>
  );
};

export default PrizeList;
