import React, { useState } from "react";
import { dateTimeFormatterForTournamentMatchinfo } from "../../../helpers/util";
import { Button } from "react-bootstrap";
import ViewMoreTeamsModal from "../Modal/ViewMoreTeamsModal";
import ViewStats from "../Modal/ViewStats";
import { getLobbyDetail } from "../../../services/lobby_api_helper";
function MatchInfoTable({ subMatches, gameType, teamsInMatchesInfo }) {
  const [showMore, setShowMore] = useState(false);
  const [showMoreTeam, setShowMoreTeamData] = useState([]);

  const handleShowMore = (showMoreTeamData) => {
    setShowMore(true);
    setShowMoreTeamData(showMoreTeamData);
  };

  const handleClose = () => {
    setShowMore(false);
    setShowMoreTeamData([]);
  };
  const [showStats, setShowStats] = useState(false);
  const handleShowStats = (lobbyId) => {
    setShowStats(true);
    getTournament(lobbyId);
  };
  const [loader, setLoader] = useState(false);
  const [stats, setStats] = useState([]);
  const [gameData, setGameData] = useState(null);
  const getTournament = async (lobbyId) => {
    try {
      setLoader(true);
      const res = await getLobbyDetail(lobbyId);
      setStats(res.player_stats);
      setGameData(res.game);

      setLoader(false);
    } catch (error) {
      setLoader(false);
    }
  };
  const handleCloseStats = () => {
    setShowStats(false);
  };
  return (
    <div className="table-rep-plugin tournamentRound">
      <div className="table-responsive mb-0" data-pattern="priority-columns">
        <div className="table-responsrowspanive seize-wallet-table">
          <table className="table table-striped table-bordered manage-complaints-table lobbies-table add-schedule tournament-match-info">
            <thead>
              <tr>
                <th scope="col" rowSpan="2">
                  Match No.
                </th>
                <th scope="col" rowSpan="2">
                  Start Date Time
                </th>
                <th scope="col" rowSpan="2">
                  Versus
                </th>
                <th scope="col" rowspan="2">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {subMatches &&
                subMatches?.matches.map((item, index) => {
                  let isPPK = gameType === "PPK";
                  const matchWithSameId = teamsInMatchesInfo
                    ? teamsInMatchesInfo.find((match) =>
                        match.matches.some((m) => m.match_id === item.id)
                      )
                    : null;
                  const specificMatch = matchWithSameId?.matches.find(
                    (m) => m.match_id === item.id
                  );
                  // Check if match_number is 0 and display "NA" if true for traditional
                  const isMatchNumberZero =
                    specificMatch?.match_number === 0 || !specificMatch;

                  const teamsArray = specificMatch?.teams.map((team) => ({
                    id: team.team_id,
                    name: team.name,
                  }));
                  let teamsNotAvailableInMatches =
                    teamsArray?.length === 0 || teamsArray === undefined;
                  let versusMatchInTradtion = isMatchNumberZero
                    ? "NA vs NA"
                    : `${teamsArray[0]?.name} vs ${teamsArray[1]?.name}`;

                  if (item.sub_match.length === 0) {
                    return (
                      <tr key={index} id={index}>
                        <td className="text-capitalize">
                          {item.match === "match 0" || item.match === "Match 0"
                            ? `Match ${index}`
                            : item.match}
                        </td>
                        <td className="text-capitalize">NA</td>
                        <td>
                          {isPPK ? (
                            <>
                              {teamsNotAvailableInMatches ? (
                                <span>No Team Found</span>
                              ) : (
                                <>
                                  {teamsArray
                                    .slice(0, 3)
                                    .map((listTeams, indx) => {
                                      return (
                                        <li key={indx} id={indx}>
                                          {listTeams.name}
                                        </li>
                                      );
                                    })}
                                  {teamsArray.length > 3 ? (
                                    <Button
                                      onClick={() => handleShowMore(teamsArray)}
                                      variant="link"
                                      className="btn btn-primary btn-sm view-button theme-color mt-2"
                                    >
                                      View more
                                    </Button>
                                  ) : null}
                                </>
                              )}
                            </>
                          ) : teamsNotAvailableInMatches ? (
                            "NA vs NA"
                          ) : (
                            <>{versusMatchInTradtion}</>
                          )}
                        </td>
                        <td>No Stats Available</td>
                      </tr>
                    );
                  } else if (item.sub_match) {
                    return item.sub_match.map((submatch, idx) => {
                      let matchStatus;
                      if (submatch.lobby.stats_status <= 3) {
                        matchStatus = "No Stats Available";
                      } else if (submatch.lobby.stats_status === 5) {
                        matchStatus = (
                          <Button variant="danger" disabled={true}>
                            Cancelled
                          </Button>
                        );
                      } else if (submatch.lobby.stats_status === 4) {
                        matchStatus = (
                          <Button
                            onClick={() => handleShowStats(submatch.lobby.id)}
                            variant="primary"
                            className="view-stats"
                          >
                            View Stats
                          </Button>
                        );
                      } else {
                        matchStatus = null;
                      }
                      return (
                        <tr key={idx} id={idx}>
                          <td className="text-capitalize">
                            {submatch.sub_match || "NA"}
                            <span className="text-lowercase"> of</span>{" "}
                            {item.match === "match 0" ||
                            item.match === "Match 0"
                              ? `Match ${index}`
                              : item.match}{" "}
                          </td>
                          <td className="text-capitalize">
                            {dateTimeFormatterForTournamentMatchinfo(
                              submatch.lobby.start_date +
                                " " +
                                submatch.lobby.start_time
                            ) || "NA"}
                          </td>
                          <td>
                            <span>
                              {isPPK ? (
                                <>
                                  {teamsNotAvailableInMatches ? (
                                    <span>No Team Found</span>
                                  ) : (
                                    <>
                                      {teamsArray
                                        .slice(0, 3)
                                        .map((listTeams, indx) => {
                                          return (
                                            <li key={indx} id={indx}>
                                              {listTeams.name}
                                            </li>
                                          );
                                        })}
                                      {teamsArray.length > 3 ? (
                                        <Button
                                          onClick={() =>
                                            handleShowMore(teamsArray)
                                          }
                                          variant="link"
                                          className="theme-color"
                                        >
                                          View more
                                        </Button>
                                      ) : null}
                                    </>
                                  )}
                                </>
                              ) : teamsNotAvailableInMatches ? (
                                "NA vs NA"
                              ) : (
                                <>{versusMatchInTradtion}</>
                              )}
                            </span>
                          </td>
                          <td>{matchStatus}</td>
                        </tr>
                      );
                    });
                  }
                  return null;
                })}
            </tbody>
          </table>
          <ViewMoreTeamsModal
            show={showMore}
            teamList={showMoreTeam}
            closeModal={handleClose}
            roundTable={false}
          />
          {stats && gameData && (
            <ViewStats
              loader={loader}
              show={showStats}
              teamList={showMoreTeam}
              player_stats={stats}
              game_data={gameData}
              closeModal={handleCloseStats}
              isPPK={gameType === "PPK"}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default MatchInfoTable;
