import { AsyncPaginate } from "react-select-async-paginate";
import React, { useEffect } from "react";
import { useState } from "react";
import { useCallback } from "react";

function InfiniteScrollDropDown({
  getList = () => {},
  updateSelectedOption = () => {},
  type = "",
}) {
  const [value, setValue] = useState(null);
  const loadOptions = useCallback(
    async (search, loadedOptions, additional) => {
      try {
        let filteredOptions;
        let responseJSON = await getList({
          q: search,
          offset: loadedOptions.length,
          type,
        });

        filteredOptions = responseJSON?.results?.map((element) => ({
          ...element,
          value: element.id,
          label: element.name,
        }));
        return {
          options: filteredOptions,
          hasMore: responseJSON?.next ? true : false,
        };
      } catch (error) {}
    },
    [type]
  );

  useEffect(() => {
    updateSelectedOption(value?.value);
  }, [value]);

  useEffect(() => {
    if (type) setValue(null);
  }, [type]);

  return (
    <AsyncPaginate
      key={type}
      classNamePrefix="select2-selection"
      debounceTimeout={300}
      value={value}
      loadOptions={loadOptions}
      onChange={setValue}
    />
  );
}

export default InfiniteScrollDropDown;
