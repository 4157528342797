import React, { useState } from "react";
import { AvField } from "availity-reactstrap-validation";
import { createPrizeTable } from "./util";
import { useDispatch, useSelector } from "react-redux"; // Redux Store
import { setPrizeList } from "../store/actions";
const AddPrizes = (count) => {
  const prizeList = useSelector((state) => state?.PrizeList);
  const [prizeBasedOnRanking, setPrizeBasedOnRanking] = useState(
    prizeList.prize
  );
  const dispatch = useDispatch();
  const handlePrize = (event, prizeListArr) => {
    const convertStringToNumber = event.target.value;
    const arr = prizeListArr.slice();
    arr[0].price = Number(convertStringToNumber);
    if (prizeBasedOnRanking === undefined) {
      const sendToReducer = arr;
      dispatch(setPrizeList(sendToReducer));
      setPrizeBasedOnRanking(() => {
        return sendToReducer;
      });
    } else {
      const index = prizeBasedOnRanking.findIndex(
        (el) => el.position === prizeListArr[0].position
      );

      if (index !== -1) {
        setPrizeBasedOnRanking(() => {
          const sendToReducer = prizeBasedOnRanking.map(
            (dataToCheckForDuplicates) =>
              prizeListArr.find(
                (alreadyPresent) =>
                  alreadyPresent.position === dataToCheckForDuplicates.position
              ) || dataToCheckForDuplicates
          );
          dispatch(setPrizeList(sendToReducer));
          return sendToReducer;
        });
      } else {
        const sendToReducer = [...prizeBasedOnRanking, ...arr];
        dispatch(setPrizeList(sendToReducer));
        setPrizeBasedOnRanking(() => {
          return sendToReducer;
        });
      }
    }
  };
  const handleKeyDown = (e) => {
    if (e.key === "." || e.key === "-") {
      e.preventDefault();
    }
  };
  return (
    <table className="table table-striped table-bordered lobbies-table responsiveTable addPrize-tournament">
      <thead>
        <tr>
          <th>Position</th>
          <th>Amount</th>
        </tr>
      </thead>
      <tbody>
        {createPrizeTable(count?.count, count.PrizeData).map(
          (prizeListMap, index) =>
            prizeListMap?.map((item) => {
              return (
                <tr id={index}>
                  <td>{item.positionLabel}</td>
                  <td>
                    <AvField
                      name={`price_${index}`}
                      placeholder="Enter amount"
                      type="number"
                      id={`amount_${index}`}
                      value={item.price}
                      validate={{
                        required: {
                          value: true,
                          errorMessage: "Amount is required.",
                        },
                        min: {
                          value: 1,
                          // item.position === 1
                          //   ? 1
                          //   : prizeBasedOnRanking === undefined ||
                          //     prizeBasedOnRanking[index - 1] === undefined
                          //   ? 0
                          //   : 0,
                          errorMessage: "Amount should be greater than 0.",
                        },
                        max: {
                          value: 1000,
                          // item.position === 1
                          //   ? 1
                          //   : prizeBasedOnRanking === undefined ||
                          //     prizeBasedOnRanking[index - 1] === undefined
                          //   ? 0
                          //   : 0,
                          errorMessage:
                            "Amount should be less than or equal to 1000.",
                        },
                        pattern: {
                          value: /^\d+$/,
                          errorMessage: "Amount can't be in decimals.",
                        },
                        // max: {
                        //   value:
                        //     item.position === 1
                        //       ? 100000
                        //       : prizeBasedOnRanking === undefined ||
                        //         prizeBasedOnRanking[index - 1] === undefined
                        //       ? item.price
                        //       : prizeBasedOnRanking[index - 1].price === 0
                        //       ? 0
                        //       : prizeBasedOnRanking[index - 1].price - 1,
                        //   errorMessage:
                        //     "Amount should be less than above position",
                        // },
                      }}
                      onBlur={(event) => handlePrize(event, prizeListMap)}
                      onKeyDown={handleKeyDown}
                    />
                  </td>
                </tr>
              );
            })
        )}
      </tbody>
    </table>
  );
};

export default AddPrizes;
