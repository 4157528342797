import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Button,
  Label,
  InputGroup,
} from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation"; // import switch
import Switch from "react-switch"; //Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { Link, withRouter } from "react-router-dom";
import Loader from "../../../components/Common/Loader";
import {
  bracketStyleMinMaxOptions,
  bracketStyleOptionsPPK,
  bracketStyleOptionsTraditonal,
  charactersForRandomText,
  checkTimeDifference,
  compareDateForTimeValidation,
  compareDateTournament,
  createPrizeTableFromApi,
  filterLabel,
  filterTeam,
  gameTypeFiltersTournaments,
  getCurrentTimeESTWith2HoursAhead,
  getEstDate,
  getEstDateObj2hoursAhead,
  getEstDateToConvert,
  getModeLabelsSelect,
  randomPasswordGenerator,
  seedingOptions,
} from "../../../helpers/util";
import Select from "react-select";
import { connect, useDispatch, useSelector } from "react-redux";
import FileUploadS3 from "../../../helpers/FileUploadS3";
import CreatePrizes from "../../../helpers/CreatePrizes";
//Editor
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

import EditorToolbar, { formats } from "../../CMS/EditorToolbar";

//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";
import { getGameList } from "../../../services/game_api_helper";
import {
  setClearPrizeList,
  setGames,
  setPrizeList,
  updatePrizeCount,
  updatePrizeList,
} from "../../../store/actions";
import {
  addTournament,
  editTournament,
  getTournamentData,
  getTournamentforQualifierSeeding,
} from "../../../services/bracket_tournaments_api_helper";
import { toast } from "react-toastify";
import toastrOptions from "../../../helpers/toastr-options/toastr-options";
import S3 from "react-aws-s3";
import info from "../../../assets/images/info-icon.svg";
import getSignedUrls from "../../../helpers/getSignedUrls";
const Offsymbol = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        fontSize: 12,
        color: "#fff",
        paddingRight: 2,
      }}
    >
      {" "}
      No
    </div>
  );
};

const OnSymbol = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        fontSize: 12,
        color: "#fff",
        paddingRight: 2,
      }}
    >
      {" "}
      Yes
    </div>
  );
};
const AddTournaments = (props) => {
  const estTime = getCurrentTimeESTWith2HoursAhead();
  const estEndTime = getEstDateObj2hoursAhead();
  const minEstDate = getEstDate();
  const [gameTypeMode, setGameTypeMode] = useState({ value: 1, label: "PPK" });
  const modeObjStore = props?.game?.modeGameTypeTournamentObj;
  const [isEdit, setIsEdit] = useState(false);
  const [selectedGame, setSelectedGame] = useState();
  const [selectedBracketStyle, setSelectedBracket] = useState(
    bracketStyleOptionsPPK[0]
  );
  const [selectedSeeding, setSelectedSeeding] = useState(seedingOptions[0]);
  const [styleButton, setStyleButton] = useState("");
  const [minimumTeam, setMinimumTeam] = useState(null);
  const [selectedMinTeam, setSelectedMinTeam] = useState([]);
  const [maximumTeam, setMaximumTeam] = useState(null);
  const [minTeamRequiredMsg, setSelectedMinTeamErrorMsg] = useState("");
  const [selectedMaxTeam, setSelectedMaxTeam] = useState([]);
  const [maxTeamRequiredMsg, setSelectedMaxTeamErrorMsg] = useState("");
  const [activeStatus, setActiveStatus] = useState(true);
  const [freeTournament, setFreeTournament] = useState(false);
  const [allowPlayersToAddRankStatus, setAllowPlayersToAddRankStatus] =
    useState(false);
  const [startTime, setStartTime] = useState(estTime);
  const [endTimeRequiredMsg, setEndTimeRequiredMsg] = useState("");
  const [endTime, setEndTime] = useState(null);
  const games = useSelector((state) => state.Games.game?.traditionalGames);
  const prizeList = useSelector((state) => state?.PrizeList);
  const dispatch = useDispatch();
  const [showLoader, setShowLoadder] = useState(false);
  const [startTimeobj, setStartTimeObj] = useState();
  const [selectedPPTOptions, setSelectedPPTOptions] = useState();
  const [selectedPPT, setSelectedPPt] = useState([]);
  const [pptError, setPPTError] = useState(null);
  const [threshold, setThreshold] = useState(0);
  const [lobbySize, setLobbySize] = useState(0);

  useEffect(() => {
    if (props.match.params.tournamentId !== undefined) {
      setShowLoadder(true);
      setIsEdit(true);
      getTournamentEditData();
    }

    if (!isEdit) {
      addMinutes(estEndTime);
    }
    if (!games?.length) {
      getGames();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [games?.length, props?.game?.traditionalGames]);
  useEffect(() => {
    if (!isEdit) {
      if (gameTypeMode?.value === 1) {
        setSelectedBracket(bracketStyleOptionsPPK[0]);
        setSelectedSeeding(seedingOptions[4]);
      } else {
        setSelectedSeeding(seedingOptions[0]);
        setSelectedBracket(bracketStyleOptionsTraditonal[0]);
      }
    }
    handleSelectGroup(
      props?.game?.modeObjTournaments?.[gameTypeMode?.value]?.[0]
    );
  }, [gameTypeMode?.value, props?.game]);

  useEffect(() => {
    setMaxPrizeCount(handleSetMaxPrizeCount(gameTypeMode?.value));
  }, [gameTypeMode?.value, selectedMaxTeam?.length, maximumTeam]);

  const [signedUrls, setSignedUrls] = useState([]);
  useEffect(() => {
    const fetchSignedUrls = async () => {
      const bucketName = process.env.REACT_APP_S3_BUCKET_NAME;
      const objectKeys = [
        process.env.REACT_APP_S3_SEEDING_CSV,
        process.env.REACT_APP_S3_SEEDING_JSON,
      ];
      try {
        const urls = await getSignedUrls(bucketName, objectKeys);
        setSignedUrls(urls);
      } catch (error) {
        console.error("Error:", error);
      }
    };
    fetchSignedUrls();
  }, []);
  const getGames = async () => {
    try {
      const res = await getGameList();
      dispatch(setGames(res));
    } catch (error) {}
  };

  const [tournamentName, setTournamentName] = useState("");
  const getTournamentEditData = async () => {
    setShowLoadder(true);
    await getTournamentData(props.match.params.tournamentId)
      .then((res) => {
        mapDetailsToForm(res);
        mapFilterDetailsToForm(res);
        // setShowLoadder(false);
      })
      .catch((err) => {
        setIsEdit(true);
        setShowLoadder(false);
      });
  };
  const [price, setPrizeData] = useState([]);
  const [s3FileUrl, setS3FileUrl] = useState(null);
  const [teamExistInTournament, setTeamExistInTournament] = useState(false);
  const mapDetailsToForm = (response) => {
    dispatch(updatePrizeCount(response.price?.length));
    const updatePrizeReducer = createPrizeTableFromApi(
      response.price?.length,
      response.price
    );
    dispatch(setPrizeList(updatePrizeReducer));
    const convertDate = getEstDateToConvert(response.start_date);
    setTournamentName(response.name);
    setMinimumTeam(response.min_team);
    setMaximumTeam(response.max_team);
    //setPlayerPerTeam(response.player_per_team);
    setEntryFee(response.entry_fee);
    setUserInputStartDate(convertDate);
    setEndTime(response?.last_entry_time);
    setStartTime(response.start_time);
    setRules(response.game_rules);
    setTournamentRules(response.tournament_rules);
    setActiveStatus(response.active);
    setFreeTournament(response.free);
    setPrizeData(response.price);
    setThreshold(response.ppk_team_selection_threshold);
    setLobbySize(response?.lobby_size);
    setAllowPlayersToAddRankStatus(response.players_to_add_rank_status);
    setS3FileUrl(
      response.team_rank_file === "" ? null : response.team_rank_file
    );
    setTeamExistInTournament(response?.team_exist_in_tournament > 0);

    const filterMinTeam = filterTeam(
      bracketStyleMinMaxOptions,
      response.min_team
    );
    setSelectedMinTeam(filterMinTeam[0]);
    const filterMaxTeam = filterTeam(
      bracketStyleMinMaxOptions,
      response.max_team
    );
    setSelectedMaxTeam(filterMaxTeam[0]);
    const filterBracket = filterLabel(
      [...bracketStyleOptionsPPK, ...bracketStyleOptionsTraditonal],
      response.bracket
    );
    setSelectedBracket(filterBracket[0]);
    const filterSeeding = filterLabel(seedingOptions, response?.seeding);
    setSelectedSeeding(filterSeeding[0]);
    if (response.game_type === "Traditional") {
      setGameTypeMode({ value: 3, label: "Traditional" });
    } else {
      setGameTypeMode({ value: 1, label: "PPK" });
    }
    const typeofGame =
      response.game_type === "Traditional"
        ? props?.game?.modeGameTypeTournamentObj?.[response?.slug]
        : props?.game?.modeGameTypeObj?.[response?.slug];
    const filterPPT = typeofGame.filter(
      (pptData) => pptData.value === response.player_per_team
    );
    setSelectedPPTOptions(
      getModeLabelsSelect(
        gameTypeMode?.value,
        modeObjStore?.[response?.slug]
      )?.filter((val) => val?.[gameTypeMode?.value])
    );
    setSelectedPPt(filterPPT);
    setSelectedQualifierTournament({
      label: response?.qualifier_tournament.name,
      value: response?.qualifier_tournament.tournament_id,
    });
  };
  const mapFilterDetailsToForm = (response) => {
    const filterGame = props?.game?.modeObj[
      response.game_type === "Traditional" ? 3 : 1
    ].filter((gameData) => gameData.name === response.game);
    let getRequiredDataFromFilter = filterGame.map((item) => ({
      label: item.name,
      value: item.id,
    }));

    setSelectedGame(getRequiredDataFromFilter[0]);
    setShowLoadder(false);
  };
  const goToListing = () => {
    props.history.push("/tournament");
    dispatch(setClearPrizeList());
  };
  const [fileUploadFieldError, setFileUploadFieldError] = useState("");
  const handleInvalidSubmit = (event, errors, values) => {
    setStyleButton(values.entryFee === "" ? true : false);
    setRuleFieldError(rules?.length === 11 ? "Please add some game rules" : "");
    setPPTError(
      selectedPPT?.length === 0 ? "Please select player per team" : null
    );
    setSelectedMinTeamErrorMsg(
      selectedMinTeam?.length === 0 ? "Please select minimum team" : ""
    );
    setSelectedMaxTeamErrorMsg(
      selectedMaxTeam?.length === 0 ? "Please select maximum team" : ""
    );
    setQualifierRequiredMsg(
      selectedSeeding.value === 1 && qualifierTournament?.length === 0
        ? "Please select tournament"
        : ""
    );
    if (!isEdit) {
      const isTimeDifference2Hours = checkTimeDifference(
        startTime,
        values.startDate
      );
      setEndTimeRequiredMsg(
        isTimeDifference2Hours
          ? ""
          : "Tournaments can be created with minimum gap of 2 hours from the current time."
      );
      //Date comparison for time validation
      if (isTimeDifference2Hours) {
        const isFutureDate = compareDate(estEndTime, values.startDate);
        if (isFutureDate === -1) {
          compareTwoDateTime(estEndTime, startTimeobj);
        } else {
          if (isFutureDate) {
            setEndTimeRequiredMsg("");
          } else {
            compareTwoDateTime(estEndTime, startTimeobj);
          }
        }
      }
    }
    // setTournamentRuleFieldError(
    //   tournamentRules === 11 || tournamentRules === ""
    //     ? "Please add some tournament rules"
    //     : ""
    // );
    if (selectedSeeding.value === 3) {
      setFileUploadFieldError(
        fileUpload === undefined ? "Please upload file" : ""
      );
    } else if (selectedSeeding.value === 4 && !allowPlayersToAddRankStatus) {
      setFileUploadFieldError(
        fileUpload === undefined ? "Please upload file" : ""
      );
    }
  };
  const handleGameTypeChange = (event) => {
    setGameTypeMode(event);
    if (event.value === 1) {
      setSelectedPPTOptions(props?.game?.modeGameTypeObj?.["apex-legends"]);
    } else {
      setSelectedPPTOptions(
        props?.game?.modeGameTypeTournamentObj?.["apex-legends"]
      );
    }
  };

  const handleSelectGroup = (selected) => {
    setSelectedGame(selected);
    setSelectedPPTOptions(
      getModeLabelsSelect(
        gameTypeMode?.value,
        modeObjStore?.[selected?.slug]
      )?.filter((val) => val?.[gameTypeMode?.value])
    );
    setSelectedPPt([]);
    setSelectedQualifierTournament([]);
    setRules(selected?.game_rules);
  };
  const handleBracketStyleChange = (selectedBracket) => {
    setSelectedBracket(selectedBracket);
    if (selectedBracket.value === 3) {
      setSelectedSeeding(seedingOptions[4]);
    }
  };
  const handleSeedingChange = (selectedUserSeeding) => {
    setSelectedSeeding(selectedUserSeeding);
    setAllowPlayersToAddRankStatus(false);
    setFileUploadFieldError("");
  };
  const handlePPTChange = (selectedUserPPT) => {
    setSelectedPPt(selectedUserPPT);
    if (selectedSeeding.value === 1)
      getAllTournamentsListforQualifierSeeding(
        selectedGame.slug,
        selectedUserPPT.value
      );
    setPPTError(null);
  };
  const [entryFee, setEntryFee] = useState("");
  const [show_raw, setShowRaw] = useState(false);
  const [raw_html, setRawHtml] = useState("");
  const [rules, setRules] = useState("");
  const [ruleFieldError, setRuleFieldError] = useState("");
  const handleRules = (content, delta, source, editor) => {
    let updatedRules = editor.getHTML();
    setRules(updatedRules + "");
    setRuleFieldError("");
  };
  const handleClickShowRaw = () => {
    setShowRaw(!show_raw);
    if (show_raw) setRawHtml(rules);
    syncViews(show_raw);
  };

  const syncViews = (fromRaw) => {
    if (fromRaw) {
      setRules(raw_html + "");
    } else {
      setRules(rules);
    }
  };
  const handleChangeRaw = (html) => {
    setRawHtml(html + "");
    syncViews(show_raw);
  };
  const [show_rawTournament, setShowRawTournament] = useState(false);
  const [raw_htmlTournament, setRawHtmlTournament] = useState("");
  const [tournamentRules, setTournamentRules] = useState("");
  // const [tournamentRuleFieldError, setTournamentRuleFieldError] = useState("");
  const [disableSubmit, setSubmitDisabled] = useState(false);
  const handleTournamentRules = (content, delta, source, editor) => {
    let updatedRules = editor.getHTML();
    setTournamentRules(updatedRules + "");
    // setTournamentRuleFieldError("");
  };
  const handleClickShowRawTournament = () => {
    setShowRawTournament(!show_rawTournament);
    if (show_rawTournament) setRawHtmlTournament(tournamentRules);
    syncViewsTournament(show_rawTournament);
  };

  const syncViewsTournament = (fromRaw) => {
    if (fromRaw) {
      setTournamentRules(raw_htmlTournament + "");
    } else {
      setTournamentRules(tournamentRules);
    }
  };
  const handleChangeRawTournament = (html) => {
    setRawHtmlTournament(html + "");
    syncViewsTournament(show_rawTournament);
  };
  const [userInputStartDate, setUserInputStartDate] = useState(null);
  const handleStartDate = (userDate) => {
    setUserInputStartDate(userDate);
    const isFutureDate = compareDate(estEndTime, userDate);
    if (isFutureDate === -1) {
      compareTwoDateTime(estEndTime, startTimeobj);
    } else {
      if (isFutureDate) {
        setEndTimeRequiredMsg("");
      } else {
        compareTwoDateTime(estEndTime, startTimeobj);
      }
    }
  };
  const addMinutes = (date) => {
    //to test setting last entry time minus -10 min
    const minusTime = date.getTime() - 120 * 60000;
    const minusT = new Date(minusTime);
    setEndTime(
      minusT.toLocaleString("en-GB", {
        hour: "2-digit",
        minute: "2-digit",
      })
    );
    setStartTime(
      date.toLocaleString("en-GB", {
        hour: "2-digit",
        minute: "2-digit",
      })
    );

    setStartTimeObj(date);

    if (userInputStartDate !== null) {
      const isFutureDate = compareDate(estEndTime, userInputStartDate);
      if (isFutureDate === -1) {
        compareTwoDateTime(estEndTime, date);
      } else {
        if (isFutureDate) {
          setEndTimeRequiredMsg("");
        } else {
          compareTwoDateTime(estEndTime, date);
        }
      }
    }
  };

  const handleAdminSelectMinTeam = (selected, bool) => {
    if (bool) {
      if (selectedMaxTeam === null) {
        setSelectedMinTeamErrorMsg("");
        setSelectedMinTeam(selected);
      } else if (selectedMaxTeam.value < selected.value) {
        setSelectedMinTeamErrorMsg(
          "Minimum team should be less than than or equal to maximum team"
        );
        setSelectedMinTeam(selected);
      } else {
        setSelectedMinTeamErrorMsg("");
        setSelectedMaxTeamErrorMsg("");
        setSelectedMinTeam(selected);
      }
    } else {
      if (selectedMinTeam === null) {
        setSelectedMaxTeamErrorMsg("");
        setSelectedMaxTeam(selected);
      } else if (selectedMinTeam.value > selected.value) {
        setSelectedMaxTeamErrorMsg(
          "Maximum team should be greater than or equal to minimum team"
        );
        setSelectedMaxTeam(selected);
      } else {
        setSelectedMaxTeamErrorMsg("");
        setSelectedMinTeamErrorMsg("");
        setSelectedMaxTeam(selected);
      }
    }
  };
  const [fileUpload, setFileToUpload] = useState();
  const uploadFileData = (fileTos3) => {
    if (fileTos3?.invalidFileBoolean) {
      setSubmitDisabled(true);
    } else {
      setSubmitDisabled(false);
      setFileUploadFieldError("");
      if (isEdit && s3FileUrl !== null) {
        let fileExtension = s3FileUrl.substring(
          s3FileUrl?.lastIndexOf("/") + 1
        );
        setFileToUpload({
          fileData: fileTos3.fileData,
          fileName: fileExtension,
        });
      } else {
        let generateFileRandomName = randomPasswordGenerator({
          data: charactersForRandomText,
          passWordLength: 10,
        });
        let fileExtension = fileTos3.fileName.split(".").pop();
        let randomFilename = `${process.env.REACT_APP_S3_EXT_NAME}_tournament_${generateFileRandomName}.${fileExtension}`;
        setFileToUpload({
          fileData: fileTos3.fileData,
          fileName: randomFilename,
        });
      }
    }
  };
  const handleValidSubmit = async (event, values) => {
    event.preventDefault();
    const isTimeDifference2Hours = checkTimeDifference(
      startTime,
      values.startDate
    );

    if (isEdit) {
      submitFormValues(values);
    } else if (selectedBracketStyle.value <= 2) {
      if (
        rules === "" ||
        // tournamentRules === 11 ||
        //  tournamentRules === "" ||
        selectedMinTeam?.length === 0 ||
        selectedMaxTeam?.length === 0 ||
        selectedPPT?.length === 0 ||
        !isTimeDifference2Hours ||
        (selectedSeeding.value === 1 && qualifierTournament?.length === 0)
      ) {
        setRuleFieldError(
          rules?.length === 11 ? "Please add some game rules" : ""
        );
        setPPTError(
          selectedPPT?.length === 0 ? "Please select player per team" : ""
        );
        setSelectedMinTeamErrorMsg(
          selectedMinTeam?.length === 0 ? "Please select minimum team" : ""
        );
        setSelectedMaxTeamErrorMsg(
          selectedMaxTeam?.length === 0 ? "Please select maximum team" : ""
        );
        setEndTimeRequiredMsg(
          isTimeDifference2Hours
            ? ""
            : "Tournaments can be created with minimum gap of 2 hours from the current time."
        );
        setQualifierRequiredMsg(
          selectedSeeding.value === 1 && qualifierTournament?.length === 0
            ? "Please select tournament"
            : ""
        );
      } else if (selectedMinTeam.value > selectedMaxTeam.value) {
        setSelectedMinTeamErrorMsg(
          "Minimum team should be less than than or equal to maximum team"
        );
      } else if (selectedPPT?.length === 0) {
        setPPTError(
          selectedPPT?.length === 0 ? "Please select player per team" : ""
        );
      } else if (!isTimeDifference2Hours) {
        setEndTimeRequiredMsg(
          "Tournaments can be created with minimum gap of 2 hours from the current time."
        );
      } else if (
        selectedSeeding.value === 1 &&
        qualifierTournament?.length === 0
      ) {
        setQualifierRequiredMsg("Please select tournament");
      } else if (fileUpload === undefined && selectedSeeding.value === 3) {
        setFileUploadFieldError("Please upload file");
      } else if (selectedSeeding.value === 4) {
        if (allowPlayersToAddRankStatus) {
          setFileUploadFieldError("");
          commonSubmitBlock(values);
        } else if (fileUpload === undefined) {
          setFileUploadFieldError("Please upload file");
        } else {
          setFileUploadFieldError("");
          commonSubmitBlock(values);
        }
      } else {
        commonSubmitBlock(values);
      }
    } else {
      if (
        rules === "" ||
        selectedPPT?.length === 0 ||
        !isTimeDifference2Hours
        // tournamentRules === 11 ||
        //  tournamentRules === "" ||
      ) {
        setRuleFieldError(
          rules?.length === 11 ? "Please add some game rules" : ""
        );
        setPPTError(
          selectedPPT?.length === 0 ? "Please select player per team" : ""
        );
        setEndTimeRequiredMsg(
          "Tournaments can be created with minimum gap of 2 hours from the current time."
        );
        // setTournamentRuleFieldError(
        //   tournamentRules === 11 || tournamentRules === ""
        //     ? "Please add some tournament rules fd"
        //     : ""
        // );
      } else if (selectedPPT?.length === 0) {
        setPPTError(
          selectedPPT?.length === 0 ? "Please select player per team" : ""
        );
      } else if (!isTimeDifference2Hours) {
        setEndTimeRequiredMsg(
          "Tournaments can be created with minimum gap of 2 hours from the current time."
        );
      } else if (fileUpload === undefined && selectedSeeding.value === 3) {
        setFileUploadFieldError("Please upload file");
      } else if (selectedSeeding.value === 4) {
        if (allowPlayersToAddRankStatus) {
          setFileUploadFieldError("");
          commonSubmitBlock(values);
        } else if (fileUpload === undefined) {
          setFileUploadFieldError("Please upload file");
        } else {
          setFileUploadFieldError("");
          commonSubmitBlock(values);
        }
      } else {
        commonSubmitBlock(values);
      }
    }
  };
  if (prizeList.prize?.length > prizeList.count) {
    dispatch(updatePrizeList());
  }
  const commonSubmitBlock = (submitval) => {
    const isFutureDate = compareDate(estEndTime, submitval.startDate);
    const isCurrentTimeOrFutureTime = compareDateTime(estEndTime, startTimeobj);

    if (isFutureDate === -1) {
      if (isCurrentTimeOrFutureTime) {
        setEndTimeRequiredMsg(
          "Tournaments can be created with minimum gap of 2 hours from the current time."
        );
      } else {
        submitFormValues(submitval);
      }
    } else if (isFutureDate) {
      submitFormValues(submitval);
    } else {
      submitFormValues(submitval);
    }
  };
  const submitFormValues = (formValues) => {
    setSubmitDisabled(true);
    if (selectedSeeding.value === 3) {
      uploadToS3(formValues);
    } else if (selectedSeeding.value === 4) {
      if (allowPlayersToAddRankStatus) {
        uploadFormData(formValues, "");
      } else {
        uploadToS3(formValues);
      }
    } else {
      uploadFormData(formValues, "");
    }
    // if (!fileUpload) {
    //   uploadFormData(formValues, "");
    // } else {
    //   const config = {
    //     bucketName: process.env.REACT_APP_S3_BUCKET_NAME,
    //     dirName: process.env.REACT_APP_S3_TOURNAMENT_CSV_FILE_PATH,
    //     region: process.env.REACT_APP_S3_REGION_NAME,
    //     accessKeyId: process.env.REACT_APP_S3_ACCESS_KEY,
    //     secretAccessKey: process.env.REACT_APP_S3_SECRET_KEY,
    //   };
    //   const ReactS3Client = new S3(config);
    //   ReactS3Client.uploadFile(fileUpload.fileData, fileUpload.fileName)
    //     .then((data) => {
    //       if (data.status === 204) {
    //         setShowLoadder(true);
    //         uploadFormData(formValues, data?.location);
    //       } else {
    //         setShowLoadder(false);
    //         toast.error("Please try again later", toastrOptions);
    //         setSubmitDisabled(false);
    //       }
    //     })
    //     .catch((err) => {
    //       toast.error(err, toastrOptions);
    //       setSubmitDisabled(false);
    //     });
    // }
  };
  const uploadToS3 = (formValuesToApi) => {
    const config = {
      bucketName: process.env.REACT_APP_S3_BUCKET_NAME,
      dirName: process.env.REACT_APP_S3_TOURNAMENT_CSV_FILE_PATH,
      region: process.env.REACT_APP_S3_REGION_NAME,
      accessKeyId: process.env.REACT_APP_S3_ACCESS_KEY,
      secretAccessKey: process.env.REACT_APP_S3_SECRET_KEY,
    };
    const ReactS3Client = new S3(config);
    ReactS3Client.uploadFile(fileUpload.fileData, fileUpload.fileName)
      .then((data) => {
        if (data.status === 204) {
          setShowLoadder(true);
          uploadFormData(formValuesToApi, data?.location);
        } else {
          setShowLoadder(false);
          toast.error("Please try again later", toastrOptions);
          setSubmitDisabled(false);
        }
      })
      .catch((err) => {
        toast.error(err, toastrOptions);
        setSubmitDisabled(false);
      });
  };
  const uploadFormData = async (values, s3url) => {
    let commonPlayloadTosend = {
      name: values.tournamentName,
      game_id: selectedGame.value,
      game_type: gameTypeMode.value,
      // entry_fee: freeTournament ? 0 : parseInt(values.entryFee),
      entry_fee: parseInt(values.entryFee),
      min_team:
        selectedBracketStyle.value <= 2
          ? selectedMinTeam.value
          : parseInt(values.minTeam),
      max_team:
        selectedBracketStyle.value <= 2
          ? selectedMaxTeam.value
          : parseInt(values.maxTeam),
      player_per_team: selectedPPT.value,
      start_date: values.startDate,
      start_time: startTime,
      last_entry_time: endTime,
      bracket: selectedBracketStyle.value,
      seeding: selectedSeeding.value,
      team_rank_file: s3url,
      players_to_add_rank_status:
        selectedSeeding.value === 4 ? allowPlayersToAddRankStatus : false,
      price: prizeList.prize,
      game_rules: rules,
      tournament_rules: tournamentRules,
      active: activeStatus,
      free: freeTournament,
      ppk_team_selection_threshold: threshold,
      lobby_size: lobbySize,
    };
    let model =
      selectedSeeding.value === 1
        ? {
            ...commonPlayloadTosend,
            qualifier_tournament: qualifierTournament.value,
          }
        : {
            ...commonPlayloadTosend,
          };
    if (isEdit) {
      await editTournament(props.match.params.tournamentId, model).then(
        () => {
          dispatch(setClearPrizeList());
          toast.success("Tournament updated Successfully", toastrOptions);
          setShowLoadder(false);
          goToListing();
          setSubmitDisabled(false);
        },
        () => {
          setShowLoadder(false);
          setSubmitDisabled(false);
        }
      );
    } else {
      await addTournament(model).then(
        () => {
          dispatch(setClearPrizeList());
          toast.success("Tournament Added Successfully", toastrOptions);
          setShowLoadder(false);
          goToListing();
          setSubmitDisabled(false);
        },
        () => {
          setShowLoadder(false);
          setSubmitDisabled(false);
        }
      );
    }
  };

  const compareTwoDateTime = (current, user) => {
    const isCurrentTimeOrFutureTime = compareDateTime(current, user);
    if (isCurrentTimeOrFutureTime) {
      setEndTimeRequiredMsg(
        "Tournaments can be created with minimum gap of 2 hours from the current time."
      );
    } else {
      setEndTimeRequiredMsg("");
    }
  };

  function compareDate(currentDate, userInputDate) {
    let dateResult = compareDateForTimeValidation(currentDate, userInputDate);
    return dateResult;
  }
  function compareDateTime(presentTime, userInputTime) {
    let dateTimeResult = compareDateTournament(presentTime, userInputTime);
    return dateTimeResult;
  }
  const handleSetMinTeam = (emincount) => {
    if (parseInt(+emincount.target.value) <= 99 || minimumTeam === null) {
      setMinimumTeam(parseInt(+emincount.target.value));
    } else {
      emincount.preventDefault();
    }
  };
  const handleSetMaxTeam = (emaxcount) => {
    if (parseInt(+emaxcount.target.value) < 100 || maximumTeam === null) {
      setMaximumTeam(parseInt(+emaxcount.target.value));
    } else {
      emaxcount.preventDefault();
    }
  };
  const handleSetMaxPrizeCount = (gameType) => {
    if (selectedBracketStyle?.value <= 2) {
      if (selectedMaxTeam?.length === 0) {
        return 0;
      } else {
        return selectedMaxTeam?.value - 1;
      }
    } else {
      return gameType == 1 ? maximumTeam - 1 : maximumTeam;
    }
  };
  const [maxprizecount, setMaxPrizeCount] = useState(
    handleSetMaxPrizeCount(gameTypeMode?.value)
  );
  const [optionsForQualifierSeeding, setTournamentListsForQualifierSeeding] =
    useState([]);
  const [qualifierTournament, setSelectedQualifierTournament] = useState([]);
  const [optionIsLoading, setOptionIsLoading] = useState(false);
  const [qualifierTournamentRequiredMSG, setQualifierRequiredMsg] =
    useState("");
  const getAllTournamentsListforQualifierSeeding = async (slug, pptVal) => {
    setOptionIsLoading(true);
    await getTournamentforQualifierSeeding(slug, pptVal)
      .then((res) => {
        if (res.tournament.length === 0) {
          setSelectedQualifierTournament([]);
          setTournamentListsForQualifierSeeding([]);
        } else {
          let setOptions = res.tournament.map((item) => ({
            label: item.name,
            value: item.id,
          }));
          setTournamentListsForQualifierSeeding(setOptions);
        }
        setOptionIsLoading(false);
      })
      .catch(() => {
        setOptionIsLoading(false);
      });
  };
  const handleSelectTournamentForQualifierSeeding = (selectedTournament) => {
    setSelectedQualifierTournament(selectedTournament);
    setQualifierRequiredMsg("");
  };
  const handleKeyDown = (e, inputFieldName) => {
    if (
      (inputFieldName === "minTeam" ||
        inputFieldName === "maxTeam" ||
        inputFieldName === "lobbySize" ||
        inputFieldName === "threshold") &&
      (e.key === "." || e.key === "-")
    ) {
      e.preventDefault();
    }
  };
  return (
    <React.Fragment>
      <Loader showLoader={showLoader} />
      <div className="page-content">
        <Breadcrumbs
          breadcrumbItem={isEdit ? "Edit Tournament" : "Add Tournament"}
        />
        <Row>
          <Col lg={12}>
            <Card>
              <CardBody>
                <Row className="mb-4">
                  <Col>
                    <p>
                      <Link to="/tournament" onClick={goToListing}>
                        <i className="mdi mdi-arrow-left"></i> back
                      </Link>
                    </p>
                  </Col>
                </Row>
                <Row className="add-lobby-row">
                  <Col className="col-lg-8 col-md-8 col-sm-12">
                    <AvForm
                      onValidSubmit={(e, v) => {
                        handleValidSubmit(e, v);
                      }}
                      onInvalidSubmit={handleInvalidSubmit}
                    >
                      <div className="mb-3">
                        <AvField
                          name="tournamentName"
                          label="Tournament Name*"
                          placeholder="Enter tournament name here"
                          type="text"
                          validate={{
                            required: {
                              value: true,
                              errorMessage: "Tournament name is required",
                            },
                            maxLength: {
                              value: 50,
                              errorMessage: "Name can have 50 characters max",
                            },
                          }}
                          value={tournamentName}
                          disabled={isEdit}
                        />
                      </div>
                      <div className="mb-3">
                        <Label>Game Type*</Label>
                        <Select
                          value={gameTypeMode}
                          options={gameTypeFiltersTournaments}
                          name="game_type"
                          classNamePrefix="select2-selection"
                          onChange={handleGameTypeChange}
                          isDisabled={isEdit}
                        />
                      </div>
                      <div className="mb-3">
                        <Label>Select Game*</Label>
                        <Select
                          key={gameTypeMode?.value}
                          value={selectedGame}
                          onChange={(event) => {
                            handleSelectGroup(event);
                          }}
                          options={props?.game?.modeObj?.[gameTypeMode?.value]}
                          name="game"
                          classNamePrefix="select2-selection"
                          isDisabled={isEdit}
                        />
                      </div>
                      <Row className="flex-align-item-center">
                        <Col className="col-lg-8 col-md-8 col-sm-8 col-12">
                          <div className="mb-3">
                            <AvField
                              name="entryFee"
                              label="Entry Fee/Player*"
                              placeholder="Enter Entry fee"
                              type="text"
                              value={entryFee}
                              validate={{
                                required: {
                                  value: true,
                                  errorMessage: "Entry fee is required",
                                },
                                number: {
                                  value: true,
                                  errorMessage: "Please enter number only",
                                },
                                min: {
                                  value: 0,
                                  errorMessage:
                                    "Entry fee value must be between 0 and 1000",
                                },
                                max: {
                                  value: 1000,
                                  errorMessage:
                                    "Entry fee must be between 0 and 1000",
                                },
                                pattern: {
                                  value: /^\d+$/,
                                  errorMessage:
                                    "Entry fee can't be in decimals.",
                                },
                              }}
                              disabled={isEdit}
                            />
                          </div>
                        </Col>
                        <Col
                          className={
                            styleButton
                              ? "validationMessage"
                              : "col-lg-2 col-md-2 col-sm-2 col-4 lobby-fees"
                          }
                        >
                          <Button
                            className="btn btn-sm btn-secondary custom-lobbytype-btn"
                            onClick={() => {
                              setEntryFee("1");
                            }}
                            disabled={isEdit}
                          >
                            $1
                          </Button>
                        </Col>
                        <Col
                          className={
                            styleButton
                              ? "validationMessage"
                              : "col-lg-2 col-md-2 col-sm-2 col-4 lobby-fees"
                          }
                        >
                          <Button
                            className="btn btn-sm btn-secondary custom-lobbytype-btn"
                            onClick={() => {
                              setEntryFee("5");
                            }}
                            disabled={isEdit}
                          >
                            $5
                          </Button>
                        </Col>
                      </Row>

                      <div className="mb-3">
                        <Label>Bracket Styles*</Label>
                        <Select
                          key={gameTypeMode?.value}
                          value={selectedBracketStyle}
                          options={
                            gameTypeMode?.value == 1
                              ? bracketStyleOptionsPPK
                              : bracketStyleOptionsTraditonal
                          }
                          name="bracketStyle"
                          classNamePrefix="select2-selection"
                          onChange={handleBracketStyleChange}
                          isDisabled={isEdit || gameTypeMode?.value == 1}
                        />
                      </div>
                      <div className="mb-3">
                        <Label>Seeding*</Label>
                        <Select
                          value={selectedSeeding}
                          options={seedingOptions}
                          name="seeding"
                          classNamePrefix="select2-selection"
                          onChange={handleSeedingChange}
                          isDisabled={
                            isEdit ||
                            gameTypeMode?.value == 1 ||
                            selectedBracketStyle.value === 3
                          }
                        />
                        {selectedSeeding.value === 4 ? (
                          <div className="mt-3">
                            <Row className="align-items-center verified-check-row">
                              <Col className="col-lg-3 col-sm-3">
                                <p className="verified-check">
                                  Allow the players to add ranks
                                </p>
                              </Col>
                              <Col className="col-lg-3 col-sm-3">
                                <Switch
                                  uncheckedIcon={<Offsymbol />}
                                  checkedIcon={<OnSymbol />}
                                  onColor="#626ed4"
                                  className={
                                    allowPlayersToAddRankStatus
                                      ? "form-label active-toggle"
                                      : "form-label"
                                  }
                                  onChange={() => {
                                    setAllowPlayersToAddRankStatus(
                                      !allowPlayersToAddRankStatus
                                    );
                                    setFileUploadFieldError("");
                                  }}
                                  checked={allowPlayersToAddRankStatus}
                                  disabled={isEdit}
                                />
                              </Col>
                            </Row>
                          </div>
                        ) : null}
                      </div>
                      {selectedSeeding.value === 3 ||
                      selectedSeeding.value === 4 ? (
                        allowPlayersToAddRankStatus ? null : (
                          <div className="mb-3">
                            <Label>Team Rank*</Label>

                            <FileUploadS3
                              dataFromUploadedFile={uploadFileData}
                              S3URL={s3FileUrl}
                              isError={fileUploadFieldError?.length !== 0}
                            />
                            {signedUrls.map((url, index) => {
                              const extractExtension = (url) => {
                                const lastDotIndex = url?.lastIndexOf(".");
                                if (lastDotIndex !== -1) {
                                  return url.substring(lastDotIndex + 1);
                                }
                                return "";
                              };
                              return (
                                <div key={index}>
                                  <a href={url} download>
                                    Download {extractExtension(url)} File
                                  </a>
                                </div>
                              );
                            })}
                            {fileUploadFieldError?.length === 0 ? null : (
                              <label
                                className={
                                  fileUploadFieldError?.length === 0
                                    ? ""
                                    : "errorMsgGames invalid-feedback"
                                }
                              >
                                {fileUploadFieldError}
                              </label>
                            )}
                          </div>
                        )
                      ) : null}
                      {selectedBracketStyle?.value <= 2 && !isEdit ? (
                        <>
                          <div className="mb-3">
                            <Label> Minimum Teams*</Label>
                            <Select
                              defaultValue={selectedMinTeam}
                              onChange={(event) => {
                                handleAdminSelectMinTeam(event, true);
                              }}
                              options={bracketStyleMinMaxOptions}
                              name="minTeam"
                              classNamePrefix="select2-selection"
                              isDisabled={isEdit}
                              styles={{
                                control: (provided, state) =>
                                  minTeamRequiredMsg?.length === 0
                                    ? provided
                                    : {
                                        ...provided,
                                        borderColor: "#ff715b",
                                      },
                              }}
                            />

                            {minTeamRequiredMsg?.length === 0 ? null : (
                              <label
                                className={
                                  minTeamRequiredMsg?.length === 0
                                    ? ""
                                    : "errorMsgGames invalid-feedback"
                                }
                              >
                                {minTeamRequiredMsg}
                              </label>
                            )}
                          </div>
                          <div className="mb-3">
                            <Label>Maximum Teams*</Label>
                            <Select
                              defaultValue={selectedMaxTeam}
                              onChange={(event) => {
                                handleAdminSelectMinTeam(event, false);
                              }}
                              options={bracketStyleMinMaxOptions}
                              name="maxTeam"
                              classNamePrefix="select2-selection"
                              isDisabled={isEdit}
                              styles={{
                                control: (provided, state) =>
                                  maxTeamRequiredMsg?.length === 0
                                    ? provided
                                    : {
                                        ...provided,
                                        borderColor: "#ff715b",
                                      },
                              }}
                            />

                            {maxTeamRequiredMsg?.length === 0 ? null : (
                              <label
                                className={
                                  maxTeamRequiredMsg?.length === 0
                                    ? ""
                                    : "errorMsgGames invalid-feedback"
                                }
                              >
                                {maxTeamRequiredMsg}
                              </label>
                            )}
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="mb-3">
                            <AvField
                              name="minTeam"
                              label="Minimum Teams*"
                              placeholder="Enter minimum team"
                              type="number"
                              value={minimumTeam}
                              validate={{
                                required: {
                                  value: true,
                                  errorMessage: "Minimum team is required",
                                },
                                min: {
                                  value: gameTypeMode.value === 3 ? 3 : 3, //for now
                                  errorMessage: `Minimum team has to be greater than or equal to ${
                                    gameTypeMode.value === 3 ? 3 : 3
                                  }`,
                                },
                                max: {
                                  value: 100,
                                  errorMessage:
                                    "Minimum team should be less than 100",
                                },
                              }}
                              onKeyDown={(e) => handleKeyDown(e, "minTeam")}
                              onChange={(eMin) => {
                                handleSetMinTeam(eMin);
                              }}
                              disabled={isEdit}
                            />
                          </div>
                          <div className="mb-3">
                            <AvField
                              name="maxTeam"
                              label="Maximum Teams*"
                              placeholder="Enter maximum team"
                              type="number"
                              value={maximumTeam}
                              validate={{
                                required: {
                                  value: true,
                                  errorMessage: "Maximum team is required",
                                },
                                min: {
                                  value: minimumTeam,
                                  errorMessage:
                                    "Maximum team has to be greater than or equal minimum team",
                                },
                                max: {
                                  value: 100,
                                  errorMessage:
                                    "Maximum team should be less than or equal to 100",
                                },
                              }}
                              onKeyDown={(e) => handleKeyDown(e, "maxTeam")}
                              onChange={(eMax) => {
                                handleSetMaxTeam(eMax);
                              }}
                              disabled={isEdit}
                            />
                          </div>
                        </>
                      )}

                      <div className="mb-3">
                        <Label>Players Per Team*</Label>
                        <Select
                          key={selectedPPT?.value}
                          value={selectedPPT}
                          options={selectedPPTOptions}
                          name="pptSelect"
                          classNamePrefix="select2-selection"
                          onChange={handlePPTChange}
                          isDisabled={isEdit}
                          getOptionLabel={(option) => option.value}
                          styles={{
                            control: (provided, state) =>
                              pptError === null
                                ? provided
                                : {
                                    ...provided,
                                    borderColor: "#ff715b",
                                  },
                          }}
                        />
                        {pptError === null ? null : (
                          <label
                            className={
                              pptError === null
                                ? ""
                                : "errorMsgGames invalid-feedback"
                            }
                          >
                            {pptError === null ? null : pptError}
                          </label>
                        )}
                      </div>

                      {selectedSeeding.value === 1 ? (
                        <div className="mb-3">
                          <Label>Select Tournament*</Label>
                          <Select
                            value={qualifierTournament}
                            options={optionsForQualifierSeeding}
                            name="seeding"
                            classNamePrefix="select2-selection"
                            onChange={handleSelectTournamentForQualifierSeeding}
                            isDisabled={isEdit}
                            isLoading={optionIsLoading}
                            styles={{
                              control: (provided, state) =>
                                qualifierTournamentRequiredMSG?.length === 0
                                  ? provided
                                  : {
                                      ...provided,
                                      borderColor: "#ff715b",
                                    },
                            }}
                          />
                          {qualifierTournamentRequiredMSG?.length ===
                          0 ? null : (
                            <label
                              className={
                                qualifierTournamentRequiredMSG?.length === 0
                                  ? ""
                                  : "errorMsgGames invalid-feedback"
                              }
                            >
                              {qualifierTournamentRequiredMSG}
                            </label>
                          )}
                        </div>
                      ) : null}
                      {gameTypeMode?.value == 1 ? (
                        <>
                          <div className="mb-3">
                            <AvField
                              name="lobbySize"
                              label="Lobby Size*"
                              placeholder="Enter Lobby Size"
                              type="number"
                              validate={{
                                required: {
                                  value: true,
                                  errorMessage: "Lobby Size is required",
                                },
                                min: {
                                  value: 3,
                                  errorMessage:
                                    "Minimum Lobby Size has to be greater than 2",
                                },
                                max: {
                                  value: 20,
                                  errorMessage:
                                    "Maxmimum Lobby Size has to be less than or equal to 20",
                                },
                              }}
                              onKeyDown={(e) => handleKeyDown(e, "lobbySize")}
                              onChange={(e) => {
                                setLobbySize(e.target.value);
                              }}
                              value={lobbySize}
                            />
                          </div>
                          <div className="mb-3">
                            <AvField
                              name="threshold"
                              label={
                                <>
                                  Threshold*
                                  <span className="info-icon stats-infoIcon">
                                    <img
                                      src={info}
                                      alt="info"
                                      className="info-image"
                                      data-tip
                                      data-for="loginTip"
                                    />
                                    <span class="tooltiptext">
                                      No. of teams moved to next round.
                                    </span>
                                  </span>
                                </>
                              }
                              placeholder="Enter Threshold"
                              type="number"
                              validate={{
                                required: {
                                  value: true,
                                  errorMessage: "Threshold is required",
                                },
                                min: {
                                  value: 1,
                                  errorMessage:
                                    "Minimum threshold has to be greater than 0",
                                },
                                max: {
                                  value: Math.floor(lobbySize / 2),
                                  errorMessage:
                                    "Maximum threshold has to be less than or equal to half of lobby size",
                                },
                              }}
                              onKeyDown={(e) => handleKeyDown(e, "threshold")}
                              onChange={(e) => {
                                setThreshold(e.target.value);
                              }}
                              value={threshold}
                            />
                          </div>
                        </>
                      ) : null}
                      <div className="mb-3">
                        <AvField
                          className="uppercase"
                          name="startDate"
                          label="Start Date (EDT)*"
                          placeholder="DD-MM-YYYY"
                          type="date"
                          validate={{
                            required: {
                              value: true,
                              errorMessage: "Start Date is required",
                            },
                            min: {
                              value: isEdit ? userInputStartDate : minEstDate,
                            },
                          }}
                          onChange={(e) => {
                            handleStartDate(e.target.value);
                          }}
                          value={userInputStartDate}
                          //defaultValue={userInputStartDate}
                          disabled={isEdit}
                        />
                      </div>
                      <FormGroup className="mb-3">
                        <Label>
                          Start Time (EDT)*
                          <span className="info-icon stats-infoIcon">
                            <img
                              src={info}
                              alt="info"
                              className="info-image"
                              data-tip
                              data-for="loginTip"
                            />
                            <span class="tooltiptext">
                              Tournaments can be created with minimum gap of 2
                              hours from the current time.
                            </span>
                          </span>
                        </Label>
                        <InputGroup>
                          <Flatpickr
                            className={
                              !isEdit
                                ? "form-control d-block startTimeBackground"
                                : "form-control d-block"
                            }
                            placeholder="Enter start time"
                            value={startTime}
                            name="startTime"
                            required
                            options={{
                              enableTime: true,
                              noCalendar: true,
                              minuteIncrement: 5,
                              dateFormat: "h:i K",
                              //time_24hr: true,
                            }}
                            onChange={(e, value, options) => {
                              addMinutes(options?.latestSelectedDateObj);
                            }}
                            disabled={isEdit}
                          />
                        </InputGroup>
                        {endTimeRequiredMsg === "" ? null : (
                          <label
                            className={
                              endTimeRequiredMsg?.length === 0
                                ? ""
                                : "errorMsgGames invalid-feedback"
                            }
                          >
                            {endTimeRequiredMsg === ""
                              ? null
                              : endTimeRequiredMsg}
                          </label>
                        )}
                      </FormGroup>
                      <FormGroup className="mb-3">
                        <Label>Last Entry Time (EDT)* </Label>
                        <InputGroup>
                          <Flatpickr
                            className="form-control d-block"
                            placeholder="Enter last entry time"
                            value={endTime}
                            name="endTime"
                            required
                            options={{
                              enableTime: true,
                              noCalendar: true,
                              minuteIncrement: 5,
                              dateFormat: "h:i K",
                              //time_24hr: true,
                            }}
                            onChange={(e, value) => {
                              setEndTime(value);
                            }}
                            disabled
                          />
                        </InputGroup>
                        {endTimeRequiredMsg === "" ? null : (
                          <label
                            className={
                              endTimeRequiredMsg?.length === 0
                                ? ""
                                : "errorMsgGames invalid-feedback"
                            }
                          >
                            {endTimeRequiredMsg === ""
                              ? null
                              : endTimeRequiredMsg}
                          </label>
                        )}
                      </FormGroup>

                      <div className="mb-3">
                        <CreatePrizes
                          EditPrizeCount={price?.length}
                          EditPrizeData={price}
                          Edit={isEdit}
                          SetMaxPrizeCount={maxprizecount}
                          gameTypeMode={gameTypeMode}
                        />
                      </div>
                      <div className={show_raw ? "mb-3 showRaw" : "mb-3"}>
                        <Label>Game Rules*</Label>
                        <EditorToolbar
                          toolbarId="toolbar-1"
                          onClickRaw={handleClickShowRaw}
                        />
                        <ReactQuill
                          theme="snow"
                          modules={{
                            toolbar: {
                              container: `#toolbar-${1}`,
                            },
                            clipboard: {
                              matchVisual: false,
                            },
                          }}
                          formats={formats}
                          onChange={handleRules}
                          value={rules || ""}
                          placeholder="Enter game rules here"
                          // className="mb-3"
                          readOnly={isEdit}
                          style={
                            isEdit
                              ? { backgroundColor: "#eff2f7", opacity: "1" }
                              : null
                          }
                        />
                        <textarea
                          className={"raw-editor"}
                          onChange={(e) => handleChangeRaw(e.target.value)}
                          value={raw_html}
                        />
                        {ruleFieldError?.length === 0 ? null : (
                          <label
                            className={
                              ruleFieldError?.length === 0
                                ? ""
                                : "errorMsgGames invalid-feedback"
                            }
                          >
                            {ruleFieldError}
                          </label>
                        )}
                      </div>
                      <div
                        className={show_rawTournament ? "mb-3 showRaw" : "mb-3"}
                      >
                        <Label>Tournament Rules</Label>
                        <EditorToolbar
                          toolbarId="toolbar-2"
                          onClickRaw={handleClickShowRawTournament}
                        />
                        <ReactQuill
                          theme="snow"
                          modules={{
                            toolbar: {
                              container: `#toolbar-${2}`,
                            },
                            clipboard: {
                              matchVisual: false,
                            },
                          }}
                          formats={formats}
                          onChange={handleTournamentRules}
                          value={tournamentRules || ""}
                          placeholder="Enter tournaments rules here"
                          readOnly={isEdit}
                          style={
                            isEdit
                              ? { backgroundColor: "#eff2f7", opacity: "1" }
                              : null
                          }
                        />
                        <textarea
                          className={"raw-editor"}
                          onChange={(e) =>
                            handleChangeRawTournament(e.target.value)
                          }
                          value={raw_htmlTournament}
                        />
                        {/* {tournamentRuleFieldError?.length === 0 ? null : (
                          <label
                            className={
                              tournamentRuleFieldError?.length === 0
                                ? ""
                                : "errorMsgGames invalid-feedback"
                            }
                          >
                            {tournamentRuleFieldError}
                          </label>
                        )} */}
                      </div>
                      <div className="mt-3">
                        <Row className="align-items-center verified-check-row">
                          <Col className="col-lg-3 col-sm-3">
                            <p className="verified-check">Active Tournament</p>
                          </Col>
                          <Col className="col-lg-3 col-sm-3">
                            <Switch
                              uncheckedIcon={<Offsymbol />}
                              checkedIcon={<OnSymbol />}
                              onColor="#626ed4"
                              className={
                                activeStatus
                                  ? "form-label active-toggle"
                                  : "form-label"
                              }
                              onChange={() => {
                                setActiveStatus(!activeStatus);
                              }}
                              checked={activeStatus}
                              disabled={teamExistInTournament}
                            />
                          </Col>
                        </Row>
                      </div>
                      <div className="mt-3">
                        <Row className="align-items-center verified-check-row">
                          <Col className="col-lg-3 col-sm-3">
                            <p className="verified-check">Free Tournament</p>
                          </Col>
                          <Col className="col-lg-3 col-sm-3">
                            <Switch
                              uncheckedIcon={<Offsymbol />}
                              checkedIcon={<OnSymbol />}
                              onColor="#626ed4"
                              className={
                                freeTournament
                                  ? "form-label active-toggle"
                                  : "form-label"
                              }
                              onChange={() => {
                                setFreeTournament(!freeTournament);
                              }}
                              checked={freeTournament}
                              disabled={isEdit}
                            />
                          </Col>
                        </Row>
                      </div>
                      <FormGroup className="mt-4">
                        <div>
                          <Button
                            type="submit"
                            color="primary"
                            className="ms-1"
                            disabled={disableSubmit}
                          >
                            Submit
                          </Button>
                        </div>
                      </FormGroup>
                    </AvForm>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};
const mapStateToProps = (state) => {
  return {
    game: state.Games?.game,
  };
};
export default withRouter(connect(mapStateToProps)(AddTournaments));
