function FindPermission(trueValue) {
  let flattenArr = [];
  const filterAllowedPermission = trueValue.map((item, id) =>
    item.permissions.filter((value) => value.type_).map((data) => data.id)
  );
  filterAllowedPermission.forEach(function (flatItem) {
    flattenArr.push(...flatItem);
  });
  return flattenArr;
}

export default FindPermission;
