import { axiosApi } from "./index";

export async function getLobbyList(filterModel) {
  let params = new URLSearchParams();
  if (filterModel.sortBy) params.append("sort_by", filterModel.sortBy);
  if (filterModel.dateTo) params.append("to_date", filterModel.dateTo);
  if (filterModel.dateFrom) params.append("from_date", filterModel.dateFrom);
  if (filterModel.gameFilter) params.append("game", filterModel.gameFilter);
  if (filterModel.pageSize) params.append("page_size", filterModel.pageSize);
  if (filterModel.pageNumber) params.append("page", filterModel.pageNumber);
  if (filterModel.lobbyFilter) params.append("type", filterModel.lobbyFilter);
  if (filterModel.gameType) params.append("gametype", filterModel.gameType);

  return await axiosApi.get("lobby/", { params });
}

export async function getLobbyDetail(lobbyId) {
  return await axiosApi.get(`lobby/${lobbyId}/`);
}

export async function deleteLobby(lobbyId) {
  return await axiosApi.delete(`lobby/${lobbyId}/`);
}

export async function addLobby(model) {
  return await axiosApi.post(`lobby/`, model);
}

export async function editLobby(lobbyId, model) {
  return await axiosApi.put(`lobby/${lobbyId}/`, model);
}

export async function changeStatusLobby(lobbyId, lobbyStatus) {
  return await axiosApi.put(`lobby/${lobbyId}/change-activation/`, {
    status: lobbyStatus,
  });
}
export async function sendImageCount(data, lobbyId) {
  return await axiosApi.put(`lobby-files/${lobbyId}/`, data);
}
export async function sendUpdateList(data, lobbyId) {
  return await axiosApi.put(`lobby-stats-update/${lobbyId}/`, data);
}
export async function sendPublishList(data, lobbyId) {
  return await axiosApi.put(`lobby-stats-update/${lobbyId}/`, data);
}
export async function removeSoloPlayer(data) {
  return await axiosApi.put(`remove-player-from-lobby/`, data);
}

export async function removeTeam(data) {
  return await axiosApi.put(`remove-team-from-lobby/`, data);
}

export async function fetchStats(model) {
  return await axiosApi.post(`fetch-lobby-stats/`, model);
}

export async function cancelLobby(model) {
  return await axiosApi.post(`cancel-lobby/`, model);
}
