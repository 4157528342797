import { getEstDateObj } from "../../../helpers/util";

export function validateRounds(rounds, formValues, roundId) {
  const start_date = formValues[0].start_date;
  const start_time = formValues[0].start_time;
  const estStartDateTimeString = getESTDateTimeTimestamp(
    start_date,
    start_time
  );
  const oneandHalfHourInMilliseconds = 5400000; // 1ndhalf hour = 90 minutes * 60 seconds * 1000 milliseconds
  for (const round of rounds) {
    if (round.id === roundId) {
      continue;
    }

    if (round.id > roundId) {
      for (const match of round.matches) {
        for (const subMatch of match.sub_match) {
          const lobbyStartDatetime = convertRoundDateTimeToTimestamp(
            subMatch.lobby.start_date,
            subMatch.lobby.start_time
          );

          if (lobbyStartDatetime <= estStartDateTimeString) {
            throw new Error(
              "Start date and time should be less than the start date and time of subsequent rounds"
            );
          }
        }
      }
    } else {
      for (const match of round.matches) {
        for (const subMatch of match.sub_match) {
          const lobbyStartDatetime = convertRoundDateTimeToTimestamp(
            subMatch.lobby.start_date,
            subMatch.lobby.start_time
          );
          const timeDifference = Math.abs(
            lobbyStartDatetime - estStartDateTimeString
          );
          if (timeDifference < oneandHalfHourInMilliseconds) {
            throw new Error(
              "The time difference between successive rounds should be at least one and half hours."
            );
          }
          if (lobbyStartDatetime === estStartDateTimeString) {
            throw new Error("Rounds can't have the same date and time");
          } else if (lobbyStartDatetime >= estStartDateTimeString) {
            throw new Error(
              "Start date and time should be greater than previous rounds"
            );
          }
        }
      }
    }
  }
}
export function convertRoundDateTimeToTimestamp(date, time) {
  const [year, month, day] = date.split("-");
  const [timeString, amPM] = time.split(" ");
  const [hour, minute] = timeString.split(":");
  const estDateTime = new Date(
    Number(year),
    Number(month) - 1,
    Number(day),
    (hour % 12) + (amPM === "PM" ? 12 : 0),
    minute
  );
  return estDateTime.getTime();
}

export function getESTDateTimeTimestamp(date, time) {
  const startDateObj = new Date(date);
  const year = startDateObj.getFullYear();
  const month = startDateObj.getMonth();
  const day = startDateObj.getDate();

  if (time.includes("AM") || time.includes("PM")) {
    // 12-hour format
    const [rawTime, amPM] = time.split(" ");
    const [hours, minutes] = rawTime.split(":");
    let formattedHours = Number(hours);
    if (formattedHours === 12) {
      formattedHours = amPM === "AM" ? 0 : 12;
    } else {
      formattedHours += amPM === "PM" ? 12 : 0;
    }
    // if (formattedHours > 12) {
    //   formattedHours -= 12;
    // } else if (formattedHours === 0) {
    //   formattedHours = 12;
    // }
    const estDateTime = new Date(year, month, day, formattedHours, minutes);
    return estDateTime.getTime();
  } else {
    // 24-hour format
    const [hours, minutes] = time.split(":");
    const estDateTime = new Date(
      year,
      month,
      day,
      Number(hours),
      Number(minutes)
    );
    return estDateTime.getTime();
  }
}

// export function getESTDateTimeTimestamp(date, time) {
//   const startDateObj = new Date(date);
//   const year = startDateObj.getFullYear();
//   const month = startDateObj.getMonth();
//   const day = startDateObj.getDate();
//   const [rawTime] = time.split(" "); // Split the time (e.g., 23:32)
//   const [hours, minutes] = rawTime.split(":"); // Split the time into hours and minutes

//   // Convert hours to 12-hour format if it's in 24-hour format
//   let formattedHours = Number(hours);
//   if (formattedHours > 12) {
//     formattedHours -= 12;
//   } else if (formattedHours === 0) {
//     formattedHours = 12;
//   }
//   const estDateTime = new Date(year, month, day, formattedHours, minutes);
//   return estDateTime.getTime();
// }

export const convertDateFormat = (format) => {
  const [year, month, day] = format.split("-");
  const convertedDate = `${month}-${day}-${year}`;
  return convertedDate;
};
const uploadFileslug = [
  {
    id: 1,
    slug: "fortnite",
  },
  {
    id: 2,
    slug: "pubg-mobile",
  },
  {
    id: 3,
    slug: "cod-mobile",
  },
];
export const checkIfLinkIsNeeded = (gameslug) => {
  const matchingGame = uploadFileslug.find((game) => game.slug === gameslug);
  return !!matchingGame;
};
export const checkMatchDateTime = (
  tournamentStartDate,
  tournamentStartTime,
  matchStartDate,
  matchStartTime
) => {
  const tournamentStartDateTime = getESTDateTimeTimestamp(
    tournamentStartDate,
    tournamentStartTime
  );
  const matchStartDateTime = getESTDateTimeTimestampWithMinus10Minutes(
    matchStartDate,
    matchStartTime
  );

  if (matchStartDateTime <= tournamentStartDateTime) {
    const timeDifferenceMS = Math.abs(
      tournamentStartDateTime - matchStartDateTime
    );

    // Convert milliseconds to minutes
    const timeDifferenceMinutes = timeDifferenceMS / (60 * 1000); // 1 minute = 60 seconds * 1000 milliseconds
    // Check if the time difference is exactly 0 minutes
    if (timeDifferenceMinutes === 0) {
      throw new Error(
        "Match start/last datetime and tournament start/last datetime can't be same."
      );
    }
    // Calculate hours and remaining minutes
    const hours = Math.floor(timeDifferenceMinutes / 60);
    const minutes = Math.floor(timeDifferenceMinutes % 60);

    let timeDifferenceMsg = "";
    if (hours > 0) {
      timeDifferenceMsg += hours + " hour";
      if (hours > 1) timeDifferenceMsg += "s";
    }

    if (minutes > 0) {
      if (timeDifferenceMsg !== "") timeDifferenceMsg += " ";
      timeDifferenceMsg += minutes + " minute";
      if (minutes > 1) timeDifferenceMsg += "s";
    }
    const errorMessage =
      "Match start/last datetime should be greater than the tournament start/last datetime. ";
    // +
    // "Time difference: " +
    //  timeDifferenceMsg;

    throw new Error(errorMessage);
  }

  return true;
};
export const findMaxSubMatchLength = (roundData, roundId) => {
  let subMatchMaxLength = null;

  function iterateRounds(arr) {
    arr.forEach((item) => {
      if (item.id === roundId) {
        if (item.matches && item.matches.length > 0) {
          item.matches.forEach((match) => {
            if (match.sub_match && match.sub_match.length > subMatchMaxLength) {
              subMatchMaxLength = match.sub_match.length;
            }
          });
        }
      }

      if (item.matches) {
        iterateRounds(item.matches);
      }
    });
  }

  iterateRounds(roundData);

  return subMatchMaxLength;
};

export const getESTDateTimeTimestampWithMinus10Minutes = (date, time) => {
  const startDateObj = new Date(date);
  const year = startDateObj.getFullYear();
  const month = startDateObj.getMonth();
  const day = startDateObj.getDate();
  const [hours, minutes] = time.split(":");
  const estDateTime = new Date(
    year,
    month,
    day,
    Number(hours),
    Number(minutes)
  );

  const newTimeInMilliseconds = estDateTime.getTime() - 10 * 60 * 1000;
  // Create a new Date object using the updated time
  const updatedEstDateTime = new Date(newTimeInMilliseconds);
  return updatedEstDateTime.getTime();
};
export const checkLastEntryTime = (matchStartDate, matchStartTime) => {
  const estCurrentDateTime = getEstDateObj(new Date(), "America/New_York");
  const currentESTTimestamp = estCurrentDateTime.getTime();
  const lastEntryTimestamp = getESTDateTimeTimestampWithMinus10Minutes(
    matchStartDate,
    matchStartTime
  );
  if (lastEntryTimestamp <= currentESTTimestamp) {
    throw new Error(
      "Last entry time should be greater than the current EST time."
    );
  }

  return true;
};
export const checkLastEntryTimeForSubmatchesEdit = (
  startDate,
  lastEntryTime
) => {
  const estCurrentDateTime = getEstDateObj(new Date(), "America/New_York");
  const currentESTTimestamp = estCurrentDateTime.getTime();
  const lastEntryTimestamp = getESTDateTimeTimestamp(startDate, lastEntryTime);
  if (lastEntryTimestamp >= currentESTTimestamp) {
    return true;
  } else {
    return false;
  }
};
export const convertTo24HourFormat = (time) => {
  const timeParts = time.split(" ")[0].split(":");
  const hours = parseInt(timeParts[0]);
  const minutes = parseInt(timeParts[1]);
  const ampm = time.split(" ")[1];
  let convertedHours = hours;
  if (ampm === "PM" && hours !== 12) {
    convertedHours += 12;
  } else if (ampm === "AM" && hours === 12) {
    convertedHours = 0;
  }
  return `${convertedHours.toString().padStart(2, "0")}:${minutes
    .toString()
    .padStart(2, "0")}`;
};
