import React from "react";
import AddManualWinner from "../Form/AddManualWinner";
import { isEmpty } from "lodash";
const ManuallyAddWinnerModal = ({
  teams,
  matchId,
  gameTypeIsTraditional,
  successCallBack,
  closeAddWinnerModal,
  threshold,
}) => {
  return (
    <>
      {isEmpty(teams) ? (
        "No Team Found"
      ) : (
        <AddManualWinner
          teams={teams}
          matchId={matchId}
          isTraditional={gameTypeIsTraditional}
          successCallBack={successCallBack}
          closeAddWinnerModal={closeAddWinnerModal}
          threshold={threshold}
        />
      )}
    </>
  );
};

export default ManuallyAddWinnerModal;
