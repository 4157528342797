import { AvField, AvForm } from "availity-reactstrap-validation";
import React from "react";
import { Button, CardTitle, FormGroup } from "reactstrap";
import backIcon from "../../assets/images/arrow-left.svg";

const EditTopic = ({
  enableListView,
  editQuestionData = {},
  editTopicFAQ,
  data,
}) => {
  const resetForm = () => {
    enableListView();
  };

  return (
    <>
      <CardTitle>
        <div style={{ cursor: "pointer" }} onClick={enableListView}>
          <img alt="back-icon" src={backIcon} />
          <span
            style={{
              display: "inline-block",
              marginLeft: "4px",
              color: "black",
            }}
          >
            back
          </span>
        </div>
      </CardTitle>
      <div className="faq-box d-flex flex-column align-items-start mb-4 pl-3">
        <div>
          <AvForm
            onValidSubmit={(e, v) => {
              editTopicFAQ({
                payload: {
                  name: v.topic,
                },
                topicSlug: editQuestionData.topic.slug,
                callback: resetForm,
              });
            }}
          >
            <div className="mb-3">
              <AvField
                key={editQuestionData.topic.id}
                name="topic"
                label="Topic"
                placeholder="Enter topic here"
                type="text"
                value={editQuestionData?.topic.name}
                validate={{
                  required: {
                    value: true,
                    errorMessage: "Topic is required",
                  },
                  maxLength: {
                    value: 50,
                    errorMessage: "Topic can have 50 characters max",
                  },
                }}
                disabled={!data.change}
              />
            </div>
            {data.change ? (
              <FormGroup style={{ marginTop: "20px" }}>
                <div>
                  <Button type="submit" color="primary" className="ms-1 mr-2">
                    Submit
                  </Button>
                  <Button
                    onClick={resetForm}
                    type="reset"
                    color="secondary"
                    outline
                    className="ms-1"
                  >
                    Cancel
                  </Button>
                </div>
              </FormGroup>
            ) : null}
          </AvForm>
        </div>
      </div>
    </>
  );
};

export default EditTopic;
