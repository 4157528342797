import React from "react";

// Formats objects for setting up the Quill editor
export const formats = [
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "align",
  "strike",
  "script",
  "blockquote",
  "background",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
  "color",
  "code-block",
];

const CustomButton = () => <span className="octicon octicon-star" />;
const CustomButtonRaw = () => <span className="octicon octicon-star" />;
// Quill Toolbar component
export const QuillToolbar = ({ toolbarId, onClickRaw }) => {
  return (
    <div id={toolbarId}>
      <span className="ql-formats">
        <select className="ql-font" defaultValue="arial">
          <option value="arial">Arial</option>
          <option value="comic-sans">Comic Sans</option>
          <option value="courier-new">Courier New</option>
          <option value="georgia">Georgia</option>
          <option value="helvetica">Helvetica</option>
          <option value="lucida">Lucida</option>
        </select>
        <select className="ql-size" defaultValue="medium">
          <option value="extra-small">Size 1</option>
          <option value="small">Size 2</option>
          <option value="medium">Size 3</option>
          <option value="large">Size 4</option>
        </select>
        <select className="ql-header" defaultValue="3">
          <option value="1">Heading</option>
          <option value="2">Subheading</option>
          <option value="3">Normal</option>
        </select>
      </span>
      <span className="ql-formats">
        <button className="ql-bold" />
        <button className="ql-italic" />
        <button className="ql-underline" />
        <button className="ql-strike" />
      </span>
      <span className="ql-formats">
        <button className="ql-list" value="ordered" />
        <button className="ql-list" value="bullet" />
        <button className="ql-indent" value="-1" />
        <button className="ql-indent" value="+1" />
      </span>
      <span className="ql-formats">
        <button className="ql-script" value="super" />
        <button className="ql-script" value="sub" />
        <button className="ql-blockquote" />
        <button className="ql-direction" />
      </span>
      <span className="ql-formats">
        <select className="ql-align" />
        <select className="ql-color">
          <option value="#00cdff" />
          <option value="#FFFFFF" />
          <option value="#C0C0C0" />
          <option value="#808080" />
          <option value="#000000" />
          <option value="#FFA500" />
          <option value="#A52A2A" />
          <option value="#800000" />
          <option value="#008000" />
          <option value="#808000" />
          <option value="#7FFFD4" />
          <option value="#FF0000" />
          <option value="#00FFFF" />
          <option value="#0000FF" />
          <option value="#00008B" />
          <option value="#ADD8E6	" />
          <option value="#800080" />
          <option value="#FFFF00" />
          <option value="#00FF00" />
          <option value="#FF00FF" />
          <option value="#FFC0CB" />
        </select>
      </span>
      <span className="ql-formats">
        <button className="ql-link" />
        <button className="ql-image" />
        <button className="ql-video" />
      </span>
      <span className="ql-formats">
        <button className="ql-formula" />
        <button className="ql-code-block" />
        <button className="ql-clean" />
      </span>

      <button type="button" onClick={onClickRaw}>
        Raw
      </button>
    </div>
  );
};

export default QuillToolbar;
