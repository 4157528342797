import React, { useEffect, useRef, useState } from "react";
import {
  deleteQuestion,
  editQuestion,
  getQuestions,
  postQuestion,
} from "../../services/cms_api_helper";
import { toast } from "react-toastify";
import toastrOptions from "../../helpers/toastr-options/toastr-options";
import Loader from "../../components/Common/Loader";
import AddQuestion from "./AddQuestion";
import QuestionList from "./QuestionList";
import { Button, CardTitle } from "reactstrap";
import { DragDropContext } from "react-beautiful-dnd";
function PPK(props) {
  const [listView, setListView] = useState(true);
  const [isEdit, setIsEdit] = useState(false);
  const topicId = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [questions, setQuestions] = useState([]);
  const [editQuestionData, setEditQuestionData] = useState({});
  const getPPKQuestions = async () => {
    try {
      setIsLoading(true);
      const filter = { filter: "PPK" };
      const res = await getQuestions(filter);
      setIsLoading(false);
      topicId.current = res?.results?.[0]?.id;
      setQuestions(res?.results?.[0]?.category?.questions);
    } catch (error) {
      setIsLoading(false);
      toast.error(error, toastrOptions);
    }
  };

  const postQuestionPPK = async ({ filter, callback }) => {
    try {
      setIsLoading(true);
      await postQuestion(filter);
      callback();
      setIsLoading(false);

      toast.success("Question Added Successfully", toastrOptions);

      getPPKQuestions();
    } catch (error) {
      setIsLoading(false);
      toast.error(error, toastrOptions);
    }
  };

  const deleteQuestionPPK = async ({ questionId, callback }) => {
    try {
      setIsLoading(true);
      await deleteQuestion({ questionId });

      toast.success("Question Deleted Successfully", toastrOptions);
      getPPKQuestions();
    } catch (error) {
      callback();
      setIsLoading(false);
      toast.error(error, toastrOptions);
    }
  };

  const editQuestionPPK = async ({ payload, questionId }) => {
    try {
      setIsLoading(true);
      await editQuestion({ payload, questionId });
      disableEditView();
      toast.success("Question Edited Successfully", toastrOptions);
      getPPKQuestions();
    } catch (error) {
      disableEditView();
      setIsLoading(false);
      toast.error(error, toastrOptions);
    }
  };

  const reorderQuestionPPK = async ({ payload, questionId }) => {
    try {
      await editQuestion({ payload, questionId });
      const filter = { filter: "PPK" };
      setIsLoading(true);
      const res = await getQuestions(filter);
      topicId.current = res?.results?.[0]?.id;
      setQuestions(res?.results?.[0]?.category?.questions);
      setIsLoading(false);
      toast.success("Question Reorder Successfully", toastrOptions);
    } catch (error) {
      toast.error(error, toastrOptions);
      setIsLoading(false);
    }
  };

  const enableEditView = ({ questionData }) => {
    setListView(false);
    setIsEdit(true);
    setEditQuestionData(questionData);
  };

  const disableEditView = () => {
    setListView(true);
    setIsEdit(false);
  };

  const enableListView = () => {
    setListView(true);
    setIsEdit(false);
  };
  const enableAddQuestionView = () => setListView(false);

  // a little function to help us with reordering the result
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const source = questions[result.source.index];
    const destination = questions[result.destination.index];
    const temp = [...questions];
    const payload = {
      sort_order: destination?.sort_order,
    };
    const questionId = source?.id;
    reorderQuestionPPK({ payload, questionId });

    const items = reorder(temp, result.source.index, result.destination.index);

    setQuestions(items);
  };
  useEffect(() => {
    if (props?.data?.view) {
      getPPKQuestions();
    }
  }, [props.data?.view]);

  return (
    <>
      <Loader showLoader={isLoading} />

      {listView ? (
        <>
          <CardTitle className="d-flex justify-content-between align-items-start mb-4">
            <span className="cms-ppk">What’s PPK</span>
            <div className="add-new-filter">
              {props?.data?.add ? (
                <Button
                  type="submit"
                  color="primary"
                  onClick={enableAddQuestionView}
                >
                  Add New
                </Button>
              ) : null}
            </div>
          </CardTitle>
          <DragDropContext onDragEnd={onDragEnd}>
            {questions?.length ? (
              <QuestionList
                enableAddQuestionView={enableAddQuestionView}
                questions={questions}
                enableEditView={enableEditView}
                deleteQuestion={deleteQuestionPPK}
                data={props.data}
              />
            ) : (
              <span>Not any questions added</span>
            )}
          </DragDropContext>
        </>
      ) : (
        <AddQuestion
          isEdit={isEdit}
          enableListView={enableListView}
          editQuestionData={editQuestionData}
          topicId={topicId.current}
          postQuestion={postQuestionPPK}
          editQuestion={editQuestionPPK}
          data={props.data}
        />
      )}
    </>
  );
}

export default PPK;
