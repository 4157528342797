import { sortBy } from "lodash";
import {
  challengePermissionsGames,
  checkIfGamePermissionIsAvailable,
  permissionConstant,
  permissionConstantSecond,
  permissionGameConstantForLobbies,
  retirevePath,
} from "../constants/permissionConstant";

import challenges from "../../src/assets/images/challenges.svg";
import leaderboard from "../../src/assets/images/leaderboard.svg";


function PermissionPath() {
  const obj = JSON.parse(localStorage.getItem("authUser"));
  const notFound =
    obj === null
      ? true
      : obj.extras?.permissions.some((dataCheck) => {
          return dataCheck.label === "Static Page";
        });
  const gamePermissions = checkIfGamePermissionIsAvailable(obj);
  const path = retirevePath(
    obj,
    notFound,
    permissionConstant,
    permissionConstantSecond
  );

  //original name newPath
  let newPath =
    gamePermissions.length != 0
      ? [...path, [permissionGameConstantForLobbies[0]]]
      : path;

  const challengePath = challengePermissionsGames(gamePermissions);

  //remove after challenges permission
  const chal = [
    {
      permissionName: "Challenges",
      pathname: "/challenges",
      label: "Challenges",
      icon: challenges,
      order: 15,
    },
    {
      permissionName: "Leaderboard",
      pathname: "/leaderboard",
      label: "Leaderboard",
      icon: leaderboard,
      order: 17,
    },
  ];
  newPath = challengePath.length ? [...newPath, chal] : newPath;
  if (newPath === null || newPath === "/login") {
    return null;
  } else if (newPath !== null || newPath !== "/login") {
    const extractArrayOfArrayPath = [];
    newPath.forEach((data) =>
      data.forEach((destructure) => extractArrayOfArrayPath.push(destructure))
    );
    const sortResult = sortBy(extractArrayOfArrayPath, ["order"], ["asc"]);
    return sortResult;
  } else {
    const extractArrayOfArrayPath = [];
    newPath.forEach((data) =>
      data.forEach((destructure) => extractArrayOfArrayPath.push(destructure))
    );
    return extractArrayOfArrayPath;
  }
}

export default PermissionPath;
