import React, { useCallback, useState } from "react";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import FilterPermission from "../../helpers/FilterPermission";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import { filterOutPermissionToShowHide } from "../../helpers/PermissionUtils";

import { getTournamentList } from "../../services/bracket_tournaments_api_helper";
import {
  bracketStyleOptionsTraditonal,
  bracketStyleOptionsPPK,
  seedingOptions,
  freeTournamentStatusOption,
  dateTimeFormatter,
  competitionStatus,
  getFilterText,
  getFilterGame,
  getFilterTextTournament,
  gameTypeFiltersTournaments,
  tournamentStatusOption,
  seedingOptionsPPK,
} from "../../helpers/util";
import Paginator from "../../helpers/paginator/paginator";
import ReactDatePicker from "react-datepicker";
import { format } from "date-fns";
import calenderIcon from "../../assets/images/calendar.svg";
import { useSelector } from "react-redux";
import ClearButton from "../../components/ClearButton";
import DeleteAlertModal from "../../components/Common/DeleteAlertModal";
import PrizeList from "./Common/PrizeList";
import { uniqBy } from "lodash";

import edit from "../../assets/images/editTournament.svg";
import disableEditIcon from "../../assets/images/disableEditIcon.svg";
import addSchedule from "../../assets/images/add-schedule.svg";
import disableSchedule from "../../assets/images/addscheduledisable.svg";
import view from "../../assets/images/view.svg";
import deleteIcon from "../../assets/images/delete.svg";
import deleteDisable from "../../assets/images/deleteDisable.svg";

const TournamentsList = (props) => {
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [addPermission, setAddPermission] = useState(false);
  const [changePermission, setChangePermission] = useState(false);
  const [deletePermission, setDeletePermission] = useState(false);
  const [showLoadder, setShowLoadder] = useState(false);
  const [tournamentLists, setTournamentLists] = useState([]);
  const [totalCount, setTotalCount] = useState(null);
  const pageSize = 25;
  const [pageNumber, setpageNumber] = useState(1);
  const [gameTypeFilterOpen, setGameTypeFilterOpen] = useState(false);
  const [
    selectedDropdownByGameTypeFilter,
    setselectedDropdownByGameTypeFilter,
  ] = useState(1);
  const [selectedDropdownByGameName, setselectedDropdownByGameName] =
    useState(null);
  const [selectedDropdownByBracket, setselectedDropdownByBracket] =
    useState(null);
  const [selectedDropdownBySeeding, setselectedDropdownBySeeding] =
    useState(null);
  const [selectedDropdownByStatusFilter, setselectedDropdownByStatusFilter] =
    useState(null);
  const [selectedDropdownByFreeFilter, setselectedDropdownByFreeFilter] =
    useState(null);

  const [singlebtnGame, setSinglebtnGame] = useState(false);
  const [singlebtnBracket, setSinglebtnBracket] = useState(false);
  const [singlebtnSeeding, setSinglebtnSeeding] = useState(false);
  const [statusFilter, setStatusFilter] = useState(false);
  const [freeFilter, setFreeFilter] = useState(false);
  const game = useSelector((state) => state.Games.game);
  useEffect(() => {
    callSetPermission();
    tournamentList();
  }, [
    pageNumber,
    dateRange,
    selectedDropdownByGameTypeFilter,
    selectedDropdownByGameName,
    selectedDropdownByBracket,
    selectedDropdownBySeeding,
    selectedDropdownByFreeFilter,
    selectedDropdownByStatusFilter,
  ]);
  const tournamentList = async () => {
    setShowLoadder(true);
    const filters = filterModel();
    await getTournamentList(filters)
      .then((res) => {
        setTournamentLists(res.data);
        setTotalCount(res.total);
        setShowLoadder(false);
      })
      .catch(() => {
        setShowLoadder(false);
      });
  };
  const dropdownChangeByGameTypeFilter = (value) => {
    setselectedDropdownByGameTypeFilter(value);
    setselectedDropdownByGameName(null);
    setselectedDropdownByBracket(null);
    setpageNumber(1);
  };
  const dropdownChangeByGameName = (value) => {
    setselectedDropdownByGameName(value);
    setpageNumber(1);
  };
  const dropdownChangeByBracket = (value) => {
    setselectedDropdownByBracket(value);
    if (value === 3 && selectedDropdownBySeeding !== 5)
      setselectedDropdownBySeeding(null);
    setpageNumber(1);
  };
  const dropdownChangeBySeeding = (value) => {
    setselectedDropdownBySeeding(value);
    setpageNumber(1);
  };
  const dropdownChangeByStatusFilter = (value) => {
    setselectedDropdownByStatusFilter(value);
    setpageNumber(1);
  };
  const dropdownChangeByFreeFilter = (value) => {
    setselectedDropdownByFreeFilter(value);
    setpageNumber(1);
  };
  function filterModel() {
    let model = {
      pageNumber: pageNumber,
      pageSize: pageSize,
      from_date: dateRange[0] && format(dateRange[0], "yyyy-MM-dd"),
      to_date: dateRange[1] && format(dateRange[1], "yyyy-MM-dd"),
      gameType: selectedDropdownByGameTypeFilter,
      game: selectedDropdownByGameName,
      bracket: selectedDropdownByBracket,
      seeding: selectedDropdownBySeeding,
      free: selectedDropdownByFreeFilter,
      type: selectedDropdownByStatusFilter,
    };
    return model;
  }
  const callSetPermission = () => {
    const type = "Tournament";
    const typeAdd = "add_tournament";
    const typeChange = "change_tournament";
    const typeDelete = "delete_tournament";

    const filteredPermission = FilterPermission(props.permission, type);
    if (filteredPermission.length !== 0) {
      const setAdd = filterOutPermissionToShowHide(
        filteredPermission[0].permissions,
        typeAdd
      );
      const setChange = filterOutPermissionToShowHide(
        filteredPermission[0].permissions,
        typeChange
      );
      const setDelete = filterOutPermissionToShowHide(
        filteredPermission[0].permissions,
        typeDelete
      );
      setAddPermission(setAdd);
      setChangePermission(setChange);
      setDeletePermission(setDelete);
    }
  };
  const handlePageClick = (pageNum) => {
    setpageNumber(pageNum);
  };
  const handleChange = useCallback((dates) => {
    const [start, end] = dates;
    if ((start && end) || (!start && !end)) {
      setpageNumber(1);
      setDateRange(dates);
    }

    setStartDate(start);
    setEndDate(end);
  }, []);
  const handleClear = () => {
    setpageNumber(1);
    setDateRange([null, null]);
    setStartDate(null);
    setEndDate(null);
    setselectedDropdownByGameTypeFilter(null);
    setselectedDropdownByGameName(null);
    setselectedDropdownByBracket(null);
    setselectedDropdownBySeeding(null);
    setselectedDropdownByStatusFilter(null);
    setselectedDropdownByFreeFilter(null);
  };
  const [delIndex, setIndex] = useState(null);
  const [deleteId, setDeleteId] = useState(null);
  const [deleteModal, setdeleteModal] = useState(false);
  const [currentStatus, setCurrentStatus] = useState(null);
  const handleCLoseAlert = () => {
    setdeleteModal(false);
    setDeleteId(null);
    setIndex(null);
    setCurrentStatus(null);
  };
  const handleDelete = (tournamentId, index, currentStat) => {
    setIndex(index);
    setDeleteId(tournamentId);
    setCurrentStatus(currentStat);
    setdeleteModal(true);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Breadcrumbs breadcrumbItem="Manage Tournaments" />
        <Row>
          <Col className="col-12">
            <Card>
              <CardBody>
                <div className="search-box tournament-filter d-flex align-items-center flex-wrap mb-4">
                  <div className="date-picker">
                    <ReactDatePicker
                      className="new-filter datepicker-width w-100"
                      selectsRange={true}
                      startDate={startDate}
                      endDate={endDate}
                      dateFormat="MM/dd/yyyy"
                      onChange={handleChange}
                      placeholderText="MM/DD/YYYY-MM/DD/YYYY"
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      yearDropdownItemNumber={6}
                      isClearable
                      customInput={
                        <CustomInput startDate={startDate} endDate={endDate} />
                      }
                    />
                  </div>
                  <Dropdown
                    isOpen={gameTypeFilterOpen}
                    toggle={() => setGameTypeFilterOpen(!gameTypeFilterOpen)}
                  >
                    <DropdownToggle
                      caret
                      color="primary"
                      className="dropdown-toggle-split sort-by-btn"
                    >
                      {/* <i className="mdi mdi-filter-outline"></i> */}
                      <span>
                        {selectedDropdownByGameTypeFilter === null
                          ? "Game Type"
                          : getFilterText(
                              gameTypeFiltersTournaments,
                              selectedDropdownByGameTypeFilter
                            )}
                      </span>
                      <i className="mdi mdi-chevron-down" />
                    </DropdownToggle>
                    <DropdownMenu>
                      {gameTypeFiltersTournaments.map((item, index) => {
                        return (
                          <DropdownItem
                            key={index}
                            onClick={() =>
                              dropdownChangeByGameTypeFilter(item.value)
                            }
                            dropdownvalue={item.value}
                          >
                            {item.text}
                            &nbsp; &nbsp;
                            {selectedDropdownByGameTypeFilter === item.value ? (
                              <i className="mdi mdi-check-circle-outline"></i>
                            ) : null}
                          </DropdownItem>
                        );
                      })}
                    </DropdownMenu>
                  </Dropdown>
                  <Dropdown
                    isOpen={singlebtnGame}
                    toggle={() => setSinglebtnGame(!singlebtnGame)}
                  >
                    <DropdownToggle
                      caret
                      color="primary"
                      className="dropdown-toggle-split sort-by-btn"
                    >
                      <span>
                        {selectedDropdownByGameName === null
                          ? "Game"
                          : getFilterGame(
                              game?.modeObj?.[
                                selectedDropdownByGameTypeFilter
                              ] ||
                                uniqBy(
                                  [...game.ppkGames, ...game.traditionalGames],
                                  "id"
                                ),
                              selectedDropdownByGameName
                            )}
                      </span>
                      <i className="mdi mdi-chevron-down" />
                    </DropdownToggle>
                    <DropdownMenu>
                      {(selectedDropdownByGameTypeFilter
                        ? game?.modeObj?.[selectedDropdownByGameTypeFilter]
                        : uniqBy(
                            [...game.ppkGames, ...game.traditionalGames],
                            "id"
                          )
                      )?.map((item, index) => {
                        return (
                          <DropdownItem
                            key={index}
                            onClick={() => dropdownChangeByGameName(item.id)}
                            dropdownvalue={item.id}
                            disabled={item.game_status === 2 ? true : false}
                          >
                            {item.name}
                            &nbsp; &nbsp;
                            {selectedDropdownByGameName === item.id ? (
                              <i className="mdi mdi-check-circle-outline"></i>
                            ) : null}
                          </DropdownItem>
                        );
                      })}
                    </DropdownMenu>
                  </Dropdown>
                  <Dropdown
                    isOpen={singlebtnBracket}
                    toggle={() => setSinglebtnBracket(!singlebtnBracket)}
                  >
                    <DropdownToggle
                      caret
                      color="primary"
                      className="dropdown-toggle-split sort-by-btn"
                    >
                      <span>
                        {selectedDropdownByBracket === null
                          ? "Bracket Style"
                          : getFilterTextTournament(
                              [
                                ...bracketStyleOptionsPPK,
                                ...bracketStyleOptionsTraditonal,
                              ],
                              selectedDropdownByBracket
                            )}
                      </span>
                      <i className="mdi mdi-chevron-down" />
                    </DropdownToggle>
                    <DropdownMenu>
                      {(selectedDropdownByGameTypeFilter == 1
                        ? bracketStyleOptionsPPK
                        : bracketStyleOptionsTraditonal
                      ).map((item, index) => {
                        return (
                          <DropdownItem
                            key={index}
                            onClick={() => dropdownChangeByBracket(item.value)}
                            dropdownvalue={item.value}
                          >
                            {item.label}
                            &nbsp; &nbsp;
                            {selectedDropdownByBracket === item.value ? (
                              <i className="mdi mdi-check-circle-outline"></i>
                            ) : null}
                          </DropdownItem>
                        );
                      })}
                    </DropdownMenu>
                  </Dropdown>
                  <Dropdown
                    isOpen={singlebtnSeeding}
                    toggle={() => setSinglebtnSeeding(!singlebtnSeeding)}
                  >
                    <DropdownToggle
                      caret
                      color="primary"
                      className="dropdown-toggle-split sort-by-btn"
                    >
                      <span>
                        {selectedDropdownBySeeding === null
                          ? "Seeding"
                          : getFilterTextTournament(
                              seedingOptions,
                              selectedDropdownBySeeding
                            )}
                      </span>
                      <i className="mdi mdi-chevron-down" />
                    </DropdownToggle>
                    <DropdownMenu>
                      {(selectedDropdownByGameTypeFilter == 1 ||
                      selectedDropdownByBracket === 3
                        ? seedingOptionsPPK
                        : seedingOptions
                      ).map((item, index) => {
                        return (
                          <DropdownItem
                            key={index}
                            onClick={() => dropdownChangeBySeeding(item.value)}
                            dropdownvalue={item.value}
                          >
                            {item.label}
                            &nbsp; &nbsp;
                            {selectedDropdownBySeeding === item.value ? (
                              <i className="mdi mdi-check-circle-outline"></i>
                            ) : null}
                          </DropdownItem>
                        );
                      })}
                    </DropdownMenu>
                  </Dropdown>
                  <Dropdown
                    isOpen={statusFilter}
                    toggle={() => setStatusFilter(!statusFilter)}
                  >
                    <DropdownToggle
                      caret
                      color="primary"
                      className="dropdown-toggle-split sort-by-btn"
                    >
                      <span>
                        {selectedDropdownByStatusFilter === null
                          ? "Tournament Status"
                          : getFilterText(
                              tournamentStatusOption,
                              selectedDropdownByStatusFilter
                            )}
                      </span>
                      <i className="mdi mdi-chevron-down" />
                    </DropdownToggle>
                    <DropdownMenu>
                      {tournamentStatusOption.map((item, index) => {
                        return (
                          <DropdownItem
                            key={index}
                            onClick={() =>
                              dropdownChangeByStatusFilter(item.value)
                            }
                            dropdownvalue={item.value}
                          >
                            {item.text}
                            &nbsp; &nbsp;
                            {selectedDropdownByStatusFilter === item.value ? (
                              <i className="mdi mdi-check-circle-outline"></i>
                            ) : null}
                          </DropdownItem>
                        );
                      })}
                    </DropdownMenu>
                  </Dropdown>

                  <Dropdown
                    isOpen={freeFilter}
                    toggle={() => setFreeFilter(!freeFilter)}
                  >
                    <DropdownToggle
                      caret
                      color="primary"
                      className="dropdown-toggle-split sort-by-btn"
                    >
                      <span>
                        {selectedDropdownByFreeFilter === null
                          ? "Free Tournament"
                          : getFilterText(
                              freeTournamentStatusOption,
                              selectedDropdownByFreeFilter
                            )}
                      </span>
                      <i className="mdi mdi-chevron-down" />
                    </DropdownToggle>
                    <DropdownMenu>
                      {freeTournamentStatusOption.map((item, index) => {
                        return (
                          <DropdownItem
                            key={index}
                            onClick={() =>
                              dropdownChangeByFreeFilter(item.value)
                            }
                            dropdownvalue={item.value}
                          >
                            {item.text}
                            &nbsp; &nbsp;
                            {selectedDropdownByFreeFilter === item.value ? (
                              <i className="mdi mdi-check-circle-outline"></i>
                            ) : null}
                          </DropdownItem>
                        );
                      })}
                    </DropdownMenu>
                  </Dropdown>
                  <ClearButton handleClear={handleClear} />
                  {addPermission ? (
                    <Link
                      to="/tournament/add"
                      className="add-btn-tournament add-btn"
                    >
                      <button className="btn btn-primary add-lobby-btn">
                        {" "}
                        Add Tournament{" "}
                      </button>
                    </Link>
                  ) : null}
                </div>
                <div className="table-rep-plugin">
                  <div
                    className="table-responsive mb-0"
                    data-pattern="priority-columns"
                  >
                    <div className="table-responsive seize-wallet-table">
                      <table className="table table-striped table-bordered manage-complaints-table lobbies-table tournament-list">
                        <thead>
                          <tr>
                            <th scope="col" rowspan="2">
                              Tournament Name
                            </th>
                            <th scope="col" rowspan="2">
                              Game
                            </th>
                            <th scope="col" rowspan="2">
                              Game Type
                            </th>
                            <th scope="col" rowspan="2">
                              Entry Fee/ Player
                            </th>
                            <th scope="col" rowspan="2">
                              Minimum Teams
                            </th>
                            <th scope="col" rowspan="2">
                              Maximum Teams
                            </th>
                            <th
                              scope="col"
                              colspan="4"
                              style={{ textAlign: "center" }}
                            >
                              Schedule
                            </th>
                            <th scope="col" rowspan="2">
                              Bracket Styles
                            </th>
                            <th scope="col" rowspan="2">
                              Seeding
                            </th>
                            <th scope="col" rowspan="2">
                              Prize
                            </th>
                            <th scope="col" rowspan="2">
                              Active Tournament
                            </th>
                            <th scope="col" rowspan="2">
                              Free Tournament
                            </th>
                            <th scope="col" rowspan="2">
                              Bracket Status
                            </th>
                            <th scope="col" rowspan="2">
                              Tournament Status
                            </th>
                            {changePermission || deletePermission ? (
                              <th scope="col" rowspan="2" colSpan="4">
                                Actions
                              </th>
                            ) : null}
                          </tr>
                          <tr>
                            <th>Start Date Time</th>
                            <th>End Date Time</th>
                            <th>Players per Team</th>
                            <th>Enrolled Teams</th>
                          </tr>
                        </thead>
                        <tbody>
                          {showLoadder ? (
                            <div
                              class="spinner-grow transaction-spinner"
                              role="status"
                            >
                              <span class="sr-only">Loading...</span>
                            </div>
                          ) : tournamentLists.length === 0 ? (
                            <tr>
                              <td colspan="20" className="text-center mt-4">
                                No Tournament(s) Found
                              </td>
                            </tr>
                          ) : (
                            tournamentLists.map((item, index) => (
                              <tr key={index}>
                                <td>{item.name}</td>
                                <td>{item.game}</td>
                                <td>{item.game_type}</td>
                                <td>{item.entry_fee}</td>
                                <td>{item.min_team}</td>
                                <td>{item.max_team}</td>
                                <td>
                                  {dateTimeFormatter(
                                    item.start_date + " " + item.start_time
                                  )}
                                  {/* {item.start_date} {item.start_time} */}
                                </td>
                                <td>
                                  {item.end_date === null &&
                                  item.end_time === null
                                    ? "NA"
                                    : item.end_date + item.end_time}
                                </td>
                                <td>{item.player_per_team}</td>
                                <td>{item.enrolled_teams}</td>
                                <td>{item.bracket}</td>
                                <td>{item.seeding}</td>
                                <td>
                                  {item.price.length === 0 ? (
                                    "NA"
                                  ) : (
                                    <PrizeList prize={item.price} />
                                  )}
                                </td>
                                <td>{item.active ? "Yes" : "No"}</td>
                                <td>{item.free ? "Yes" : "No"}</td>
                                <td>
                                  {item.status === 1
                                    ? "New"
                                    : "Bracket created"}
                                </td>
                                <td>
                                  {competitionStatus[item.current_status]}
                                </td>
                                {changePermission ? (
                                  <>
                                    {item.status === 1 ? (
                                      <>
                                        <td className="Action-td">
                                          <Button className="btn btn-sm btn-secondary info-icon btn-tournament complaint-view-btn action-td-btn">
                                            <span
                                              className="tooltipTournament tooltiptext"
                                              data-tip
                                              data-for="loginTip"
                                            >
                                              {item.game_type === "Traditional"
                                                ? "Admin can add schedule only when enrollment end time is reached"
                                                : "Admin can add schedule after the 15 mins of tournament enrollment end time"}
                                            </span>

                                            <img
                                              alt="disableSchedule-Admin-icon"
                                              src={disableSchedule}
                                              className="action-td-icon"
                                            />
                                          </Button>
                                        </td>

                                        <td className="Action-td">
                                          <Link
                                            to={`/tournament/edit/${item?.slug}/${item?.id}`}
                                            className="btn btn-sm complaint-view-btn btn-secondary info-icon action-td-btn"
                                            disabled={item.current_status === 3}
                                          >
                                            <img
                                              alt="edit-Admin-icon"
                                              src={edit}
                                              width="16px"
                                            />{" "}
                                            <span
                                              className="addTriunamentTip tooltiptext"
                                              data-tip
                                              data-for="loginTip"
                                            >
                                              Edit
                                            </span>
                                          </Link>
                                        </td>
                                      </>
                                    ) : (
                                      <>
                                        <td className="Action-td">
                                          {!item.is_add_schedule_active ? (
                                            <button className="btn btn-sm  btn-secondary disabled disabled-link action-td-btn">
                                              <img
                                                alt="disableSchedule-Admin-icon"
                                                src={disableSchedule}
                                                className="action-td-icon"
                                              />
                                            </button>
                                          ) : (
                                            <Link
                                              to={{
                                                pathname: `/tournament/addschedule/${item?.slug}/${item?.id}`,
                                                data: {
                                                  nameOfGame: item.game,
                                                  details: item,
                                                },
                                              }}
                                              className="info-icon action-td-btn  addSchedule-Admin-icon"
                                            >
                                              <img
                                                alt="addSchedule-Admin-icon"
                                                src={addSchedule}
                                                className="action-td-icon"
                                              />
                                              <span
                                                className="addTrounamentTip tooltiptext"
                                                data-tip
                                                data-for="loginTip"
                                              >
                                                Add Tournament Schedule
                                              </span>
                                            </Link>
                                          )}
                                        </td>
                                        <td className="Action-td">
                                          <Button className="btn btn-sm btn-secondary info-icon btn-tournament complaint-view-btn action-td-btn pt-0">
                                            <span
                                              className="tooltipTournament tooltiptext"
                                              data-tip
                                              data-for="loginTip"
                                            >
                                              Tournament cannot be edited as the
                                              schedule has been created.
                                            </span>

                                            <img
                                              alt="disableEditIcon-Admin-icon"
                                              src={disableEditIcon}
                                            />
                                          </Button>
                                        </td>
                                      </>
                                    )}
                                  </>
                                ) : null}
                                <td className="Action-td">
                                  <Link
                                    to={{
                                      pathname: `/tournament/view/${item?.slug}/${item?.id}`,
                                      data: { nameOfGame: item.game },
                                    }}
                                    className="btn btn-sm complaint-view-btn info-icon btn-secondary action-td-btn"
                                  >
                                    <img
                                      alt="view"
                                      src={view}
                                      className="action-td-icon"
                                    />
                                    <span
                                      className="viewtooltip tooltiptext"
                                      data-tip
                                      data-for="loginTip"
                                    >
                                      View
                                    </span>
                                  </Link>
                                </td>
                                {deletePermission ? (
                                  <td className="Action-td">
                                    <Button
                                      className="btn btn-sm complaint-view-btn info-icon tournamnet-delete action-td-btn"
                                      onClick={() =>
                                        handleDelete(
                                          item?.id,
                                          index,
                                          item.current_status
                                        )
                                      }
                                      disabled={
                                        delIndex === index || !item.is_delete
                                      }
                                    >
                                      <img
                                        alt="deleteIcon"
                                        src={
                                          delIndex === index || !item.is_delete
                                            ? deleteDisable
                                            : deleteIcon
                                        }
                                        className="action-td-icon"
                                      />
                                      <span
                                        className="deletetooltip tooltiptext"
                                        data-tip
                                        data-for="loginTip"
                                      >
                                        Delete
                                      </span>
                                    </Button>
                                  </td>
                                ) : null}
                              </tr>
                            ))
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <Paginator
                  totalCount={totalCount}
                  pageSize={pageSize}
                  pageClick={handlePageClick}
                  forcePage={pageNumber}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
        {deleteModal ? (
          <DeleteAlertModal
            DeleteId={deleteId}
            currentstat={currentStatus}
            onClose={() => handleCLoseAlert()}
            callApi={() => tournamentList()}
            Loader={() => setShowLoadder(true)}
            StopLoader={() => setShowLoadder(false)}
          />
        ) : null}
      </div>
    </React.Fragment>
  );
};

export default TournamentsList;
const CustomInput = React.forwardRef((props, ref) => {
  return (
    <div
      className="form-control dateFilterIcon d-flex"
      onClick={props.onClick}
      ref={ref}
    >
      <img src={calenderIcon} alt="calendar-icon" />
      <label
        className={`${props.startDate && props.endDate ? "selectedDate" : ""}`}
      >
        {props.value || props.placeholder}
      </label>
    </div>
  );
});
