import React from "react";
import AddTournamentSchedule from "../Common/AddTournamentSchedule";
import { Card, CardBody, Row } from "reactstrap";
const ScheduleTab = ({ details, callBackToDetails }) => {
  return (
    <Card className="mb-0">
      <CardBody>
        <Row>
          {" "}
          <AddTournamentSchedule
            details={details}
            viewMode={true}
            stopApiCall={true}
            callBackToDetails={callBackToDetails}
          />
        </Row>
      </CardBody>
    </Card>
  );
};

export default ScheduleTab;
