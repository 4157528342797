import { axiosApi } from "./index";

// API Methods
export async function getReferralList(filterModel) {
  let params = new URLSearchParams();

  if (filterModel.searchTerm && filterModel.searchTerm !== "")
    params.append("query", filterModel.searchTerm);

  if (filterModel.sortBy && filterModel.sortBy !== "")
    params.append("sort_by", filterModel.sortBy);

  if (filterModel.pageNumber) params.append("page", filterModel.pageNumber);

  if (filterModel.pageSize) params.append("page_size", filterModel.pageSize);

  if (filterModel.from_date) params.append("from_date", filterModel.from_date);
  if (filterModel.to_date) params.append("to_date", filterModel.to_date);

  return await axiosApi.get("referees/", { params }).then((response) => {
    const {
      daily_money_given,
      total_money_distributed,
      current_month_given,
      total,
      results,
    } = response;
    return {
      data: results,
      total,
      total_money_distributed,
      daily_money_given,
      current_month_given,
    };
  });
}

export async function getReferralDetail(userId) {
  return await axiosApi.get(`referees/${userId}/`);
}
