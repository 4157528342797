import info from "../../../assets/images/info-icon.svg";

export const IMG = (data) => {
  return (
    <>
      Statistic Code*
      <span className="info-icon stats-infoIcon">
        <img
          src={info}
          alt="info"
          className="info-image"
          data-tip
          data-for="loginTip"
        />
        <span class="tooltiptext">
          {data?.data === "valorant"
            ? "Please update any one player's riot ID to generate game stats."
            : "Please make sure the code being entered matches exactly whats provided by Games."}
        </span>
      </span>
    </>
  );
};
