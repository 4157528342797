import PermissionPath from "./PermissionPath";
function SearchPath(userRoutes) {
  const allPath = PermissionPath();
  if (allPath == "/login") {
    return;
  } else if (allPath === null) {
    return;
  } else {
    const filterPath = [];
    allPath?.filter(
      (elem) =>
        !userRoutes.find((data) => {
          if (elem?.pathname == data.commonName) {
            filterPath.push(data);
          }
        })
    );
    const allowedPathForUser = [filterPath];
    return allowedPathForUser;
  }
}

export default SearchPath;
