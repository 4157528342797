export const LOGIN_USER = "LOGIN_USER";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";

export const LOGOUT_USER = "LOGOUT_USER";
export const LOGOUT_USER_SUCCESS = "LOGOUT_USER_SUCCESS";
export const API_ERROR = "LOGIN_API_ERROR";

export const SOCIAL_LOGIN = "SOCIAL_LOGIN";
export const SET_GAMES = "SET_GAMES";
export const SET_PRIZE_LIST = "SET_PRIZE_LIST";
export const SET_CLEAR_PRIZE_LIST = "SET_CLEAR_PRIZE_LIST";
export const UPDATE_PRIZE_COUNT = "UPDATE_PRIZE_COUNT";
export const UPDATE_PRIZE_LIST = "UPDATE_PRIZE_LIST";

export const SET_SCHEDULE_LIST = "SET_SCHEDULE_LIST";
export const SET_SCHEDULE_COUNT = "SET_SCHEDULE_COUNT";
export const UPDATE_SCHEDULE_LIST = "UPDATE_SCHEDULE_LIST";
export const SET_CLEAR_SCHEDULE_LIST = "SET_CLEAR_SCHEDULE_LIST";
