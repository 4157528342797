import React from "react";
import PPKDranNDrop from "./PPKDranNDrop";
const QuestionList = ({
  enableAddQuestionView,
  questions = [],
  enableEditView,
  deleteQuestion,
  onDragEnd,
  isFaq = false,
  subQuestionid,
  questionNum,
  data,
}) => {
  return (
    <>
      <PPKDranNDrop
        questions={questions}
        deleteQuestion={deleteQuestion}
        enableEditView={enableEditView}
        onDragEnd={onDragEnd}
        isFaq={isFaq}
        subQuestionid={subQuestionid}
        questionNum={questionNum}
        data={data}
      />
    </>
  );
};

export default QuestionList;
