import React, { useState } from "react";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import toastrOptions from "../../helpers/toastr-options/toastr-options";
import SweetAlert from "react-bootstrap-sweetalert";
import { deleteTournament } from "../../services/bracket_tournaments_api_helper";
const DeleteAlertModal = (props) => {
  const [disableSwal2, setDisabled2] = useState(false);
  const handleDelete = async () => {
    props.Loader();
    props.onClose();
    setDisabled2(true);
    await deleteTournament(props.DeleteId)
      .then((res) => {
        toast.success("Tournament deleted successfully", toastrOptions);
        props.StopLoader();
        props.callApi();
        setDisabled2(false);
      })
      .catch(() => {
        props.onClose();
        props.StopLoader();
        setDisabled2(false);
      });
  };
  const closeAlert = () => {
    props.onClose();
  };
  return (
    <SweetAlert
      title="Delete Tournament"
      warning
      showCancel
      confirmButtonText="Yes"
      confirmBtnBsStyle="success"
      cancelButtonText="No"
      cancelBtnBsStyle="danger"
      onConfirm={() => handleDelete()}
      onCancel={() => closeAlert()}
      disabled={props.currentstat === 2 ? true : disableSwal2}
    >
      {props.currentstat === 2 ? (
        <p>
          Sorry, You cannot delete the tournament as the tournament has already
          been started{" "}
        </p>
      ) : (
        <p>Are you sure you want to delete this tournament?</p>
      )}
    </SweetAlert>
  );
};
DeleteAlertModal.propTypes = {
  onClose: PropTypes.func,
  callApi: PropTypes.func,
  Loader: PropTypes.func,
  StopLoader: PropTypes.func,
};
export default DeleteAlertModal;
