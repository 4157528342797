import { Link, withRouter } from "react-router-dom";

import {
  Card,
  CardBody,
  Col,
  Row,
  Button,
  TabContent,
  TabPane,
  NavLink,
  NavItem,
  Nav,
} from "reactstrap";

import Breadcrumbs from "../../components/Common/Breadcrumb";
import {
  getUserDetail,
  activateDeactivateUser,
} from "../../services/user_api_helper";

import React, { Component } from "react";

// Alert
import SweetAlert from "react-bootstrap-sweetalert";
import classnames from "classnames";
import LatestTransaction from "../Dashboard/latest-transaction";
import EarningTimeline from "../../helpers/EarningTimeline";
import FilterPermission from "../../helpers/FilterPermission";
import { filterOutPermissionToShowHide } from "../../helpers/PermissionUtils";
import EditReferral from "../../helpers/EditReferral";
import EditFreeLobby from "../../helpers/EditFreeLobby";
import { isEmpty } from "lodash";
class UserDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: null,
      loader: true,
      deactivate_alert: false,
      activate_alert: false,
      deactivateReason: null,
      activeTab: this.props.location?.data?.activeTab == "1" ? "2" : "1",
      //confirm_alert_For_Seize_Wallet: false,
      selectedUser: null,
      deactivateUserSuccessMessage: false,
      disableSwal: false,

      disableSwal2: false,
      editEarning: false,

      permission: [],
      permissionTransaction: [],
      changePermission: false,
      deletePermission: false,
      viewPermission: false,
      changePermissionTransaction: false,
      viewPermissionTransaction: false,
    };
  }

  componentDidMount() {
    this.setState({ loader: true });
    let userId = this.props.match.params.userId;
    if (userId) {
      this.getDetailOfUser(userId);
    }
    if (isEmpty(this.props.permission)) {
      this.setState({
        changePermission: true,
        deletePermission: true,
        viewPermission: true,
      });
    } else {
      this.callSetPermission();
    }
  }
  callSetPermission = () => {
    const type = "Users";
    const typeChange = "change_user";
    const typeDelete = "delete_user";
    const typeView = "view_user";

    const filteredPermission = FilterPermission(this.props.permission, type);
    if (filteredPermission.length !== 0) {
      const setchange =
        filteredPermission.length === 0
          ? false
          : filterOutPermissionToShowHide(
              filteredPermission[0]?.permissions,
              typeChange
            );
      const setdelete =
        filteredPermission.length === 0
          ? false
          : filterOutPermissionToShowHide(
              filteredPermission[0]?.permissions,
              typeDelete
            );
      const setview =
        filteredPermission.length === 0
          ? false
          : filterOutPermissionToShowHide(
              filteredPermission[0]?.permissions,
              typeView
            );
      this.setState(
        {
          changePermission: setchange,
          deletePermission: setdelete,
          viewPermission: setview,
          permission: filteredPermission[0].permissions,
        },
        () => {}
      );
    }
    const typeTransaction = "Transaction Detail";
    const typeChangeTransaction = "change_transactiondetail";
    const typeViewTransaction = "view_transactiondetail";
    const filteredPermissionTransaction = FilterPermission(
      this.props.permission,
      typeTransaction
    );
    if (filteredPermissionTransaction.length !== 0) {
      const setchangetransaction =
        filteredPermissionTransaction.length === 0
          ? false
          : filterOutPermissionToShowHide(
              filteredPermissionTransaction[0]?.permissions,
              typeChangeTransaction
            );
      const setviewtransaction =
        filteredPermissionTransaction.length === 0
          ? false
          : filterOutPermissionToShowHide(
              filteredPermissionTransaction[0]?.permissions,
              typeViewTransaction
            );
      this.setState(
        {
          permissionTransaction: filteredPermissionTransaction[0].permissions,
          changePermissionTransaction: setchangetransaction,
          viewPermissionTransaction: setviewtransaction,
        },
        () => {}
      );
    }
  };
  handleDeactivateText(e) {
    e.preventDefault();
    this.setState({
      deactivateReason: e.target.value,
    });
  }

  async getDetailOfUser(userId) {
    try {
      this.setState({ loader: true });
      const res = await getUserDetail(userId);
      this.setState({ user: res, loader: false });
    } catch (error) {
      this.setState({ loader: false });
    }
  }

  async deactivateUserStatus() {
    this.setState({
      disableSwal: true,
      deactivate_alert: false,
      activate_alert: false,
    });

    if (this.state.selectedUser.status === 1)
      if (!this.state.deactivateReason || this.state.deactivateReason === "") {
        return;
      }

    let currentStatus = this.state.user.status === 1 ? 2 : 1;

    await activateDeactivateUser(
      this.state.user.id,
      currentStatus,
      this.state.deactivateReason
    )
      .then(() => {
        this.setState({
          deactivateUserSuccessMessage: true,
          disableSwal: false,
        });

        this.closeAlert();
        setTimeout(() => {
          this.goToListing();
        }, 2000);
      })
      .catch(() => {
        this.closeAlert();
        this.setState({
          disableSwal: false,
          deactivate_alert: false,
          activate_alert: false,
        });
      });
  }

  openAlert(user) {
    this.setState({
      deactivateReason: null,
      deactivate_alert: user.status === 1,
      activate_alert: user.status === 2,
      selectedUser: user,
    });
  }

  closeAlert() {
    this.setState({
      deactivate_alert: false,
      deactivateReason: null,
      activate_alert: false,
    });
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
    if (tab == 1) {
      let userId = this.props.match.params.userId;
      if (userId) {
        this.getDetailOfUser(userId);
      }
    }
  }
  goToListing() {
    this.props.history.push("/users");
  }
  handleEditEarning = () => {
    this.setState({
      editEarning: true,
    });
  };
  handleCLose = () => {
    this.setState({
      editEarning: false,
    });
  };
  callUserDetail = () => {
    let userId = this.props.match.params.userId;
    if (userId) {
      this.getDetailOfUser(userId);
    }
  };
  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <Breadcrumbs breadcrumbItem="User Detail" />
          <Row>
            <Row className="mb-4">
              <Col>
                <p>
                  <Link
                    to={
                      this.props.location.data?.prevPath === "/referrals"
                        ? "/referrals"
                        : this.props.location.data === undefined
                        ? "/users"
                        : this.props.location.data?.prevPath === "/report"
                        ? {
                            pathname: this.props.location.data?.prevPath,
                            state: {
                              active: this.props.location.data.activeTab,
                              filter: this.props.location.data.filter,
                              activeFilter:
                                this.props.location.data.activeFilter,
                              detailView: this.props.location.data.detailView,
                              setDates: this.props.location.data.setDates,
                            },
                          }
                        : "/users"
                    }
                  >
                    <i className="mdi mdi-arrow-left"></i> back
                  </Link>
                </p>
              </Col>
            </Row>
            {this.state.loader ? (
              <div class="spinner-grow spinner-class" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            ) : (
              <Col lg={12}>
                <Nav tabs className="user-detail-tabs">
                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({
                        active: this.state.activeTab === "1",
                      })}
                      onClick={() => {
                        this.toggle("1");
                      }}
                    >
                      <span className="d-block d-sm-none">
                        <i className="fas fa-home"></i>
                      </span>
                      <span className="d-none d-sm-block">User Details</span>
                    </NavLink>
                  </NavItem>
                  {this.state.permissionTransaction.length !== 0 ? (
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: this.state.activeTab === "2",
                        })}
                        onClick={() => {
                          this.toggle("2");
                        }}
                      >
                        <span className="d-block d-sm-none">
                          <i className="far fa-user"></i>
                        </span>
                        <span className="d-none d-sm-block">
                          Transaction history
                        </span>
                      </NavLink>
                    </NavItem>
                  ) : null}
                </Nav>
                <TabContent
                  activeTab={this.state.activeTab}
                  className="p-3 text-muted home-tab"
                  style={{
                    marginTop: "-1pc",
                    width: "103%",
                    marginLeft: "-15px",
                  }}
                >
                  <TabPane tabId="1">
                    <Card className="mb-0">
                      {this.state.user && (
                        <CardBody>
                          <Row>
                            <Col>
                              <h4 className="mb-4">
                                {this.state.user?.first_name}{" "}
                                {this.state.user?.last_name}
                              </h4>
                            </Col>
                            <Col className="col-12 mb-3">
                              <strong className="capitalize">
                                Gamer tag/Username :
                              </strong>{" "}
                              <span>{this.state.user?.username}</span>
                            </Col>
                            <Col className="col-12 mb-3">
                              <strong className="capitalize">
                                Game accounts :
                              </strong>{" "}
                              <span>
                                {this.state.user?.game_accounts?.length === 0
                                  ? "Not added any game accounts"
                                  : this.state.user?.game_accounts?.map(
                                      (item, id) => (
                                        <Col className="col-12" key={id}>
                                          {item.game.gaming_account_name} :{" "}
                                          {item.gaming_account}
                                        </Col>
                                      )
                                    )}
                              </span>
                            </Col>
                            <Col className="col-12 mb-3">
                              {Object.keys(this.state.user.referral || {})
                                .length === 0 ? (
                                <>
                                  <strong className="capitalize">
                                    {" "}
                                    Referral :{" "}
                                  </strong>
                                  <span>No referral used</span>
                                </>
                              ) : (
                                <>
                                  <Col className="col-12">
                                    <strong className="capitalize">
                                      {" "}
                                      Referral Name :
                                    </strong>{" "}
                                    <span>{this.state.user.referral.name}</span>
                                  </Col>
                                  <Col className="col-12">
                                    <strong className="capitalize">
                                      Referral Code :
                                    </strong>{" "}
                                    <span>
                                      {this.state.user.referral.referral_code}
                                    </span>
                                  </Col>
                                </>
                              )}
                            </Col>

                            <Col className="col-12 mb-3">
                              <strong className="capitalize">
                                Social media handles :
                              </strong>{" "}
                              {Object.keys(
                                this.state?.user?.social_accounts || {}
                              ).map((item, index) => (
                                <Col className="col-12" key={index}>
                                  <span className="capitalize"> {item}</span> :{" "}
                                  {item === "facebook" ? (
                                    this.state?.user?.social_accounts[item] ===
                                    "Not Connected" ? (
                                      this.state?.user?.social_accounts[item]
                                    ) : this.state?.user?.social_accounts[
                                        item
                                      ] ===
                                      "Account is connected. Information is missing." ? (
                                      this.state?.user?.social_accounts[item]
                                    ) : (
                                      <Link
                                        to={{
                                          pathname:
                                            this.state?.user?.social_accounts[
                                              item
                                            ],
                                        }}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                      >
                                        View facebook profile
                                      </Link>
                                    )
                                  ) : item === "google" ? (
                                    this.state?.user?.social_accounts[item] ===
                                    "Not Connected" ? (
                                      this.state?.user?.social_accounts[item]
                                    ) : this.state?.user?.social_accounts[
                                        item
                                      ] ===
                                      "Account is connected. Information is missing." ? (
                                      this.state?.user?.social_accounts[item]
                                    ) : (
                                      <a
                                        href={`mailto:${this.state?.user?.social_accounts[item]}`}
                                      >
                                        {
                                          this.state?.user?.social_accounts[
                                            item
                                          ]
                                        }
                                      </a>
                                    )
                                  ) : item === "twitter" ? (
                                    this.state?.user?.social_accounts[item] ===
                                    "Not Connected" ? (
                                      this.state?.user?.social_accounts[item]
                                    ) : this.state?.user?.social_accounts[
                                        item
                                      ] ===
                                      "Account is connected. Information is missing." ? (
                                      this.state?.user?.social_accounts[item]
                                    ) : (
                                      //for linking future purpose
                                      <p>
                                        {
                                          this.state?.user?.social_accounts[
                                            item
                                          ]
                                        }
                                      </p>
                                    )
                                  ) : (
                                    this.state?.user?.social_accounts[item]
                                  )}
                                </Col>
                              ))}
                            </Col>
                            <Col className="col-12 mb-3">
                              <strong className="capitalize">
                                Paypal Id :
                              </strong>
                              <span>
                                {this.state.user?.profile?.paypal_id || "NA"}
                              </span>
                            </Col>
                            <Col className="col-12 mb-3">
                              <strong className="capitalize">
                                Discord Id :
                              </strong>
                              <span>
                                {this.state.user?.profile?.discord_id || "NA"}
                              </span>
                            </Col>
                            <Col className="col-12 mb-3">
                              <strong className="capitalize">
                                Email address :
                              </strong>{" "}
                              <span>{this.state.user?.email}</span>
                            </Col>
                            <Col className="col-12 mb-3">
                              <strong className="capitalize">
                                Phone Number :{" "}
                              </strong>{" "}
                              <span>{this.state.user?.phone || "NA"}</span>
                            </Col>
                            <Col className="col-12 mb-3">
                              <strong className="capitalize">Street :</strong>{" "}
                              <span>
                                {this.state.user?.address?.full || "NA"}
                              </span>
                            </Col>
                            <Col className="col-12 mb-3">
                              <strong className="capitalize">
                                Wallet Balance :
                              </strong>{" "}
                              <span>
                                {"$ " +
                                  this.state.user?.profile?.wallet_balance ||
                                  "-"}
                              </span>
                            </Col>
                            <Col className="col-12 mb-3">
                              <strong className="capitalize">
                                Profile Status :
                              </strong>{" "}
                              <span>
                                {this.state.user?.profile?.is_verified
                                  ? "Verified"
                                  : "Unverified"}
                              </span>
                            </Col>
                            <Col className="col-12 mb-3">
                              <strong className="capitalize">
                                KYC Status :
                              </strong>{" "}
                              {this.state.user?.profile?.kyc_verification_status
                                ? "Verified, " +
                                  new Date(
                                    this.state.user?.profile?.kyc_verification_date
                                  ).toLocaleString("en-US", {
                                    weekday: "short", // long, short, narrow
                                    day: "numeric", // numeric, 2-digit
                                    year: "numeric", // numeric, 2-digit
                                    month: "long", // numeric, 2-digit, long, short, narrow
                                    hour: "numeric", // numeric, 2-digit
                                    minute: "numeric", // numeric, 2-digit
                                    second: "numeric", // numeric, 2-digit
                                    timeZone: "UTC",
                                    hour12: false,
                                  })
                                : "Unverified"}
                            </Col>
                            <Col className="col-12 mb-3">
                              <strong className="capitalize">
                                Joining Date:
                              </strong>{" "}
                              {new Date(
                                this.state.user?.profile?.affiliate_days_expire
                              ).toLocaleString("en-US", {
                                weekday: "short", // long, short, narrow
                                day: "numeric", // numeric, 2-digit
                                year: "numeric", // numeric, 2-digit
                                month: "long", // numeric, 2-digit, long, short, narrow
                                hour: "numeric", // numeric, 2-digit
                                minute: "numeric", // numeric, 2-digit
                                second: "numeric", // numeric, 2-digit
                                timeZone: "UTC",
                                hour12: false,
                              })}
                            </Col>
                            <Col className="col-12 mb-3">
                              <strong className="capitalize">
                                Free Lobby Limit:
                              </strong>{" "}
                              <span>
                                {this.state.user?.profile?.free_lobby_limit}
                              </span>
                            </Col>
                            {this.state.changePermission ? (
                              <div className="button-items">
                                {this.state.editEarning ? (
                                  <>
                                    <EarningTimeline
                                      userId={this.state.user.id}
                                      onClose={() => this.handleCLose()}
                                      days={
                                        this.state.user?.profile?.affiliate_days
                                      }
                                      callApi={() => this.callUserDetail()}
                                    />
                                    <EditReferral
                                      userId={this.state.user.id}
                                      defaultReferral={
                                        this.state.user?.profile?.referral_code
                                      }
                                      onClose={() => this.handleCLose()}
                                      callApi={() => this.callUserDetail()}
                                    />
                                    <EditFreeLobby
                                      userId={this.state.user.id}
                                      defaultFreeLobbyLimit={
                                        this.state.user?.profile
                                          ?.free_lobby_limit
                                      }
                                      onClose={() => this.handleCLose()}
                                      callApi={() => this.callUserDetail()}
                                    />
                                  </>
                                ) : (
                                  <Button
                                    onClick={() => this.handleEditEarning()}
                                  >
                                    Edit
                                  </Button>
                                )}
                                <button
                                  className={`btn ${
                                    this.state.user.status === 1
                                      ? "btn-danger"
                                      : "btn-success"
                                  }`}
                                  onClick={() => {
                                    this.openAlert(this.state.user);
                                  }}
                                >
                                  {this.state.user.status === 1
                                    ? "Deactivate "
                                    : "Activate "}{" "}
                                  User
                                </button>
                              </div>
                            ) : null}
                          </Row>
                        </CardBody>
                      )}

                      {this.state.deactivate_alert && (
                        <SweetAlert
                          title="Deactivate User"
                          warning
                          showCancel
                          confirmButtonText="Yes"
                          confirmBtnBsStyle="success"
                          cancelButtonText="No"
                          cancelBtnBsStyle="danger"
                          onConfirm={() => this.deactivateUserStatus()}
                          onCancel={() => this.closeAlert()}
                          disabled={this.state.disableSwal}
                        >
                          Are you sure you want to deactivate the user from the
                          listings?
                          <textarea
                            required
                            rows="5"
                            className="form-control"
                            placeholder="enter description here"
                            onChange={(e) => {
                              this.handleDeactivateText(e);
                            }}
                          ></textarea>
                          {this.state.deactivateReason ? null : (
                            <p className="text-danger fst-italic fs-6 mt-2 mb-3">
                              Description is required
                            </p>
                          )}
                        </SweetAlert>
                      )}
                      {this.state.deactivateUserSuccessMessage ? (
                        <SweetAlert success showConfirm={false}>
                          User{" "}
                          {this.state.user.status === 1
                            ? "Deactivated "
                            : "Activated "}{" "}
                          Successfully
                        </SweetAlert>
                      ) : null}
                      {this.state.activate_alert && (
                        <SweetAlert
                          title="Activate User"
                          warning
                          showCancel
                          confirmButtonText="Yes"
                          confirmBtnBsStyle="success"
                          cancelButtonText="No"
                          cancelBtnBsStyle="danger"
                          onConfirm={() => this.deactivateUserStatus()}
                          onCancel={() => this.closeAlert()}
                          disabled={this.state.disableSwal}
                        >
                          Are you sure you want to activate the user from the
                          listings?
                          <p>
                            Deactivation Reason : <br />
                            {this.state.selectedUser.account_status.reason}
                          </p>
                        </SweetAlert>
                      )}
                    </Card>
                  </TabPane>
                  <TabPane
                    tabId="2"
                    className="p-3 history-tab"
                    style={{
                      marginTop: "-1pc",
                      width: "154%",
                      marginLeft: "-15px",
                    }}
                  >
                    {this.state.activeTab === "2" ? (
                      <LatestTransaction
                        data={this.state.user}
                        permission={this.props.permission}
                      />
                    ) : null}
                  </TabPane>
                </TabContent>
              </Col>
            )}
          </Row>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(UserDetail);
