import React from "react";
import { Link } from "react-router-dom";

function ReportNavigationLink({
  children,
  activeTab,
  transactionFilter,
  detailView,
  url,
  activeFilter,
  setDates,
  userTab,
  nameOfGame,
}) {
  return (
    <Link
      to={{
        pathname: url,
        data: {
          prevPath: window.location.pathname,
          activeTab,
          filter: transactionFilter,
          activeFilter,
          detailView,
          setDates,
          userTab,
          nameOfGame,
        },
      }}
    >
      {children}
    </Link>
  );
}

export default ReportNavigationLink;
