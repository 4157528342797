import React, { useMemo, useEffect, useState } from "react";
import { Button } from "reactstrap";
import JoditEditor from "jodit-react";

function JoditEditorForm({
  dataFromAPI = () => {},
  postDataToServer = () => {},
  changePermission,
}) {
  const [content, setContent] = useState("");
  const [apiData, setApiData] = useState("");
  const config = useMemo(
    () => ({
      readonly: !changePermission,
      showBrowserColorPicker: false,
      removeButtons: ["brush"],
    }),
    [changePermission]
  );

  const handleSetContext = (value) => {
    setContent(value);
    setApiData(value);
  };

  useEffect(() => {
    dataFromAPI({ setContent });
  }, []);

  return (
    <>
      <form onSubmit={(event) => postDataToServer({ event, content, apiData })}>
        <JoditEditor
          value={content}
          config={config}
          tabIndex={1}
          onBlur={handleSetContext}
          onChange={handleSetContext}
        />
        <Button
          type="submit"
          color="primary"
          className="ms-1 cms-button privacy-policy-submit-btn"
          style={{ marginTop: "60px" }}
          disabled={!changePermission}
        >
          Submit
        </Button>
      </form>
    </>
  );
}

export default JoditEditorForm;
