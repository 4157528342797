import React, { useState } from "react";
import { AvForm } from "availity-reactstrap-validation";

import Select from "react-select";
import { FormGroup, Label, Button } from "reactstrap";
import { toast } from "react-toastify";
import toastrOptions from "../../../helpers/toastr-options/toastr-options";

import tickIcon from "../../../assets/images/user-tick-icon.svg";
import crossIcon from "../../../assets/images/user-cross-icon.svg";
import { useEffect } from "react";
import {
  getAdminUserListTournamentForDropdown,
  updateAssignedAdminForTournament,
} from "../../../services/bracket_tournaments_api_helper";

const ChangeAssignedAdmin = ({
  TournamentId,
  MatchId,
  SaveAssignedAdmin,
  CloseAssignedAdmin,
}) => {
  const [admins, setAdminOptions] = useState([]);
  const [adminId, setSelectedAdmin] = useState(null);
  const [adminRequiredMsg, setSelectedAdminErrorMsg] = useState("");
  useEffect(() => {
    adminList();
    // }
  }, []);

  const adminList = async () => {
    await getAdminUserListTournamentForDropdown(TournamentId)
      .then((res) => {
        if (res?.length) {
          let data = res.map((item) => ({
            label: item.email,
            value: item.id,
          }));
          setAdminOptions(data);
        }
      })
      .catch(() => {
        toast.error("Error", toastrOptions);
      });
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    if (adminId === null) {
      setSelectedAdminErrorMsg(adminId === null ? "Please select admin" : "");
    } else {
      let data = {
        admin_id: adminId.value,
      };
      await updateAssignedAdminForTournament(TournamentId, MatchId, data)
        .then(() => {
          SaveAssignedAdmin();
          CloseAssignedAdmin();
          toast.success("Admin updated successfully", toastrOptions);
        })
        .catch(() => {
          toast.error("Error", toastrOptions);
        });
    }
  };
  const handleAdminSelectGroup = (selected) => {
    setSelectedAdminErrorMsg("");
    setSelectedAdmin(selected);
  };
  const handleInvalidSubmit = () => {
    setSelectedAdminErrorMsg(adminId === null ? "Please select admin" : "");
  };
  return (
    <>
      <AvForm
        className="editEarning-timeline"
        onValidSubmit={(e) => {
          handleSubmit(e);
        }}
        onInvalidSubmit={handleInvalidSubmit}
      >
        <Label>Select Admin*</Label>
        <Select
          value={adminId}
          onChange={(event) => {
            handleAdminSelectGroup(event);
          }}
          options={admins}
          name="admin_id"
          classNamePrefix="select2-selection"
        />
        {adminRequiredMsg.length === 0 ? null : (
          <label
            className={
              adminRequiredMsg.length === 0
                ? ""
                : "errorMsgGames invalid-feedback"
            }
          >
            {adminRequiredMsg}
          </label>
        )}
        <FormGroup >
          <div className="mt-4 d-flex">
            <Button type="submit" color="primary" className="edit-icon">
              <img alt="user-tick-icon" src={tickIcon} />
            </Button>
            <Button
              className="edit-icon mt-2"
              onClick={() => {
                CloseAssignedAdmin();
              }}
            >
              <img alt="user-cross-icon" src={crossIcon} />
            </Button>
          </div>
        </FormGroup>
      </AvForm>
    </>
  );
};
export default ChangeAssignedAdmin;
