import React, { useState, useEffect } from "react";
import CreatePrizes from "../../../helpers/CreatePrizes";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { Row, Col, FormGroup, Label, InputGroup, Button } from "reactstrap";
import {
  compareDateForTimeValidation,
  compareDateTournamentForBestOfMatches,
  compareTwoArraysOfObjects,
  createMatchesTable,
  createStartEndTimeList,
  getEstDate,
  getEstDateObj,
} from "../../../helpers/util";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";
import { IMG } from "../../../components/Common/StatsCodeToolTip";
import { useDispatch } from "react-redux";
import { setScheduleCount, setScheduleList } from "../../../store/actions";
import { toast } from "react-toastify";
import toastrOptions from "../../../helpers/toastr-options/toastr-options";
import { useStateWithCallback } from "../useStateWithCallback";
import {
  checkLastEntryTime,
  checkMatchDateTime,
  findMaxSubMatchLength,
  validateRounds,
} from "../Common/utils";
const AddBestOfMatches = ({
  matchId,
  gameslug,
  Error,
  clearError,
  allSchedule,
  roundId,
  disableSave,
  errorOccuredDisableSubmitButton,
  tournamentDetails,
}) => {
  const dispatch = useDispatch();
  const [countForForm, setCount] = useState(null);
  const [errorValidateRound, setErrorValidateRound] = useState(null);
  const currentTime = new Date();
  const estTime = currentTime.toLocaleTimeString("en-US", {
    timeZone: "America/New_York",
  });
  const estEndTime = getEstDateObj(new Date(), "America/New_York");
  const minEstDate = getEstDate();
  const [userInputStartDate, setUserInputStartDate] = useState(null);
  const [startTimeByID, setStartTimeById] = useStateWithCallback([]);

  const maxSubMatchLength = findMaxSubMatchLength(allSchedule, roundId);

  useEffect(() => {
    addMinutes(estEndTime);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (maxSubMatchLength !== null) {
      setCount(maxSubMatchLength);
      dispatch(setScheduleCount(maxSubMatchLength));
      setTimeList(createStartEndTimeList(maxSubMatchLength, timeList));
    }
  }, []);
  const [startTime, setStartTime] = useState(estTime);
  const [endTimeRequiredMsg, setEndTimeRequiredMsg] = useState("");
  const [startTimeobj, setStartTimeObj] = useState();
  //const [endTime, setEndTime] = useState(null);
  const [fixedEndTime, setFixedEndTime] = useState(null);
  const [fixedStartTime, setFixedStartTime] = useState(null);
  const [fixedStartTimeObj, setFixedStartTimeObj] = useState(estEndTime);
  const [startDateByID, setStartDateById] = useState([]);
  const [errorId, setErrorId] = useState(null);
  const handleStartDate = (userDate, errorid) => {
    setErrorId(null);
    setUserInputStartDate(userDate);
    let startdateobj = [
      {
        id: errorid,
        startDate: userDate,
      },
    ];
    if (startTimeByID.length === 0) {
      let obj2 = [
        {
          id: errorid,
          start_Time: startTime,
          startTimeObj: fixedStartTimeObj,
        },
      ];
      callUpdateStartTimeByID(obj2, errorid);
    } else {
      const indextest = startTimeByID.filter((el) => el.id === errorid);
      let obj2 = [
        {
          id: errorid,
          start_Time:
            indextest.length === 0 ? startTime : indextest[0]?.start_Time,

          startTimeObj:
            indextest.length === 0
              ? fixedStartTimeObj
              : indextest[0]?.startTimeObj,
        },
      ];
      callUpdateStartTimeByID(obj2, errorid);
    }
    if (startDateByID.length === 0) {
      if (errorid !== undefined) {
        if (startDateByID.length === 0) {
          setStartDateById([...startdateobj]);
        } else {
          callStartDateDuplicatesUpdate(startdateobj, errorid);
        }
      }
    } else {
      callStartDateDuplicatesUpdate(startdateobj, errorid);
    }
  };
  const callStartDateDuplicatesUpdate = (StartDateOBJ, duplicatesId) => {
    const duplicateIndex = startDateByID.findIndex(
      (el) => el.id === duplicatesId
    );
    if (duplicateIndex !== -1) {
      setStartDateById(() => {
        return startDateByID.map(
          (dataToCheckForDuplicates) =>
            StartDateOBJ.find(
              (alreadyPresent) =>
                alreadyPresent.id === dataToCheckForDuplicates.id
            ) || dataToCheckForDuplicates
        );
      });
    } else {
      setStartDateById([...startDateByID, ...StartDateOBJ]);
    }
  };
  const callToCheckForFutureDate = (
    isFutureDateTrue,
    fixedStartTimeValue,
    idOfObj
  ) => {
    if (isFutureDateTrue === -1) {
      compareTwoDateTime(estEndTime, fixedStartTimeValue, idOfObj);
    } else {
      if (isFutureDateTrue) {
        setErrorId(null);
        setEndTimeRequiredMsg("");
      } else {
        compareTwoDateTime(estEndTime, fixedStartTimeValue, idOfObj);
      }
    }
  };
  const [timeList, setTimeList] = useState([]);
  const count = (countLength) => {
    setCount(countLength);
    setTimeList(createStartEndTimeList(countLength, timeList));
  };

  const addMinutes = (date, targetId) => {
    setEndTimeRequiredMsg("");
    setErrorValidateRound("");
    const minusTime = date.getTime() - 10 * 60000;
    const minusT = new Date(minusTime);
    const start = date.toLocaleString("en-GB", {
      hour: "2-digit",
      minute: "2-digit",
    });
    const end = minusT.toLocaleString("en-GB", {
      hour: "2-digit",
      minute: "2-digit",
    });

    var dateObj =
      userInputStartDate === null
        ? date
        : new Date(userInputStartDate + " " + start + ":00");
    if (fixedEndTime === null) {
      setFixedStartTime(start);
      setFixedEndTime(end);
      setFixedStartTimeObj(date);
    }
    setStartTimeObj(date);

    // setEndTime(end);
    setStartTime(start);
    let obj2 = [
      {
        id: targetId,
        start_Time: start,
        startTimeObj: dateObj,
      },
    ];
    if (startTimeByID.length === 0) {
      let timelistobj = {
        start_Time: start,
        end_Time: end,
      };
      updateTimelist(targetId, timelistobj);
      callUpdateStartTimeByID(obj2, targetId);
      callToCheckForFutureTime(date, targetId);
    } else {
      callUpdateStartTimeByID(obj2, targetId);
      let obj = {
        start_Time: start,
        end_Time: end,
      };
      updateTimelist(targetId, obj);
      callToCheckForFutureTime(date, targetId);
    }
  };

  const callToCheckForFutureTime = (userDate, targetIndex) => {
    if (userInputStartDate !== null) {
      const isFutureDate = compareDate(minEstDate, userInputStartDate);
      callToCheckForFutureDate(isFutureDate, userDate, targetIndex);
    }
  };
  const updateTimelist = (idx, ob) => {
    setTimeList(
      timeList.map((item) => {
        if (item.id === idx) {
          return { ...item, ...ob };
        } else {
          return item;
        }
      })
    );
  };
  const [multipleFormValues, setMultipleFormValues] = useState([]);
  const handleValidSubmit = (event, values, id) => {
    event.preventDefault();

    //Date comparison for time validation
    const isFutureDate = compareDate(minEstDate, values.startDate);
    if (isFutureDate === -1) {
      //for current date
      submitFormValues(values, id);
    } else if (isFutureDate) {
      setEndTimeRequiredMsg("");
      setErrorId(null);
      submitFormValues(values, id);
      clearError();
    } else {
      submitFormValues(values, id);
    }
  };
  const callUpdateStartTimeByID = (starttimeobj, starttimeid) => {
    if (starttimeid !== undefined) {
      if (startTimeByID.length === 0) {
        setStartTimeById([...starttimeobj], (prevValue, newValue) => {
          callAsyncFutureTimeCheck(starttimeid, newValue[0].startTimeObj);
        });
      } else {
        const index = startTimeByID.findIndex((el) => el.id === starttimeid);
        if (index !== -1) {
          const data = startTimeByID.map(
            (dataToCheckForDuplicates) =>
              starttimeobj.find(
                (alreadyPresent) =>
                  alreadyPresent.id === dataToCheckForDuplicates.id
              ) || dataToCheckForDuplicates
          );
          setStartTimeById(data, (prevValue, newValue) => {
            callAsyncFutureTimeCheck(starttimeid, newValue[0].startTimeObj);
          });
        } else {
          setStartTimeById(
            [...startTimeByID, ...starttimeobj],
            (prevValue, newValue) => {
              callAsyncFutureTimeCheck(starttimeid, newValue[0].startTimeObj);
            }
          );
        }
      }
    }
  };
  const callAsyncFutureTimeCheck = (fieldId, timecompare) => {
    const userSelectedDate = document.getElementById(
      `startDate${fieldId}`
    ).value;
    const isFutureDate = compareDate(minEstDate, userSelectedDate);
    const fixedStartTimeValue = timecompare;
    callToCheckForFutureDate(isFutureDate, fixedStartTimeValue, fieldId);
  };
  const callUpdateDuplicatesMultipleFormValue = (formValues) => {
    setMultipleFormValues(() => {
      const sendToReducer = multipleFormValues.map(
        (dataToCheckForDuplicates) =>
          formValues.find(
            (alreadyPresent) =>
              alreadyPresent.id === dataToCheckForDuplicates.id
          ) || dataToCheckForDuplicates
      );
      dispatch(setScheduleList(sendToReducer));
      return sendToReducer;
    });
  };
  const callUpdateNewMultipleFormValue = (newFormValue, idx) => {
    toast.success(`Match ${idx} saved successfully`, toastrOptions);
    const sendToReducer = [...multipleFormValues, ...newFormValue];
    dispatch(setScheduleList(sendToReducer));
    setMultipleFormValues(() => {
      return sendToReducer;
    });
  };
  const submitFormValues = (values, id) => {
    setEndTimeRequiredMsg("");
    const indexCheck = startTimeByID.filter((el) => el.id === id);

    let model = [
      {
        id: id,
        map: values.map,
        start_date: values.startDate,
        start_time:
          indexCheck.length === 0 ? startTime : indexCheck[0]?.start_Time,
        admin_code: values.admin_code,
        participant_code: values.participant_code,
        stats_code: values.stats_code,
      },
    ];
    try {
      checkLastEntryTime(model[0].start_date, model[0].start_time);
      checkMatchDateTime(
        tournamentDetails.start_date,
        tournamentDetails.start_time,
        model[0].start_date,
        model[0].start_time
      );
      validateRounds(allSchedule, model, roundId);
      if (multipleFormValues.length === 0) {
        setMultipleFormValues(model);
        dispatch(setScheduleList(model));
        toast.success(`Match ${id} saved successfully`, toastrOptions);
      } else {
        const combineDateTime = startTimeByID.map((starttimebyid) => ({
          ...starttimebyid,
          ...startDateByID.find(
            (startdatebyid) => startdatebyid.id === starttimebyid.id
          ),
        }));
        const matchedIndex1 = combineDateTime.filter((el) => el.id === id);
        const matchedIndex2 = combineDateTime.filter((el) => el.id !== id);
        if (startTimeByID.length === 0) {
          setErrorId(id);
          setEndTimeRequiredMsg("Please choose different time");
          errorOccuredDisableSubmitButton();
        } else if (matchedIndex1.length === 0) {
          updateMultipleFormValues(model, id);
        } else {
          const a = compareTwoArraysOfObjects(matchedIndex2, matchedIndex1);
          if (a) {
            setErrorId(id);
            setEndTimeRequiredMsg("Please choose different time");
            errorOccuredDisableSubmitButton();
          } else {
            const index = multipleFormValues.findIndex((el) => el.id === id);
            if (index !== -1) {
              updateMultipleFormValues(model, id);
            } else {
              callUpdateNewMultipleFormValue(model, id);
            }
          }
        }
      }
      clearError();
    } catch (error) {
      setErrorId(id);
      setErrorValidateRound(error.message);
      errorOccuredDisableSubmitButton();
    }
  };

  const updateMultipleFormValues = (modelData, idx) => {
    setErrorId(null);
    toast.success(`Match ${idx} saved successfully`, toastrOptions);
    callUpdateDuplicatesMultipleFormValue(modelData);
  };
  const handleInvalidSubmit = (event, errors, values, id) => {
    //Date comparison for time validation
    const isFutureDate = compareDate(minEstDate, values.startDate);
    callToCheckForFutureDate(isFutureDate, startTimeobj, id);
  };
  const errorHandle = () => {
    clearError();
  };
  const compareTwoDateTime = (current, user, errorid) => {
    const isCurrentTimeOrFutureTime = compareDateTime(current, user);
    if (isCurrentTimeOrFutureTime) {
      setErrorId(errorid);
      setEndTimeRequiredMsg("Please select future time");
      errorOccuredDisableSubmitButton();
    } else {
      setErrorId(null);
      setEndTimeRequiredMsg("");
    }
  };

  function compareDate(currentDate, userInputDate) {
    let dateResult = compareDateForTimeValidation(currentDate, userInputDate);
    return dateResult;
  }
  function compareDateTime(presentTime, userInputTime) {
    let dateTimeResult = compareDateTournamentForBestOfMatches(
      presentTime,
      userInputTime
    );
    return dateTimeResult;
  }
  return (
    <Row className="add-lobby-row">
      <Col className="col-sm-12">
        {maxSubMatchLength === null || maxSubMatchLength === 0 ? (
          <CreatePrizes
            BestOfMatches={true}
            matchId={matchId}
            count={count}
            resetError={errorHandle}
          />
        ) : null}
        {countForForm === null ? null : (
          <Accordion allowZeroExpanded className="add-tournament-schedule">
            {createMatchesTable(countForForm).map((prizeList, index) =>
              prizeList.map((item, idx) => {
                return (
                  <div id={idx}>
                    <AvForm
                      onValidSubmit={(e, v) => {
                        handleValidSubmit(e, v, item.id);
                      }}
                      onInvalidSubmit={(e, er, v) => {
                        handleInvalidSubmit(e, er, v, item.id);
                      }}
                      id={item.id}
                    >
                      <AccordionItem key={item.id} uuid={item.id}>
                        <AccordionItemHeading>
                          <AccordionItemButton>
                            {item.match}
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel accordionId={item.id}>
                          <Row className="mb-3">
                            <div className="col-sm-12 col-md-6">
                              <AvField
                                name="map"
                                label="Map"
                                placeholder="Enter map here"
                                type="text"
                                // validate={{
                                //   required: {
                                //     value: false,
                                //     errorMessage: "Map is required",
                                //   },
                                // }}
                              />
                            </div>
                            <div className="col-sm-12 col-md-6">
                              <AvField
                                name="startDate"
                                label="Start Date (EDT)*"
                                placeholder="DD-MM-YYYY"
                                type="date"
                                validate={{
                                  required: {
                                    value: true,
                                    errorMessage: "Start Date is required",
                                  },
                                  min: { value: minEstDate },
                                }}
                                onChange={(e) => {
                                  handleStartDate(e.target.value, item.id);
                                }}
                                id={`startDate${item.id}`}
                              />
                            </div>
                          </Row>

                          <Row className="mb-3">
                            <div className="col-sm-12 col-md-6">
                              <FormGroup>
                                <Label id={item.id}>Start Time (EDT)* </Label>
                                <InputGroup id={item.id}>
                                  <Flatpickr
                                    className="form-control d-block startTimeBackground"
                                    placeholder="Enter start time"
                                    defaultValue={
                                      timeList[index].start_Time === null
                                        ? fixedStartTime
                                        : timeList[index].start_Time
                                    }
                                    name={`startTime${item.id}`}
                                    required
                                    options={{
                                      enableTime: true,
                                      noCalendar: true,
                                      minuteIncrement: 1,
                                      dateFormat: "h:i K",
                                      timeZone: "America/New_York",
                                      //time_24hr: true,
                                    }}
                                    onChange={(e, value, options) => {
                                      addMinutes(
                                        options.latestSelectedDateObj,
                                        item.id
                                      );
                                    }}
                                  />
                                </InputGroup>
                                {endTimeRequiredMsg === "" ? null : errorId !==
                                  item.id ? null : (
                                  <label
                                    className={
                                      errorId === item.id
                                        ? "errorMsgGames invalid-feedback"
                                        : ""
                                    }
                                    id={item.id}
                                  >
                                    {errorId === item.id
                                      ? endTimeRequiredMsg
                                      : ""}
                                  </label>
                                )}
                              </FormGroup>
                            </div>
                            <div className="col-sm-12 col-md-6">
                              <FormGroup>
                                <Label id={item.id}>
                                  Last Entry Time (EDT)*{" "}
                                </Label>
                                <InputGroup id={item.id}>
                                  <Flatpickr
                                    className="form-control d-block"
                                    placeholder="Enter last entry time"
                                    value={
                                      timeList[index].end_Time === null
                                        ? fixedEndTime
                                        : timeList[index].end_Time
                                    }
                                    name={`endTime${item.id}`}
                                    required
                                    options={{
                                      enableTime: true,
                                      noCalendar: true,
                                      minuteIncrement: 5,
                                      dateFormat: "h:i K",
                                      timeZone: "America/New_York",
                                      //time_24hr: true,
                                    }}
                                    // onChange={(e, value) => {
                                    //  // setEndTime(value);
                                    // }}
                                    disabled
                                  />
                                </InputGroup>
                                {endTimeRequiredMsg === "" ? null : errorId !==
                                  item.id ? null : (
                                  <label
                                    className={
                                      errorId === item.id
                                        ? "errorMsgGames invalid-feedback"
                                        : ""
                                    }
                                    id={item.id}
                                  >
                                    {errorId === item.id
                                      ? endTimeRequiredMsg.length === 28 ||
                                        endTimeRequiredMsg.length === 25
                                        ? endTimeRequiredMsg
                                        : "Last entry time is required"
                                      : ""}
                                  </label>
                                )}
                              </FormGroup>
                            </div>
                          </Row>

                          <Row className="mb-3">
                            <div className="col-sm-12 col-md-6">
                              <AvField
                                name="admin_code"
                                label="Admin Code*"
                                placeholder="Enter admin code here"
                                type="text"
                                validate={{
                                  required: {
                                    value:
                                      gameslug === "fortnite" ||
                                      gameslug === "cod-mobile" ||
                                      gameslug === "pubg-mobile" ||
                                      gameslug === "valorant" ||
                                      gameslug == "call-of-duty-warzone"
                                        ? false
                                        : true,
                                    errorMessage: "Admin code is required",
                                  },
                                }}
                                disabled={
                                  gameslug === "fortnite" ||
                                  gameslug === "valorant" ||
                                  gameslug == "call-of-duty-warzone"
                                    ? true
                                    : false
                                }
                              />
                            </div>
                            <div className="col-sm-12 col-md-6">
                              <AvField
                                name="participant_code"
                                label="Participant Code*"
                                placeholder="Enter participant code here"
                                type="text"
                                validate={{
                                  required: {
                                    value:
                                      gameslug === "fortnite" ||
                                      gameslug === "cod-mobile" ||
                                      gameslug === "pubg-mobile" ||
                                      gameslug === "valorant" ||
                                      gameslug == "call-of-duty-warzone"
                                        ? false
                                        : true,
                                    errorMessage:
                                      "Participant code is required",
                                  },
                                }}
                                disabled={
                                  gameslug === "valorant" ||
                                  gameslug == "call-of-duty-warzone"
                                    ? true
                                    : false
                                }
                              />
                            </div>
                          </Row>
                          <div className="mb-3">
                            <AvField
                              name="stats_code"
                              label={
                                <>
                                  <IMG data={gameslug} />
                                </>
                              }
                              placeholder="Enter statistic code here"
                              type="text"
                              validate={{
                                required: {
                                  value:
                                    gameslug === "fortnite" ||
                                    gameslug === "cod-mobile" ||
                                    gameslug === "valorant" ||
                                    gameslug === "pubg" ||
                                    gameslug === "pubg-mobile"
                                      ? false
                                      : true,

                                  errorMessage: "Statistic code is required",
                                },
                              }}
                              disabled={
                                gameslug === "fortnite" ||
                                gameslug === "cod-mobile" ||
                                gameslug === "valorant" ||
                                gameslug === "pubg" ||
                                gameslug === "pubg-mobile"
                                  ? true
                                  : false
                              }
                            />
                          </div>
                          <Label
                            className={
                              errorId === item.id
                                ? "errorMsgGames invalid-feedback"
                                : ""
                            }
                          >
                            {errorId === item.id ? errorValidateRound : ""}
                          </Label>
                          <FormGroup className="mt-4">
                            <div>
                              {disableSave ? (
                                <Button
                                  type="submit"
                                  color="primary"
                                  className="ms-1"
                                  disabled={true}
                                  id={item.id}
                                >
                                  {disableSave && (
                                    <span className="spinner-border spinner-border-sm mr-4"></span>
                                  )}{" "}
                                  Save
                                </Button>
                              ) : (
                                <Button
                                  type="submit"
                                  color="primary"
                                  className="ms-1"
                                  id={item.id}
                                  disabled={
                                    errorId === null || errorId === undefined
                                      ? false
                                      : true
                                  }
                                >
                                  Save
                                </Button>
                              )}
                            </div>
                          </FormGroup>
                        </AccordionItemPanel>
                      </AccordionItem>
                    </AvForm>
                  </div>
                );
              })
            )}
          </Accordion>
        )}
        <Label className={Error !== "" ? "errorMsgGames invalid-feedback" : ""}>
          {Error}
        </Label>
      </Col>
    </Row>
  );
};

export default AddBestOfMatches;
