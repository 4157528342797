import React, { useState } from "react";
import {
  AvForm,
  AvCheckboxGroup,
  AvCheckbox,
  AvRadioGroup,
  AvRadio,
} from "availity-reactstrap-validation";
import { Row, FormGroup, Button } from "reactstrap";
import { addManualWinner } from "../../../services/bracket_tournaments_api_helper";
import toastrOptions from "../../../helpers/toastr-options/toastr-options";
import { toast } from "react-toastify";

const AddManualWinner = ({
  teams,
  matchId,
  isTraditional,
  successCallBack,
  closeAddWinnerModal,
  threshold,
}) => {
  const [disabled, setDisabled] = useState(false);
  const handleValidSubmit = async (event, values) => {
    event.preventDefault();
    const data = {
      teams: isTraditional ? [values.teamSelection] : values.teamSelection,
    };
    await addManualWinner(matchId, data)
      .then(() => {
        successCallBack();
        closeAddWinnerModal();
        setDisabled(false);
        toast.success("Winner Added Successfully", toastrOptions);
      })
      .catch((err) => {
        setDisabled(false);
      });
  };

  const [error, setError] = useState("");
  const thresholdValidation = (value) => {
    const thresholdValue = threshold;
    if (value.length > thresholdValue) {
      setError(" You cannot select more winners than the threshold.");
      return false;
    } else {
      setError(" ");
      return true;
    }
  };
  return (
    <Row className="add-lobby-row">
      <AvForm
        onValidSubmit={(e, v) => {
          handleValidSubmit(e, v);
        }}
      >
        {isTraditional ? (
          <AvRadioGroup
            name="teamSelection"
            required
            errorMessage="Please select a team."
          >
            {teams &&
              teams.map((teamList, index) => (
                <div className="d-grid removePLayer" id={index} key={index}>
                  <AvRadio label={teamList.name} value={teamList.id} required />
                </div>
              ))}
          </AvRadioGroup>
        ) : (
          <AvCheckboxGroup
            name="teamSelection"
            required
            errorMessage={
              error !== ""
                ? `Please select at least ${threshold} team.${error}`
                : `Please select at least ${threshold} team.`
            }
            validate={{ thresholdValidation }}
          >
            {teams &&
              teams.map((teamList, index) => (
                <div className="d-grid removePLayer" id={index} key={index}>
                  <AvCheckbox
                    label={teamList.name}
                    value={teamList.id}
                    required
                  />
                </div>
              ))}
          </AvCheckboxGroup>
        )}
        <FormGroup>
          <Button
            type="submit"
            color="primary"
            className="mt-4"
            disabled={disabled}
          >
            {disabled && (
              <span className="spinner-border spinner-border-sm mr-4"></span>
            )}{" "}
            Submit
          </Button>
        </FormGroup>
      </AvForm>
    </Row>
  );
};

export default AddManualWinner;
