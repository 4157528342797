import { SET_GAMES } from "./actionTypes";

const initialState = {
  game: {
    ppkGames: [],
    killRaceGames: [],
    traditionalGames: [],
    allGames: [],
    modeObj: {},
  },
};

const gameReducer = (state = initialState, { type, payload } = {}) => {
  if (type === SET_GAMES) {
    return { ...state, game: payload };
  } else {
    return (state = { ...state });
  }
};

export default gameReducer;
