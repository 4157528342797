import React, { useEffect, useState } from "react";
import {
  getCommunityList,
  deleteCommunity,
} from "../../services/community_api_helper";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Link } from "react-router-dom";
import { Row, Col, Card, CardBody } from "reactstrap";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import Paginator from "../../helpers/paginator/paginator";

// Alert
import SweetAlert from "react-bootstrap-sweetalert";
import { toast } from "react-toastify";
import toastrOptions from "../../helpers/toastr-options/toastr-options";
import FilterPermission from "../../helpers/FilterPermission";
import { filterOutPermissionToShowHide } from "../../helpers/PermissionUtils";
import {
  permissionsStrings,
  SUCCESS_MESSAGES,
} from "../../helpers/StringConstant";
import { isEmpty } from "lodash";
const CommunityList = (props) => {
  const [community, setCommunity] = useState([]);
  const [isLoading, setisLoading] = useState(false);
  const [totalCount, settotalCount] = useState(null);
  const [deleteId, setDeleteId] = useState(null);
  const [deleteModal, setdeleteModal] = useState(false);
  const [disableSwal2, setDisabled2] = useState(false);
  //filter keys
  const pageSize = 10;
  const [pageNumber, setpageNumber] = useState(1);
  const [changePermission, setChange] = useState(false);
  const [deletePermission, setDelete] = useState(false);
  const [addPermission, setAdd] = useState(false);
  useEffect(() => {
    getList();
    if (isEmpty(props.permission)) {
      setChange(true);
      setDelete(true);
      setAdd(true);
    } else {
      callSetPermission();
    }
  }, [pageNumber]);
  const callSetPermission = () => {
    const type = permissionsStrings.communityVideo;
    const typeChange = permissionsStrings.typeCommunityChange;
    const typeDelete = permissionsStrings.typeCommunityDelete;
    const typeAdd = permissionsStrings.typeCommunityAdd;
    const filteredPermission = FilterPermission(props.permission, type);
    if (filteredPermission.length !== 0) {
      const setchange = filterOutPermissionToShowHide(
        filteredPermission[0].permissions,
        typeChange
      );
      const setdelete = filterOutPermissionToShowHide(
        filteredPermission[0].permissions,
        typeDelete
      );
      const setadd = filterOutPermissionToShowHide(
        filteredPermission[0].permissions,
        typeAdd
      );

      setChange(setchange);
      setDelete(setdelete);
      setAdd(setadd);
    }
  };
  const getList = async () => {
    const filterModel = {
      pageSize: pageSize,
      pageNumber: pageNumber,
    };
    setisLoading(true);
    await getCommunityList(filterModel)
      .then((res) => {
        setCommunity(res.data);
        settotalCount(res.total);
        setisLoading(false);
      })
      .catch(() => {
        setisLoading(false);
      });
  };

  const handlePageClick = (pageNum) => {
    setpageNumber(pageNum);
  };
  const openAlert = (id) => {
    setDeleteId(id);
    setdeleteModal(true);
  };
  async function deleteCommunitySwal() {
    setisLoading(true);
    setDisabled2(true);
    setdeleteModal(false);
    await deleteCommunity(deleteId)
      .then((res) => {
        getList();
        toast.success(SUCCESS_MESSAGES.communitySuccess, toastrOptions);
        setdeleteModal(false);
        setDisabled2(false);
        setisLoading(false);
      })
      .catch(() => {
        setdeleteModal(false);
        setDisabled2(false);
        setisLoading(false);
      });
  }
  const closeAlert = () => {
    setdeleteModal(false);
  };
  return (
    <>
      <React.Fragment>
        <div className="page-content">
          <Breadcrumbs breadcrumbItem="Manage Community" />
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <Row className="search-box">
                    {addPermission ? (
                      <Col className="col-xl-2 col-lg-3 col-md-3 col-sm-3">
                        <Link to="/community/add">
                          <button className="btn btn-primary">
                            {" "}
                            Add Videos Clips{" "}
                          </button>
                        </Link>
                      </Col>
                    ) : null}
                  </Row>
                  <div className="table-rep-plugin">
                    <div
                      className="table-responsive mb-0"
                      data-pattern="priority-columns"
                    >
                      <Table
                        id="tech-companies-1"
                        className="table table-striped table-bordered manage-complaints-table"
                      >
                        <Thead>
                          <Tr>
                            <Th data-priority="1">Sr.No.</Th>
                            <Th data-priority="1">Youtube Link</Th>
                            <Th data-priority="1">Lobby Name</Th>
                            <Th data-priority="1">Username</Th>
                            <Th data-priority="1">Create Date</Th>
                            <Th data-priority="6">Description</Th>
                            <Th data-priority="6">Pinned Video</Th>
                            {changePermission || deletePermission ? (
                              <Th data-priority="1" colSpan="2">
                                Actions
                              </Th>
                            ) : null}
                          </Tr>
                        </Thead>
                        <Tbody>
                          {isLoading ? (
                            <div
                              class="spinner-grow transaction-spinner"
                              role="status"
                            >
                              <span class="sr-only">Loading...</span>
                            </div>
                          ) : community.length === 0 ? (
                            <h5 className="text-center mt-4">
                              No community(s) Found
                            </h5>
                          ) : (
                            community.map((item, index) => {
                              const date = new Date(item?.create_date);
                              const options = {
                                weekday: "short",
                                day: "numeric",
                                year: "numeric",
                                month: "long",
                                hour: "numeric",
                                minute: "numeric",
                                second: "numeric",
                                timeZone: "UTC",
                                hour12: false,
                              };
                              const dateTime = date.toLocaleString(
                                "en-US",
                                options
                              );
                              return (
                                <Tr key={index}>
                                  <td>
                                    {pageSize * (pageNumber - 1) + index + 1}
                                  </td>
                                  <Td>{item?.youtube_link}</Td>
                                  <Td>{item?.lobby_name}</Td>
                                  <Td>{item?.username}</Td>
                                  <Td>{dateTime}</Td>
                                  <Td>{item?.description}</Td>
                                  <Td>{item?.pinned ? "True" : "False"}</Td>
                                  {changePermission ? (
                                    <Td>
                                      <Link
                                        className="btn btn-sm complaint-view-btn btn-secondary"
                                        role="button"
                                        to={`/community/edit/${item?.id}`}
                                      >
                                        Edit {"  "}
                                      </Link>
                                    </Td>
                                  ) : null}
                                  {deletePermission ? (
                                    <Td className="location-delete-btn">
                                      <button
                                        className="btn btn-sm complaint-view-btn btn-secondary"
                                        onClick={() => {
                                          openAlert(item.id);
                                        }}
                                      >
                                        Delete
                                      </button>
                                    </Td>
                                  ) : null}
                                </Tr>
                              );
                            })
                          )}
                        </Tbody>
                      </Table>
                    </div>
                  </div>

                  <Paginator
                    totalCount={totalCount}
                    pageSize={pageSize}
                    pageClick={handlePageClick}
                    forcePage={pageNumber}
                  />
                </CardBody>
                {deleteModal ? (
                  <SweetAlert
                    title="Delete Community"
                    warning
                    showCancel
                    confirmButtonText="Yes"
                    confirmBtnBsStyle="success"
                    cancelButtonText="No"
                    cancelBtnBsStyle="danger"
                    onConfirm={() => deleteCommunitySwal()}
                    onCancel={() => closeAlert()}
                    disabled={disableSwal2}
                  ></SweetAlert>
                ) : null}
              </Card>
            </Col>
          </Row>
        </div>
      </React.Fragment>
    </>
  );
};

export default CommunityList;
