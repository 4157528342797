import React from "react";
import PropTypes from "prop-types";

function Loader(props) {
  return (
    <React.Fragment>
      {props.showLoader ? (
        <div id={props.isTransparent ? "transparent-loader" : "preloader"}>
          <div id="status">
            <div className="spinner-chase">
              <div className="chase-dot"></div>
              <div className="chase-dot"></div>
              <div className="chase-dot"></div>
              <div className="chase-dot"></div>
              <div className="chase-dot"></div>
              <div className="chase-dot"></div>
            </div>
          </div>
        </div>
      ) : null}
    </React.Fragment>
  );
}

Loader.propTypes = {
  showLoader: PropTypes.bool,
};

export default Loader;
