// Palyer's deopsit amonut -> 'money_deposit_to_walltes'
// Player's withdrawn amount -> 'money_transferred_from_walltes'
// Amount distributed in player's wallet -> 'total_distributed_money'
// Ryvals fee paid by players -> 'fees_paid_to_ryvals'
// Seized wallet amount -> 'seized_wallet_money'
// Winnnng amount distributed -> 'winning_amount_distributed'
// Referral amount distributed -> 'referral_amount_distributed'
export const reportCardsConstant = [
  {
    type: 1,
    cardLabels: [
      {
        units: "$",
        label: "Players' Deposit Amount",
        key: "money_deposit_to_walltes",
        tileKey: 1,
      },
      {
        units: "$",
        label: "Players' Withdrawn Amount",
        key: "money_transferred_from_walltes",
        tileKey: 2,
      },
      {
        units: "$",
        label: "Amount Distributed in Players' Wallet",
        key: "total_distributed_money",
        tileKey: 3,
      },
      {
        units: "$",
        label: "Ryvals Fee paid by Players'",
        key: "fees_paid_to_ryvals",
        tileKey: 4,
      },
      {
        units: "$",
        label: "Seized Wallet Amount",
        key: "seized_wallet_money",
        tileKey: 5,
      },
      {
        units: "$",
        label: "Winning Amount Distributed",
        key: "winning_amount_distributed",
        tileKey: 6,
      },
      {
        units: "$",
        label: "Referral Amount Distributed",
        key: "referral_amount_distributed",
        tileKey: 7,
      },

      {
        units: "$",
        label: "Unclaimed Money",
        key: "unclaimed_money",
        tileKey: 8,
      },
      {
        units: "$",
        label: "Seized Winning Money",
        key: "seized_winning_money",
        tileKey: 10,
      },
      {
        units: "$",
        label: "Dormant Money",
        key: "dormant_money",
        tileKey: 11,
      },
      {
        units: "$",
        label: "Inactivity Charges",
        key: "inactivity_charges",
        tileKey: 12,
      },
    ],
  },
  {
    type: 2,
    cardLabels: [
      {
        label: "Total Users",
        key: "total_users",
        tileKey: 1,
      },
      {
        label: "New users signed up on website ",
        key: "recent_user",
        tileKey: 2,
      },
      {
        label: "Users signed up through referrals",
        key: "referrals",
        tileKey: 3,
      },
    ],
  },
  {
    type: 3,
    cardLabels: [
      {
        label: "Total Created lobbies",
        key: "total_created_lobbies_count",
        tileKey: 1,
      },
      {
        label: "Played lobbies",
        key: "played_lobbies_count",
        tileKey: 2,
      },
      {
        label: "Cancelled lobbies",
        key: "cancelled_lobbies_count",
        tileKey: 3,
      },
      {
        label: "Refunded lobbies ",
        key: "refunded_lobbies_count",
        tileKey: 4,
      },
      {
        label: "Upcoming lobbies",
        key: "upcoming_lobbies_count",
        tileKey: 5,
      },
      {
        label: "Running lobbies",
        key: "active_lobbies_count",
        tileKey: 6,
      },
      {
        label: "Paid lobbies",
        key: "paid_lobbies_count",
        tileKey: 8,
      },
      {
        label: "Free lobbies",
        key: "free_lobbies_count",
        tileKey: 7,
      },
    ],
  },

  {
    type: 4,
    cardLabels: [
      {
        label: "Total complaints received",
        key: "total_cmp",
        tileKey: 1,
      },
      {
        label: "Resolved complaints",
        key: "resolved_cmp",
        tileKey: 2,
      },
      {
        label: "Unresolved complaints",
        key: "unresolved_cmp",
        tileKey: 3,
      },
    ],
  },
];
