import React, { useEffect, useState } from "react";

import {
  Row,
  Col,
  Card,
  CardBody,
  Modal,
  ModalBody,
  DropdownToggle,
  DropdownMenu,
  Dropdown,
  DropdownItem,
  CustomInput,
} from "reactstrap";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import {
  getDemoList,
  deleteSelectedDemoApi,
  updateDemoPagePinStatus,
  getPinnedList,
} from "../../services/demo_api_helper";
import { getGameList, updateGameData } from "../../services/game_api_helper";
import { Link } from "react-router-dom";

// Paginator
import Paginator from "../../helpers/paginator/paginator";
// import switch
import Switch from "react-switch";

// Alert
import SweetAlert from "react-bootstrap-sweetalert";

import { toast } from "react-toastify";
import toastrOptions from "../../helpers/toastr-options/toastr-options";
import { uploadFile } from "react-s3";
import FilterPermission from "../../helpers/FilterPermission";
import { filterOutPermissionToShowHide } from "../../helpers/PermissionUtils";
import {
  ERROR_MESSAGES,
  permissionsStrings,
  SUCCESS_MESSAGES,
} from "../../helpers/StringConstant";
import { OffSymbol } from "../../components/Switch/OffSymbol";
import { OnSymbol } from "../../components/Switch/OnSymbol";
import ClearButton from "../../components/ClearButton";
import { getFilterGame } from "../../helpers/util";

const config = {
  bucketName: process.env.REACT_APP_S3_BUCKET_NAME,
  region: process.env.REACT_APP_S3_REGION_NAME,
  accessKeyId: process.env.REACT_APP_S3_ACCESS_KEY,
  secretAccessKey: process.env.REACT_APP_S3_SECRET_KEY,
};
const DemoPageList = (props) => {
  const [demo, setDemo] = useState([]);
  const [singlebtnGame, setSinglebtnGame] = useState(false);
  const [selectedDropdownByGameName, setselectedDropdownByGameName] =
    useState(null);
  const [isLoading, setisLoading] = useState(false);
  const [totalCount, settotalCount] = useState(null);

  //filter keys
  const pageSize = 10;
  const [pageNumber, setpageNumber] = useState(1);
  const [searchByGame, setSearchByGame] = useState(null);

  const [games, setGames] = useState([]);

  const [pinDemo, setPinDemo] = useState(false);
  const [changePermission, setChange] = useState(false);
  const [deletePermission, setDelete] = useState(false);
  const [addPermission, setAdd] = useState(false);
  useEffect(() => {
    getDemoListing();
    if (props.permission.length !== 0) {
      callSetPermission();
    } else {
      setChange(true);
      setDelete(true);
      setAdd(true);
    }
  }, [
    searchByGame,
    // gameFilter,
    pageNumber,
  ]);
  const callSetPermission = () => {
    const type = permissionsStrings.gameDemo;
    const typeChange = permissionsStrings.typeDemoChange;
    const typeDelete = permissionsStrings.typeDemoDelete;
    const typeAdd = permissionsStrings.typeDemoAdd;
    const filteredPermission = FilterPermission(props.permission, type);
    if (filteredPermission.length !== 0) {
      const setchange = filterOutPermissionToShowHide(
        filteredPermission[0].permissions,
        typeChange
      );
      const setdelete = filterOutPermissionToShowHide(
        filteredPermission[0].permissions,
        typeDelete
      );

      const setadd = filterOutPermissionToShowHide(
        filteredPermission[0].permissions,
        typeAdd
      );
      setChange(setchange);
      setDelete(setdelete);
      setAdd(setadd);
    }
  };
  async function getDemoListing() {
    setisLoading(true);
    setIsloadingForImages(true);
    const filters = filterModel();
    await getDemoList(filters)
      .then((res) => {
        setDemo(res.data);
        settotalCount(res.total);
        setisLoading(false);
      })
      .catch(() => {
        setisLoading(false);
      });
    getGameList().then((res) => {
      setIsloadingForImages(false);
      setGames(res);
    });
    getPinnedList().then((res) => {
      setOptions(res.results);
      setTotalCountPinned(res.total);
      if (res.length != 0)
        setMaxPinCount(res?.results[0]?.max_allowed_pinned_demo);
    });
  }
  const [options, setOptions] = useState({});
  const [maxPin, setMaxPinCount] = useState(null);
  const [totalCountPinVideo, setTotalCountPinned] = useState(null);

  const dropdownChangeByGameName = (value) => {
    setselectedDropdownByGameName(value);
    setSearchByGame(value);
  };
  function filterModel() {
    return {
      pageSize: pageSize,
      pageNumber: pageNumber,
    };
  }

  const handlePageClick = (pageNum) => {
    setpageNumber(pageNum);
  };
  const [pinIndex, setPinIndex] = useState(null);
  const [replaceModal, setReplaceModal] = useState(false);
  const [demoIdToBePinned, setDemoIdToBePinned] = useState(null);
  const handlePinChange = async (index, e, data) => {
    if (maxPin === totalCountPinVideo && e) {
      setReplaceModal(true);
      setDemoIdToBePinned(data.id);
    } else {
      setPinIndex(index);
      setPinDemo(e);
      const game_id = data.id;
      let model = {
        is_pinned: e,
      };
      await updateDemoPagePinStatus(game_id, model).then(
        () => {
          toast.success(SUCCESS_MESSAGES.gameDemoSuccess, toastrOptions);
          getDemoListing();
          setPinIndex(null);
        },
        (err) => {
          toast.error(err, toastrOptions);
          setPinIndex(null);
        }
      );
    }
  };
  const handleClose = () => {
    setReplaceModal(false);
    setReplaceOnTable(null);
  };
  const handleStatusChange = async (index, e, statusData) => {
    const game_id = statusData.id;
    let model = {
      status: e ? 1 : 2,
    };
    await updateDemoPagePinStatus(game_id, model).then(
      (data) => {
        toast.success(SUCCESS_MESSAGES.gameDemoSuccess, toastrOptions);
        getDemoListing();
      },
      (err) => {
        toast.error(err, toastrOptions);
      }
    );
  };
  const [selectedDemo, setselectedDemo] = useState(null);
  const openAlert = (selectedDemoAlert) => {
    setselectedDemo(selectedDemoAlert);
    setDeleteModal(true);
  };

  const [deleteModal, setDeleteModal] = useState(false);
  const [disableSwal, setDisabled] = useState(false);

  const deleteSelectedDemo = async () => {
    setDisabled(true);
    setDeleteModal(false);
    setisLoading(true);
    await deleteSelectedDemoApi(selectedDemo)
      .then(() => {
        setDisabled(false);
        closeAlert();
        getDemoListing();
        toast.success(SUCCESS_MESSAGES.gameDemoDeleteSuccess, toastrOptions);
      })
      .catch(() => {
        setDisabled(false);
        closeAlert();
        setisLoading(false);
      });
  };

  const closeAlert = () => {
    setDeleteModal(false);
  };
  const [replaceId, setReplaceOnTable] = useState(null);

  const handleRadio = (e, id) => {
    setReplaceOnTable(e.target.value);
  };
  const handleSave = async () => {
    const game_id = demoIdToBePinned;
    let model = {
      replace_with: parseInt(replaceId),
      is_pinned: true,
    };
    await updateDemoPagePinStatus(game_id, model).then(
      (data) => {
        toast.success(SUCCESS_MESSAGES.gameDemoSuccess, toastrOptions);
        getDemoListing();
        setReplaceOnTable(null);
        setReplaceModal(false);
      },
      (err) => {
        setReplaceOnTable(null);
        setReplaceModal(false);
      }
    );
  };
  const [disabledBackground, setDisabledBackground] = useState(false);
  const [backgroundImageFieldError, setBackgroundImageFieldError] =
    useState("");
  const [errorMsgForBackground, showErrorForBackgroundImage] = useState({
    isErrorForBackground: false,
    errorMsgForBackground: "",
  });
  const [indexCheck, setIndexCheck] = useState(null);
  const [isLoadingForImages, setIsloadingForImages] = useState();
  const onUploadBackgroundFile = (event, index, item) => {
    setDisabledBackground(true);
    event.preventDefault();
    //Check whether the file is valid Image.
    var regex = new RegExp("([a-zA-Z0-9s_\\.-:])+(.jpg|.jpeg|.png)$");
    if (regex.test(event.target.value.toLowerCase())) {
      const reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = function (e) {
        //Initiate the JavaScript Image object..
        var image = new Image();
        //Set the Base64 string return from FileReader as source.
        image.src = e.target.result;
        //Validate the File Height and Width.
        image.onload = function () {
          setIsloadingForImages(true);
          uploadFile(event.target.files[0], config).then(function (data) {
            setDisabledBackground(false);
            showErrorForBackgroundImage({
              isErrorForBackground: false,
              errorMsgForBackground: "",
            });
            setIndexCheck(null);
            let model = {
              game_demo_background_image: data.location,
              background_image: item.background_image,
              game_rules: item.game_rules,
              helper_image: item.helper_image,
            };
            updateGameData(model, item.id)
              .then(() => {
                setIsloadingForImages(false);
                toast.success(SUCCESS_MESSAGES.imageSuccess, toastrOptions);
                getGameList().then((res) => {
                  setGames(res);
                });
              })
              .catch((err) => {
                setIsloadingForImages(false);
              });
            return data;
          });
          return true;
        };
      };
    } else {
      showErrorForBackgroundImage({
        isErrorForBackground: true,
        errorMsgForBackground: ERROR_MESSAGES.validFile,
      });
      setDisabledBackground(true);
      setIndexCheck(index);
      return false;
    }
    setBackgroundImageFieldError("");
    setDisabledBackground(false);
    showErrorForBackgroundImage({
      isErrorForBackground: false,
      errorMsgForBackground: "",
    });
  };
  const handleClear = () => {
    setpageNumber(1);
    setSearchByGame(null);
    setselectedDropdownByGameName(null);
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <Breadcrumbs breadcrumbItem="Manage Demo" />
        <Row>
          <Col className="col-12">
            <Card>
              <CardBody>
                <div className="table-rep-plugin">
                  <div
                    className="table-responsive mb-0"
                    data-pattern="priority-columns"
                  >
                    <h4>Game Background Image</h4>
                    <Table
                      id="tech-companies-1"
                      className="table table-striped table-bordered lobbies-table"
                    >
                      <Thead>
                        <Tr>
                          <Th>Game</Th>
                          {changePermission ? (
                            <Th data-priority="3">Upload Background Image</Th>
                          ) : null}
                          <Th data-priority="3">Thumbnail</Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {isLoadingForImages ? (
                          <div
                            class="spinner-grow transaction-spinner"
                            role="status"
                          >
                            <span class="sr-only">Loading...</span>
                          </div>
                        ) : games.length === 0 ? (
                          <h5 className="text-center mt-4">No game(s) Found</h5>
                        ) : (
                          games.map((item, i) => {
                            return (
                              <Tr key={i}>
                                <Td className="username-field">{item.name}</Td>

                                {changePermission ? (
                                  <Td>
                                    <CustomInput
                                      className="image-picker-game-flow"
                                      type="file"
                                      id={`exampleCustomFileBrowser${i}`}
                                      name="customFile"
                                      label={"Choose an image file"}
                                      onChange={(e) =>
                                        onUploadBackgroundFile(e, i, item)
                                      }
                                      disabled={
                                        indexCheck === i
                                          ? false
                                          : disabledBackground
                                      }
                                    />
                                    {indexCheck === i ? (
                                      <>
                                        {errorMsgForBackground.isErrorForBackground ? null : (
                                          <label>
                                            {
                                              errorMsgForBackground.errorMsgForBackground
                                            }
                                          </label>
                                        )}
                                        <label className="errorMsgGames">
                                          {backgroundImageFieldError?.length ===
                                          0
                                            ? errorMsgForBackground.errorMsgForBackground
                                            : backgroundImageFieldError}
                                        </label>
                                      </>
                                    ) : (
                                      <label>
                                        Width and Height dimension must be
                                        920x470
                                      </label>
                                    )}
                                  </Td>
                                ) : null}
                                <Td>
                                  <img
                                    alt="Imagebg"
                                    src={item.game_demo_background_image}
                                    className="img-thumbnail"
                                    style={{ height: "100px", width: "100px" }}
                                  />
                                </Td>
                              </Tr>
                            );
                          })
                        )}
                      </Tbody>
                    </Table>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col className="col-12">
            <Card>
              <CardBody>
                <Row className="search-box">
                  <Col className="col-xl-3 col-lg-4 col-md-4 col-sm-3 col-xs-6">
                    <Dropdown
                      isOpen={singlebtnGame}
                      toggle={() => setSinglebtnGame(!singlebtnGame)}
                    >
                      <DropdownToggle
                        caret
                        color="primary"
                        className="dropdown-toggle-split sort-by-btn"
                      >
                        <span>
                          {selectedDropdownByGameName === null
                            ? "Game"
                            : getFilterGame(games, selectedDropdownByGameName)}
                        </span>
                        <i className="mdi mdi-chevron-down" />
                      </DropdownToggle>
                      <DropdownMenu>
                        {games.map((item, index) => {
                          return (
                            <DropdownItem
                              key={index}
                              onClick={() => dropdownChangeByGameName(item.id)}
                              dropdownvalue={item.id}
                              disabled={item.game_status === 2 ? true : false}
                            >
                              {item.name}
                              &nbsp; &nbsp;
                              {selectedDropdownByGameName === item.id ? (
                                <i className="mdi mdi-check-circle-outline"></i>
                              ) : null}
                            </DropdownItem>
                          );
                        })}
                      </DropdownMenu>
                    </Dropdown>
                  </Col>
                  <Col className="col-xl-2 col-lg-3 col-md-3 col-sm-3 col-xs-6 mb-4">
                    <ClearButton handleClear={handleClear} />
                  </Col>
                  {addPermission ? (
                    <Col className="col-xl-2 col-lg-3 col-md-3 col-sm-3 col-xs-6">
                      <Link to="/gameDemo/add">
                        <button className="btn btn-primary add-lobby-btn">
                          {" "}
                          Add Demo{" "}
                        </button>
                      </Link>
                    </Col>
                  ) : null}
                </Row>

                <div className="table-rep-plugin">
                  <div
                    className="table-responsive mb-0"
                    data-pattern="priority-columns"
                  >
                    <h4>Game Demos</h4>
                    <Table
                      id="tech-companies-1"
                      className="table table-striped table-bordered lobbies-table"
                    >
                      <Thead>
                        <Tr>
                          <Th>Sr.No</Th>
                          <Th>Game</Th>
                          <Th data-priority="3">Title</Th>
                          <Th data-priority="3">Video Link</Th>

                          {changePermission || deletePermission ? (
                            <>
                              <Th data-priority="3">Pin Video</Th>
                              <Th data-priority="3">Status</Th>
                              <Th data-priority="6" colSpan="2">
                                Actions
                              </Th>
                            </>
                          ) : null}
                        </Tr>
                      </Thead>
                      <Tbody>
                        {isLoading ? (
                          <div
                            class="spinner-grow transaction-spinner"
                            role="status"
                          >
                            <span class="sr-only">Loading...</span>
                          </div>
                        ) : demo.length === 0 ? (
                          <h5 className="text-center mt-4">No Demo(s) Found</h5>
                        ) : (
                          demo.map((item, index) => (
                            <Tr key={index}>
                              <Td>{(pageNumber - 1) * 10 + index + 1}</Td>
                              <Td className="username-field">
                                {item.game.name}
                              </Td>
                              <Td>{item.title || "NA"}</Td>
                              <Td>{item.video || "NA"}</Td>
                              {changePermission ? (
                                <>
                                  <Td>
                                    <Switch
                                      uncheckedIcon={<OffSymbol />}
                                      checkedIcon={<OnSymbol />}
                                      onColor="#626ed4"
                                      className={
                                        pinIndex === index
                                          ? pinDemo
                                          : item.is_pinned
                                          ? "form-label active-toggle"
                                          : "form-label"
                                      }
                                      onChange={(e) => {
                                        handlePinChange(index, e, item);
                                      }}
                                      checked={
                                        pinIndex === index
                                          ? pinDemo
                                          : item.is_pinned
                                      }
                                    />
                                  </Td>
                                  <Td>
                                    <Switch
                                      uncheckedIcon={<OffSymbol />}
                                      checkedIcon={<OnSymbol />}
                                      onColor="#626ed4"
                                      className={
                                        item.status === 1
                                          ? "form-label active-toggle"
                                          : "form-label"
                                      }
                                      onChange={(e) => {
                                        handleStatusChange(index, e, item);
                                      }}
                                      checked={item.status === 1 ? true : false}
                                    />
                                  </Td>
                                  <Td>
                                    <Link
                                      className="btn btn-sm complaint-view-btn btn-secondary"
                                      role="button"
                                      to={`/gameDemo/edit/${item?.id}`}
                                    >
                                      Edit {"  "}
                                    </Link>
                                  </Td>
                                </>
                              ) : null}
                              {deletePermission ? (
                                <Td className="location-delete-btn">
                                  <button
                                    className="btn btn-sm complaint-view-btn btn-secondary"
                                    onClick={() => {
                                      openAlert(item.id);
                                    }}
                                  >
                                    Delete
                                  </button>
                                </Td>
                              ) : null}
                            </Tr>
                          ))
                        )}
                      </Tbody>
                    </Table>
                  </div>
                </div>
                <Paginator
                  totalCount={totalCount}
                  pageSize={pageSize}
                  pageClick={handlePageClick}
                  forcePage={pageNumber}
                />
              </CardBody>
              {deleteModal ? (
                <SweetAlert
                  title="Delete Demo"
                  warning
                  showCancel
                  confirmButtonText="Yes"
                  confirmBtnBsStyle="success"
                  cancelButtonText="No"
                  cancelBtnBsStyle="danger"
                  onConfirm={() => deleteSelectedDemo()}
                  onCancel={() => closeAlert()}
                  disabled={disableSwal}
                >
                  Are you sure you want to delete this demo
                </SweetAlert>
              ) : null}
              {replaceModal ? (
                <Modal
                  className="pinVideoModal"
                  isOpen={replaceModal}
                  toggle={() => {
                    handleClose();
                  }}
                  onHide={() => handleClose()}
                >
                  <ModalBody className="py-3 px-5">
                    <h2 className="text-center">
                      Please Unpin one demo to Pin another one
                    </h2>
                    <button
                      className="crossButton previewCrossIcon"
                      onClick={() => {
                        handleClose();
                      }}
                    >
                      X
                    </button>
                    {options.map((item, id) => {
                      return (
                        <div className="d-flex justify-content-between">
                          <div>
                            <label>{id + 1}</label>
                            {"     "}

                            <label>{item.title}</label>
                          </div>
                          <input
                            class="form-check-input"
                            type="radio"
                            name="exampleRadios"
                            id="exampleRadios2"
                            value={item.id}
                            onClick={(e) => handleRadio(e)}
                          />
                        </div>
                      );
                    })}
                    <div className="d-flex mt-4">
                      <button
                        className="btn btn-primary add-lobby-btn demoCancelbtn"
                        onClick={() => handleClose()}
                      >
                        {" "}
                        Cancel
                      </button>
                      <button
                        className="btn btn-primary add-lobby-btn"
                        onClick={() => handleSave()}
                        disabled={replaceId === null ? true : false}
                      >
                        {" "}
                        Save
                      </button>
                    </div>
                  </ModalBody>
                </Modal>
              ) : null}
            </Card>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};

export default DemoPageList;
