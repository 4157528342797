import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

//i18n
import { withTranslation } from "react-i18next";
import SidebarContent from "./SidebarContent";
const Sidebar = (props) => {
  const [username, setusername] = useState(null);
  const [permissions, setPermissions] = useState("");
  useEffect(() => {
    if (localStorage.getItem("authUser")) {
      const obj = JSON.parse(localStorage.getItem("authUser"));
      setusername(obj.extras.first_name + " " + obj.extras.last_name);
      setPermissions(obj.extras.permissions);
    }
  }, [props.success]);

  return (
    <React.Fragment>
      <div className="vertical-menu">
        <div className="h-100">
          <div className="user-wid text-center py-4">
            <div className="mt-3">
              <p className="text-dark fw-medium font-size-24 username asdfgh">
                {username}
              </p>
            </div>
          </div>
          <div data-simplebar className="h-100">
            <SidebarContent permissions={permissions} />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

Sidebar.propTypes = {
  type: PropTypes.string,
};

const mapStatetoProps = (state) => {
  return {
    layout: state.Layout,
  };
};
export default connect(
  mapStatetoProps,
  {}
)(withRouter(withTranslation()(Sidebar)));
