import React, { useState } from "react";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { FormGroup, Button } from "reactstrap";
import { toast } from "react-toastify";
import toastrOptions from "../../../helpers/toastr-options/toastr-options";

import tickIcon from "../../../assets/images/user-tick-icon.svg";
import crossIcon from "../../../assets/images/user-cross-icon.svg";
import IconsToolTip from "../../../components/IconsToolTip";
import { updateWinningPercentageOfRound } from "../../../services/bracket_tournaments_api_helper";
const AddWinningPercentage = ({
  roundDetails,
  SaveWinningPercentageOfRound,
  CloseWinningPercentageOfRound,
}) => {
  const [value, setValue] = useState(roundDetails.round_percent);
  const handleChange = (val) => {
    setValue(val);
  };
  const handleSubmit = async (event, values) => {
    event.preventDefault();
    let data = {
      round_percentage: Number(values.winningPercentage),
    };
    await updateWinningPercentageOfRound(
      roundDetails.tournament_id,
      roundDetails.id,
      data
    )
      .then(() => {
        SaveWinningPercentageOfRound();
        CloseWinningPercentageOfRound();
        toast.success("Winning percentage updated successfully", toastrOptions);
      })
      .catch(() => {
        toast.error("Error", toastrOptions);
      });
  };
  const handleKeyDown = (e) => {
    if (e.key === "." || e.key === "-") {
      e.preventDefault();
    }
  };
  return (
    <>
      <AvForm
        className="d-flex editEarning-timeline"
        onValidSubmit={(e, v) => {
          handleSubmit(e, v);
        }}
      >
        <AvField
          className="winningPercentage"
          name="winningPercentage"
          label="Winning Percentage*"
          placeholder="Winning Percentage"
          type="text"
          validate={{
            required: {
              value: true,
              errorMessage: "Winning Percentage is required",
            },
            number: {
              value: true,
              errorMessage: "Please enter number only",
            },
            min: {
              value: 1,
              errorMessage: "Winning Percentage should be greater than 0",
            },
            max: {
              value: 1000,
              errorMessage:
                "Winning Percentage should be less than or equal to 1000",
            },
          }}
          onKeyDown={(e) => handleKeyDown(e)}
          onChange={(e) => handleChange(e.target.value)}
          defaultValue={value}
        />
        <FormGroup>
          <div className="mt-4 d-flex">
            <Button
              type="submit"
              color="primary"
              className="edit-icon info-icon"
            >
              <IconsToolTip
                image={tickIcon}
                altImageText="user-tick-icon"
                text="Save"
              />
            </Button>
            <Button
              className="edit-icon mt-2 ms-0 px-0 info-icon"
              onClick={() => {
                CloseWinningPercentageOfRound();
              }}
            >
              <IconsToolTip
                image={crossIcon}
                altImageText="user-cross-icon"
                text="Cancel"
              />
            </Button>
          </div>
        </FormGroup>
      </AvForm>
    </>
  );
};
export default AddWinningPercentage;
