import React, { useState } from "react";
import { Button, Modal, ModalBody, ModalHeader, ModalFooter } from "reactstrap";
import AddBestOfMatches from "../Form/AddBestOfMatches";
import { useDispatch, useSelector } from "react-redux";
import { addSchedule } from "../../../services/bracket_tournaments_api_helper";
import toastrOptions from "../../../helpers/toastr-options/toastr-options";
import { toast } from "react-toastify";
import ViewMatches from "./ViewMatches";
import {
  setClearScheduleList,
  updateScheduleList,
} from "../../../store/actions";
import ChangeAssignedAdmin from "./ChangeAssignedAdmin";
import edit from "../../../assets/images/edit.svg";
import disableEditIcon from "../../../assets/images/disableEditIcon.svg";
import ManuallyAddWinnerModal from "../Modal/ManuallyAddWinnerModal";
import { isEmpty } from "lodash";
import ViewMoreTeamsModal from "../Modal/ViewMoreTeamsModal";
function TournamentScheduleTable({
  matches,
  tournamentId,
  roundId,
  gameslug,
  callApi,
  currentStatus,
  allSchedule,
  nameOfGame,
  tournamentDetails,
  teamsInRound,
}) {
  const scheduleList = useSelector((state) => state);
  const [addBestOfMatchesModal, setAddBestOfMatchesModal] = useState(false);
  const [modalId, setModalId] = useState();
  const openModal = (values) => {
    setModalId(values.id);
    setAddBestOfMatchesModal(true);
  };
  const closeModal = () => {
    setAddBestOfMatchesModal(false);
    dispatch(setClearScheduleList());
    clearError();
  };
  const [showLoadder, setShowLoadder] = useState(false);
  const [showerror, setShowError] = useState("");
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [disableSaveOnSubmit, setDisableSaveOnSubmit] = useState(false);
  const dispatch = useDispatch();
  if (scheduleList.ScheduleList.schedule.length > scheduleList.count.count) {
    dispatch(updateScheduleList());
  }
  const submitSchedule = async () => {
    setDisableSubmit(true);
    setDisableSaveOnSubmit(true);
    if (
      scheduleList.count.count === scheduleList.ScheduleList.schedule.length
    ) {
      setShowLoadder(true);
      let model = { data: scheduleList.ScheduleList?.schedule };
      await addSchedule(model, tournamentId, roundId, modalId).then(
        () => {
          dispatch(setClearScheduleList());
          toast.success("Best of matches added successfully", toastrOptions);
          closeModal();
          setShowLoadder(false);
          callApi();
          setDisableSubmit(false);
          setDisableSaveOnSubmit(false);
        },
        () => {
          setShowLoadder(false);
          setDisableSubmit(false);
          setDisableSaveOnSubmit(false);
        }
      );
    } else {
      setShowError("Please fill remaining matches");
      setDisableSubmit(false);
      setDisableSaveOnSubmit(false);
    }
  };

  const [viewMatchesModal, setViewMatchesModal] = useState(false);
  const [viewMatchesList, setViewMatchesList] = useState();
  const openViewMatchesModal = (values) => {
    setViewMatchesList(values);
    setViewMatchesModal(true);
  };
  const closeViewMatchesModal = () => {
    setViewMatchesModal(false);
  };

  const clearError = () => {
    setShowError("");
    setDisableSubmit(false);
  };
  const handleSaveAssignedAdmin = () => {
    callApi();
    setAdminIndexTochange(null);
  };
  const handleCloseAssignedAdmin = () => {
    setAdminIndexTochange(null);
  };
  const [adminIndexToChange, setAdminIndexTochange] = useState(null);
  const handleDisableSubmit = () => {
    setDisableSubmit(true);
  };
  const [matchIdForAddManualWinner, setMatchIdForAddManualWinner] = useState(0);
  const [addManualWinnerModal, setAddManualWinnerModal] = useState(false);
  const [filteredTeamFromMatchInfo, setFilteredTeamFromMatchInfo] = useState(
    []
  );

  const openManualWinnerModal = (matchid) => {
    setMatchIdForAddManualWinner(matchid);

    const matchWithSameId =
      tournamentDetails && tournamentDetails.match_info
        ? tournamentDetails.match_info.find((match) =>
            match.matches.some((m) => m.match_id === matchid)
          )
        : null;
    if (!matchWithSameId) {
      return null;
    }
    const specificMatch = matchWithSameId.matches.find(
      (m) => m.match_id === matchid
    );
    const teamsArray = specificMatch.teams.map((team) => ({
      id: team.team_id,
      name: team.name,
    }));
    //  const filteredMatches =
    //       tournamentDetails &&
    //       tournamentDetails.match_info.filter((match) =>
    //         match.matches.some((m) => m.match_id === matchid)
    //       );
    //     const teamIdsSet = new Set();
    //     const teamsArray = filteredMatches.flatMap((match) =>
    //       match.matches.flatMap((m) =>
    //         m.teams
    //           .filter((team) => {
    //             if (!teamIdsSet.has(team.team_id)) {
    //               teamIdsSet.add(team.team_id);
    //               return true;
    //             }
    //             return false;
    //           })
    //           .map((team) => ({
    //             id: team.team_id,
    //             name: team.name,
    //           }))
    //       )
    //     );
    setFilteredTeamFromMatchInfo(teamsArray);
    setAddManualWinnerModal(true);
  };
  const closeManualWinnerModal = () => {
    setMatchIdForAddManualWinner(0);
    setAddManualWinnerModal(false);
  };
  const handleRecallapi = () => {
    callApi();
  };
  const [showMore, setShowMore] = useState(false);
  const [showMoreTeam, setShowMoreTeamData] = useState([]);

  const handleShowMore = (showMoreTeamData) => {
    setShowMore(true);
    setShowMoreTeamData(showMoreTeamData);
  };

  const handleClose = () => {
    setShowMore(false);
    setShowMoreTeamData([]);
  };
  return (
    <div className="table-rep-plugin tournamentRound">
      <div className="table-responsive mb-0" data-pattern="priority-columns">
        <div className="table-responsrowspanive seize-wallet-table">
          <table className="table table-striped table-bordered manage-complaints-table lobbies-table add-schedule">
            <thead>
              <tr>
                <th scope="col" rowSpan="2">
                  Match
                </th>
                <th scope="col" rowSpan="2">
                  Start Date Time
                </th>
                <th scope="col" rowSpan="2">
                  End Date Time
                </th>
                <th scope="col" rowSpan="2">
                  Admin
                </th>
                <th scope="col" rowSpan="2">
                  Best of Matches
                </th>
                <th scope="col" rowSpan="2">
                  Winner
                </th>
              </tr>
            </thead>
            <tbody>
              {showLoadder ? (
                <div class="spinner-grow transaction-spinner" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              ) : (
                matches
                  .sort((first, second) => {
                    return first.id > second.id ? 1 : -1;
                  })
                  .map((item, index) => {
                    let winnerDisplay;
                    if (item.winner_set_manually) {
                      if (isEmpty(item.winner)) {
                        winnerDisplay = (
                          <Button
                            className={
                              currentStatus === 3
                                ? "btn btn-sm  btn-secondary disabled disabled-link"
                                : "btn btn-sm complaint-view-btn btn-secondary"
                            }
                            id={index}
                            disabled={true}
                          >
                            {isEmpty(teamsInRound)
                              ? "No Teams Present"
                              : "No Winner Found"}
                          </Button>
                        );
                      } else {
                        winnerDisplay = (
                          <>
                            {" "}
                            {item.winner.slice(0, 3).map((winnerList, indx) => {
                              return <li id={indx}>{winnerList.team}</li>;
                            })}
                            {item?.winner?.length > 3 ? (
                              <Button
                                onClick={() => handleShowMore(item.winner)}
                                variant="link"
                                className="btn btn-primary btn-sm view-button theme-color mt-2"
                              >
                                View more
                              </Button>
                            ) : null}
                          </>
                        );
                      }
                    } else {
                      winnerDisplay = (
                        <Button
                          className={
                            "btn btn-sm complaint-view-btn btn-secondary"
                          }
                          onClick={() => openManualWinnerModal(item.id)}
                          id={index}
                          disabled={
                            item.sub_match.length === 0 || isEmpty(teamsInRound)
                          }
                        >
                          {isEmpty(teamsInRound)
                            ? "No Teams Present"
                            : "Add Winner"}
                        </Button>
                      );
                    }
                    return (
                      <tr key={index}>
                        <td className="text-capitalize">{item.match}</td>
                        <td className="text-capitalize">
                          {item.round_datetime.start_datetime || "NA"}
                        </td>
                        <td className="text-capitalize">
                          {item.round_datetime.end_datetime || "NA"}
                        </td>
                        <td className="text-capitalize">
                          <div className="d-flex justify-content-between tournamentRound-data">
                            {item.admin !== null
                              ? item.admin.username
                              : "Please select admin"}
                            <Button
                              type="button"
                              color={
                                item.is_admin_editable ? "primary" : "secondary"
                              }
                              className="edit-icon"
                              onClick={(e) => {
                                if (!item.is_admin_editable) {
                                  e.preventDefault();
                                } else {
                                  setAdminIndexTochange(index);
                                }
                              }}
                              disabled={!item.is_admin_editable}
                            >
                              <img
                                alt="edit-Admin-icon"
                                src={
                                  item.is_admin_editable
                                    ? edit
                                    : disableEditIcon
                                }
                              />
                            </Button>
                          </div>
                          {adminIndexToChange ===
                          null ? null : adminIndexToChange === index ? (
                            <ChangeAssignedAdmin
                              TournamentId={tournamentId}
                              MatchId={item.id}
                              SaveAssignedAdmin={handleSaveAssignedAdmin}
                              CloseAssignedAdmin={handleCloseAssignedAdmin}
                            />
                          ) : null}
                        </td>
                        <td>
                          {item.sub_match.length === 0 ? (
                            <Button
                              className={
                                currentStatus === 3
                                  ? "btn btn-sm  btn-secondary disabled disabled-link"
                                  : "btn btn-sm complaint-view-btn btn-secondary"
                              }
                              onClick={() => openModal(item)}
                              id={index}
                              disabled={currentStatus === 3}
                            >
                              Add Match
                            </Button>
                          ) : (
                            <Button
                              className="btn btn-sm complaint-view-btn btn-secondary"
                              onClick={() => openViewMatchesModal(item)}
                              id={index}
                            >
                              {item.sub_match.length}
                              {item.sub_match.length > 1
                                ? " Matches"
                                : " Match"}
                            </Button>
                          )}
                        </td>

                        <td>{winnerDisplay}</td>
                      </tr>
                    );
                  })
              )}
            </tbody>
          </table>
        </div>
      </div>

      <Modal
        isOpen={addBestOfMatchesModal}
        scrollable={true}
        className="custom-modal w-706 addbestofmatches"
      >
        <ModalHeader toggle={closeModal} tag="h4">
          Add Best of Matches
        </ModalHeader>
        <>
          <ModalBody className="events">
            <AddBestOfMatches
              matchId={modalId}
              gameslug={gameslug}
              Error={showerror}
              clearError={clearError}
              allSchedule={allSchedule}
              roundId={roundId}
              disableSave={disableSaveOnSubmit}
              errorOccuredDisableSubmitButton={handleDisableSubmit}
              tournamentDetails={tournamentDetails}
            />
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={closeModal}>
              Cancel
            </Button>
            <Button
              color="primary"
              onClick={submitSchedule}
              disabled={
                disableSubmit
                  ? disableSubmit
                  : scheduleList.count.count === 0
                  ? true
                  : scheduleList.count.count ===
                    scheduleList.ScheduleList?.schedule?.length
                  ? false
                  : true
                // scheduleList.ScheduleList?.schedule,
                // scheduleList.ScheduleList?.schedule?.length

                // scheduleList.count.count === 0 ||
                // isNaN(scheduleList.count.count) ||
                // disableSubmit
              }
            >
              {showLoadder && (
                <span className="spinner-border spinner-border-sm mr-4"></span>
              )}{" "}
              Submit
            </Button>{" "}
          </ModalFooter>
        </>
      </Modal>

      <Modal
        isOpen={viewMatchesModal}
        scrollable={true}
        className="custom-modal"
      >
        <ModalHeader toggle={closeViewMatchesModal} tag="h4">
          View {viewMatchesList?.sub_match?.length > 1 ? "Matches" : "Match"}
        </ModalHeader>
        <ModalBody className="events">
          <ViewMatches
            matchData={viewMatchesList}
            gameslug={gameslug}
            nameOfGame={nameOfGame}
            isPPK={
              tournamentDetails && tournamentDetails?.game_type === "PPK"
                ? true
                : false
            }
            successCallBack={handleRecallapi}
          />
        </ModalBody>
      </Modal>
      <Modal
        isOpen={addManualWinnerModal}
        scrollable={true}
        className="custom-modal"
      >
        <ModalHeader toggle={closeManualWinnerModal} tag="h4">
          Add Winner
        </ModalHeader>
        <ModalBody className="events">
          {isEmpty(teamsInRound) ? null : (
            <ManuallyAddWinnerModal
              teams={filteredTeamFromMatchInfo}
              matchId={matchIdForAddManualWinner}
              gameTypeIsTraditional={
                tournamentDetails &&
                tournamentDetails?.game_type === "Traditional"
                  ? true
                  : false
              }
              successCallBack={handleRecallapi}
              closeAddWinnerModal={closeManualWinnerModal}
              threshold={
                tournamentDetails &&
                tournamentDetails?.ppk_team_selection_threshold
              }
            />
          )}
        </ModalBody>
      </Modal>

      <ViewMoreTeamsModal
        show={showMore}
        teamList={showMoreTeam}
        closeModal={handleClose}
        roundTable={true}
      />
    </div>
  );
}

export default TournamentScheduleTable;
