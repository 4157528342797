import { axiosApi } from "./index";
const urlTournament = process.env.REACT_APP_BASEURL + `tournament/tournament/`;
const urlTournamentBaseUrl = process.env.REACT_APP_BASEURL;
export async function getBracketsAndTournamentsDropdownList() {
  //const url = urlTournament + `tournament/`;
  return await axiosApi.get(urlTournament);
}
export async function addTournament(model) {
  //const url = urlTournament + `tournament/`;
  return await axiosApi.post(urlTournament, model);
}
export async function editTournament(id, model) {
  //const url = urlTournament + `tournament/`;
  return await axiosApi.put(`${urlTournament}${id}/`, model);
}
export async function getTournamentList(filterModel) {
  let params = new URLSearchParams();
  if (filterModel.from_date) params.append("from_date", filterModel.from_date);
  if (filterModel.to_date) params.append("to_date", filterModel.to_date);
  if (filterModel.gameType) params.append("gametype", filterModel.gameType);
  if (filterModel.game) params.append("game", filterModel.game);
  if (filterModel.bracket) params.append("bracket", filterModel.bracket);
  if (filterModel.seeding) params.append("seeding", filterModel.seeding);
  if (filterModel.free) params.append("free", filterModel.free);
  if (filterModel.type) params.append("type", filterModel.type);
  if (filterModel.pageSize) params.append("page_size", filterModel.pageSize);
  if (filterModel.pageNumber) params.append("page", filterModel.pageNumber);
  return await axiosApi.get(urlTournament, { params }).then((response) => {
    return { data: response.results, total: response.total };
  });
}
export async function getTournamentRoundList(id) {
  return await axiosApi.get(`${urlTournament}${id}/round/`);
}
export async function addSchedule(model, tournamentId, roundId, modalId) {
  return await axiosApi.post(
    `${urlTournament}${tournamentId}/round/${roundId}/match/${modalId}/add-matches/`,
    model
  );
}
export async function getAdminUserListTournamentForDropdown(id) {
  return await axiosApi.get(`${urlTournament}${id}/admins/`);
}
export async function updateAssignedAdminForTournament(
  tournamentId,
  matchId,
  model
) {
  return await axiosApi.put(
    `${urlTournament}${tournamentId}/match/${matchId}/`,
    model
  );
}
export async function getTournamentData(id) {
  return await axiosApi.get(`${urlTournament}${id}/`);
}
export async function deleteTournament(tournamentId) {
  return await axiosApi.delete(`${urlTournament}${tournamentId}/`);
}
export async function getTournamentsById(id) {
  return await axiosApi.get(`${urlTournament}${id}/`);
}
export async function removeTeamFromTournament(tournamentId, data) {
  return await axiosApi.put(
    `${urlTournamentBaseUrl}tournament/${tournamentId}/remove-team/`,
    data
  );
}
export async function sendManualRankByAdmin(tournamentId, data) {
  return await axiosApi.put(
    `${urlTournamentBaseUrl}tournament/${tournamentId}/seeding/`,
    data
  );
}
export async function getTournamentforQualifierSeeding(slug, ppt) {
  return await axiosApi.get(
    `${urlTournamentBaseUrl}tournament/qualifier-seeding/${slug}/${ppt}/`
  );
}

export async function getTournamentsForLeaderBoard({
  q = "",
  limit = 10,
  offset = 0,
  type = "",
}) {
  return await axiosApi.get(
    `${urlTournamentBaseUrl}tournament/tournament-scroll/?q=${q}&limit=${limit}&offset=${offset}&type=${type}`
  );
}
export async function getLeaderboardStandings({
  page,
  page_size,
  tournament_id,
}) {
  let params = new URLSearchParams();

  if (page_size) params.append("page_size", page_size);
  if (page) params.append("page", page);
  return await axiosApi.get(`${urlTournament}leaderboard/${tournament_id}`, {
    params,
  });
}

export async function getBracketsTempalate(bracketId) {
  let params = new URLSearchParams();
  params.append("type", "admin");
  return await axiosApi.get(
    `${urlTournamentBaseUrl}tournament/iframe/${bracketId}/template/`,
    { params }
  );
}
export async function addManualWinner(matchId, model) {
  return await axiosApi.post(`${urlTournament}${matchId}/add-winners/`, model);
}
export async function updateWinningPercentageOfRound(
  tournamentId,
  roundId,
  model
) {
  return await axiosApi.put(
    `${urlTournament}${tournamentId}/round/${roundId}/percent/`,
    model
  );
}
