import {
  SET_SCHEDULE_LIST,
  SET_SCHEDULE_COUNT,
  UPDATE_SCHEDULE_LIST,
  SET_CLEAR_SCHEDULE_LIST,
} from "./actionTypes";

const initialState = {
  schedule: [],
  count: 0,
};

const scheduleReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SCHEDULE_LIST: {
      return {
        ...state,
        schedule:
          action === undefined
            ? []
            : action.payload
                .sort((a, b) => a.id - b.id)
                .map(({ id, ...rest }) => ({
                  ...rest,
                })),
      };
    }
    case SET_SCHEDULE_COUNT: {
      return { ...state, count: action.payload };
    }
    case UPDATE_SCHEDULE_LIST:
      return {
        ...state,
        schedule: [...state.schedule.slice(0, state.count)],
      };
    case SET_CLEAR_SCHEDULE_LIST:
      return {
        count: 0,
        schedule: [],
      };
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default scheduleReducer;
