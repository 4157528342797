import React, { useState } from "react";
import {
  checkIfLinkIsNeeded,
  checkLastEntryTimeForSubmatchesEdit,
  convertDateFormat,
} from "./utils";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { Button } from "reactstrap";
import edit from "../../../assets/images/edit.svg";
import disableEditIcon from "../../../assets/images/disableEditIcon.svg";
import UpdateAdminCode from "./UpdateAdminCode";
const ViewMatches = ({
  matchData,
  gameslug,
  nameOfGame,
  isPPK,
  successCallBack,
}) => {
  const isLinkRequired = checkIfLinkIsNeeded(gameslug);
  const history = useHistory();
  const handleClick = (lobbyid) => {
    const data = {
      prevPath: window.location.pathname,
      nameOfGame: nameOfGame,
      redirectName: "/tournament",
      active: "2",
      hideButton: true,
      isPPK: isPPK,
    };

    const url = `/lobby/${lobbyid}?data=${encodeURIComponent(
      JSON.stringify(data)
    )}`;
    history.push(url);
  };

  const [editAdminCodeIndex, setEditAdminCodeIndex] = useState(null);
  const [editParticipantCodeIndex, setEditParticipantCodeIndex] =
    useState(null);
  const handleSaveEditAdminCode = (newValue) => {
    const updatedMatchData = { ...matchData };
    updatedMatchData.sub_match[editAdminCodeIndex].lobby.admin_code = newValue;
    successCallBack(updatedMatchData);
    setEditAdminCodeIndex(null);
  };
  const handleCloseEditAdminCode = () => {
    setEditAdminCodeIndex(null);
  };
  const handleSaveEditParticipantCode = (newValue) => {
    const updatedMatchData = { ...matchData };
    updatedMatchData.sub_match[
      editParticipantCodeIndex
    ].lobby.participant_code = newValue;
    successCallBack(updatedMatchData);
    setEditParticipantCodeIndex(null);
  };
  const handleCloseEditParticipantCode = () => {
    setEditParticipantCodeIndex(null);
  };
  return (
    <div className="table-rep-plugin">
      <div className="table-responsive mb-0" data-pattern="priority-columns">
        <div className="table-responsive seize-wallet-table">
          <table className="table table-striped table-bordered manage-complaints-table lobbies-table">
            <thead>
              <tr>
                <th scope="col" rowspan="2">
                  Match
                </th>
                <th scope="col" rowspan="2">
                  Start Date Time
                </th>
                <th scope="col" rowspan="2">
                  End Date Time
                </th>
                <th scope="col" rowspan="2">
                  Admin code
                </th>
                <th scope="col" rowspan="2">
                  Statistical Code
                </th>
                <th scope="col" rowspan="2">
                  Participant Code
                </th>
                <th scope="col" rowspan="2">
                  Map
                </th>
                {isLinkRequired ? (
                  <th scope="col" rowspan="2">
                    View Details
                  </th>
                ) : null}
              </tr>
            </thead>
            <tbody>
              {matchData.sub_match.map((item, index) => {
                const isLastEntryTimeGreater =
                  checkLastEntryTimeForSubmatchesEdit(
                    item.lobby.start_date,
                    item.lobby.last_entry_time
                  );
                return (
                  <tr key={index} id={index}>
                    <td className="text-capitalize">
                      {item.sub_match}
                      <span className="text-lowercase"> of</span>{" "}
                      {matchData.match}
                    </td>
                    <td>
                      {convertDateFormat(item.lobby.start_date)}{" "}
                      {item.lobby.start_time}
                    </td>
                    <td>
                      {item.lobby.end_date === null
                        ? "NA"
                        : `${convertDateFormat(item.lobby.start_date)} ${
                            item.lobby.end_time
                          }`}
                    </td>
                    <td>
                      {editAdminCodeIndex === null ||
                      editAdminCodeIndex !== index ? (
                        <div className="d-flex gap-2 admin-code">
                          {item.lobby.admin_code || "NA"}
                          {gameslug === "cod-mobile" ||
                          gameslug === "pubg-mobile" ? (
                            <Button
                              type="button"
                              color={
                                isLastEntryTimeGreater ? "primary" : "secondary"
                              }
                              className="edit-icon"
                              onClick={(e) => {
                                if (!isLastEntryTimeGreater) {
                                  e.preventDefault();
                                } else {
                                  setEditAdminCodeIndex(index);
                                }
                              }}
                              disabled={!isLastEntryTimeGreater}
                            >
                              <img
                                alt="edit-Admin-icon"
                                src={
                                  isLastEntryTimeGreater
                                    ? edit
                                    : disableEditIcon
                                }
                              />
                            </Button>
                          ) : null}
                        </div>
                      ) : editAdminCodeIndex === index ? (
                        <UpdateAdminCode
                          isAdminCode={true}
                          lobbyData={item.lobby}
                          SaveAdminCode={handleSaveEditAdminCode}
                          CloseAdminCode={handleCloseEditAdminCode}
                        />
                      ) : null}
                    </td>
                    <td>{item.lobby.stats_code || "NA"}</td>
                    <td>
                      {editParticipantCodeIndex === null ||
                      editParticipantCodeIndex !== index ? (
                        <div className="d-flex gap-2 admin-code">
                          {item.lobby.participant_code || "NA"}
                          {gameslug === "cod-mobile" ||
                          gameslug === "pubg-mobile" ? (
                            <Button
                              type="button"
                              color={
                                isLastEntryTimeGreater ? "primary" : "secondary"
                              }
                              className="edit-icon"
                              onClick={(e) => {
                                if (!isLastEntryTimeGreater) {
                                  e.preventDefault();
                                } else {
                                  setEditParticipantCodeIndex(index);
                                }
                              }}
                              disabled={!isLastEntryTimeGreater}
                            >
                              <img
                                alt="edit-Admin-icon"
                                src={
                                  isLastEntryTimeGreater
                                    ? edit
                                    : disableEditIcon
                                }
                              />
                            </Button>
                          ) : null}
                        </div>
                      ) : editParticipantCodeIndex === index ? (
                        <UpdateAdminCode
                          isAdminCode={false}
                          lobbyData={item.lobby}
                          SaveAdminCode={handleSaveEditParticipantCode}
                          CloseAdminCode={handleCloseEditParticipantCode}
                        />
                      ) : null}
                    </td>
                    <td>{item.lobby.map || "NA"}</td>

                    {isLinkRequired ? (
                      <td>
                        <Button
                          className="px-0 pt-0 pb-0 text-start"
                          color="link"
                          onClick={() => handleClick(item?.lobby?.lobby_id)}
                        >
                          {item?.lobby?.name}
                        </Button>
                      </td>
                    ) : null}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default ViewMatches;
