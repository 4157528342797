import React from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import ViewMoreTeamTable from "../Table/ViewMoreTeamTable";
function ViewMoreTeamsModal({ show, teamList, closeModal, roundTable }) {
  return (
    <Modal isOpen={show} scrollable={true} className="custom-modal w-706">
      <ModalHeader toggle={closeModal} tag="h4" className="text-capitalize">
        Teams
      </ModalHeader>

      <ModalBody className="events">
        <div className="view-more-teams">
          <ViewMoreTeamTable teamList={teamList} roundTable={roundTable} />
        </div>
      </ModalBody>
    </Modal>
  );
}

export default ViewMoreTeamsModal;
