import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";

import { Row, Col, Alert, Container } from "reactstrap";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { AvForm, AvField } from "availity-reactstrap-validation";
import logo from "../../assets/images/ryvals-logo.png";
import { login } from "../../services/auth_api_helper";
import PermissionPath from "../../helpers/PermissionPath";
const Login = (props) => {
  const [errorMessage, seterrorMessage] = useState(null);
  const [isLoading, setisLoading] = useState(false);

  const [showPassword, setshowPassword] = useState(false);
  useEffect(() => {
    document.body.className = "authentication-bg";
    // remove classname when component will unmount
    return function cleanup() {
      document.body.className = "";
    };
  });

  async function handleValidSubmit(event, values) {
    event.preventDefault();
    let body = { email: values.email, password: values.password };
    toggleLoader(true);
    seterrorMessage(null);

    await login(body)
      .then((res) => {
        const redirectPath = PermissionPath();
        setTimeout(() => {
          toggleLoader(false);
          props.history.push(
            redirectPath === null ? "/users" : redirectPath[0].pathname
          );
        }, 3000);
      })
      .catch((err) => {
        toggleLoader(false);
        if (err) {
          seterrorMessage(err);
        }
      });
  }

  function toggleLoader(isLoad) {
    setisLoading(isLoad);
  }
  const toggleShow = () => {
    setshowPassword(!showPassword);
  };
  return (
    <React.Fragment>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <div className="card overflow-hidden">
                <div className="bg-login text-center">
                  <div className="bg-login-overlay"> </div>{" "}
                  <div className="position-relative">
                    <h5 className="text-white font-size-20"> Welcome Back! </h5>{" "}
                    <p className="text-white-50 mb-0"> Sign in </p>{" "}
                    <Link to="/" className="logo logo-admin mt-4">
                      <img src={logo} alt="" height="30" />
                    </Link>{" "}
                  </div>{" "}
                </div>{" "}
                <div className="card-body pt-5">
                  <div className="p-2">
                    <AvForm
                      className="form-horizontal"
                      onValidSubmit={(e, v) => {
                        handleValidSubmit(e, v);
                      }}
                    >
                      {props.error && typeof props.error === "string" ? (
                        <Alert color="danger"> {props.error} </Alert>
                      ) : null}
                      <div className="mb-3">
                        <AvField
                          name="email"
                          label="Email/Username"
                          className="form-control"
                          placeholder="Enter email/username"
                          validate={{
                            required: {
                              value: true,
                              errorMessage: "Email/username is required",
                            },
                            minLength: {
                              value: 5,
                              errorMessage:
                                "Your email/username must be between 5 and 50 characters",
                            },
                            maxLength: {
                              value: 50,
                              errorMessage:
                                "Your email/username must be between 5 and 50 characters",
                            },
                          }}
                          autoComplete="off"
                        />{" "}
                      </div>
                      <div className="mb-3">
                        <AvField
                          name="password"
                          label="Password"
                          type={showPassword ? "text" : "password"}
                          placeholder="Enter Password"
                          maxLength="50"
                          validate={{
                            required: {
                              value: true,
                              errorMessage: "Password is required",
                            },
                          }}
                          autoComplete="off"
                        />
                        <i
                          onClick={toggleShow}
                          class={showPassword ? "fa fa-eye" : "fa fa-eye-slash"}
                          aria-hidden="true"
                          style={{ cursor: "pointer" }}
                        ></i>
                      </div>
                      {errorMessage ? (
                        <p className="error-msg"> {errorMessage} </p>
                      ) : null}
                      <div className="mt-3">
                        <button
                          className="btn btn-primary w-100 waves-effect waves-light"
                          type="submit"
                          disabled={isLoading}
                        >
                          {isLoading && (
                            <span className="spinner-border spinner-border-sm mr-4"></span>
                          )}{" "}
                          Log In{" "}
                        </button>{" "}
                      </div>
                      <div className="mt-4 text-center">
                        <Link to="/forgot-password" className="text-muted">
                          <i className="mdi mdi-lock me-1"> </i> Forgot your
                          password ?
                        </Link>{" "}
                      </div>{" "}
                    </AvForm>{" "}
                  </div>{" "}
                </div>{" "}
              </div>{" "}
            </Col>{" "}
          </Row>{" "}
        </Container>{" "}
      </div>{" "}
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  const { error } = state.Login;
  return { error };
};

export default withRouter(connect(mapStateToProps)(Login));

Login.propTypes = {
  error: PropTypes.any,
  history: PropTypes.object,
};
