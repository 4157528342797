export const permissionsStrings = {
  //user
  userList: "Users",
  typeUserListView: "view_user",
  //cms
  staticPage: "Static Page",
  PPKFAQ: "PPK & FAQ",
  typeStaticPageChange: "change_staticpage",
  typeStaticPageView: "view_staticpage",
  typePPKFAQChange: "change_question",
  typePPKFAQView: "view_question",
  typePPKFAQAdd: "add_question",
  typePPKFAQDelete: "delete_question",
  //complaint
  complaintList: "Complaint",
  typeComplaintListView: "view_complaint",
  //location
  bannedLocation: "Banned location",
  typeBannedLocationChange: "change_restrictlocation",
  typeBannedLocationDelete: "delete_restrictlocation",
  typeBannedLocationAdd: "add_restrictlocation",
  //community
  communityVideo: "Community Video",
  typeCommunityChange: "change_communityvideo",
  typeCommunityDelete: "delete_communityvideo",
  typeCommunityAdd: "add_communityvideo",
  //Global seetings
  globalSetting: "Config",
  typeChangeConfig: "change_config",
  //Demo page
  gameDemo: "Game Demo",
  typeDemoChange: "change_gamedemo",
  typeDemoDelete: "delete_gamedemo",
  typeDemoAdd: "add_gamedemo",
  //ActivityLogs
  log: "Logs",
  //ChallengesList
  challenge: "Challenge",
  deleteChallenge: "delete_challenge",
};
export const gameType = {
  //ChallengesList
  gameTypePPW: "Pay Per Win",
};
export const SUCCESS_MESSAGES = {
  gameDemoSuccess: "Game demo updated successfully",
  gameDemoDeleteSuccess: "Game demo deleted successfully",
  imageSuccess: "Image uploaded sucessfully",
  gameDemoAddSuccess: "Game demo has been added successfully",
  gameDemoUpdateSuccess: "Game demo has been updated successfully",
  updateSuccess: "Updated successfully",
  communitySuccess: "Community video has been deleted successfully",
  addedSuccess: "Added successfully",
  deletedSuccess: "Deleted successfully",
  tAndcAddSuccess: "Terms and Conditions added successfully",
  tAndcUpdatedSuccess: "Terms and Conditions updated successfully",
  ppAddSuccess: "Privacy policy added successfully",
  ppUpdatedSuccess: "Privacy policy updated successfully",
  cpAddSuccess: "California privacy added successfully",
  cpUpdatedSuccess: "California privacy updated successfully",
};
export const ERROR_MESSAGES = {
  validFile: "Please select a valid Image file.",
  validGame: "Please select game.",
  addContent: "Please add some content",
  lobbyRequired: "Lobby name is required",
  usernameRequired: "Username is required",
};
export const MESSAGES = {
  InactivityReminder:
    "Inactivity Reminder value can't be more than Inactivity Timeline value",
  communityM: "Community",
  communityU: "Updated",
  communityA: "Added",
  communityS: " Successfully",
};
export const CONSTANT_STRINGS = {
  tANDc: "Terms and Conditions",
  PP: "Privacy policy",
  CP: "California privacy",
};
