import React from "react";
const CsvView = ({ csvContents }) => {
  const isjson = csvContents[0]?.tournamentName === "[";
  return (
    <>
      {isjson ? (
        csvContents?.map((csvItem, csvIndex) => {
          return (
            <>
              {csvItem.tournamentName}
              {csvItem.tournamentRank}
            </>
          );
        })
      ) : (
        <table className="table table-striped table-bordered responsiveTable">
          <tbody>
            {csvContents?.map((csvItem, csvIndex) => {
              return (
                <tr key={csvIndex}>
                  <>
                    {csvIndex === 0 ? (
                      <>
                        <th>{csvItem.tournamentName}</th>
                        <th>{csvItem.tournamentRank}</th>
                      </>
                    ) : (
                      <>
                        <td>{csvItem.tournamentName || "N/A"}</td>
                        <td>{csvItem.tournamentRank || "0"}</td>
                      </>
                    )}
                  </>
                </tr>
              );
            })}
          </tbody>
        </table>
      )}
    </>
  );
};
export default CsvView;
