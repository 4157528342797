import axios from "axios";
import authHeader from "../helpers/jwt-token-access/authHeader";
import { toast } from "react-toastify";
import toastrOptions from "../helpers/toastr-options/toastr-options";
//apply base url for axios
const API_URL = process.env.REACT_APP_APIURL;

export const axiosApi = axios.create({
  baseURL: API_URL,
});

axiosApi.interceptors.request.use(
  (config) => {
    config.headers["Authorization"] = authHeader();
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
// response interceptors =================================================
let isRefreshing = false;
let failedQueue = [];

axiosApi.interceptors.response.use(
  (response) => response.data,
  async (error) => {
    let errStatus = error?.response?.status;
    const originalRequest = error.config;
    if (errStatus === 500) {
      // no error message in case of refresh token expire
      if (originalRequest?.url?.includes("/token")) {
        toast.error(error.response.data.detail, toastrOptions);
        localStorage.removeItem("authUser");
        window.location.replace("/login");
        return Promise.reject(error.response.data.detail);
      } else {
        let errRes = error?.response?.data;
        let errMsg = "";
        for (const key in errRes) {
          if (Object.hasOwnProperty.call(errRes, key)) {
            if (errRes[key] instanceof Array) {
              errMsg = errMsg + errRes[key][0] + " ";
              toast.error(errMsg, toastrOptions);
            } else {
              errMsg = errMsg + errRes[key] + " ";
              toast.error(errMsg, toastrOptions);
            }
          }
        }
        return Promise.reject(errMsg);
      }
    } else if (errStatus === 401) {
      if (!isRefreshing) {
        isRefreshing = true;
        refershTokenUpdate()
          .then((res) => {
            isRefreshing = false;
            const obj = JSON.parse(localStorage.getItem("authUser"));
            obj.extras.access_token = res.access_token;
            obj.extras.refresh_token = res.refresh_token;
            localStorage.setItem("authUser", JSON.stringify({ ...obj }));
            error.config.headers[
              "Authorization"
            ] = `Bearer ${res.access_token}`;
            error.config.baseURL = undefined;
            onRrefreshed(res.access_token);
          })
          .catch((e) => {
            window.location = "/logout";
            return;
          });

        const retryOrigReq = new Promise((resolve, reject) => {
          subscribeTokenRefresh((accessToken) => {
            // replace the expired accessToken and retry
            originalRequest.headers["Authorization"] = "Bearer " + accessToken;
            resolve(axiosApi(originalRequest));
          });
        });
        return retryOrigReq;
      }
    } else {
      let errRes = error?.response?.data;
      console.log({ errRes });
      let errMsg = "";
      for (const key in errRes) {
        if (key == "status") continue;
        if (Object.hasOwnProperty.call(errRes, key)) {
          if (errRes[key] instanceof Array) {
            errMsg = errMsg + errRes[key][0] + " ";
            toast.error(errMsg, toastrOptions);
          } else if (key === "teams") {
            errMsg = errRes[key].error + " ";
            toast.error(errMsg, toastrOptions);
          } else {
            errMsg = errMsg + errRes[key] + " ";
            console.log({ errMsg });
            toast.error(errMsg, toastrOptions);
          }
        }
      }
      return Promise.reject(errMsg);
    }
  }
);
function subscribeTokenRefresh(cb) {
  failedQueue.push(cb);
}

function onRrefreshed(accessToken) {
  failedQueue.forEach((cb) => cb(accessToken));
}
export async function refershTokenUpdate() {
  const url = process.env.REACT_APP_BASEURL + `accounts/token/`;
  const obj = JSON.parse(localStorage.getItem("authUser"));
  let body = {
    refresh_token: obj.extras.refresh_token,
    access_token: obj.extras.access_token,
  };
  let headers = {
    "Content-Type": "application/json",
  };

  return await axiosApi.post(url, body, { headers });
}
