import React from "react";
// Paginator
import ReactPaginate from "react-paginate";

const Paginator = (props) => {
  const handlePageChange = ({ selected }) => {
    props.pageClick(selected + 1);
  };
  // let forcePageObj = {};
  // if (window.location.pathname == "/activitylog") {
  //   forcePageObj["forcePage"] = props.forcePage - 1;
  // }
  return (
    <>
      <div className="mt-3">
        {props.totalCount > props.pageSize ? (
          <ReactPaginate
            //{...forcePageObj}
            forcePage={props.forcePage - 1}
            pageCount={props.totalCount / props.pageSize}
            pageRangeDisplayed={3}
            marginPagesDisplayed={1}
            previousLabel={"<"}
            nextLabel={">"}
            breakLabel={"..."}
            onPageChange={handlePageChange}
            initialPage={0}
            containerClassName={"pagination"}
            pageClassName={"page-item"}
            pageLinkClassName={"page-link"}
            previousClassName={"page-item"}
            previousLinkClassName={"page-link"}
            nextClassName={"page-item"}
            nextLinkClassName={"page-link"}
            breakClassName={"page-link"}
            breakLinkClassName={"page-item"}
            activeClassName={"active"}
            activeLinkClassName={"active"}
            disabledClassName={"disabled"}
          />
        ) : null}
      </div>
    </>
  );
};

export default Paginator;
