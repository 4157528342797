import { axiosApi } from "./index";

export async function getDemoList(filterModel) {
  let params = new URLSearchParams();
  if (filterModel.gameFilter) params.append("game", filterModel.gameFilter);
  if (filterModel.pageSize) params.append("page_size", filterModel.pageSize);
  if (filterModel.pageNumber) params.append("page", filterModel.pageNumber);

  return await axiosApi.get("game-demo/", { params }).then((response) => {
    return { data: response.results, total: response.total };
  });
}
export async function getPinnedList() {
  return await axiosApi.get(`pinned-game-demo/`);
}

export async function addDemoPage(model) {
  return await axiosApi.post(`game-demo/`, model);
}

export async function deleteSelectedDemoApi(delId) {
  return await axiosApi.delete(`game-demo/${delId}/`);
}

export async function updateDemoPagePinStatus(game_id, model) {
  return await axiosApi.put(`game-demo/${game_id}/`, model);
}

export async function getEditDemoDataById(id) {
  return await axiosApi.get(`game-demo/${id}/`);
}

export async function editDemoPage(model) {
  return await axiosApi.put(`game-demo/${model.id}/`, model);
}
