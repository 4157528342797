import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import {
  Card,
  CardBody,
  Col,
  Row,
  NavLink,
  NavItem,
  Nav,
  TabContent,
  TabPane,
} from "reactstrap";
import classnames from "classnames";
import FilterPermission from "../../helpers/FilterPermission";
import { filterOutPermissionToShowHide } from "../../helpers/PermissionUtils";
import { getTournamentsById } from "../../services/bracket_tournaments_api_helper";
import { GoBack } from "../../components/GoBack";
import TournamentDetailsTab from "./Tabs/TournamentDetailsTab";
import ScheduleTab from "./Tabs/ScheduleTab";
import StandingsTab from "./Tabs/StandingsTab";
import TeamsTab from "./Tabs/TeamsTab";
import MatchInfoTab from "./Tabs/MatchInfoTab";
const TournamentDetails = (props) => {
  const returnUrl = "/tournament";
  const [tournamentDetails, setTournamentDetails] = useState(null);
  const [isLoading, setisLoading] = useState(false);
  const [changePermission, setChangePermission] = useState(false);
  const tournamentid = window.location.href;
  const tournamentId = tournamentid.substring(
    tournamentid.lastIndexOf("/") + 1
  );
  const [activeTab, setActiveTab] = useState(
    props.location.data?.active ? props.location.data?.active : "1"
  );
  useEffect(() => {
    if (tournamentId > 0) {
      getDetail();
    } else {
      goToBack();
    }
    callSetPermission();
  }, [tournamentId]);
  const getDetail = async () => {
    setisLoading(true);
    await getTournamentsById(tournamentId)
      .then((res) => {
        if (props.match.params.gameSlug === res.slug) {
          setTournamentDetails(res);
          setisLoading(false);
        } else {
          props.history.push({
            pathname: `/tournament/view/${res.slug}/${tournamentId}`,
            data: { nameOfGame: res.game },
          });
          window.location.reload();
        }
      })
      .catch(() => {
        setisLoading(false);
        goToBack();
      });
  };

  const goToBack = () => {
    props.history.push(returnUrl);
  };
  const toggle = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  const callSetPermission = () => {
    const type = "Tournament";
    const typeChange = "change_tournament";

    const filteredPermission = FilterPermission(props.permission, type);
    if (filteredPermission.length !== 0) {
      const setChange = filterOutPermissionToShowHide(
        filteredPermission[0].permissions,
        typeChange
      );
      setChangePermission(setChange);
    }
  };
  const [roundData, setRoundData] = useState([]);
  const callBackFromSchedule = (response) => {
    setRoundData(response);
  };
  return (
    <>
      <div className="page-content">
        <Breadcrumbs breadcrumbItem="Tournament Detail" />

        <GoBack backUrl="/tournament" />
        <Row>
          <Col>
            <Col lg={12}>
              <Nav tabs>
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      active: activeTab === "1",
                    })}
                    onClick={() => {
                      toggle("1");
                    }}
                  >
                    <span className="d-block d-sm-none">
                      <i className="fas fa-home"></i>
                    </span>
                    <span className="d-none d-sm-block">
                      Tournament Details
                    </span>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      active: activeTab === "2",
                    })}
                    onClick={() => {
                      toggle("2");
                    }}
                  >
                    <span className="d-block d-sm-none">
                      <i className="far fa-user"></i>
                    </span>
                    <span className="d-none d-sm-block">Schedule</span>
                  </NavLink>
                </NavItem>

                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      active: activeTab === "3",
                    })}
                    onClick={() => {
                      toggle("3");
                    }}
                  >
                    <span className="d-block d-sm-none">
                      <i className="far fa-user"></i>
                    </span>
                    <span className="d-none d-sm-block">Match Info</span>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      active: activeTab === "4",
                    })}
                    onClick={() => {
                      toggle("4");
                    }}
                  >
                    <span className="d-block d-sm-none">
                      <i className="far fa-user"></i>
                    </span>
                    <span className="d-none d-sm-block">Standings</span>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      active: activeTab === "5",
                    })}
                    onClick={() => {
                      toggle("5");
                    }}
                  >
                    <span className="d-block d-sm-none">
                      <i className="far fa-user"></i>
                    </span>
                    <span className="d-none d-sm-block">Enrolled Teams</span>
                  </NavLink>
                </NavItem>
              </Nav>
            </Col>
            {isLoading ? (
              <div class="spinner-grow transaction-spinner" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            ) : (
              tournamentDetails && (
                <TabContent
                  activeTab={activeTab}
                  className="p-3 text-muted lobby-details-view"
                  style={{
                    marginTop: "-1pc",
                    width: "103%",
                    marginLeft: "-15px",
                  }}
                >
                  {" "}
                  <TabPane tabId="1">
                    <TournamentDetailsTab details={tournamentDetails} />
                  </TabPane>
                  <TabPane
                    tabId="2"
                    className="p-3 enrolled-players-tab px-0 pt-0"
                  >
                    {tournamentDetails.status === 1 ? (
                      <Card className="mb-0">
                        <CardBody>
                          <Row>Brackets not generated yet</Row>
                        </CardBody>
                      </Card>
                    ) : (
                      <ScheduleTab
                        details={tournamentDetails}
                        callBackToDetails={callBackFromSchedule}
                      />
                    )}
                  </TabPane>
                  <TabPane
                    tabId="3"
                    className="p-3 enrolled-players-tab  px-0 pt-0"
                  >
                    <MatchInfoTab
                      matchInfo={roundData}
                      gameType={tournamentDetails.game_type}
                      teamsInMatchesInfo={tournamentDetails.match_info}
                    />
                  </TabPane>
                  <TabPane tabId="4">
                    <StandingsTab
                      standingsDetails={tournamentDetails.standings}
                    />
                  </TabPane>
                  <TabPane tabId="5">
                    <Card className="mb-0">
                      <CardBody>
                        <Row>
                          <TeamsTab
                            details={tournamentDetails}
                            changePermission={changePermission}
                            callTournamentDetail={getDetail}
                          />
                        </Row>
                      </CardBody>
                    </Card>
                  </TabPane>
                </TabContent>
              )
            )}
          </Col>
        </Row>
      </div>
    </>
  );
};

export default withRouter(TournamentDetails);
