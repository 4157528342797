export const LobbyMode = [
  { label: "Solo", value: 1 },
  { label: "Duo", value: 2 },
  { label: "Trio", value: 3 },
  { label: "Quad", value: 4 },
  { label: "Penta", value: 5 },
];
export const LobbyModeForCODWarzone = [
  { label: "Solo", value: 1 },
  { label: "Duo", value: 2 },
];
export const LobbyModeMap = {
  1: { label: "Solo", value: 1 },
  2: { label: "Duo", value: 2 },
  3: { label: "Trio", value: 3 },
  4: { label: "Quad", value: 4 },
  5: { label: "Penta", value: 5 },
};
export const LobbyResultMap = {
  1: { label: "New", value: 1 },
  2: { label: "Uploaded", value: 2 },
  3: { label: "Ready", value: 3 },
  4: { label: "Published", value: 4 },
  5: { label: "Cancelled", value: 5 },
};
export const GameType = [
  { label: "PPK", value: 1 },
  { label: "Kill Race", value: 2 },
  { label: "Traditional", value: 3 },
];
export const GameTypeMap = {
  1: { label: "PPK", value: 1 },
  2: { label: "Kill Race", value: 2 },
  3: { label: "Traditional", value: 3 },
};

export const convertTime12To24 = (timeInAmPm) => {
  if (!timeInAmPm) return;
  const time = timeInAmPm.split(" ")[0] + " " + timeInAmPm.split(" ")[1];
  var hours = Number(time.match(/^(\d+)/)[1]);
  var minutes = Number(time.match(/:(\d+)/)[1]);
  var AMPM = time.match(/\s(.*)$/)[1];
  if (AMPM === "PM" && hours < 12) hours = hours + 12;
  if (AMPM === "AM" && hours === 12) hours = hours - 12;
  var sHours = hours.toString();
  var sMinutes = minutes.toString();
  if (hours < 10) sHours = "0" + sHours;
  if (minutes < 10) sMinutes = "0" + sMinutes;
  return sHours + ":" + sMinutes;
};

export const removeNotValidKeysForComparison = (obj1, obj2) => {
  let tempObj = {
    obj1,
  };
  let refTempObj = {
    obj2,
  };
  delete tempObj?.obj1?.gameNew;
  delete tempObj?.obj1?.game?.rules;
  delete tempObj?.obj1?.game?.min_players;
  delete tempObj?.obj1?.game?.max_players;
  delete tempObj?.obj1?.optionGroup;
  delete tempObj?.obj1?.editSubmitDisable;
  delete tempObj?.obj1?.admins;
  delete refTempObj?.obj2?.optionGroup;
  delete refTempObj?.obj2?.gameNew;
  delete refTempObj?.obj2?.editSubmitDisable;
  delete refTempObj?.obj2?.admins;
};

export const parseIntKeysForCompare = (obj) => {
  obj["maxPlayer"] = parseInt(obj["maxPlayer"]);
  obj["minPlayer"] = parseInt(obj["minPlayer"]);
  obj["entryFee"] = parseInt(obj["entryFee"]);
};

export const trimObjectKeysForCompare = (obj) => {
  obj.stats_code = obj?.stats_code?.trim();

  obj.participant_code = obj?.participant_code?.trim();

  obj.admin_code = obj?.admin_code?.trim();

  obj.name = obj?.name?.trim();
};
