import React from "react";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import { Card, CardBody, Row } from "reactstrap";
import MatchInfoTable from "../Table/MatchInfoTable";
const MatchInfoTab = ({ matchInfo, gameType, teamsInMatchesInfo }) => {
  return (
    <Card className="mb-0">
      <CardBody>
        <Row>
          {matchInfo.length === 0 ? (
            <Card className="mb-0">
              <CardBody>
                <Row>Match info not available yet</Row>
              </CardBody>
            </Card>
          ) : (
            <Accordion className="add-tournament-schedule" allowZeroExpanded>
              {matchInfo &&
                matchInfo.map((item, id) => {
                  return (
                    <AccordionItem key={id}>
                      <AccordionItemHeading className="text-capitalize">
                        <AccordionItemButton>
                          {item.round}{" "}
                          {item.tour_bracket_style === 2 &&
                          item.round_type != null
                            ? `[${item.round_type}]`
                            : null}
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel accordionId="1">
                        {item?.matches?.length === 0 ? (
                          <span>No data found</span>
                        ) : (
                          <MatchInfoTable
                            subMatches={item}
                            gameType={gameType}
                            teamsInMatchesInfo={teamsInMatchesInfo}
                          />
                        )}
                      </AccordionItemPanel>
                    </AccordionItem>
                  );
                })}
            </Accordion>
          )}
        </Row>
      </CardBody>
    </Card>
  );
};

export default MatchInfoTab;
