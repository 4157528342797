import {
  SET_PRIZE_LIST,
  SET_CLEAR_PRIZE_LIST,
  UPDATE_PRIZE_COUNT,
  UPDATE_PRIZE_LIST,
} from "./actionTypes";

const initialState = {
  prize: [],
  count: 0,
};

const prizeReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_PRIZE_LIST:
      return {
        ...state,
        prize:
          action === undefined
            ? []
            : action.payload.map(({ positionLabel, ...rest }) => ({
                ...rest,
              })),
      };
    case UPDATE_PRIZE_COUNT:
      return {
        ...state,
        count: action.payload,
      };
    case UPDATE_PRIZE_LIST:
      return {
        ...state,
        prize: [...state.prize.slice(0, state.count)],
      };
    case SET_CLEAR_PRIZE_LIST:
      return {
        count: 0,
        prize: [],
      };
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default prizeReducer;
