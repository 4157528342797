import React from "react";
import { Button } from "reactstrap";
function ClearButton({ handleClear }) {
  return (
    <Button className="new-filter" color="primary" onClick={handleClear}>
      Clear
    </Button>
  );
}

export default ClearButton;
