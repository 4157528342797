import React from "react";
import { Redirect } from "react-router-dom";

import Pages404 from "../pages/Utility/pages-404";
import Pages500 from "../pages/Utility/pages-500";

// Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import ForgetPwd from "../pages/Authentication/ForgetPassword";
import changePassword from "../pages/Authentication/ChangePassword";

// Inner pages
import UserList from "../pages/UsersList/UserList";
import UserDetail from "../pages/UserDetail/UserDetail";
import GamesList from "../pages/GamesList/GamesList";
import EditGame from "../pages/GamesList/EditGame";
import LobbyList from "../pages/LobbyList/LobbyList";
import LobbyResult from "../pages/LobbyList/LobbyResult";
import LobbyDetail from "../pages/LobbyDetail/LobbyDetail";
import LobbyForm from "../pages/LobbyForm/LobbyForm";
import ResetPassword from "../pages/Authentication/ResetPassword";
import AdminUserList from "../pages/AdminUserList/AdminUserList";
import AdminUserForm from "../pages/AdminUserForm/AdminUserForm";
import AdminUserDetail from "../pages/AdminUserDetail/AdminUserDetail";
import ImageSliderList from "../pages/ImageSliderList/ImageSliderList";

import CMS from "../pages/CMS/CMS";
import ComplaintList from "../pages/Complaints/ComplaintList";
import ComplaintDetail from "../pages/Complaints/ComplaintDetail";
import LocationList from "../pages/AdminLocation/LocationList";
import BanLocation from "../pages/AdminLocation/BanLocation";
import EditLocation from "../pages/AdminLocation/EditLocation";
import CommunityList from "../pages/Community/CommunityList";
import AddEditCommunity from "../pages/Community/AddEditCommunity";
import ReferralDetails from "../pages/ReferralDetail/ReferralDetails";
import ViewDetails from "../pages/ReferralDetail/ViewDetails";
import GlobalSettings from "../pages/GlobalSetting/GlobalSettings";
import DemoPageList from "../pages/DemoPage/DemoPageList";
import AddDemoPage from "../pages/DemoPage/AddDemoPage";
import EditDemoPage from "../pages/DemoPage/EditDemoPage";
import GroupList from "../pages/Groups/GroupList";
import AddEditGroup from "../pages/Groups/AddEditGroup";
import ActivityLog from "../pages/ActivityLog/ActivityLog";
import SearchPath from "../helpers/SearchPath";
import { findPathsBetweenTwo } from "../helpers/PermissionUtils";
import Reports from "../pages/Reports/Reports";
import ChallengesList from "../pages/ChallengesModule/ChallengesList";
import ChallengesDetail from "../pages/ChallengesModule/ChallengesDetail";
import TournamentsList from "../pages/BracketsAndTournaments/TournamentsList";
import AddTournaments from "../pages/BracketsAndTournaments/Form/AddTournaments";
import AddTournamentSchedule from "../pages/BracketsAndTournaments/Common/AddTournamentSchedule";
import TournamentDetails from "../pages/BracketsAndTournaments/TournamentDetails";
import { getFromLocalStorage } from "../helpers/util";
import LeaderBoard from "../pages/LeaderBoard";
const obj = getFromLocalStorage("authUser");
const notFound =
  obj === null
    ? true
    : obj.extras?.permissions.some((objItem) => {
        return objItem.label === "Static Page";
      });
const commonPath = [
  {
    path: "/users",
    component: UserList,
    commonName: "/users",
    codename: "view_user",
  },
  {
    path: "/user/:userId",
    component: UserDetail,
    commonName: "/users",
    codename: "view_user",
  },

  {
    path: "/games",
    component: GamesList,
    commonName: "/games",
    codename: "view_game",
  },
  {
    path: "/game/edit/:gameSlug/:gameId",
    component: EditGame,
    commonName: "/games",
    codename: "change_game",
  },

  {
    path: "/lobby",
    component: LobbyList,
    commonName: "/lobby",
    codename: "view_lobby",
  },
  {
    path: "/lobby/add",
    component: LobbyForm,
    commonName: "/lobby",
    codename: "add_lobby",
  },
  {
    path: "/lobby/edit/:lobbyId",
    component: LobbyForm,
    commonName: "/lobby",
    codename: "change_lobby",
  },
  {
    path: "/lobby/:lobbyId",
    component: LobbyDetail,
    commonName: "/lobby",
    codename: "view_lobby",
  },

  {
    path: "/lobby/view-result/:lobbyId",
    component: LobbyResult,
    commonName: "/lobby",
    codename: "view_lobby",
  },

  {
    path: "/staff",
    component: AdminUserList,
    commonName: "/staff",
    codename: "view_ryvalsadmin",
  },
  {
    path: "/staff/add",
    component: AdminUserForm,
    commonName: "/staff",
    codename: "add_ryvalsadmin",
  },
  {
    path: "/staff/edit/:adminId",
    component: AdminUserForm,
    commonName: "/staff",
    codename: "change_ryvalsadmin",
  },
  {
    path: "/staff/:adminId",
    component: AdminUserDetail,
    commonName: "/staff",
    codename: "view_ryvalsadmin",
  },

  { path: "/banners", component: ImageSliderList, commonName: "/banners" },

  { path: "/logout", component: Logout },
  {
    path: "/change-password",
    component: changePassword,
    commonName: "/changePassword",
  },
  {
    path: "/challenges",
    component: ChallengesList,
    commonName: "/challenges",
    codename: "view_challenge",
  },
  {
    path: "/challenges/:challengesId",
    component: ChallengesDetail,
    commonName: "/challenges",
    codename: "view_challenge",
  },

  {
    path: "/complaints",
    component: ComplaintList,
    commonName: "/complaints",
    codename: "view_complaint",
  },
  {
    path: "/locationLists",
    component: LocationList,
    commonName: "/locationLists",
    codename: "view_restrictlocation",
  },
  {
    path: "/complaint/:compId",
    component: ComplaintDetail,
    commonName: "/complaints",
    codename: "view_complaint",
  },
  {
    path: "/locationLists/banLocation",
    component: BanLocation,
    commonName: "/locationLists",
    codename: "add_restrictlocation",
  },
  {
    path: "/locationLists/editLocation/:id",
    component: EditLocation,
    commonName: "/locationLists",
    codename: "change_restrictlocation",
  },

  {
    path: "/community",
    component: CommunityList,
    commonName: "/community",
    codename: "view_communityvideo",
  },
  {
    path: "/community/add",
    component: AddEditCommunity,
    commonName: "/community",
    codename: "add_communityvideo",
  },
  {
    path: "/community/edit/:lobbyId",
    component: AddEditCommunity,
    commonName: "/community",
    codename: "change_communityvideo",
  },

  {
    path: "/referrals",
    component: ReferralDetails,
    commonName: "/referrals",
    codename: "view_referralreport",
  },
  {
    path: "/referrals/:userId",
    component: ViewDetails,
    commonName: "/referrals",
    codename: "view_referralreport",
  },

  {
    path: "/globalsettings",
    component: GlobalSettings,
    commonName: "/globalsettings",
    codename: "view_config",
  },
  {
    path: "/gameDemo",
    component: DemoPageList,
    commonName: "/gameDemo",
    codename: "view_gamedemo",
  },
  {
    path: "/gameDemo/add",
    component: AddDemoPage,
    commonName: "/gameDemo",
    codename: "add_gamedemo",
  },
  {
    path: "/gameDemo/edit/:id",
    component: EditDemoPage,
    commonName: "/gameDemo",
    codename: "change_gamedemo",
  },

  {
    path: "/groups",
    component: GroupList,
    commonName: "/groups",
    codename: "view_group",
  },
  {
    path: "/groups/add",
    component: AddEditGroup,
    commonName: "/groups",
    codename: "add_group",
  },
  {
    path: "/groups/edit/:groupId",
    component: AddEditGroup,
    commonName: "/groups",
    codename: "change_group",
  },
  {
    path: "/report",
    component: Reports,
    commonName: "/report",
    codename: "view_report",
  },
  {
    path: "/activitylog",
    component: ActivityLog,
    commonName: "/activitylog",
    codename: "view_logs",
  },
  {
    path: "/tournament",
    component: TournamentsList,
    commonName: "/tournament",
    codename: "view_tournament",
  },
  {
    path: "/tournament/add",
    component: AddTournaments,
    commonName: "/tournament",
    codename: "add_tournament",
  },
  {
    path: "/tournament/edit/:gameSlug/:tournamentId",
    component: AddTournaments,
    commonName: "/tournament",
    codename: "add_tournament",
  },
  {
    path: "/tournament/addschedule/:gameSlug/:tournamentId",
    component: AddTournamentSchedule,
    commonName: "/tournament",
    codename: "add_tournament",
  },
  {
    path: "/tournament/view/:gameSlug/:tournamentId",
    component: TournamentDetails,
    commonName: "/tournament",
    codename: "view_tournament",
  },
  {
    path: "/leaderboard",
    component: LeaderBoard,
    commonName: "/leaderboard",
    codename: "view_leaderboard",
  },
];
const userRoutes1 = notFound
  ? [
      ...commonPath,
      {
        path: "/cms",
        component: CMS,
        commonName: "/cms",
        codename: "view_staticpage",
      },

      // this route should be at the end of all other routes
      { path: "/", exact: true, component: () => <Redirect to="/users" /> },
    ]
  : [
      ...commonPath,
      {
        path: "/cms",
        component: CMS,
        commonName: "/cms",
        codename: "view_question",
      },

      // this route should be at the end of all other routes
      { path: "/", exact: true, component: () => <Redirect to="/users" /> },
    ];

const filterOutGivenPermissionPath = SearchPath(userRoutes1);
const findPaths =
  filterOutGivenPermissionPath == null
    ? userRoutes1
    : findPathsBetweenTwo(filterOutGivenPermissionPath);
const userRoutes =
  filterOutGivenPermissionPath == null ? userRoutes1 : findPaths;

const authRoutes = [
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/reset-password", component: ResetPassword },
  { path: "/pages-404", component: Pages404 },
  { path: "/pages-500", component: Pages500 },
];

export { userRoutes, authRoutes };
