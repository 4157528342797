import React from "react";
import ReactDatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import { Link } from "react-router-dom";
import calenderIcon from "../../../assets/images/calendar.svg";
import { Button } from "reactstrap";
function DateFiltersReports({
  startDate,
  endDate,
  updateDateFromPicker,
  handleToday,
  handleMonth,
  handleWeek,
  handleExport,
  handleYear,
  handleClear,
  detailView,
  setDetailView,
  resetPagination,
  active,
  activeTab,
}) {
  const handleChange = (dates) => {
    updateDateFromPicker(dates);
  };
  return (
    <>
      {window.location.pathname == "/activitylog" ? (
        <div className="d-flex align-items-center flex-wrap ms-2">
          <ReactDatePicker
            className="new-filter datepicker-width"
            selectsRange={true}
            startDate={startDate}
            endDate={endDate}
            dateFormat="MM/dd/yyyy"
            onChange={handleChange}
            placeholderText="MM/DD/YYYY-MM/DD/YYYY"
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
            yearDropdownItemNumber={6}
            customInput={
              <CustomInput startDate={startDate} endDate={endDate} />
            }
          />
          <Button
            className={
              active.today === "1"
                ? "active ms-2 new-filter"
                : "ms-2 new-filter"
            }
            color="primary"
            onClick={handleToday}
          >
            Today
          </Button>
          <Button
            className={
              active.week === "2" ? "active ms-2 new-filter" : "ms-2 new-filter"
            }
            color="primary"
            onClick={handleWeek}
          >
            This Week{" "}
          </Button>
          <Button
            className={
              active.month === "3"
                ? "active ms-2 new-filter"
                : "ms-2 new-filter"
            }
            color="primary"
            onClick={handleMonth}
          >
            {" "}
            This Month
          </Button>
          <Button
            className={
              active.year === "4" ? "active ms-2 new-filter" : "ms-2 new-filter"
            }
            color="primary"
            onClick={handleYear}
          >
            This Year
          </Button>

          <Button
            className={
              active.clear === "5"
                ? "active ms-2 new-filter"
                : "ms-2 new-filter"
            }
            color="primary"
            onClick={handleClear}
          >
            Clear
          </Button>
        </div>
      ) : (
        <>
          {detailView ? (
            <div class="row">
              <div class="col">
                <p onClick={resetPagination}>
                  <Link to="/report">
                    <i className="mdi mdi-arrow-left"></i> back
                  </Link>
                </p>
              </div>
            </div>
          ) : null}

          <div className="d-flex align-items-center ms-2 flex-wrap mb-4">
            <span className="filterBy">Filter By : </span>
            <ReactDatePicker
              className="new-filter datepicker-width"
              selectsRange={true}
              startDate={startDate}
              endDate={endDate}
              dateFormat="MM/dd/yyyy"
              onChange={handleChange}
              placeholderText="MM/DD/YYYY-MM/DD/YYYY"
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
              yearDropdownItemNumber={6}
              customInput={
                <CustomInput startDate={startDate} endDate={endDate} />
              }
            />
            <Button
              className={
                active === "1" ? "active ms-2 new-filter" : "ms-2 new-filter"
              }
              color="primary"
              onClick={handleToday}
            >
              Today
            </Button>
            <Button
              className={
                active === "2" ? "active ms-2 new-filter" : "ms-2 new-filter"
              }
              color="primary"
              onClick={handleWeek}
            >
              This Week{" "}
            </Button>
            <Button
              className={
                active === "3" ? "active ms-2 new-filter" : "ms-2 new-filter"
              }
              color="primary"
              onClick={handleMonth}
            >
              {" "}
              This Month
            </Button>
            <Button
              className={
                active === "4" ? "active ms-2 new-filter" : "ms-2 new-filter"
              }
              color="primary"
              onClick={handleYear}
            >
              This Year
            </Button>

            <Button
              className={
                active === "5" ? "active ms-2 new-filter" : "ms-2 new-filter"
              }
              color="primary"
              onClick={handleClear}
            >
              Clear
            </Button>
            <div className="export-csv">
              <Button
                className="ms-2 new-filter"
                color="primary"
                onClick={handleExport}
              >
                Export to CSV
              </Button>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default DateFiltersReports;

const CustomInput = React.forwardRef((props, ref) => {
  return (
    <div
      className="form-control dateFilterIcon d-flex"
      onClick={props.onClick}
      ref={ref}
    >
      <img src={calenderIcon} alt="calendar-icon" />
      <label
        className={`${props.startDate && props.endDate ? "selectedDate" : ""}`}
      >
        {props.value || props.placeholder}
      </label>
    </div>
  );
});
