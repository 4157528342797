import React, { useCallback, useEffect, useRef, useState } from "react";

import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Link } from "react-router-dom";
// Paginator
import Paginator from "../../helpers/paginator/paginator";
import {
  changeAdminStatus,
  getAdminUserList,
  deleteAdmin,
} from "../../services/admin_user_api_helper";

// Alert
import SweetAlert from "react-bootstrap-sweetalert";

// toastr
import { toast } from "react-toastify";
import toastrOptions from "../../helpers/toastr-options/toastr-options";
import FilterPermission from "../../helpers/FilterPermission";
import { filterOutPermissionToShowHide } from "../../helpers/PermissionUtils";
import { debounce, isEmpty } from "lodash";
import ClearButton from "../../components/ClearButton";
import { getFilterText } from "../../helpers/util";

const AdminUserList = (props) => {
  const [admins, setadmins] = useState([]);
  const [isLoading, setisLoading] = useState(false);
  const [disableDelBtn, setDisableDel] = useState(false);
  const [totalCount, settotalCount] = useState(null);

  const [selectedUser, setselectedUser] = useState(null);
  const [deleteModal, setdeleteModal] = useState(false);

  //filter keys
  const pageSize = 10;
  const [pageNumber, setpageNumber] = useState(1);
  const [changePermission, setChange] = useState(false);
  const [deletePermission, setDelete] = useState(false);
  const [addPermission, setAdd] = useState(false);

  const [selectedDropdown, setselectedDropdown] = useState(null);
  const [sortBy, setsortBy] = useState(null);
  const [searchTerm, setsearchTerm] = useState("");
  const [singlebtn, setSinglebtn] = useState(false);
  const dropdownOptions = [
    {
      value: "",
      text: "All",
    },
    {
      value: "first_name",
      text: "Name : Asc",
    },
    {
      value: "-first_name",
      text: "Name : Desc",
    },
  ];
  const dropdownChange = (value) => {
    setselectedDropdown(value);
    setsortBy(value);
  };

  useEffect(() => {
    getListing();
    if (isEmpty(props.permission)) {
      setChange(true);
      setDelete(true);
      setAdd(true);
    } else {
      callSetPermission();
    }
  }, [sortBy, searchTerm, pageNumber]);
  const handleSearch = (event) => {
    let term = event.target.value.trim();
    setsearchTerm(term);
  };
  const callSetPermission = () => {
    const type = "Staff";
    const typeChange = "change_ryvalsadmin";
    const typeDelete = "delete_ryvalsadmin";
    const typeAdd = "add_ryvalsadmin";

    const filteredPermission = FilterPermission(props.permission, type);
    if (filteredPermission.length !== 0) {
      const setchange = filterOutPermissionToShowHide(
        filteredPermission[0].permissions,
        typeChange
      );
      const setdelete = filterOutPermissionToShowHide(
        filteredPermission[0].permissions,
        typeDelete
      );
      const setadd = filterOutPermissionToShowHide(
        filteredPermission[0].permissions,
        typeAdd
      );
      setChange(setchange);
      setDelete(setdelete);
      setAdd(setadd);
    }
  };
  async function getListing() {
    setisLoading(true);
    const filters = filterModel();
    await getAdminUserList(filters)
      .then((res) => {
        setadmins(res.data);
        settotalCount(res.total);
        setisLoading(false);
      })
      .catch(() => {
        setisLoading(false);
      });
  }

  function filterModel() {
    if (pageNumber >= 2 && searchTerm.length != 0) {
      let model = {
        searchTerm: searchTerm,
        sortBy: sortBy,
        pageNumber: 1,
        pageSize: pageSize,
      };
      return model;
    } else {
      let model = {
        searchTerm: searchTerm,
        sortBy: sortBy,
        pageNumber: pageNumber,
        pageSize: pageSize,
      };
      return model;
    }
  }

  const handlePageClick = (pageNum) => {
    setpageNumber(pageNum);
  };
  const [disableSwal, setDisabled] = useState(false);
  const changeUserStatus = () => {
    setDisabled(true);
    let currentStatus = selectedUser.status === 1 ? 2 : 1;

    changeAdminStatus(selectedUser?.id, currentStatus)
      .then((res) => {
        toast.success(
          `Staff Member ${
            selectedUser?.status === 1 ? "Deactivated" : "Activated"
          } Successfully`,
          toastrOptions
        );
        getListing();
        closeAlert();

        setDisabled(false);
      })
      .catch((err) => {
        closeAlert();
        toast.error(err, toastrOptions);
        setDisabled(false);
      });
  };

  const openAlert = (selectedUserAlert) => {
    setselectedUser(selectedUserAlert);
    setdeleteModal(true);
  };

  const closeAlert = () => {
    setdeleteModal(false);
  };
  const handleDelete = async (delId) => {
    setDisableDel(true);
    await deleteAdmin(delId)
      .then((res) => {
        getListing();
        setDisableDel(false);
      })
      .catch(() => {
        setDisableDel(false);
      });
  };
  const debouncedResults = useCallback(
    debounce((event) => {
      handleSearch(event);
    }, 500),
    []
  );
  const ref = useRef(null);
  useEffect(() => {
    return () => {
      debouncedResults.cancel();
    };
  });
  const handleClear = () => {
    setpageNumber(1);
    setsearchTerm("");
    setselectedDropdown(null);
    setsortBy(null);
    ref.current.value = "";
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <Breadcrumbs breadcrumbItem="Manage Staff Members" />
        <Row>
          <Col className="col-12">
            <Card>
              <CardBody>
              <div className="search-box tournament-filter d-flex align-items-center flex-wrap mb-4">
                      <input
                        ref={ref}
                        className="form-control search-bar w-540"
                        type="search"
                        placeholder="Search staff"
                        onChange={(event) => {
                          debouncedResults(event);
                        }}
                      />
                    <Dropdown
                      isOpen={singlebtn}
                      toggle={() => setSinglebtn(!singlebtn)}
                    >
                      <DropdownToggle
                        caret
                        color="primary"
                        className="dropdown-toggle-split sort-by-btn"
                      >
                        <span>
                          {selectedDropdown === null
                            ? "Sort By"
                            : getFilterText(dropdownOptions, selectedDropdown)}
                        </span>
                        <i className="mdi mdi-chevron-down" />
                      </DropdownToggle>
                      <DropdownMenu className="lobbies-menuListing">
                        {dropdownOptions.map((item, index) => {
                          return (
                            <DropdownItem
                              key={index}
                              onClick={() => dropdownChange(item.value)}
                              dropdownvalue={item.value}
                            >
                              {item.text}
                              &nbsp; &nbsp;
                              {selectedDropdown === item.value ? (
                                <i className="mdi mdi-check-circle-outline"></i>
                              ) : null}
                            </DropdownItem>
                          );
                        })}
                      </DropdownMenu>
                    </Dropdown>
                    <ClearButton handleClear={handleClear} />

                  {addPermission ? (
                    <div className="add-btn">
                      <Link to="/staff/add">
                        <button className="btn btn-primary"> Add Staff </button>
                      </Link>
                    </div>
                  ) : null}

                  </div>

                <div className="table-rep-plugin">
                  <div
                    className="table-responsive mb-0"
                    data-pattern="priority-columns"
                  >
                    <Table
                      id="tech-companies-1"
                      className="table table-striped table-bordered staff-table"
                    >
                      <Thead>
                        <Tr>
                          <Th>Full Name</Th>
                          <Th data-priority="1">Email Address</Th>
                          <Th data-priority="1">Status</Th>
                          {changePermission ? (
                            <>
                              <Th data-priority="6">Actions</Th>
                              <Th data-priority="6">Manage Permissions</Th>
                            </>
                          ) : null}
                          {deletePermission ? (
                            <Th data-priority="6">Remove Admin</Th>
                          ) : null}
                        </Tr>
                      </Thead>
                      <Tbody>
                        {isLoading ? (
                          <div
                            class="spinner-grow transaction-spinner"
                            role="status"
                          >
                            <span class="sr-only">Loading...</span>
                          </div>
                        ) : admins.length === 0 ? (
                          <h5 className="text-center mt-4">
                            No Staff Member(s) Found
                          </h5>
                        ) : (
                          admins.map((item, index) => (
                            <Tr key={index}>
                              <Td>
                                <Link to={"staff/" + item?.id}>
                                  {item?.first_name} {item?.last_name}
                                </Link>
                              </Td>
                              <Td>
                                <a href={"mailto:" + item?.email}>
                                  {item?.email}
                                </a>
                              </Td>
                              <Td>
                                {item?.status === 1 ? "Active" : "Inactive"}
                              </Td>
                              {changePermission ? (
                                <>
                                  <Td>
                                    <p
                                      onClick={() => {
                                        openAlert(item);
                                      }}
                                      className="link-color"
                                    >
                                      {item?.status === 1
                                        ? "Deactivate"
                                        : "Activate"}
                                    </p>
                                  </Td>
                                  <Td>
                                    <Link to={`/staff/edit/${item?.id}`}>
                                      <Button className="btn btn-sm complaint-view-btn btn-secondary">
                                        Edit {"  "}
                                      </Button>
                                    </Link>
                                  </Td>
                                </>
                              ) : null}
                              {deletePermission ? (
                                <Td className="location-delete-btn">
                                  <button
                                    className="btn btn-sm complaint-view-btn btn-secondary"
                                    onClick={() => handleDelete(item?.id)}
                                    disabled={disableDelBtn}
                                  >
                                    Delete
                                  </button>
                                </Td>
                              ) : null}
                            </Tr>
                          ))
                        )}
                      </Tbody>
                    </Table>
                  </div>
                </div>
                <Paginator
                  totalCount={totalCount}
                  pageSize={pageSize}
                  pageClick={handlePageClick}
                  forcePage={pageNumber}
                />
              </CardBody>
              {deleteModal ? (
                <SweetAlert
                  title={
                    selectedUser?.status === 1
                      ? "Deactivate Staff Member"
                      : "Activate Staff Member"
                  }
                  warning
                  showCancel
                  confirmButtonText="Yes"
                  confirmBtnBsStyle="success"
                  cancelButtonText="No"
                  cancelBtnBsStyle="danger"
                  onConfirm={() => changeUserStatus()}
                  onCancel={() => closeAlert()}
                  disabled={disableSwal}
                >
                  Are you sure you want to{" "}
                  {selectedUser?.status === 1 ? "deactivate " : "activate "}
                  <strong className="capitalize">
                    {selectedUser?.first_name + " " + selectedUser?.last_name}
                  </strong>{" "}
                  from the listings?
                </SweetAlert>
              ) : null}
            </Card>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};

export default AdminUserList;
