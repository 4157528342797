import React from "react";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { FormGroup, Button } from "reactstrap";
import { toast } from "react-toastify";
import toastrOptions from "../../../helpers/toastr-options/toastr-options";
import tickIcon from "../../../assets/images/user-tick-icon.svg";
import crossIcon from "../../../assets/images/user-cross-icon.svg";
import IconsToolTip from "../../../components/IconsToolTip";
import { editLobby } from "../../../services/lobby_api_helper";
import { convertTo24HourFormat } from "./utils";
const UpdateAdminCode = ({
  isAdminCode,
  lobbyData,
  SaveAdminCode,
  CloseAdminCode,
}) => {
  const handleSubmit = async (event, values) => {
    event.preventDefault();

    try {
      let data = {
        ...lobbyData,
      };
      let successMessage = "";
      data.end_time =
        lobbyData.end_time === null
          ? null
          : convertTo24HourFormat(lobbyData.end_time);
      data.last_entry_time = convertTo24HourFormat(lobbyData.last_entry_time);
      data.start_time = convertTo24HourFormat(lobbyData.start_time);
      if (isAdminCode) {
        data.admin_code = values.admin_code;
        successMessage = "Admin code";
      } else {
        data.participant_code = values.participant_code;
        successMessage = "Participant code";
      }
      await editLobby(lobbyData.lobby_id, data);
      toast.success(`${successMessage} updated successfully`, toastrOptions);
      SaveAdminCode(
        successMessage === "Admin code"
          ? data.admin_code
          : data.participant_code
      );
    } catch (error) {
      CloseAdminCode();
    }
  };
  return (
    <>
      <AvForm
        className="d-flex editEarning-timeline"
        onValidSubmit={(e, v) => {
          handleSubmit(e, v);
        }}
      >
        <AvField
          name={isAdminCode ? "admin_code" : "participant_code"}
          label={isAdminCode ? "Admin Code*" : "Participant Code*"}
          placeholder={
            isAdminCode
              ? "Enter admin code here"
              : "Enter participant code here"
          }
          type="text"
          validate={{
            required: {
              value: true,
              errorMessage: isAdminCode
                ? "Admin code is required"
                : "Participant code is required",
            },
          }}
        />
        <FormGroup>
          <div className="mt-4 d-flex">
            <Button
              type="submit"
              color="primary"
              className="edit-icon info-icon update-admin-button"
            >
              <IconsToolTip
                image={tickIcon}
                altImageText="user-tick-icon"
                text="Save"
              />
            </Button>
            <Button
              className="edit-icon mt-2 ms-0 px-0 info-icon  update-admin-button"
              onClick={() => {
                CloseAdminCode();
              }}
            >
              <IconsToolTip
                image={crossIcon}
                altImageText="user-cross-icon"
                text="Cancel"
              />
            </Button>
          </div>
        </FormGroup>
      </AvForm>
    </>
  );
};
export default UpdateAdminCode;
