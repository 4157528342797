import { put, takeEvery } from "redux-saga/effects";

// Login Redux States
import { LOGIN_USER } from "./actionTypes";
import { apiError } from "./actions";

//Include Both Helper File with needed methods

import axios from "axios";
function* loginUser({ payload: { user, history } }) {
  try {
    const body = {
      email: user.email,
      password: user.password,
    };

    const url = process.env.REACT_APP_APIURL + "login/";
    const isProduction = process.env.REACT_APP_PRODUCTION;
    let headers;

    if (!isProduction) {
      headers = {
        Authorization: `Bearer ${process.env.REACT_APP_CLIENTTOKEN}`,
        "Content-Type": "application/json",
      };
    }

    axios
      .post(url, body, { headers })
      .then((res) => {
        localStorage.setItem("authUser", JSON.stringify(res.data));
        history.push("/users");
      })
      .catch((err) => {
        put(apiError(err));
      });
  } catch (error) {
    yield put(apiError(error));
  }
}

function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser);
}

export default authSaga;
