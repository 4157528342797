import { GetObjectCommand } from "@aws-sdk/client-s3";
import { getSignedUrl } from "@aws-sdk/s3-request-presigner";
import { S3Client } from "@aws-sdk/client-s3";

async function getSignedUrls(bucketName, objectKeys) {
  const CREDENTIAL = {
    accessKeyId: process.env.REACT_APP_S3_ACCESS_KEY,
    secretAccessKey: process.env.REACT_APP_S3_SECRET_KEY,
  };

  const s3Client = new S3Client({
    region: process.env.REACT_APP_S3_REGION_NAME,
    credentials: CREDENTIAL,
  });

  const signedUrls = [];

  for (const objectKey of objectKeys) {
    const bucketData = {
      Bucket: bucketName,
      Key: objectKey,
    };

    const signedUrl = await getSignedUrl(
      s3Client,
      new GetObjectCommand(bucketData)
    );
    const urlWithoutParams = signedUrl.split("?")[0];
    signedUrls.push(urlWithoutParams);
  }

  return signedUrls;
}

export default getSignedUrls;
