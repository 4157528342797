import React from "react";
function ViewMoreTeamTable({ teamList, roundTable }) {
  return (
    <div className="table-rep-plugin tournamentRound">
      <div className="table-responsive mb-0" data-pattern="priority-columns">
        <div className="table-responsrowspanive seize-wallet-table">
          <table className="table table-striped table-bordered manage-complaints-table lobbies-table add-schedule ppk-table">
            <thead>
              <tr>
                <th scope="col" rowSpan="2">
                  Team Name
                </th>
              </tr>
            </thead>
            <tbody>
              {teamList &&
                teamList.map((item, index) => {
                  return (
                    <tr key={roundTable ? item.team_id : item.id} id={index}>
                      <td className="text-capitalize">
                        {roundTable ? item.team : item.name}
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default ViewMoreTeamTable;
