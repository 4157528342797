import React from "react";
function IconsToolTip({ image, altImageText, text }) {
  return (
    <>
      <img alt={altImageText} src={image} />
      <span
        className="edit-tournament-tooltip  tooltiptext"
        data-tip
        data-for="loginTip"
      >
        {text}
      </span>
    </>
  );
}

export default IconsToolTip;
