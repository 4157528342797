import React, { useCallback, useEffect, useRef, useState } from "react";

import {
  Row,
  Col,
  Card,
  CardBody,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { getReferralList } from "../../services/referral_api_helper";
import { Link } from "react-router-dom";
// Paginator
import Paginator from "../../helpers/paginator/paginator";
import ReactDatePicker from "react-datepicker";
import { format } from "date-fns";
import calenderIcon from "../../assets/images/calendar.svg";
import {
  dateFormat,
  dropdownOptionsForReferral,
  getFilterText,
} from "../../helpers/util";
import { debounce } from "lodash";
import ClearButton from "../../components/ClearButton";
const ReferralDetails = () => {
  const [referrals, setReferrals] = useState([]);
  const [singlebtn, setSinglebtn] = useState(false);
  const [loader, setLoader] = useState(false);
  const [selectedDropdown, setselectedDropdown] = useState(null);
  const [totalCount, settotalCount] = useState(null);

  // filter model
  const pageSize = 10;
  const [pageNumber, setpageNumber] = useState(1);
  const [searchTerm, setsearchTerm] = useState(null);
  const [sortBy, setsortBy] = useState(null);
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [tileObj, setTileObj] = useState({
    total_money_distributed: null,
    current_month_given: null,
    daily_money_given: null,
  });

  useEffect(() => {
    getReferralListing();
  }, [sortBy, searchTerm, pageNumber, dateRange]);

  const getReferralListing = () => {
    setLoader(true);
    const filters = filterModel();
    getReferralList(filters)
      .then((res) => {
        setReferrals(res.data);
        settotalCount(res.total);
        const {
          total_money_distributed,
          current_month_given,
          daily_money_given,
        } = res;
        setLoader(false);
        setTileObj({
          total_money_distributed: total_money_distributed,
          current_month_given,
          daily_money_given,
        });
      })
      .catch(() => {
        setLoader(false);
      });
  };

  const handleSearch = (event) => {
    let term = event.target.value.trim();
    setpageNumber(1);
    setsearchTerm(term);
  };

  const dropdownChange = (value) => {
    setselectedDropdown(value);
    setsortBy(value);
  };

  function filterModel() {
    let model = {
      searchTerm: searchTerm,
      sortBy: sortBy,
      pageNumber: pageNumber,
      pageSize: pageSize,
      from_date: dateRange[0] && format(dateRange[0], "yyyy-MM-dd"),
      to_date: dateRange[1] && format(dateRange[1], "yyyy-MM-dd"),
    };
    return model;
  }

  const handlePageClick = (pageNum) => {
    setpageNumber(pageNum);
  };

  const handleChange = useCallback((dates) => {
    const [start, end] = dates;
    if ((start && end) || (!start && !end)) {
      setpageNumber(1);
      setDateRange(dates);
    }

    setStartDate(start);
    setEndDate(end);
  }, []);
  const debouncedResults = useCallback(
    debounce((event) => {
      handleSearch(event);
    }, 500),
    []
  );
  useEffect(() => {
    return () => {
      debouncedResults.cancel();
    };
  });
  const ref = useRef(null);
  const handleClear = () => {
    setpageNumber(1);
    setsearchTerm("");
    setDateRange([null, null]);
    setStartDate(null);
    setEndDate(null);
    setselectedDropdown(null);
    setsortBy(null);
    ref.current.value = "";
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <Breadcrumbs breadcrumbItem="REFERRAL LISTING" />
        <Row>
          <Col className="col-12">
            <Card>
              <CardBody>
                <Row className="referal-listing search-box">
                  <div className="col-lg-2 total-referal">
                    <h5>Total Referral money distributed</h5>
                    <h2>
                      {tileObj.total_money_distributed
                        ? "$ " + tileObj.total_money_distributed.toFixed(2)
                        : "$ 0.00"}
                    </h2>
                  </div>
                  <div className="col-lg-2 total-referal">
                    <h5>Money distributed this month</h5>
                    <h2>
                      {tileObj.current_month_given
                        ? "$ " + tileObj.current_month_given.toFixed(2)
                        : "$ 0.00"}
                    </h2>
                  </div>

                  <div className="col-lg-2 total-referal">
                    <h5>Daily distributions </h5>
                    <h2 style={{ marginTop: "28px" }}>
                      {tileObj.daily_money_given
                        ? "$ " + tileObj.daily_money_given.toFixed(2)
                        : "$ 0.00"}
                    </h2>
                  </div>
                </Row>
                <div className="search-box tournament-filter d-flex align-items-center flex-wrap mb-4">
                    <input
                      ref={ref}
                      className="form-control search-bar w-540"
                      type="search"
                      placeholder="Search Users by email, referral code or username"
                      onChange={(event) => {
                        debouncedResults(event);
                      }}
                    />
                    <Dropdown
                      isOpen={singlebtn}
                      toggle={() => setSinglebtn(!singlebtn)}
                    >
                      <DropdownToggle
                        caret
                        color="primary"
                        className="dropdown-toggle-split sort-by-btn"
                      >
                        <span>
                          {selectedDropdown === null
                            ? "Sort By"
                            : getFilterText(
                                dropdownOptionsForReferral,
                                selectedDropdown
                              )}
                        </span>{" "}
                        <i className="mdi mdi-chevron-down" />
                      </DropdownToggle>
                      <DropdownMenu>
                        {dropdownOptionsForReferral.map((item, index) => {
                          return (
                            <DropdownItem
                              key={index}
                              onClick={() => dropdownChange(item.value)}
                              dropdownvalue={item.value}
                            >
                              {item.text}
                              &nbsp; &nbsp;
                              {selectedDropdown === item.value ? (
                                <i className="mdi mdi-check-circle-outline"></i>
                              ) : null}
                            </DropdownItem>
                          );
                        })}
                      </DropdownMenu>
                    </Dropdown>
                    <ReactDatePicker
                      className="new-filter datepicker-width"
                      selectsRange={true}
                      startDate={startDate}
                      endDate={endDate}
                      dateFormat="MM/dd/yyyy"
                      onChange={handleChange}
                      placeholderText="MM/DD/YYYY-MM/DD/YYYY"
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      yearDropdownItemNumber={6}
                      isClearable
                      customInput={
                        <CustomInput startDate={startDate} endDate={endDate} />
                      }
                    />
                    <ClearButton handleClear={handleClear} />
                </div>

                <div className="table-rep-plugin">
                  <div
                    className="table-responsive mb-0"
                    data-pattern="priority-columns"
                  >
                    <Table
                      id="tech-companies-1"
                      className="table table-striped table-bordered list-of-all-users-table"
                    >
                      <Thead>
                        <Tr>
                          <Th>Player Username</Th>
                          <Th data-priority="1">Total Referral Earnings</Th>
                          <Th data-priority="3">Total Successful Referrals</Th>
                          <Th data-priority="1">
                            Referral Earnings This Month
                          </Th>
                          <Th data-priority="3">Date Of Signing Up</Th>
                          <Th data-priority="6">Email Address</Th>
                          <Th>Referred By</Th>
                          <Th data-priority="6" colSpan="2">
                            More Information
                          </Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {loader ? (
                          <div
                            class="spinner-grow spinner-class"
                            role="status"
                            style={{ marginTop: "40px" }}
                          >
                            <span class="sr-only">Loading...</span>
                          </div>
                        ) : referrals.length === 0 ? (
                          <Tr>
                            <Td colspan="10" className="text-center mt-4">
                              No Referrals Found
                            </Td>
                          </Tr>
                        ) : (
                          referrals.map((item, index) => (
                            <Tr key={index}>
                              <Th>
                                <Link
                                  to={{
                                    pathname: `/user/${item?.id}`,
                                    data: {
                                      prevPath: window.location.pathname,
                                    },
                                  }}
                                >
                                  {item.username || "NA"}
                                </Link>
                              </Th>
                              <Td>$ {item.total_earn || 0.0}</Td>
                              <Td>{item.total_join || "0"}</Td>
                              <Td>$ {item?.monthly_earn?.toFixed(2) || 0.0}</Td>
                              <Td>{dateFormat(item?.joined_date)}</Td>
                              <Td>{item.email}</Td>
                              <Td>{item.referral_code}</Td>
                              <Td>
                                <Link
                                  className="btn btn-primary btn-sm view-button users-view-btn"
                                  to={`/referrals/${item.id}`}
                                >
                                  User Details
                                </Link>
                              </Td>
                            </Tr>
                          ))
                        )}
                      </Tbody>
                    </Table>
                  </div>
                </div>
                <span className="reports-result-count">
                  {loader || !referrals.length
                    ? ""
                    : `${
                        (pageNumber - 1) * 10 + referrals.length
                      } of ${totalCount}`}
                </span>
                <Paginator
                  totalCount={totalCount}
                  pageSize={pageSize}
                  pageClick={handlePageClick}
                  forcePage={pageNumber}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};

export default ReferralDetails;

const CustomInput = React.forwardRef((props, ref) => {
  return (
    <div
      className="form-control dateFilterIcon d-flex"
      onClick={props.onClick}
      ref={ref}
    >
      <img src={calenderIcon} alt="calendar-icon" />
      <label
        className={`${props.startDate && props.endDate ? "selectedDate" : ""}`}
      >
        {props.value || props.placeholder}
      </label>
    </div>
  );
});
