import React from "react";
function PlayersRankInfoTable({ players }) {
  return (
    <div className="table-rep-plugin tournamentRound">
      <div className="table-responsive mb-0" data-pattern="priority-columns">
        <div className="table-responsrowspanive seize-wallet-table">
          <table className="table table-striped table-bordered manage-complaints-table lobbies-table add-schedule">
            <thead>
              <tr>
                <th scope="col" rowSpan="2">
                  Username
                </th>
                <th scope="col" rowSpan="2">
                  Player Rank
                </th>
              </tr>
            </thead>
            <tbody>
              {players &&
                players.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td className="text-capitalize">{item.username}</td>
                      <td className="text-capitalize">
                        {item.player_rank || "NA"}
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default PlayersRankInfoTable;
