import React from "react";
import { Row, Col, Label, Button } from "reactstrap";
import { useState } from "react";
import { sendManualRankByAdmin } from "../../../services/bracket_tournaments_api_helper";
import toastrOptions from "../../../helpers/toastr-options/toastr-options";
import { toast } from "react-toastify";
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from "react-accessible-accordion";
import PlayersRankInfoTable from "../Table/PlayersRankInfoTable";
const ManualPlacementAddRankForTeam = ({
  teamsDataToAddRank,
  tournamentId,
  callApi,
  disableSubmit,
}) => {
  const [rankData, setRankData] = useState([]);
  const [errorForDuplicateTeamRank, setErrorForDuplicateTeamRank] =
    useState("");
  const handleSubmit = () => {
    if (rankData.length === 0) {
      const formattedData = teamsDataToAddRank.map(({ team_id, number }) => ({
        team_id,
        number,
      }));
      const data = { teams: formattedData };
      callSubmitApi(data);
    } else {
      const remainingTeams = teamsDataToAddRank
        .filter(
          (team) => !rankData.some((obj) => obj?.team_id === team?.team_id)
        )
        .map(({ team_id, number }) => ({ team_id, number }));
      const finalCombinedRanks = [...rankData, ...remainingTeams];
      const formattedData = finalCombinedRanks.filter(
        (team) => team !== undefined
      );
      const data = { teams: formattedData };
      callSubmitApi(data);
    }
  };
  const callSubmitApi = async (payload) => {
    const nonZeroTeams = payload.teams.filter((team) => team.number > 0);
    const numberSet = new Set(nonZeroTeams.map((team) => team.number));
    if (numberSet.size !== nonZeroTeams.length) {
      setErrorForDuplicateTeamRank("Team ranking can't be the same");
      return;
    }
    try {
      await sendManualRankByAdmin(tournamentId, payload);
      toast.success("Rank added successfully", toastrOptions);
      callApi();
      setRankData([]);
    } catch (error) {
      console.log("API call error:", error);
    }
  };

  const handleTeamRank = (index, e, item) => {
    e.preventDefault();
    const newData = [...rankData];
    newData[index] = {
      ...newData[index],
      team_id: item.team_id,
      number: parseInt(e.target.value),
    };
    setRankData(newData);
    setErrorForDuplicateTeamRank("");
  };
  const [isRankInputFocused, setRankInputFocused] = useState(false);

  const handleRankInputFocus = () => {
    setRankInputFocused(true);
  };

  const handleRankInputBlur = () => {
    setRankInputFocused(false);
  };
  return (
    <Row className="add-lobby-row">
      <Col className="col-xxl-10 col-md-12">
        <h4>Ranks</h4>
        <Accordion
          className="add-tournament-schedule add-tournment-rank"
          allowZeroExpanded
        >
          {teamsDataToAddRank &&
            teamsDataToAddRank.map((item, idx) => {
              return (
                <AccordionItem key={idx}>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      <div
                        className="d-flex justify-content-between align-items-center w-50"
                        onClick={(e) => {
                          if (isRankInputFocused) {
                            e.stopPropagation();
                          }
                        }}
                      >
                        <h5 className="faq-heading text-capitalize">
                          Team name : <span>{item.name}</span>
                        </h5>
                        <span className="faq-heading text-capitalize font-16 rank">
                          <label>Rank</label>
                          <input
                            defaultValue={item.number || 0}
                            type="number"
                            name="number"
                            min="1"
                            className="form-control"
                            disabled={!disableSubmit}
                            onChange={(e) => handleTeamRank(idx, e, item)}
                            onFocus={handleRankInputFocus}
                            onBlur={handleRankInputBlur}
                          />
                        </span>
                      </div>
                    </AccordionItemButton>
                  </AccordionItemHeading>

                  <AccordionItemPanel accordionId="1">
                    {item.players.length === 0 ? (
                      <span>Not data found</span>
                    ) : (
                      <PlayersRankInfoTable players={item.players} />
                    )}
                  </AccordionItemPanel>
                </AccordionItem>
              );
            })}
        </Accordion>

        <Label
          className={
            Error !== "" || errorForDuplicateTeamRank !== ""
              ? "errorMsgGames invalid-feedback"
              : ""
          }
        >
          {errorForDuplicateTeamRank}
          {Error}
        </Label>
        <Button
          type="submit"
          onClick={handleSubmit}
          disabled={rankData.length === 0 ? true : !disableSubmit}
          className="ms-0 "
          color={disableSubmit ? "primary" : "disabled"}
        >
          Submit
        </Button>
      </Col>
    </Row>
  );
};

export default ManualPlacementAddRankForTeam;
