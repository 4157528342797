import React from "react";
import { components } from "react-select";
import CreatableSelect from "react-select/creatable";
const TopicsDropDown = ({ setTopic, topic, options, data }) => {
  const formatCreateLabel = (inputValue) => `Create new topic.. ${inputValue}`;

  const handleChange = (topicChange) => {
    setTopic(topicChange);
  };

  const Input = (props) => {
    const { maxLength } = props.selectProps;
    return <components.Input {...props} maxLength={maxLength} />;
  };

  return (
    <CreatableSelect
      components={{ Input }}
      isClearable
      isDisabled={!data.change}
      options={options}
      key={topic?.value}
      defaultValue={topic}
      formatCreateLabel={formatCreateLabel}
      placeholder="Select or create new topic"
      onChange={handleChange}
      value={topic}
      maxLength={50}
    />
  );
};

export default TopicsDropDown;
