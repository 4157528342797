import React from "react";
import { Modal } from "react-bootstrap";
import LobbyResult from "../../LobbyList/LobbyResult";
function ViewStats({
  loader,
  show,
  player_stats,
  game_data,
  closeModal,
  isPPK,
}) {
  return (
    <Modal
      show={show}
      onHide={closeModal}
      scrollable={true}
      className="custom-modal w-706"
      backdrop="static"
    >
      <Modal.Header closeButton tag="h4">
        <Modal.Title className="text-capitalize">View Stats</Modal.Title>
      </Modal.Header>

      <Modal.Body className="events">
        {loader ? (
          <div class="spinner-grow transaction-spinner" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        ) : (
          <div className="view-more-teams">
            <LobbyResult
              data={player_stats}
              gameData={game_data}
              isTournament={true}
              isPPK={isPPK}
            />
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
}

export default ViewStats;
