import React, { useEffect, useState } from "react";
import { Row, Col, Card, Alert, Container } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { Link, withRouter } from "react-router-dom";

import logo from "../../assets/images/logo-sm-dark.png";
import Loader from "../../components/Common/Loader";
import { confirmpassword } from "../../services/auth_api_helper";

import { toast } from "react-toastify";
import toastrOptions from "../../helpers/toastr-options/toastr-options";

const ResetPassword = (props) => {
  const [isLoading, setisLoading] = useState(false);
  const [successMsg, setsuccessMsg] = useState(null);
  const [errorMsg, seterrorMsg] = useState(null);
  const [showPassword, setshowPassword] = useState(false);
  const [showConfirmPassword, setshowConfirmPassword] = useState(false);
  var url_string = window.location.href;

  var url = new URL(url_string);

  const id = url.searchParams.get("id");
  const token = url.searchParams.get("token");
  async function handleValidSubmit(event, values) {
    event.preventDefault();
    showLoader(true);
    setsuccessMsg(null);
    seterrorMsg(null);

    await confirmpassword(values.newpassword, id, token)
      .then((res) => {
        showLoader(false);
        setsuccessMsg(res.success);
        toast.success(res.success, toastrOptions);
        props.history.push("/login");
      })
      .catch((err) => {
        showLoader(false);
        seterrorMsg(err);
      });
  }

  useEffect(() => {
    document.body.className = "authentication-bg";
    // remove classname when component will unmount
    return function cleanup() {
      document.body.className = "";
    };
  });

  function showLoader(load) {
    setisLoading(load);
  }
  const toggleShow = () => {
    setshowPassword(!showPassword);
  };
  const toggleConfirmPassword = () => {
    setshowConfirmPassword(!showConfirmPassword);
  };
  return (
    <React.Fragment>
      <Loader showLoader={isLoading} />
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-login text-center">
                  <div className="bg-login-overlay"></div>
                  <div className="position-relative">
                    <h5 className="text-white font-size-20">Reset Password</h5>
                    <Link to="/" className="logo logo-admin mt-4">
                      <img src={logo} alt="" height="30" />
                    </Link>
                  </div>
                </div>
                <div className="card-body pt-5">
                  <div className="p-2">
                    {successMsg ? (
                      <Alert
                        color="success"
                        className="text-center mb-4"
                        style={{ marginTop: "13px" }}
                      >
                        {successMsg}
                      </Alert>
                    ) : null}

                    <AvForm
                      className="form-horizontal"
                      onValidSubmit={(e, v) => {
                        handleValidSubmit(e, v);
                      }}
                    >
                      <div className="mb-3">
                        <AvField
                          name="newpassword"
                          label="New Password"
                          type={showPassword ? "text" : "password"}
                          required
                          placeholder="Enter New Password"
                          validate={{
                            required: {
                              value: true,
                              errorMessage: "New password is required",
                            },
                          }}
                        />
                        <i
                          onClick={toggleShow}
                          class={showPassword ? "fa fa-eye" : "fa fa-eye-slash"}
                          aria-hidden="true"
                        ></i>
                      </div>

                      <div className="mb-3">
                        <AvField
                          name="confirmpassword"
                          label="Confirm Password"
                          type={showConfirmPassword ? "text" : "password"}
                          required
                          placeholder="Confirm Password"
                          validate={{
                            required: {
                              value: true,
                              errorMessage: "Confirm password is required",
                            },
                            match: {
                              value: "newpassword",
                              errorMessage: "Confirm password didn't match",
                            },
                          }}
                        />
                        <i
                          onClick={toggleConfirmPassword}
                          class={
                            showConfirmPassword
                              ? "fa fa-eye"
                              : "fa fa-eye-slash"
                          }
                          aria-hidden="true"
                        ></i>
                      </div>

                      {errorMsg ? (
                        <p className="error-msg">{errorMsg}</p>
                      ) : null}

                      <div className="mt-4">
                        <button
                          className="btn btn-primary w-100 waves-effect waves-light"
                          type="submit"
                        >
                          Reset Password
                        </button>
                      </div>

                      <div className="mt-5 text-center">
                        <p>
                          Remember It ?{" "}
                          <Link to="/login" className="fw-medium text-primary">
                            {" "}
                            Sign In here
                          </Link>{" "}
                        </p>
                      </div>
                    </AvForm>
                  </div>
                </div>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(ResetPassword);
