import { axiosApi } from "./index";

// API Methods
export async function getUserList(filterModel) {
  let params = new URLSearchParams();

  if (filterModel.searchTerm && filterModel.searchTerm !== "")
    params.append("query", filterModel.searchTerm);

  if (filterModel.sortBy && filterModel.sortBy !== "")
    params.append("sort_by", filterModel.sortBy);

  if (filterModel.pageNumber) params.append("page", filterModel.pageNumber);

  if (filterModel.pageSize) params.append("page_size", filterModel.pageSize);

  return await axiosApi.get("users/", { params })
}

export async function getUserDetail(userId) {
  return await axiosApi.get(`users/${userId}/`);
}

export async function activateDeactivateUser(userId, activeStatus, reason) {
  return await axiosApi.put(`users/${userId}/change-activation/`, {
    status: activeStatus,
    reason: reason,
  });
}
export async function seizeWalletOfUser(userId) {
  return await axiosApi.put(`seize-money/${userId}/`);
}

export async function userTransactionHistory(userId, pageNumber, pageSize) {
  let params = new URLSearchParams();

  if (pageNumber) params.append("page", pageNumber);

  if (pageSize) params.append("page_size", pageSize);
  return await axiosApi
    .get(`users/${userId}/transaction-history/`, { params })
    .then((response) => {
      return { data: response.results, total: response.total };
    });
}
export async function seizeWinningAmountOfUser(userId, transactionId) {
  return await axiosApi.put(`seize-money/${userId}/`, {
    transactionId: transactionId,
  });
}

export async function releaseWinningOfUser(userId, transactionId) {
  return await axiosApi.put(`users/${userId}/transaction-status`, {
    transaction_id: transactionId,
  });
}
export async function seizeParticularWinningOfUser(userId, transactionId) {
  return await axiosApi.put(`seize-money/${userId}/`, {
    transaction_id: transactionId,
  });
}
export async function changeAdminPassword(model) {
  return await axiosApi.put(`/change-password/`, model);
}
export async function updateEarning(userId, model) {
  return await axiosApi.put(`users/affiliate-days/${userId}/`, model);
}

//Reports
export async function getCompliantReports(filterModel) {
  let params = new URLSearchParams();

  if (filterModel.from_date) params.append("from_date", filterModel.from_date);
  if (filterModel.to_date) params.append("to_date", filterModel.to_date);
  if (filterModel.page_size) params.append("page_size", filterModel.page_size);
  if (filterModel.page) params.append("page", filterModel.page);
  if (filterModel.type && filterModel.type != 0)
    params.append("type", filterModel.type);

  return await axiosApi.get("report/complaints/", { params });
}
export async function getUserReports(filterModel) {
  let params = new URLSearchParams();

  if (filterModel.from_date) params.append("from_date", filterModel.from_date);
  if (filterModel.to_date) params.append("to_date", filterModel.to_date);
  if (filterModel.page_size) params.append("page_size", filterModel.page_size);
  if (filterModel.page) params.append("page", filterModel.page);
  if (filterModel.type && filterModel.type != 0)
    params.append("type", filterModel.type);

  return await axiosApi.get("report/users/", { params });
}

export async function getTransactionReport({
  from_date,
  to_date,
  page_size,
  type,
  page,
}) {
  let params = new URLSearchParams();
  if (from_date) params.append("from_date", from_date);
  if (to_date) params.append("to_date", to_date);
  if (page_size) params.append("page_size", page_size);
  if (type) params.append("type", type);
  if (page) params.append("page", page);
  return await axiosApi.get(`reports/transactions/`, { params });
}

export async function getLobbiesReport({
  from_date,
  to_date,
  page_size,
  type,
  page,
}) {
  let params = new URLSearchParams();
  if (from_date) params.append("from_date", from_date);

  if (to_date) params.append("to_date", to_date);
  if (page_size) params.append("page_size", page_size);
  if (type) params.append("type", type);
  if (page) params.append("page", page);
  return await axiosApi.get(`report/lobby/`, { params });
}

export async function getCSV({ from_date, to_date, report, type }) {
  let params = new URLSearchParams();
  if (from_date) params.append("from_date", from_date);

  if (to_date) params.append("to_date", to_date);
  if (report) params.append("report", report);
  if (type) params.append("type", type);
  if (report === "transactions") params.append("page_size", 0);

  return await axiosApi.get(`report/export-csv/`, { params });
}
//Activity logs api
export async function getEventList() {
  return await axiosApi.get(`events/`);
}
export async function getActivityList({
  event,
  from_date,
  to_date,
  type,
  page,
  page_size,
  sortBy,
}) {
  let params = new URLSearchParams();
  if (sortBy && sortBy !== "")
    params.append(
      sortBy === "create_date" || sortBy === "-create_date" ? "date" : "order",
      sortBy
    );
  if (type.length != 0) params.append("type", `${type},`);
  if (event.length != 0) params.append("event", `${event},`);
  if (from_date) params.append("from_date", from_date);
  if (to_date) params.append("to_date", to_date);
  if (page) params.append("page", page);
  if (page_size) params.append("page_size", page_size);

  return await axiosApi
    .get("logs/", {
      params,
    })
    .then((response) => {
      return { data: response.results, total: response.total };
    });
}
//Edit Referral
export async function updateReferral(userId, model) {
  return await axiosApi.put(`users/referral-code/${userId}/`, model);
}
//update free lobby limit
export async function updateFreeLobbyLimit(userId, model) {
  return await axiosApi.put(`users/free-lobby-limit/${userId}/`, model);
}
