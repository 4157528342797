import React, { useEffect, useState } from "react";
import { Row, Col, Card, CardBody } from "reactstrap";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { withRouter } from "react-router-dom";
import Loader from "../../../components/Common/Loader";

import { connect } from "react-redux";
import {
  getTournamentRoundList,
  getTournamentsById,
} from "../../../services/bracket_tournaments_api_helper";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";

// Demo styles, see 'Styles' section below for some notes on use.
import "react-accessible-accordion/dist/fancy-example.css";
import TournamentsRoundHeading from "./TournamentsRoundHeading";
import TournamentScheduleTable from "./TournamentScheduleTable";
import "../BracketsAndTournaments.scss";
import { GoBack } from "../../../components/GoBack";
import ManualPlacementAddRankForTeam from "../Form/ManualPlacementAddRankForTeam";
import ViewBracket from "./ViewBracket";
import ViewManualCreatedBracket from "./ViewManualCreatedBracket";

const AddTournamentSchedule = (props) => {
  const { callBackToDetails } = props;
  const [showLoadder, setShowLoadder] = useState(false);
  const [tournamentScheduleLists, setTournamentScheduleLists] = useState([]);
  const [bracketId, setBracketEmbedId] = useState();
  let tournamentId = props.match.params.tournamentId;
  let nameOfGame = props.location?.data?.nameOfGame || props.details?.game;
  let tournamentname =
    props.location?.data?.details?.name || props?.details?.name;
  let seed = props.location?.data?.details?.seeding || props?.details?.seeding;
  const returnUrl = "/tournament";
  const [tournamentDetails, setTournamentDetails] = useState(null);
  useEffect(() => {
    if (tournamentId) {
      getRoundData(tournamentId);
      getDetail();
      setShowLoadder(true);
    }
  }, [props.match.params.tournamentId, tournamentId]);
  const getDetail = async () => {
    await getTournamentsById(tournamentId)
      .then((res) => {
        if (props.match.params.gameSlug === res.slug) {
          setTournamentDetails(res);
        } else {
          props.history.push({
            pathname: `/tournament/addschedule/${res.slug}/${tournamentId}`,
            data: { nameOfGame: res.game },
          });
          setShowLoadder(true);
          window.location.reload();
        }
      })
      .catch(() => {
        goToBack();
      });
  };
  const goToBack = () => {
    props.history.push(returnUrl);
  };
  const reCallApi = () => {
    getRoundData(tournamentId);
    getDetail();
    setShowLoadder(true);
  };

  const getRoundData = async (id) => {
    await getTournamentRoundList(id)
      .then((res) => {
        setTournamentScheduleLists(res);
        setBracketEmbedId(res[0].bracket_cloud_id);
        setShowLoadder(false);
        AddRankObj(res[0]?.teams);
        callBackToDetails(res);
      })
      .catch(() => {
        setShowLoadder(false);
      });
  };
  const [rank, setRank] = useState([]);
  const AddRankObj = (data) => {
    const formatData =
      data &&
      data.map(({ team_id, seeding_number, name, players }) => ({
        team_id,
        number: seeding_number,
        name,
        players,
      }));
    setRank(formatData);
  };
  return (
    <React.Fragment>
      <Loader showLoader={showLoadder} />
      <div
        className={
          props?.viewMode
            ? "page-content tournament-section-tab px-0 pt-0 pb-0 mt-0"
            : "page-content"
        }
      >
        {props?.viewMode ? null : (
          <Breadcrumbs breadcrumbItem="Add Tournament Schedule" />
        )}
        <Row>
          <Col lg={12}>
            <Card className=" px-0 pt-0 pb-0 ">
              <CardBody>
                {props?.viewMode ? null : <GoBack backUrl="/tournament" />}
                {tournamentScheduleLists?.length === 0 ||
                tournamentScheduleLists[0]?.tour_seeding === 5 ||
                rank?.length === 0 ? null : (
                  <ManualPlacementAddRankForTeam
                    teamsDataToAddRank={rank}
                    tournamentId={parseInt(tournamentId)}
                    callApi={reCallApi}
                    disableSubmit={tournamentScheduleLists?.[0]?.seeding}
                  />
                )}
                <Row className="add-lobby-row mt-4">
                  <Col className="col-xxl-10 col-md-12">
                    <h3 className="mb-3">Rounds</h3>
                    <Accordion
                      className="add-tournament-schedule"
                      allowZeroExpanded
                    >
                      {tournamentScheduleLists?.length === 0 ? (
                        <h5>No data found</h5>
                      ) : (
                        tournamentScheduleLists.map((item, id) => {
                          return (
                            <AccordionItem key={id}>
                              <AccordionItemHeading>
                                <AccordionItemButton>
                                  <TournamentsRoundHeading
                                    data={item}
                                    callApi={reCallApi}
                                    isPPK={
                                      tournamentDetails?.game_type === "PPK"
                                    }
                                  />
                                </AccordionItemButton>
                              </AccordionItemHeading>
                              <AccordionItemPanel accordionId="1">
                                {item.matches.length === 0 ? (
                                  <span>Not data found</span>
                                ) : (
                                  <TournamentScheduleTable
                                    matches={item.matches}
                                    tournamentId={
                                      props.match.params.tournamentId
                                    }
                                    roundId={item.id}
                                    gameslug={
                                      props.match.params.gameSlug ||
                                      tournamentDetails?.slug
                                    }
                                    callApi={reCallApi}
                                    currentStatus={
                                      props?.details?.current_status ||
                                      tournamentDetails?.current_status
                                    }
                                    allSchedule={tournamentScheduleLists}
                                    nameOfGame={nameOfGame}
                                    tournamentDetails={tournamentDetails}
                                    teamsInRound={item.teams}
                                  />
                                )}
                              </AccordionItemPanel>
                            </AccordionItem>
                          );
                        })
                      )}
                    </Accordion>
                    {tournamentScheduleLists?.length === 0 ? null : props
                        ?.details?.game_type === "PPK" ||
                      tournamentDetails?.game_type === "PPK" ? (
                      <Col lg={12} className=" bracket-preview mt-4">
                        <h3 className="mb-3">Bracket Preview</h3>
                        <div className="bracket_header">
                          <h4>{tournamentname}</h4>
                          <p>{seed}</p>
                        </div>
                        {tournamentDetails && (
                          <ViewManualCreatedBracket
                            details={tournamentDetails.match_info}
                          />
                        )}
                      </Col>
                    ) : (
                      <Col lg={12} className=" bracket-preview mt-4">
                        <h3 className="mb-3">Bracket Preview</h3>
                        <ViewBracket bracketId={bracketId} />
                      </Col>
                    )}
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};
const mapStateToProps = (state) => {
  return {
    game: state.Games?.game,
  };
};
export default withRouter(connect(mapStateToProps)(AddTournamentSchedule));
