export const dynamicTableValuesTransactions = {
  0: "tableHead",
  1: "tableHead",
  2: "tableHead",
  3: "tableHead",
  4: "tableHead",
  5: "tableHead",
  6: "tableHead",
  7: "tableHead",
  8: "tableHeadUnclaimed",
  9: "tableHead",
  10: "tableHead",
  11: "tableHeadDormant",
  12: "tableHeadInactivity",
};
