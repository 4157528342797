import React, { useState } from "react";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import { updateFreeLobbyLimit } from "../services/user_api_helper";
import PropTypes from "prop-types";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { FormGroup, Button } from "reactstrap";
import { toast } from "react-toastify";
import toastrOptions from "../helpers/toastr-options/toastr-options";

import tickIcon from "../assets/images/user-tick-icon.svg";
import crossIcon from "../assets/images/user-cross-icon.svg";
import IconsToolTip from "../components/IconsToolTip";

const EditFreeLobby = (props) => {
  const [value, setValue] = useState(props.defaultFreeLobbyLimit);
  const handleChange = (val) => {
    setValue(val);
  };
  const handleSubmit = async (event, values) => {
    let data = {
      limit: values.freeLobbyLimit,
    };
    if (value)
      await updateFreeLobbyLimit(props.userId, data)
        .then((res) => {
          props.onClose();
          props.callApi();
          toast.success("Free lobby limit successfully updated", toastrOptions);
        })
        .catch((err) => {
          console.log(err);
        });
  };
  return (
    <>
      <AvForm
        className="d-flex editEarning-timeline"
        onValidSubmit={(e, v) => {
          handleSubmit(e, v);
        }}
      >
        <AvField
          className="min-width-261"
          name="freeLobbyLimit"
          label="Free Lobby Limit"
          placeholder="Free Lobby Limit"
          type="text"
          validate={{
            required: {
              value: true,
              errorMessage: "Free lobby limit is required",
            },
            number: {
              value: true,
              errorMessage: "Please enter number only",
            },
            min: {
              value: 0,
              errorMessage: "Free lobby limit can't be less than 0",
            },
            pattern: {
              value: /^(0|[1-9]\d*)(e-?(0|[1-9]\d*))?$/,
              errorMessage: "Free lobby limit can't be in decimals",
            },
          }}
          onChange={(e) => handleChange(e.target.value)}
          defaultValue={value}
        />
        <FormGroup >
          <div className="mt-4 d-flex">
            <Button
              type="submit"
              color="primary"
              className="edit-icon info-icon"
            >
              <IconsToolTip
                image={tickIcon}
                altImageText="user-tick-icon"
                text="Save"
              />
            </Button>
            <Button
              className="edit-icon mt-2 ms-0 px-0 info-icon"
              onClick={() => {
                props.onClose();
              }}
            >
              <IconsToolTip
                image={crossIcon}
                altImageText="user-cross-icon"
                text="Cancel"
              />
            </Button>
          </div>
        </FormGroup>
      </AvForm>
    </>
  );
};
EditFreeLobby.propTypes = {
  onClose: PropTypes.func,
  callApi: PropTypes.func,
};
export default EditFreeLobby;
