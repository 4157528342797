import community from "../../src/assets/images/community.svg";
import referrals from "../../src/assets/images/referrals.svg";
import globalsettings from "../../src/assets/images/globalsettings.svg";
import demopage from "../../src/assets/images/demo-icon.svg";
import bannedLocation from "../../src/assets/images/Banned Locations.svg";
import cms from "../../src/assets/images/CMS.svg";
import complaints from "../../src/assets/images/Complaints.svg";
import lobby from "../../src/assets/images/Lobbies.svg";
import staff from "../../src/assets/images/Staff.svg";
import user from "../../src/assets/images/Users.svg";
import games from "../../src/assets/images/Game.svg";
import groups from "../../src/assets/images/Groups.svg";
import reports from "../../src/assets/images/Reports-icon.svg";
import activitylog from "../../src/assets/images/activity-log.svg";
import tournament from "../../src/assets/images/tournament.svg";
const commonPermissions = [
  {
    permissionName: "Report",
    pathname: "/report",
    label: "Reports",
    icon: reports,
    order: 1,
  },
  {
    permissionName: "Staff",
    pathname: "/staff",
    label: "Staff",
    icon: staff,
    order: 2,
  },
  {
    permissionName: "Users",
    pathname: "/users",
    label: "Users",
    icon: user,
    order: 3,
  },
  {
    permissionName: "Game",
    pathname: "/games",
    label: "Games",
    icon: games,
    order: 4,
  },
  {
    permissionName: "Lobby",
    pathname: "/lobby",
    label: "Lobbies",
    icon: lobby,
    order: 5,
  },
  {
    permissionName: "Complaint",
    pathname: "/complaints",
    label: "Complaint",
    icon: complaints,
    order: 7,
  },
  {
    permissionName: "Banned location",
    pathname: "/locationLists",
    label: "Banned locations",
    icon: bannedLocation,
    order: 8,
  },
  {
    permissionName: "Community Video",
    pathname: "/community",
    label: "Community",
    icon: community,
    order: 9,
  },

  {
    permissionName: "Referral Report",
    pathname: "/referrals",
    label: "Referrals",
    icon: referrals,
    order: 10,
  },
  {
    permissionName: "Config",
    pathname: "/globalsettings",
    label: "Global Settings",
    icon: globalsettings,
    order: 11,
  },
  {
    permissionName: "Game Demo",
    pathname: "/gameDemo",
    label: "Game Demo",
    icon: demopage,
    order: 12,
  },
  {
    permissionName: "Group",
    pathname: "/groups",
    label: "Groups",
    icon: groups,
    order: 13,
  },
  {
    permissionName: "Logs",
    pathname: "/activitylog",
    label: "Logs",
    icon: activitylog,
    order: 14,
  },
  {
    permissionName: "Tournament",
    pathname: "/tournament",
    label: "Tournament",
    icon: tournament,
    order: 16,
  },

  // {
  //   permissionName: "Challenge",
  //   pathname: "/challenges",
  //   label: "Challenge",
  //   icon: challenges,
  //   order: 15,
  // },
];
export const permissionConstant = [
  ...commonPermissions,
  {
    permissionName: "Static Page",
    pathname: "/cms",
    label: "CMS",
    icon: cms,
    order: 6,
  },
];
export const permissionConstantSecond = [
  ...commonPermissions,

  {
    permissionName: "PPK & FAQ",
    pathname: "/cms",
    label: "CMS",
    icon: cms,
    order: 6,
  },
];
// Add new games here
export const permissionGameConstantForLobbies = [
  {
    permissionName: "Apex",
    pathname: "/lobby",
    label: "Lobbies",
    icon: lobby,
    order: 5,
    shortName: "apex",
  },
  {
    permissionName: "COD Mobile",
    pathname: "/lobby",
    label: "Lobbies",
    icon: lobby,
    order: 5,
    shortName: "codmobile",
  },
  {
    permissionName: "Fortnite",
    pathname: "/lobby",
    label: "Lobbies",
    icon: lobby,
    order: 5,
    shortName: "fortnite",
  },
  {
    permissionName: "PUBG Mobile",
    pathname: "/lobby",
    label: "Lobbies",
    icon: lobby,
    order: 5,
    shortName: "pubgmobile",
  },
  {
    permissionName: "PUBG PC",
    pathname: "/lobby",
    label: "Lobbies",
    icon: lobby,
    order: 5,
    shortName: "pubgpc",
  },
  {
    permissionName: "Valorant",
    pathname: "/lobby",
    label: "Lobbies",
    icon: lobby,
    order: 5,
    shortName: "valorant",
  },
  {
    permissionName: "Cod Warzone",
    pathname: "/lobby",
    label: "Lobbies",
    icon: lobby,
    order: 5,
    shortName: "codwarzone",
  },
];

export const ChallengesGamesLabels = ["Valorant", "Apex"];

export const checkIfGamePermissionIsAvailable = (obj) =>
  obj === null
    ? []
    : obj.extras?.permissions.filter((el) => {
        return permissionGameConstantForLobbies.find((element) => {
          return element.permissionName === el.label;
        });
      });

export const challengePermissionsGames = (gamePermissions) =>
  ChallengesGamesLabels.filter((challengeLabel) =>
    gamePermissions.find((game) => game.label === challengeLabel)
  );

export const retirevePath = (
  obj,
  notFound,
  permissionConstantPath,
  permissionConstantSecondPath
) =>
  obj === null
    ? "/login"
    : notFound
    ? checkIfCMSorPPK(obj, permissionConstantPath)
    : checkIfCMSorPPK(obj, permissionConstantSecondPath);

export const checkIfCMSorPPK = (obj, permissionsObj) =>
  obj.extras?.permissions?.map((data, idx) => {
    return permissionsObj.filter((type) => type.permissionName === data.label);
  });
export const codename = [
  {
    label: "codmobile",
    value: "cod_mobile",
  },
  {
    label: "pubgmobile",
    value: "pubg_mobile",
  },
  {
    label: "pubgpc",
    value: "pubg_pc",
  },
  {
    label: "codwarzone",
    value: "cod_warzone",
  },
];
export const searchCodename = (label) =>
  codename.filter((challengeLabel) => challengeLabel.label === label);
