import React, { useEffect, useState } from "react";
import { getChallengesList } from "../../services/challenges_api_helper";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Link } from "react-router-dom";
import {
  Row,
  Col,
  Card,
  CardBody,
  DropdownToggle,
  DropdownMenu,
  Dropdown,
  DropdownItem,
  Button,
} from "reactstrap";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import Paginator from "../../helpers/paginator/paginator";
import FilterPermission from "../../helpers/FilterPermission";
import { filterOutPermissionToShowHide } from "../../helpers/PermissionUtils";
import { getGameList } from "../../services/game_api_helper";
import {
  challengesStatusOption,
  competitionStatus,
  dateFormat,
  DropDownOptionsChallenges,
  dropdownOptionsForChallengeMode,
  GAME_MAP_CHALLENGES,
  gameTypeEnumForChallenges,
  getGameFromId,
  getFilterText,
  getFilterChallengeGame,
} from "../../helpers/util";
import DeleteAlert from "./DeleteAlert";
import ClearButton from "../../components/ClearButton";
const ChallengesList = (props) => {
  const [challenges, setChallenges] = useState([]);
  const [games, setGames] = useState([]);
  const [traditional, setTraditionalGames] = useState([]);
  const [isLoading, setisLoading] = useState(false);
  const [totalCount, settotalCount] = useState(null);

  //filter keys
  const pageSize = 10;
  const [pageNumber, setpageNumber] = useState(1);

  //filters states
  const [singlebtn, setSinglebtn] = useState(false);
  const [singlebtnChallengeMode, setSinglebtnChallengeMode] = useState(false);
  const [singlebtnGame, setSinglebtnGame] = useState(false);
  const [statusFilter, setStatusFilter] = useState(false);
  const [sortBy, setsortBy] = useState(null);
  const [selectedDropdown, setselectedDropdown] = useState(null);
  const [selectedDropdownByChallengeMode, setselectedDropdownByChallengeMode] =
    useState(null);
  const [selectedDropdownByGameName, setselectedDropdownByGameName] =
    useState(null);
  const [selectedDropdownByStatusFilter, setselectedDropdownByStatusFilter] =
    useState(null);
  const [deleteId, setDeleteId] = useState(null);
  const [vs, setVs] = useState(null);
  const [deleteModal, setdeleteModal] = useState(false);
  useEffect(() => {
    getList();
  }, [
    pageNumber,
    sortBy,
    selectedDropdownByChallengeMode,
    selectedDropdownByGameName,
    selectedDropdownByStatusFilter,
  ]);
  const canDeleteChallenge = (gameObj = {}) => {
    const filteredPermission = FilterPermission(
      props.permission,
      gameObj?.permissionType
    );
    if (filteredPermission.length !== 0) {
      const setdelete = filterOutPermissionToShowHide(
        filteredPermission[0].permissions,
        gameObj.permissionDeleteType
      );
      return setdelete;
    }
    return false;
  };
  const getList = async () => {
    setisLoading(true);
    const filters = filterModel();
    await getChallengesList(filters)
      .then((res) => {
        setChallenges(res.data);
        settotalCount(res.total);
      })
      .catch(() => {
        setisLoading(false);
      });
    getGameList()
      .then((res) => {
        setGames(res);

        const GetTraditional = res.filter((data) => {
          return data.types_of_game.find((gameTypeMode) => gameTypeMode == "3");
        });
        setTraditionalGames(GetTraditional);
        setisLoading(false);
      })
      .catch(() => {
        setisLoading(false);
      });
  };
  function filterModel() {
    let model = {
      sortBy: sortBy,
      challengeMode: selectedDropdownByChallengeMode,
      game: selectedDropdownByGameName,
      status: selectedDropdownByStatusFilter,
      pageNumber: pageNumber,
      pageSize: pageSize,
    };
    return model;
  }
  const handlePageClick = (pageNum) => {
    setpageNumber(pageNum);
  };

  const dropdownChange = (value) => {
    setselectedDropdown(value);
    setsortBy(value);
  };

  const dropdownChangeByChallengeMode = (value) => {
    setselectedDropdownByChallengeMode(value);
    setpageNumber(1);
  };
  const dropdownChangeByGameName = (value) => {
    setselectedDropdownByGameName(value);
    setpageNumber(1);
  };
  const dropdownChangeByStatusFilter = (value) => {
    setselectedDropdownByStatusFilter(value);
    setpageNumber(1);
  };
  const openAlert = (id, vrs) => {
    setDeleteId(id);
    setVs(vrs);
    setdeleteModal(true);
  };

  const handleCLoseAlert = () => {
    setdeleteModal(false);
  };
  const handleClear = () => {
    setpageNumber(1);
    setselectedDropdown(null);
    setsortBy(null);
    setselectedDropdownByGameName(null);
    setselectedDropdownByChallengeMode(null);
    setselectedDropdownByStatusFilter(null);
  };
  return (
    <>
      <React.Fragment>
        <div className="page-content">
          <Breadcrumbs breadcrumbItem="Manage Challenges" />
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <Row className="search-box">
                    <Col className="col-xl-2 col-lg-4 col-md-3 col-sm-3 col-xs-6">
                      <Dropdown
                        isOpen={singlebtn}
                        toggle={() => setSinglebtn(!singlebtn)}
                      >
                        <DropdownToggle
                          caret
                          color="primary"
                          className="dropdown-toggle-split sort-by-btn"
                        >
                          <span>
                            {selectedDropdown === null
                              ? "Sort By"
                              : getFilterText(
                                  DropDownOptionsChallenges,
                                  selectedDropdown
                                )}
                          </span>
                          <i className="mdi mdi-chevron-down" />
                        </DropdownToggle>
                        <DropdownMenu className="lobbies-menuListing">
                          {DropDownOptionsChallenges.map((item, index) => {
                            return (
                              <DropdownItem
                                key={item.value}
                                onClick={() => dropdownChange(item.value)}
                                dropdownvalue={item.value}
                              >
                                {item.text}
                                &nbsp; &nbsp;
                                {selectedDropdown === item.value ? (
                                  <i className="mdi mdi-check-circle-outline"></i>
                                ) : null}
                              </DropdownItem>
                            );
                          })}
                        </DropdownMenu>
                      </Dropdown>
                    </Col>
                    <Col className="col-xl-3 col-lg-4 col-md-4 col-sm-3 col-xs-6">
                      <Dropdown
                        isOpen={singlebtnChallengeMode}
                        toggle={() =>
                          setSinglebtnChallengeMode(!singlebtnChallengeMode)
                        }
                      >
                        <DropdownToggle
                          caret
                          color="primary"
                          className="dropdown-toggle-split sort-by-btn"
                        >
                          <span>
                            {selectedDropdownByChallengeMode === null
                              ? "Challenge Mode"
                              : getFilterText(
                                  dropdownOptionsForChallengeMode,
                                  selectedDropdownByChallengeMode
                                )}
                          </span>
                          <i className="mdi mdi-chevron-down" />
                        </DropdownToggle>
                        <DropdownMenu>
                          {dropdownOptionsForChallengeMode.map(
                            (item, index) => {
                              return (
                                <DropdownItem
                                  key={item.value}
                                  onClick={() =>
                                    dropdownChangeByChallengeMode(item.value)
                                  }
                                  dropdownvalue={item.value}
                                >
                                  {item.text}
                                  &nbsp; &nbsp;
                                  {selectedDropdownByChallengeMode ===
                                  item.value ? (
                                    <i className="mdi mdi-check-circle-outline"></i>
                                  ) : null}
                                </DropdownItem>
                              );
                            }
                          )}
                        </DropdownMenu>
                      </Dropdown>
                    </Col>
                    <Col className="col-xl-3 col-lg-4 col-md-4 col-sm-3 col-xs-6">
                      <Dropdown
                        isOpen={singlebtnGame}
                        toggle={() => setSinglebtnGame(!singlebtnGame)}
                      >
                        <DropdownToggle
                          caret
                          color="primary"
                          className="dropdown-toggle-split sort-by-btn"
                        >
                          <span>
                            {selectedDropdownByGameName === null
                              ? "Game"
                              : getFilterChallengeGame(
                                  traditional,
                                  selectedDropdownByGameName
                                )}
                          </span>
                          <i className="mdi mdi-chevron-down" />
                        </DropdownToggle>
                        <DropdownMenu>
                          {traditional.map((item, index) => {
                            return (
                              <DropdownItem
                                key={item.value}
                                onClick={() =>
                                  dropdownChangeByGameName(item.slug)
                                }
                                dropdownvalue={item.slug}
                              >
                                {item.name}
                                &nbsp; &nbsp;
                                {selectedDropdownByGameName === item.slug ? (
                                  <i className="mdi mdi-check-circle-outline"></i>
                                ) : null}
                              </DropdownItem>
                            );
                          })}
                        </DropdownMenu>
                      </Dropdown>
                    </Col>
                    <Col className="col-xl-2 col-lg-3 col-md-3 col-sm-3 col-xs-6">
                      <Dropdown
                        isOpen={statusFilter}
                        toggle={() => setStatusFilter(!statusFilter)}
                      >
                        <DropdownToggle
                          caret
                          color="primary"
                          className="dropdown-toggle-split sort-by-btn"
                        >
                          <span>
                            {" "}
                            {selectedDropdownByStatusFilter === null
                              ? "Challenge Status"
                              : getFilterText(
                                  challengesStatusOption,
                                  selectedDropdownByStatusFilter
                                )}
                          </span>
                          <i className="mdi mdi-chevron-down" />
                        </DropdownToggle>
                        <DropdownMenu>
                          {challengesStatusOption.map((item, index) => {
                            return (
                              <DropdownItem
                                key={item.value}
                                onClick={() =>
                                  dropdownChangeByStatusFilter(item.value)
                                }
                                dropdownvalue={item.value}
                              >
                                {item.text}
                                &nbsp; &nbsp;
                                {selectedDropdownByStatusFilter ===
                                item.value ? (
                                  <i className="mdi mdi-check-circle-outline"></i>
                                ) : null}
                              </DropdownItem>
                            );
                          })}
                        </DropdownMenu>
                      </Dropdown>
                    </Col>
                    <Col className="col-xl-2 col-lg-3 col-md-3 col-sm-3 col-xs-6 mb-4">
                      <ClearButton handleClear={handleClear} />
                    </Col>
                  </Row>
                  <div className="table-rep-plugin">
                    <div
                      className="table-responsive mb-0"
                      data-pattern="priority-columns"
                    >
                      <Table
                        id="tech-companies-1"
                        className="table table-striped table-bordered manage-complaints-table lobbies-table"
                      >
                        <Thead>
                          <Tr>
                            <Th data-priority="1">Game Name</Th>
                            <Th data-priority="1">Game Type</Th>
                            <Th data-priority="1">Game Mode</Th>
                            <Th data-priority="1">Competition</Th>
                            <Th data-priority="1">Created By</Th>
                            <Th data-priority="1">Enrolled Players</Th>
                            <Th data-priority="1">Create Date</Th>
                            <Th data-priority="1">Match Date</Th>
                            <Th data-priority="1">Match Time</Th>
                            <Th data-priority="1">Match End Time</Th>
                            <Th data-priority="1">Entry Fee</Th>
                            <Th data-priority="1">Competition Status</Th>
                            <Th data-priority="1" colSpan="2">
                              Actions
                            </Th>
                          </Tr>
                        </Thead>

                        <Tbody>
                          {isLoading ? (
                            <div
                              class="spinner-grow transaction-spinner"
                              role="status"
                            >
                              <span class="sr-only">Loading...</span>
                            </div>
                          ) : challenges.length === 0 ? (
                            <td colspan="12">
                              <h5 className="text-center mt-4">
                                No challenges(s) Found
                              </h5>
                            </td>
                          ) : (
                            challenges.map((item, index) => {
                              return (
                                <Tr key={index}>
                                  <Td>
                                    {/* {gameName[0]?.name} */}
                                    {getGameFromId(games, item.game)?.name}
                                  </Td>
                                  <Td>
                                    {gameTypeEnumForChallenges[item.game_type]}
                                  </Td>
                                  <Td>{item?.modetag}</Td>
                                  <Td>
                                    {}
                                    {item.challenge_open ? "Open" : "Team"}
                                  </Td>
                                  <Td>
                                    {item?.challenge_creator.creator_username}
                                  </Td>
                                  <Td>{item?.enrolled_teams}</Td>
                                  <Td>
                                    {new Date(item?.create_date).toLocaleString(
                                      "en-US",
                                      {
                                        weekday: "short", // long, short, narrow
                                        day: "numeric", // numeric, 2-digit
                                        year: "numeric", // numeric, 2-digit
                                        month: "long", // numeric, 2-digit, long, short, narrow
                                        hour: "numeric", // numeric, 2-digit
                                        minute: "numeric", // numeric, 2-digit
                                        second: "numeric", // numeric, 2-digit
                                        timeZone: "UTC",
                                        hour12: false,
                                      }
                                    )}
                                  </Td>
                                  <Td>{dateFormat(item?.match_date)}</Td>
                                  <Td>{item?.match_time}</Td>
                                  <Td>
                                    {" "}
                                    {item?.end_time === null
                                      ? "NA"
                                      : item?.end_time}
                                  </Td>
                                  <Td>
                                    ${Number(item?.entry_fee)?.toFixed(2)} per
                                    player
                                  </Td>
                                  <Td>
                                    {(item?.challenge_mode == 1 ||
                                      item?.challenge_mode == 2) &&
                                    item?.end_date === null
                                      ? competitionStatus[2]
                                      : competitionStatus[item?.challenge_mode]}
                                  </Td>
                                  <Td>
                                    <Link
                                      className="btn btn-sm complaint-view-btn btn-secondary challenge-actions"
                                      role="button"
                                      to={`/challenges/${item.id}`}
                                    >
                                      View
                                    </Link>
                                  </Td>
                                  <Td className="location-delete-btn">
                                    {canDeleteChallenge(
                                      GAME_MAP_CHALLENGES[
                                        getGameFromId(games, item.game)?.slug
                                      ]
                                    ) ? (
                                      <Button
                                        type="button"
                                        className="btn btn-danger manageChallengesDelete"
                                        onClick={() => {
                                          openAlert(item.id, item.versus);
                                        }}
                                        disabled={!item.challenge_delete_buffer}
                                      >
                                        Delete
                                      </Button>
                                    ) : null}
                                  </Td>
                                </Tr>
                              );
                            })
                          )}
                        </Tbody>
                      </Table>
                    </div>
                  </div>

                  <Paginator
                    totalCount={totalCount}
                    pageSize={pageSize}
                    pageClick={handlePageClick}
                    forcePage={pageNumber}
                  />
                </CardBody>

                {deleteModal ? (
                  <DeleteAlert
                    DeleteId={deleteId}
                    Versus={vs}
                    onClose={() => handleCLoseAlert()}
                    callApi={() => getList()}
                    Loader={() => setisLoading(true)}
                    StopLoader={() => setisLoading(false)}
                  />
                ) : null}
              </Card>
            </Col>
          </Row>
        </div>
      </React.Fragment>
    </>
  );
};

export default ChallengesList;
