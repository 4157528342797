import React, { useEffect, useState } from "react";

import {
  Row,
  Col,
  Card,
  CardBody,
  DropdownToggle,
  DropdownMenu,
  Dropdown,
  DropdownItem,
} from "reactstrap";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { getLobbyList } from "../../services/lobby_api_helper";
import { getGameList } from "../../services/game_api_helper";
import { Link } from "react-router-dom";
import Paginator from "../../helpers/paginator/paginator";
import { GameTypeMap, LobbyResultMap } from "../LobbyForm/data";
import FilterPermission from "../../helpers/FilterPermission";
import { filterOutPermissionToShowHide } from "../../helpers/PermissionUtils";
import { canFetchResults } from "../../helpers/util";
import { dateFormat, getFilterGame, getFilterText } from "../../helpers/util";
import ClearButton from "../../components/ClearButton";
import { isEmpty } from "lodash";

const LobbyList = (props) => {
  const [lobbies, setLobbies] = useState([]);
  const [singlebtn, setSinglebtn] = useState(false);
  const [singlebtnGame, setSinglebtnGame] = useState(false);
  const [lobbyFilterOpen, setLobbyFilterOpen] = useState(false);
  const [gameTypeFilterOpen, setGameTypeFilterOpen] = useState(false);
  const [selectedDropdown, setselectedDropdown] = useState(null);
  const [selectedDropdownByGameName, setselectedDropdownByGameName] =
    useState(null);
  const [selectedDropdownByLobbyFilter, setselectedDropdownByLobbyFilter] =
    useState(null);
  const [
    selectedDropdownByGameTypeFilter,
    setselectedDropdownByGameTypeFilter,
  ] = useState(null);
  const [isLoading, setisLoading] = useState(false);
  const [totalCount, settotalCount] = useState(null);

  const pageSize = 10;
  const [pageNumber, setpageNumber] = useState(1);
  const [sortBy, setsortBy] = useState(null);
  const [searchByGame, setSearchByGame] = useState(null);
  const [searchByLobby, setSearchByLobby] = useState(null);
  const [searchByGameType, setSearchByGameType] = useState(null);

  const [games, setGames] = useState([]);

  const gameFilterAllOption = {
    name: "All",
  };
  const lobbyFiltersOptions = [
    {
      value: "",
      text: "All",
    },
    {
      value: "upcoming",
      text: "Upcoming Lobbies",
    },
    {
      value: "current",
      text: "Current Lobbies",
    },
    {
      value: "ended",
      text: "Ended Lobbies",
    },
  ];
  const lobbyGameTypeFiltersOptions = [
    {
      value: "",
      text: "All",
    },
    {
      value: 1,
      text: "PPK",
    },
    {
      value: 2,
      text: "Kill Race",
    },
    {
      value: 3,
      text: "Traditional",
    },
  ];
  const dropdownOptions = [
    {
      value: "",
      text: "All",
    },
    {
      value: "start_date,start_time",
      text: "Lobby Start Date : Asc",
    },
    {
      value: "-start_date,-start_time",
      text: "Lobby Start Date : Desc",
    },
  ];
  const [changePermission, setChange] = useState(false);
  const [addPermission, setAdd] = useState(false);
  const [uploadPermission, setUpload] = useState(false);
  const [codMobile, setPermissionForCODMobile] = useState({
    Change: false,
    Delete: false,
    Add: false,
    View: false,
    upload: false,
  });
  const [fortnite, setPermissionForFortnite] = useState({
    Change: false,
    Delete: false,
    Add: false,
    View: false,
    upload: false,
  });
  const [pubgmobile, setPermissionForpubgmobile] = useState({
    Change: false,
    Delete: false,
    Add: false,
    View: false,
    upload: false,
  });
  const [pubgpc, setPermissionForpubgpc] = useState({
    Change: false,
    Delete: false,
    Add: false,
    View: false,
    upload: false,
  });
  const [valorant, setPermissionForValorant] = useState({
    Change: false,
    Delete: false,
    Add: false,
    View: false,
    upload: false,
  });
  const [codwarzone, setPermissionForcodwarzone] = useState({
    Change: false,
    Delete: false,
    Add: false,
    View: false,
    upload: false,
  });
  useEffect(() => {
    getListing();
    if (isEmpty(props.permission)) {
      setChange(true);
      setAdd(true);
    } else {
      callSetPermission();
    }
  }, [sortBy, searchByGame, pageNumber, searchByLobby, searchByGameType]);
  const callSetPermission = () => {
    const type = "Apex";
    const typeChange = "change_apex";
    const typeAdd = "add_apex";
    const typeUpload = "can_upload_publish_apex";

    const filteredPermission = FilterPermission(props.permission, type);
    if (filteredPermission.length !== 0) {
      const setchange = filterOutPermissionToShowHide(
        filteredPermission[0].permissions,
        typeChange
      );
      const setadd = filterOutPermissionToShowHide(
        filteredPermission[0].permissions,
        typeAdd
      );

      const setupload = filterOutPermissionToShowHide(
        filteredPermission[0].permissions,
        typeUpload
      );
      setChange(setchange);
      setAdd(setadd);
      setUpload(setupload);
    }
    const typecodmobile = "COD Mobile";
    const typeChangecodmobile = "change_codmobile";
    const typeDeletecodmobile = "delete_codmobile";
    const typeAddcodmobile = "add_codmobile";
    const typeViewcodmobile = "view_codmobile";
    const typeUploadcodmobile = "can_upload_publish_cod_mobile";
    const filteredPermissioncodmobile = FilterPermission(
      props.permission,
      typecodmobile
    );
    if (filteredPermissioncodmobile.length !== 0) {
      const setchange = filterOutPermissionToShowHide(
        filteredPermissioncodmobile[0].permissions,
        typeChangecodmobile
      );
      const setdelete = filterOutPermissionToShowHide(
        filteredPermissioncodmobile[0].permissions,
        typeDeletecodmobile
      );
      const setadd = filterOutPermissionToShowHide(
        filteredPermissioncodmobile[0].permissions,
        typeAddcodmobile
      );

      const setview = filterOutPermissionToShowHide(
        filteredPermissioncodmobile[0].permissions,
        typeViewcodmobile
      );

      const setupload = filterOutPermissionToShowHide(
        filteredPermissioncodmobile[0].permissions,
        typeUploadcodmobile
      );
      setPermissionForCODMobile({
        Change: setchange,
        Delete: setdelete,
        Add: setadd,
        View: setview,
        upload: setupload,
      });
    }

    const typeFortnite = "Fortnite";
    const typeChangeFortnite = "change_fortnite";
    const typeDeleteFortnite = "delete_fortnite";
    const typeAddFortnite = "add_fortnite";
    const typeViewFortnite = "view_fortnite";
    const typeUploadFortnite = "can_upload_publish_fortnite";

    const filteredPermissionFortnite = FilterPermission(
      props.permission,
      typeFortnite
    );
    if (filteredPermissionFortnite.length !== 0) {
      const setchange = filterOutPermissionToShowHide(
        filteredPermissionFortnite[0].permissions,
        typeChangeFortnite
      );
      const setdelete = filterOutPermissionToShowHide(
        filteredPermissionFortnite[0].permissions,
        typeDeleteFortnite
      );
      const setadd = filterOutPermissionToShowHide(
        filteredPermissionFortnite[0].permissions,
        typeAddFortnite
      );

      const setview = filterOutPermissionToShowHide(
        filteredPermissionFortnite[0].permissions,
        typeViewFortnite
      );
      const setupload = filterOutPermissionToShowHide(
        filteredPermissionFortnite[0].permissions,
        typeUploadFortnite
      );
      setPermissionForFortnite({
        Change: setchange,
        Delete: setdelete,
        Add: setadd,
        View: setview,
        upload: setupload,
      });
    }
    const typepubgmobile = "PUBG Mobile";
    const typeChangepubgmobile = "change_pubgmobile";
    const typeDeletepubgmobile = "delete_pubgmobile";
    const typeAddpubgmobile = "add_pubgmobile";
    const typeViewpubgmobile = "view_pubgmobile";
    const typeUploadpubgmobile = "can_upload_publish_pubg_mobile";

    const filteredPermissionpubgmobile = FilterPermission(
      props.permission,
      typepubgmobile
    );
    if (filteredPermissionpubgmobile.length !== 0) {
      const setchange = filterOutPermissionToShowHide(
        filteredPermissionpubgmobile[0].permissions,
        typeChangepubgmobile
      );
      const setdelete = filterOutPermissionToShowHide(
        filteredPermissionpubgmobile[0].permissions,
        typeDeletepubgmobile
      );
      const setadd = filterOutPermissionToShowHide(
        filteredPermissionpubgmobile[0].permissions,
        typeAddpubgmobile
      );

      const setview = filterOutPermissionToShowHide(
        filteredPermissionpubgmobile[0].permissions,
        typeViewpubgmobile
      );
      const setupload = filterOutPermissionToShowHide(
        filteredPermissionpubgmobile[0].permissions,
        typeUploadpubgmobile
      );
      setPermissionForpubgmobile({
        Change: setchange,
        Delete: setdelete,
        Add: setadd,
        View: setview,
        upload: setupload,
      });
    }
    const typepubgpc = "PUBG PC";
    const typeChangepubgpc = "change_pubgpc";
    const typeDeletepubgpc = "delete_pubgpc";
    const typeAddpubgpc = "add_pubgpc";
    const typeViewpubgpc = "view_pubgpc";
    const typeUploadpubgpc = "can_upload_publish_pubg_pc";

    const filteredPermissionpubgpc = FilterPermission(
      props.permission,
      typepubgpc
    );
    if (filteredPermissionpubgpc.length !== 0) {
      const setchange = filterOutPermissionToShowHide(
        filteredPermissionpubgpc[0].permissions,
        typeChangepubgpc
      );
      const setdelete = filterOutPermissionToShowHide(
        filteredPermissionpubgpc[0].permissions,
        typeDeletepubgpc
      );
      const setadd = filterOutPermissionToShowHide(
        filteredPermissionpubgpc[0].permissions,
        typeAddpubgpc
      );

      const setview = filterOutPermissionToShowHide(
        filteredPermissionpubgpc[0].permissions,
        typeViewpubgpc
      );
      const setupload = filterOutPermissionToShowHide(
        filteredPermissionpubgpc[0].permissions,
        typeUploadpubgpc
      );
      setPermissionForpubgpc({
        Change: setchange,
        Delete: setdelete,
        Add: setadd,
        View: setview,
        upload: setupload,
      });
    }
    const typeValorant = "Valorant";
    const typeChangeValorant = "change_valorant";
    const typeDeleteValorant = "delete_valorant";
    const typeAddValorant = "add_valorant";
    const typeViewValorant = "view_valorant";
    const typeUploadValorant = "can_upload_publish_valorant";

    const filteredPermissionValorant = FilterPermission(
      props.permission,
      typeValorant
    );
    if (filteredPermissionValorant.length !== 0) {
      const setchange = filterOutPermissionToShowHide(
        filteredPermissionValorant[0].permissions,
        typeChangeValorant
      );
      const setdelete = filterOutPermissionToShowHide(
        filteredPermissionValorant[0].permissions,
        typeDeleteValorant
      );
      const setadd = filterOutPermissionToShowHide(
        filteredPermissionValorant[0].permissions,
        typeAddValorant
      );

      const setview = filterOutPermissionToShowHide(
        filteredPermissionValorant[0].permissions,
        typeViewValorant
      );
      const setupload = filterOutPermissionToShowHide(
        filteredPermissionValorant[0].permissions,
        typeUploadValorant
      );
      setPermissionForValorant({
        Change: setchange,
        Delete: setdelete,
        Add: setadd,
        View: setview,
        upload: setupload,
      });
    }
    const typecodwarzone = "Cod Warzone";
    const typeChangecodwarzone = "change_codwarzone";
    const typeDeletecodwarzone = "delete_codwarzone";
    const typeAddcodwarzone = "add_codwarzone";
    const typeViewcodwarzone = "view_codwarzone";
    const typeUploadcodwarzone = "can_upload_publish_cod_warzone";

    const filteredPermissioncodwarzone = FilterPermission(
      props.permission,
      typecodwarzone
    );
    if (filteredPermissioncodwarzone.length !== 0) {
      const setchange = filterOutPermissionToShowHide(
        filteredPermissioncodwarzone[0].permissions,
        typeChangecodwarzone
      );
      const setdelete = filterOutPermissionToShowHide(
        filteredPermissioncodwarzone[0].permissions,
        typeDeletecodwarzone
      );
      const setadd = filterOutPermissionToShowHide(
        filteredPermissioncodwarzone[0].permissions,
        typeAddcodwarzone
      );

      const setview = filterOutPermissionToShowHide(
        filteredPermissioncodwarzone[0].permissions,
        typeViewcodwarzone
      );
      const setupload = filterOutPermissionToShowHide(
        filteredPermissioncodwarzone[0].permissions,
        typeUploadcodwarzone
      );
      setPermissionForcodwarzone({
        Change: setchange,
        Delete: setdelete,
        Add: setadd,
        View: setview,
        upload: setupload,
      });
    }
  };
  const handleSearch = (event) => {
    getListing();
  };

  async function getListing() {
    try {
      setisLoading(true);
      const filters = filterModel();
      const { results, total } = await getLobbyList(filters);
      setLobbies(results);
      settotalCount(total);
      setisLoading(false);
      const res = await getGameList();
      setGames([gameFilterAllOption, ...res]);
    } catch (error) {
      setisLoading(false);
    }
  }

  const dropdownChange = (value) => {
    setselectedDropdown(value);
    setsortBy(value);
  };
  const dropdownChangeByGameName = (value) => {
    setselectedDropdownByGameName(value);
    setSearchByGame(value);
    setpageNumber(1);
  };

  const dropdownChangeByLobbyFilter = (value) => {
    setselectedDropdownByLobbyFilter(value);
    setSearchByLobby(value);
    setpageNumber(1);
  };
  const dropdownChangeByGameTypeFilter = (value) => {
    setselectedDropdownByGameTypeFilter(value);
    setSearchByGameType(value);
    setpageNumber(1);
  };
  function filterModel() {
    return {
      sortBy: sortBy,
      gameFilter: searchByGame,
      pageSize: pageSize,
      pageNumber: pageNumber,
      lobbyFilter: searchByLobby,
      gameType: searchByGameType,
    };
  }

  function getCurrentStatus(status) {
    switch (status) {
      case 1:
        return "Upcoming";
      case 2:
        return "Active";
      case 3:
        return "Ended";
      default:
        return "-";
    }
  }

  const handlePageClick = (pageNum) => {
    setpageNumber(pageNum);
  };
  const handleClear = () => {
    setpageNumber(1);
    setselectedDropdown(null);
    setsortBy(null);
    setSearchByGame(null);
    setSearchByLobby(null);
    setSearchByGameType(null);
    setselectedDropdownByGameName(null);
    setselectedDropdownByLobbyFilter(null);
    setselectedDropdownByGameTypeFilter(null);
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <Breadcrumbs breadcrumbItem="Manage Lobbies" />
        <Row>
          <Col className="col-12">
            <Card>
              <CardBody>
                <Row className="search-box">
                  <Col className="col-8 d-none">
                    <div>
                      <input
                        className="form-control search-bar"
                        type="search"
                        placeholder="Search Lobbies"
                        onChange={(event) => handleSearch(event)}
                      />
                    </div>
                  </Col>
                  <Col className="col-xl-2 col-lg-4 col-md-3 col-sm-3 col-xs-6">
                    <Dropdown
                      isOpen={singlebtn}
                      toggle={() => setSinglebtn(!singlebtn)}
                    >
                      <DropdownToggle
                        caret
                        color="primary"
                        className="dropdown-toggle-split sort-by-btn"
                      >
                        <span>
                          {selectedDropdown === null
                            ? "Sort By"
                            : getFilterText(dropdownOptions, selectedDropdown)}
                        </span>
                        <i className="mdi mdi-chevron-down" />
                      </DropdownToggle>
                      <DropdownMenu className="lobbies-menuListing">
                        {dropdownOptions.map((item, index) => {
                          return (
                            <DropdownItem
                              key={index}
                              onClick={() => dropdownChange(item.value)}
                              dropdownvalue={item.value}
                            >
                              {item.text}
                              &nbsp; &nbsp;
                              {selectedDropdown === item.value ? (
                                <i className="mdi mdi-check-circle-outline"></i>
                              ) : null}
                            </DropdownItem>
                          );
                        })}
                      </DropdownMenu>
                    </Dropdown>
                  </Col>
                  <Col className="col-xl-3 col-lg-4 col-md-4 col-sm-3 col-xs-6">
                    <Dropdown
                      isOpen={gameTypeFilterOpen}
                      toggle={() => setGameTypeFilterOpen(!gameTypeFilterOpen)}
                    >
                      <DropdownToggle
                        caret
                        color="primary"
                        className="dropdown-toggle-split sort-by-btn"
                      >
                        <span>
                          {selectedDropdownByGameTypeFilter === null
                            ? "Game Type"
                            : getFilterText(
                                lobbyGameTypeFiltersOptions,
                                selectedDropdownByGameTypeFilter
                              )}
                        </span>
                        <i className="mdi mdi-chevron-down" />
                      </DropdownToggle>
                      <DropdownMenu>
                        {lobbyGameTypeFiltersOptions.map((item, index) => {
                          return (
                            <DropdownItem
                              key={index}
                              onClick={() =>
                                dropdownChangeByGameTypeFilter(item.value)
                              }
                              dropdownvalue={item.value}
                            >
                              {item.text}
                              &nbsp; &nbsp;
                              {selectedDropdownByGameTypeFilter ===
                              item.value ? (
                                <i className="mdi mdi-check-circle-outline"></i>
                              ) : null}
                            </DropdownItem>
                          );
                        })}
                      </DropdownMenu>
                    </Dropdown>
                  </Col>
                  <Col className="col-xl-3 col-lg-4 col-md-4 col-sm-3 col-xs-6">
                    <Dropdown
                      isOpen={singlebtnGame}
                      toggle={() => setSinglebtnGame(!singlebtnGame)}
                    >
                      <DropdownToggle
                        caret
                        color="primary"
                        className="dropdown-toggle-split sort-by-btn"
                      >
                        <span>
                          {selectedDropdownByGameName === null
                            ? "Game"
                            : getFilterGame(games, selectedDropdownByGameName)}
                        </span>
                        <i className="mdi mdi-chevron-down" />
                      </DropdownToggle>
                      <DropdownMenu>
                        {games.map((item, index) => {
                          return (
                            <DropdownItem
                              key={index}
                              onClick={() => dropdownChangeByGameName(item.id)}
                              dropdownvalue={item.id}
                              disabled={item.game_status === 2 ? true : false}
                            >
                              {item.name}
                              &nbsp; &nbsp;
                              {selectedDropdownByGameName === item.id ? (
                                <i className="mdi mdi-check-circle-outline"></i>
                              ) : null}
                            </DropdownItem>
                          );
                        })}
                      </DropdownMenu>
                    </Dropdown>
                  </Col>
                  <Col className="col-xl-2 col-lg-3 col-md-3 col-sm-3 col-xs-6">
                    <Dropdown
                      isOpen={lobbyFilterOpen}
                      toggle={() => setLobbyFilterOpen(!lobbyFilterOpen)}
                    >
                      <DropdownToggle
                        caret
                        color="primary"
                        className="dropdown-toggle-split sort-by-btn"
                      >
                        <span>
                          {selectedDropdownByLobbyFilter === null
                            ? "Lobby Status"
                            : getFilterText(
                                lobbyFiltersOptions,
                                selectedDropdownByLobbyFilter
                              )}
                        </span>

                        <i className="mdi mdi-chevron-down" />
                      </DropdownToggle>
                      <DropdownMenu>
                        {lobbyFiltersOptions.map((item, index) => {
                          return (
                            <DropdownItem
                              key={index}
                              onClick={() =>
                                dropdownChangeByLobbyFilter(item.value)
                              }
                              dropdownvalue={item.value}
                            >
                              {item.text}
                              &nbsp; &nbsp;
                              {selectedDropdownByLobbyFilter === item.value ? (
                                <i className="mdi mdi-check-circle-outline"></i>
                              ) : null}
                            </DropdownItem>
                          );
                        })}
                      </DropdownMenu>
                    </Dropdown>
                  </Col>
                  <Col className="col-xl-2 col-lg-3 col-md-3 col-sm-3 col-xs-6 mb-4">
                    <ClearButton handleClear={handleClear} />
                  </Col>
                  {addPermission ||
                  codMobile.Add ||
                  fortnite.Add ||
                  pubgmobile.Add ||
                  pubgpc.Add ||
                  valorant.Add ||
                  codwarzone.Add ? (
                    <Col className="col-xl-2 col-lg-3 col-md-3 col-sm-3 col-xs-6">
                      <Link to="/lobby/add">
                        <button className="btn btn-primary add-lobby-btn">
                          {" "}
                          Add Lobby{" "}
                        </button>
                      </Link>
                    </Col>
                  ) : null}
                </Row>

                <div className="table-rep-plugin">
                  <div
                    className="table-responsive mb-0"
                    data-pattern="priority-columns"
                  >
                    <Table
                      id="tech-companies-1"
                      className="table table-striped table-bordered lobbies-table"
                    >
                      <Thead>
                        <Tr>
                          <Th>Game Name</Th>
                          <Th data-priority="1">Lobby Type</Th>
                          <Th data-priority="1">Game Type</Th>
                          <Th data-priority="3">Lobby Mode</Th>
                          <Th data-priority="3">Result </Th>
                          <Th data-priority="3">Number Of Enrolled Players</Th>
                          <Th data-priority="3">Minimum Players</Th>
                          <Th data-priority="3">Maximum Players</Th>
                          <Th data-priority="6" className="no-wrap">
                            Start Date
                          </Th>
                          <Th data-priority="6" className="no-wrap">
                            Start Time
                          </Th>
                          <Th data-priority="6" className="no-wrap">
                            Game End Time
                          </Th>
                          <Th data-priority="6">Entry Fee</Th>

                          <Th data-priority="6">Free Lobby Status</Th>
                          <Th data-priority="1">Lobby Current Status</Th>
                          <Th data-priority="6">Lobby Status</Th>
                          {changePermission ||
                          uploadPermission ||
                          codMobile.Change ||
                          codMobile.upload ||
                          fortnite.Change ||
                          codMobile.upload ||
                          pubgmobile.Change ||
                          pubgmobile.upload ||
                          pubgpc.Change ||
                          pubgpc.upload ||
                          valorant.Change ||
                          valorant.upload ||
                          codwarzone.Change ||
                          codwarzone.upload ? (
                            <Th data-priority="6" colSpan="2">
                              Action
                            </Th>
                          ) : null}
                          {/* <Th data-priority="6" colSpan="2">
                          Results
                        </Th> */}
                        </Tr>
                      </Thead>
                      <Tbody>
                        {isLoading ? (
                          <div
                            class="spinner-grow transaction-spinner"
                            role="status"
                          >
                            <span class="sr-only">Loading...</span>
                          </div>
                        ) : lobbies.length === 0 ? (
                          <tr>
                            <td colspan="15" className="text-center mt-4">
                              No Lobbie(s) Found
                            </td>
                          </tr>
                        ) : (
                          lobbies.map((item, index) => (
                            <Tr key={index}>
                              <Td>{item?.game?.name}</Td>
                              <Th>
                                <Link
                                  to={{
                                    pathname: `/lobby/${item?.id}`,
                                    state: { item },
                                  }}
                                >
                                  {item?.name}
                                </Link>
                                <br />
                                <small>$ {item?.reward}</small>
                              </Th>
                              <Td>{GameTypeMap[item?.game_type]?.label}</Td>
                              <Td>{item?.modetag}</Td>
                              <Td>
                                {LobbyResultMap[item?.stats_status]?.label}
                              </Td>
                              <Td>{item?.enrolled_players}</Td>
                              <Td>{item?.min_players}</Td>
                              <Td>{item?.max_players}</Td>
                              <Td className="no-wrap">
                                {dateFormat(item?.start_date)}
                              </Td>
                              <Td className="no-wrap">{item?.start_time}</Td>
                              <Td className="no-wrap">
                                {item?.end_time === null
                                  ? "NA"
                                  : item?.end_time}
                              </Td>
                              <Td>
                                <p className="mb-1">{"$" + item.entry_fee}</p>{" "}
                                {item?.free_lobby === "Yes" ? (
                                  <p className="mb-0 freeEntry">Free Entry</p>
                                ) : null}
                              </Td>
                              <Td>{item?.free_lobby}</Td>
                              <Td>{getCurrentStatus(item?.current_status)}</Td>
                              <Td>
                                {item?.status === 1
                                  ? "Activated"
                                  : "Deactivated"}
                              </Td>
                              {changePermission ||
                              uploadPermission ||
                              codMobile.Change ||
                              codMobile.upload ||
                              fortnite.Change ||
                              codMobile.upload ||
                              pubgmobile.Change ||
                              pubgmobile.upload ||
                              pubgpc.Change ||
                              pubgpc.upload ||
                              valorant.Change ||
                              valorant.upload ||
                              codwarzone.Change ||
                              codwarzone.upload ? (
                                <Td>
                                  {item.game.slug === "pubg" &&
                                  item?.current_status === 3 ? (
                                    <button className="btn btn-sm  btn-secondary disabled disabled-link">
                                      Edit
                                    </button>
                                  ) : (item.game.slug === "pubg" ||
                                      item.game.slug === "valorant") &&
                                    (item?.current_status === 1 ||
                                      item?.current_status === 2) ? (
                                    <>
                                      <Link
                                        to={`/lobby/edit/${item?.id}`}
                                        className="btn btn-sm complaint-view-btn btn-secondary"
                                      >
                                        Edit {"  "}
                                      </Link>
                                    </>
                                  ) : item?.current_status !== 1 &&
                                    !canFetchResults(item) ? (
                                    <button className="btn btn-sm  btn-secondary disabled disabled-link">
                                      Edit
                                    </button>
                                  ) : (
                                    <>
                                      <Link
                                        to={`/lobby/edit/${item?.id}`}
                                        className="btn btn-sm complaint-view-btn btn-secondary"
                                      >
                                        Edit {"  "}
                                      </Link>
                                    </>
                                  )}
                                </Td>
                              ) : null}

                              {/* <Td className="lobbies-view-result-btn">
                              {item?.current_status === 3 ? (
                                <Link
                                  to={{
                                    pathname: `/lobby/view-result/${item?.id}`,
                                    state: { item },
                                  }}
                                >
                                  View Result
                                </Link>
                              ) : (
                                <p className="disabled-link">View Result</p>
                              )}
                            </Td> */}
                            </Tr>
                          ))
                        )}
                      </Tbody>
                    </Table>
                  </div>
                </div>
                <Paginator
                  totalCount={totalCount}
                  pageSize={pageSize}
                  pageClick={handlePageClick}
                  forcePage={pageNumber}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};

export default LobbyList;
