import React, { useEffect, useState } from "react";

import { Row, Col, Card, CardBody, Button } from "reactstrap";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Link } from "react-router-dom";
import {
  getGroupList,
  deleteGroup,
} from "../../services/admin_user_api_helper";

// toastr
import { toast } from "react-toastify";
import toastrOptions from "../../helpers/toastr-options/toastr-options";
import FilterPermission from "../../helpers/FilterPermission";
import { filterOutPermissionToShowHide } from "../../helpers/PermissionUtils";
import { isEmpty } from "lodash";

const GroupList = (props) => {
  const [groups, setGroups] = useState([]);
  const [isLoading, setisLoading] = useState(false);
  const [changePermission, setChange] = useState(false);
  const [deletePermission, setDelete] = useState(false);
  const [addPermission, setAdd] = useState(false);
  useEffect(() => {
    getListing();
    if (isEmpty(props.permission)) {
      setChange(true);
      setDelete(true);
      setAdd(true);
    } else {
      callSetPermission();
    }
  }, []);
  const callSetPermission = () => {
    const type = "Group";
    const typeChange = "change_group";
    const typeDelete = "delete_group";
    const typeAdd = "add_group";
    const filteredPermission = FilterPermission(props.permission, type);
    if (filteredPermission.length !== 0) {
      const setchange = filterOutPermissionToShowHide(
        filteredPermission[0].permissions,
        typeChange
      );
      const setdelete = filterOutPermissionToShowHide(
        filteredPermission[0].permissions,
        typeDelete
      );
      const setadd = filterOutPermissionToShowHide(
        filteredPermission[0].permissions,
        typeAdd
      );
      setChange(setchange);
      setDelete(setdelete);
      setAdd(setadd);
    }
  };
  async function getListing() {
    setisLoading(true);
    await getGroupList()
      //filters
      .then((res) => {
        setGroups(res.data);
        setisLoading(false);
      })
      .catch(() => {
        setisLoading(false);
      });
  }

  const handleDelete = async (delId) => {
    setisLoading(true);
    await deleteGroup(delId)
      .then(() => {
        getListing();
        toast.success("Deleted Successfully", toastrOptions);
        setisLoading(true);
      })
      .catch(() => {
        setisLoading(false);
      });
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <Breadcrumbs breadcrumbItem="Manage Groups" />
        <Row>
          <Col className="col-12">
            <Card>
              <CardBody>
                {addPermission ? (
                  <Row className="search-box">
                    <Col className="col-lg-2 col-sm-4">
                      <Link to="/groups/add">
                        <button className="btn btn-primary">
                          {" "}
                          Add Groups{" "}
                        </button>
                      </Link>
                    </Col>
                  </Row>
                ) : null}

                <div className="table-rep-plugin">
                  <div
                    className="table-responsive mb-0"
                    data-pattern="priority-columns"
                  >
                    <Table
                      id="tech-companies-1"
                      className="table table-striped table-bordered staff-table"
                    >
                      <Thead>
                        <Tr>
                          <Th>Level</Th>
                          <Th data-priority="1">Permission</Th>
                          {changePermission || deletePermission ? (
                            <Th data-priority="6">Actions</Th>
                          ) : null}
                        </Tr>
                      </Thead>
                      <Tbody>
                        {isLoading ? (
                          <div
                            class="spinner-grow transaction-spinner"
                            role="status"
                          >
                            <span class="sr-only">Loading...</span>
                          </div>
                        ) : groups.length === 0 ? (
                          <h5 className="text-center mt-4">
                            No Group(s) Found
                          </h5>
                        ) : (
                          groups.map((item, index) => (
                            <Tr key={index}>
                              <Td>{item?.name}</Td>
                              <Td>
                                {item.permissions.map((list, id) => {
                                  return (
                                    <ul key={id}>
                                      <li>
                                        {list.name}
                                        {list.permission_name === null ? (
                                          ""
                                        ) : (
                                          <>({list.permission_name})</>
                                        )}
                                      </li>
                                    </ul>
                                  );
                                })}
                              </Td>
                              {changePermission ? (
                                <Td>
                                  <Link to={`/groups/edit/${item?.id}`}>
                                    <Button className="btn btn-sm complaint-view-btn btn-secondary">
                                      Edit {"  "}
                                    </Button>
                                  </Link>
                                </Td>
                              ) : null}
                              {deletePermission ? (
                                <Td className="location-delete-btn">
                                  <button
                                    className="btn btn-sm complaint-view-btn btn-secondary"
                                    onClick={() => handleDelete(item?.id)}
                                  >
                                    Delete
                                  </button>
                                </Td>
                              ) : null}
                            </Tr>
                          ))
                        )}
                      </Tbody>
                    </Table>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};

export default GroupList;
