import { Redirect } from "react-router-dom";
import {
  challengePermissionsGames,
  checkIfGamePermissionIsAvailable,
  permissionGameConstantForLobbies,
  searchCodename,
} from "../constants/permissionConstant";

import ChangePassword from "../pages/Authentication/ChangePassword";
import Logout from "../pages/Authentication/Logout";
import PermissionPath from "./PermissionPath";
import LobbyList from "../pages/LobbyList/LobbyList";
import LobbyResult from "../pages/LobbyList/LobbyResult";
import LobbyDetail from "../pages/LobbyDetail/LobbyDetail";
import LobbyForm from "../pages/LobbyForm/LobbyForm";
import ChallengesList from "../pages/ChallengesModule/ChallengesList";
import ChallengesDetail from "../pages/ChallengesModule/ChallengesDetail";
import LeaderBoard from "../pages/LeaderBoard";
export const filterOutPermissionToShowHide = (permission, type) => {
  return permission.some((data) => data.codename === type && data.type_);
};
export const findPathsBetweenTwo = (userRoutes1) => {
  const allPath = PermissionPath();
  const obj = JSON.parse(localStorage.getItem("authUser"));
  const filterPath = [];
  const data =
    obj === null
      ? "/login"
      : obj.extras?.permissions?.map((dataPresent, idx) => {
          return dataPresent.permissions.map((type) => {
            return type;
          });
        });
  const gamePermissioRoutes = newData(obj);
  const finalRoutes =
    gamePermissioRoutes.length === 0
      ? userRoutes1[0]
      : [...userRoutes1[0], ...gamePermissioRoutes];
  if (data == "/login" || data == null) {
    return null;
  } else {
    //removed [0]th index from old code
    for (
      let userRoutesIndex = 0;
      userRoutesIndex < finalRoutes.length;
      userRoutesIndex++
    ) {
      for (
        let assignedPermissionRoutes = 0;
        assignedPermissionRoutes < data.length;
        assignedPermissionRoutes++
      ) {
        for (
          let filteringSpecificRoutes = 0;
          filteringSpecificRoutes < data[assignedPermissionRoutes].length;
          filteringSpecificRoutes++
        ) {
          if (
            finalRoutes[userRoutesIndex].codename ===
            data[assignedPermissionRoutes][filteringSpecificRoutes].codename
          ) {
            filterPath.push(finalRoutes[userRoutesIndex]);
          }
        }
      }
    }

    const gamePermissions = checkIfGamePermissionIsAvailable(obj);
    const challengePath = challengePermissionsGames(gamePermissions);

    let challengePaths = challengePath.length
      ? [
          {
            path: "/challenges",
            component: ChallengesList,
            commonName: "/challenges",
            codename: "view_challenges",
          },
          {
            path: "/challenges/:challengesId",
            component: ChallengesDetail,
            commonName: "/challenges",
            codename: "view_challenge",
          },
        ]
      : [];
    let b = [
      {
        path: "/change-password",
        component: ChangePassword,
        commonName: "/changePassword",
      },
      ...challengePaths,

      { path: "/logout", component: Logout },
      {
        path: "/leaderboard",
        component: LeaderBoard,
        commonName: "/leaderboard",
        codename: "view_leaderboard",
      },
      {
        path: "*",
        exact: true,
        component: () => <Redirect to={allPath[0].pathname} />,
      },
    ];

    const allowedPathForUser = [...filterPath, ...b];
    return allowedPathForUser;
  }
};
const newData = (obj) => {
  const gamePermissionConstant = [
    {
      path: "/lobby",
      component: LobbyList,
      commonName: "/lobby",
      codename: "view_apex",
    },
    {
      path: "/lobby/add",
      component: LobbyForm,
      commonName: "/lobby",
      codename: "add_apex",
    },
    {
      path: "/lobby/edit/:lobbyId",
      component: LobbyForm,
      commonName: "/lobby",
      codename: "change_apex",
    },
    {
      path: "/lobby/:lobbyId",
      component: LobbyDetail,
      commonName: "/lobby",
      codename: "view_apex",
    },
    {
      path: "/lobby/view-result/:lobbyId",
      component: LobbyResult,
      commonName: "/lobby",
      codename: "view_apex",
    },
    {
      path: "/lobby/:lobbyId",
      component: LobbyDetail,
      commonName: "/lobby",
      codename: "can_upload_publish_apex",
    },
    {
      path: "/lobby",
      component: LobbyList,
      commonName: "/lobby",
      codename: "can_upload_publish_apex",
    },

    {
      path: "/lobby/edit/:lobbyId",
      component: LobbyForm,
      commonName: "/lobby",
      codename: "can_upload_publish_apex",
    },
  ];
  const gamePermissionArray = [];
  const findGameArePresentOrNot =
    obj === null
      ? false
      : obj.extras?.permissions?.map((data, idx) => {
          return permissionGameConstantForLobbies.filter(
            (type) => type.permissionName === data.label
          );
        });
  findGameArePresentOrNot.forEach((data) =>
    gamePermissionConstant.forEach((destructure) => {
      if (data.length != 0) {
        let gameCodename = destructure.codename;
        let splitCodename = gameCodename.split("_");
        const forunevencodename =
          splitCodename.length === 4 ? searchCodename(data[0]?.shortName) : [];

        let concatNewGamecodename =
          splitCodename.length === 4
            ? `${splitCodename[0]}_${splitCodename[1]}_${splitCodename[2]}_${
                forunevencodename.length !== 0
                  ? forunevencodename[0].value
                  : data[0]?.shortName
              }`
            : `${splitCodename[0]}_${data[0]?.shortName}`;
        let finalResult = {
          path: destructure.path,
          component: destructure.component,
          commonName: destructure.commonName,
          codename: concatNewGamecodename,
        };
        gamePermissionArray.push(finalResult);
      }
    })
  );
  return gamePermissionArray;
};

export const filterDefaultViewPermissions = (upload, type, allPermission) => {
  let findDefaultViewPermissionObj = [];
  if (upload == "Upload" && !type) {
    allPermission.forEach((a) => {
      const permissionName = a.name.split(" ");
      if (permissionName[1] === "view") {
        findDefaultViewPermissionObj.push(a.id);
      }
    });
  }
  return findDefaultViewPermissionObj;
};

export const givenAndUploadpermission = (permissonObj, newPermission, type) => {
  return permissonObj.includes(newPermission)
    ? permissonObj.filter((n) => n !== newPermission)
    : type
    ? [...permissonObj, newPermission]
    : permissonObj;
};

export const defaultViewPermission = (
  type,
  defaultpermissonObj,
  defaultViewPermissionobj
) => {
  return !type
    ? [...defaultpermissonObj, defaultViewPermissionobj]
    : defaultpermissonObj;
};
export const assignDefaultViewPermission = (
  defaultpermissonObj,
  newPermission
) => {
  return defaultpermissonObj.includes(newPermission)
    ? defaultpermissonObj.filter((n) => n !== newPermission)
    : defaultpermissonObj;
};
export const deleteGivenPermission = (
  type,
  upload,
  permissonObj,
  newPermission,
  idOfgameUpload
) => {
  return type && upload === "Upload"
    ? permissonObj.includes(newPermission)
      ? permissonObj.filter((elem) => {
          if (elem === newPermission) {
            return idOfgameUpload.includes(elem);
          } else {
            return !idOfgameUpload.includes(elem);
          }
        })
      : permissonObj
    : permissonObj;
};
export const findViewPermissionId = (
  type,
  upload,
  permissonObj,
  newPermission
) => {
  return type && upload === "Upload"
    ? permissonObj.includes(newPermission)
      ? permissonObj.filter((n) => n !== newPermission)
      : permissonObj
    : permissonObj;
};
export const givenAndUploadpermissionRadio = (
  type,
  permissonObj,
  allPermissions,
  defaultPermissions
) => {
  return !type
    ? permissonObj.filter(
        (iGiven) => !allPermissions.permissions.find((f) => f.id === iGiven)
      )
    : [...permissonObj, ...defaultPermissions];
};
export const checkForAllowedPermission = (
  permissonObj,
  type,
  allPermissions
) => {
  return permissonObj.includes(allPermissions)
    ? permissonObj.filter((n) => n !== allPermissions)
    : type
    ? [...permissonObj, allPermissions]
    : permissonObj.filter((val) => val.label != allPermissions.label);
};
export const defaultCheckedPermissions = (
  list,
  permissionName,
  givenPermission
) => {
  return list.type_
    ? "checked"
    : permissionName == "view"
    ? "checked"
    : givenPermission.includes(list.id)
    ? "checked"
    : "";
};
