function FindAllowedPermission(trueValue) {
  const defaultAssignedPermissions = trueValue
    .filter(({ permissions }) => permissions.some(({ type_ }) => type_))
    .map((o) => ({
      ...o,
      permissions: o.permissions.filter(({ type_ }) => o.permissions),
    }));

  return defaultAssignedPermissions;
}

export default FindAllowedPermission;
