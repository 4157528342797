import React from "react";
import { Card, CardBody, Row } from "reactstrap";
import StandingsTable from "../Table/StandingsTable";
const StandingsTab = ({ standingsDetails }) => {
  return (
    <Card className="mb-0">
      <CardBody>
        <Row>
          {standingsDetails?.length === 0 ? (
            <Card className="mb-0">
              <CardBody>
                <Row>Standings not available yet</Row>
              </CardBody>
            </Card>
          ) : (
            <StandingsTable standingsDetails={standingsDetails} />
          )}
        </Row>
      </CardBody>
    </Card>
  );
};

export default StandingsTab;
