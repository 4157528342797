import React, { useState } from "react";
import { Row, Col } from "reactstrap";
import "react-quill/dist/quill.snow.css";
import Dropzone from "react-dropzone";
import { useEffect } from "react";
import { csvConverterToPreviewFile } from "./util";
import CsvView from "./CsvView";
const FileUploadS3 = (props) => {
  useEffect(() => {
    if (props.S3URL !== null) callUrl();
  }, []);
  const [previewOldcsvData, setPreviewOldCsvData] = useState([]);
  const callUrl = async () => {
    fetch(props.S3URL)
      .then((response) => response.text())
      .then((data) => {
        const csvpreview = csvConverterToPreviewFile(data);
        setPreviewOldCsvData(csvpreview);
      });
  };
  const fileFormat = [".csv", ".json"];
  const [invalidFile, setInvalidFile] = useState({
    invalidFileBoolean: false,
    invalidFileMsg: "",
  });
  const [csvData, setCsvData] = useState([]);
  const isValidType = (dataValid) => {
    const ext = fileFormat.map((dataFileFormat, i) => {
      return dataFileFormat;
    });
    return ext.some((el) => dataValid.endsWith(el));
  };
  const handleAcceptedFiles = (files) => {
    if (files.length === 0) {
      props.dataFromUploadedFile({ invalidFileBoolean: true });
      setInvalidFile({
        invalidFileBoolean: true,
        invalidFileMsg: `Please upload ${fileFormat.map((data) => {
          return data;
        })} file only`,
      });
    } else {
      for (let i = 0; i < files.length; i++) {
        let fname = files[i].name;
        if (!isValidType(fname)) {
          setInvalidFile({
            invalidFileBoolean: true,
            invalidFileMsg: `Please upload ${fileFormat.map((data) => {
              return data;
            })} file only`,
          });
          return false;
        } else {
          setPreviewOldCsvData([]);
          const file = files[i];
          const reader = new FileReader();
          reader.onload = (e) => {
            const text = e.target.result;
            const csvpreview = csvConverterToPreviewFile(text);
            setCsvData(csvpreview);
            const fileToUpload = {
              fileData: files[i],
              fileName: files[i].name,
            };
            props.dataFromUploadedFile(fileToUpload);
          };
          reader.readAsText(file);
          files.map((fileN) =>
            Object.assign(fileN, {
              preview: URL.createObjectURL(fileN),
            })
          );
          setInvalidFile({
            invalidFileBoolean: false,
            invalidFileMsg: "",
          });
        }
      }
    }
  };

  return (
    <Row>
      <Col className="col-12">
        <div className="mb-3 chooseImage">
          <Dropzone
            onDrop={(acceptedFiles) => {
              handleAcceptedFiles(acceptedFiles);
            }}
            accept={[
              ".csv",
              "text/csv",
              "application/vnd.ms-excel",
              "application/csv",
              "text/x-csv",
              "application/x-csv",
              "text/comma-separated-values",
              "text/x-comma-separated-values",
              ".json",
            ]}
          >
            {({ getRootProps, getInputProps }) => (
              <div
                className={`dropzone dz-clickable ${
                  (props.isError && csvData.length === 0) ||
                  invalidFile.invalidFileBoolean
                    ? "error-border"
                    : ""
                }`}
              >
                <div className="dz-message needsclick" {...getRootProps()}>
                  <input {...getInputProps()} />
                  <div className="mb-3">
                    <i className="display-4 text-muted mdi mdi-upload-network-outline"></i>
                  </div>

                  <h4>
                    {invalidFile.invalidFileBoolean ? (
                      <p className="error-msg">{invalidFile.invalidFileMsg}</p>
                    ) : (
                      "Upload file"
                    )}
                    <p>
                      Accepted file
                      {fileFormat.map((data, i) => (
                        <span> {data}</span>
                      ))}
                    </p>
                  </h4>
                </div>
              </div>
            )}
          </Dropzone>
          <Col className="col-12 mt-3 ml-3">
            {csvData?.length === 0 ? null : <CsvView csvContents={csvData} />}
            {previewOldcsvData?.length === 0 ? null : (
              <>
                <h6>Preview file</h6>
                <CsvView csvContents={previewOldcsvData} />
              </>
            )}
          </Col>
        </div>
      </Col>
    </Row>
  );
};

export default FileUploadS3;
