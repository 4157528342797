import { AvField, AvForm } from "availity-reactstrap-validation";
import React, { useEffect, useRef, useState } from "react";
import ReactQuill from "react-quill";
import { Button, CardTitle, FormGroup } from "reactstrap";
import { getTopics } from "../../services/cms_api_helper";

import { formats } from "./EditorToolbar";
import TopicsDropDown from "./TopicsDropDown";
import backIcon from "../../assets/images/arrow-left.svg";
const modules = {
  toolbar: [
    [{ header: "1" }, { header: "2" }, { font: [] }],
    [{ size: [] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
    ["clean"],
  ],

  clipboard: {
    // toggle to add extra line breaks when pasting HTML:
    matchVisual: false,
  },
};
const AddQuestion = ({
  enableListView,
  isEdit,
  editQuestionData = {},
  topicId,
  postQuestion = () => {},
  editQuestion = () => {},
  isFaq = false,
  data,
}) => {
  const [editorValue, setEditorValue] = useState("");
  const formRef = useRef(null);
  const isQuillEmpty = (quill) => {
    if ((quill?.getContents()?.["ops"] || [])?.length !== 1) {
      return false;
    }
    return quill.getText()?.trim()?.length === 0;
  };
  const UpdateEditorValue = (content, delta, source, editor) => {
    let updatedTermsNdCondition = editor.getHTML();
    setEditorValue(updatedTermsNdCondition + "");
    setEditorError(isQuillEmpty(editor));
  };

  const quillRef = useRef(null);
  const [options, setOptions] = useState([]);
  const [topic, setTopic] = useState(null);
  const formSubmitRef = useRef(false);
  const [topicError, setTopicError] = useState(false);
  const [editorError, setEditorError] = useState(false);

  const getFAQTopics = async () => {
    try {
      const res = await getTopics({ topicType: "FAQ" });
      setOptions(
        res?.results.map((category) => ({
          ...category,
          label: category.name,
          value: category.id,
        }))
      );
    } catch (error) {}
  };

  const resetForm = () => {
    formRef?.current?.reset();
    setEditorValue("");
    setEditorValue("");
    setTopic(null);
    formSubmitRef.current = false;
    setTopicError(false);
    setEditorError(false);
    enableListView();
  };

  useEffect(() => {
    if (options?.length < 1 && isFaq) {
      getFAQTopics();
    }
  }, [isEdit, options]);

  useEffect(() => {
    if (isEdit) {
      setEditorValue(editQuestionData?.answer);
      if (isFaq) {
        const optionFound = options?.filter(
          (topicData) => topicData.id == editQuestionData?.topic
        );
        if (optionFound?.length) {
          setTopic({
            ...optionFound,
            label: optionFound?.[0]?.name,
            value: optionFound?.[0]?.id,
          });
        }
      }
    } else {
      setEditorValue("");
      formRef?.current?.reset();
    }
  }, [editQuestionData, isEdit, options, isFaq]);

  useEffect(() => {
    if (formSubmitRef.current) {
      setTopicError(!topic?.value);
    }
  }, [topic]);

  return (
    <>
      <CardTitle>
        <div style={{ cursor: "pointer" }} onClick={enableListView}>
          <img alt="back-icon" src={backIcon} />
          <span
            style={{
              display: "inline-block",
              marginLeft: "4px",
              color: "black",
            }}
          >
            back
          </span>
        </div>
      </CardTitle>
      <div className="faq-box d-flex flex-column align-items-start mb-2">
        <span>{isEdit ? "Edit What's PPK" : "Add What's PPK"}</span>
        <div>
          <AvForm
            ref={formRef}
            onValidSubmit={(e, v) => {
              formSubmitRef.current = true;
              if ((isFaq && !topic?.value) || !editorValue || editorError) {
                if (isFaq && !topic?.value) {
                  setTopicError(true);
                }
                if (editorError) {
                  setEditorError(true);
                }
                return;
              }
              const { question } = v;
              const topicText =
                isFaq && topic && topic?.value == topic?.label
                  ? { topic_text: topic?.label }
                  : {};
              const topic_type = isFaq ? { topic_type: "FAQ" } : {};
              const topicIdPPK = isEdit ? undefined : topicId;
              const topicIdFAQ = topic?.value == topic?.label ? 0 : topic?.id;

              const payload = {
                topic_id: isFaq ? topicIdFAQ : topicIdPPK,
                answer: editorValue,
                ...topicText,
                question,
                ...topic_type,
              };
              if (isEdit) {
                editQuestion({ payload, questionId: editQuestionData.id });
              } else {
                postQuestion({ filter: payload, callback: resetForm });
              }
            }}
            onInvalidSubmit={() => {
              console.log({ quillRef });

              formSubmitRef.current = true;
              if ((isFaq && !topic?.value) || !editorValue || editorError) {
                if (isFaq && !topic?.value) {
                  setTopicError(true);
                }
                if (!editorValue) {
                  setEditorError(true);
                }
                return;
              }
            }}
          >
            {isFaq ? (
              <div className={`mb-4`}>
                <span
                  className={`mb-2 ${
                    topicError && formSubmitRef.current
                      ? "text-danger form-group"
                      : ""
                  }`}
                >
                  Topic
                </span>
                <TopicsDropDown
                  topic={topic}
                  setTopic={setTopic}
                  options={options}
                  data={data}
                />
                {topicError && formSubmitRef.current ? (
                  <span className="invalid-feedback d-inline-block">
                    Please Select some Topic
                  </span>
                ) : (
                  <span className="invalid-feedback d-inline-block opacity-0">
                    Please Select some Topic
                  </span>
                )}
              </div>
            ) : null}

            <div className="mb-3">
              <AvField
                key={editQuestionData.id}
                name="question"
                label="Question"
                placeholder="Enter question here"
                type="text"
                value={editQuestionData?.question}
                validate={{
                  required: {
                    value: true,
                    errorMessage: "Question is required",
                  },
                  maxLength: {
                    value: 500,
                    errorMessage: "Question can have 500 characters max",
                  },
                }}
                disabled={!data.change}
              />
            </div>
            <div>
              <span
                className={`mb-2 ${
                  editorError && formSubmitRef.current
                    ? "text-danger form-group"
                    : ""
                }`}
              >
                Answer
              </span>
              <ReactQuill
                ref={quillRef}
                theme="snow"
                modules={modules}
                formats={formats}
                onChange={UpdateEditorValue}
                value={editorValue}
                placeholder="Write something"
                style={{
                  height: "150px",
                }}
                readon
                readOnly={!data.change}
              />
              {editorError && formSubmitRef.current ? (
                <span className="invalid-feedback d-inline-block quill-error">
                  Please enter answer
                </span>
              ) : (
                <span className="invalid-feedback d-inline-block opacity-0 quill-error">
                  Please enter answer
                </span>
              )}
            </div>
            {data.change ? (
              <FormGroup style={{ marginTop: "20px" }}>
                <div>
                  <Button type="submit" color="primary" className="ms-1 mr-2">
                    Submit
                  </Button>
                  <Button
                    onClick={resetForm}
                    type="reset"
                    color="secondary"
                    outline
                    className="ms-1"
                  >
                    Cancel
                  </Button>
                </div>
              </FormGroup>
            ) : null}
          </AvForm>
        </div>
      </div>
    </>
  );
};

export default AddQuestion;
