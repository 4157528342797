import React, { useEffect, useState } from "react";

import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Button,
  CustomInput,
} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

import { Link } from "react-router-dom";

import { toast } from "react-toastify";
import toastrOptions from "../../helpers/toastr-options/toastr-options";
import { updateGameData, getGameBySlug } from "../../services/game_api_helper";
import { uploadFile } from "react-s3";
import ReactQuill from "react-quill"; //Editor
import "react-quill/dist/quill.snow.css";
import EditorToolbar, { formats } from "../CMS/EditorToolbar";
const config = {
  bucketName: process.env.REACT_APP_S3_BUCKET_NAME,
  region: process.env.REACT_APP_S3_REGION_NAME,
  accessKeyId: process.env.REACT_APP_S3_ACCESS_KEY,
  secretAccessKey: process.env.REACT_APP_S3_SECRET_KEY,
};

const EditGame = (props) => {
  useEffect(() => {
    getGame();
  }, []);
  const [loader, setLoader] = useState(false);
  const [game, setGame] = useState(null);
  const getGame = async () => {
    setLoader(true);
    await getGameBySlug(props.match.params.gameSlug)
      .then((res) => {
        setImagePreview(res.background_image);
        setHelperImage(res.helper_image);
        setBackgroundImage(res.game_demo_background_image);
        setRules(res.game_rules);
        setGame(res);
        if (res.slug == "apex-legends") {
          setApexTDMPreview(res.traditional_image);
        }
        setLoader(false);
      })
      .catch(() => {
        setLoader(false);
      });
  };
  const [errorMsg, showError] = useState({
    isError: false,
    errorMsg: "",
  });
  const [errorMsgForHelper, showErrorForHelperImage] = useState({
    isErrorForHelper: false,
    errorMsgForHelper: "",
  });
  const [errorMsgForApexTDM, showErrorMsgForApexTDM] = useState({
    isError: false,
    errorMsg: "",
  });
  const [imagePreview, setImagePreview] = useState(null);
  const [helperImage, setHelperImage] = useState(null);
  const [apexTDMPreview, setApexTDMPreview] = useState(null);

  const [text, setText] = useState(true);
  const [textHelper, setTextHelper] = useState(true);
  const [textApexTDM, setTextApexTDM] = useState(true);

  const [ruleFieldError, setRuleFieldError] = useState("");
  const [imageFieldError, setImageFieldError] = useState("");
  const [helperImageFieldError, setHelperImageFieldError] = useState("");
  const [apexTDMImageFieldError, setApexTDMImageFieldError] = useState("");

  const [spinnerLoader, setSpinnerLoader] = useState(false);
  const [spinnerLoaderHelper, setSpinnerLoaderHelper] = useState(false);
  const [spinnerLoaderApexTDM, setSpinnerLoaderApexTDM] = useState(false);

  const [disabled, setDisabled] = useState(true);
  const onUpload = (
    event,
    setSpinnerLoaderFunc,
    uploadFunc,
    setImageFunc,
    setTextFunc,
    showErrorFunc,
    setDisabledFunc,
    isHelper = false
  ) => {
    setSpinnerLoaderFunc(true);
    event.preventDefault();
    const regex = new RegExp("([a-zA-Z0-9s_\\.-:])+(.jpg|.jpeg|.png)$");
    if (regex.test(event.target.value.toLowerCase())) {
      const reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = (e) => {
        const image = new Image();
        image.src = e.target.result;
        image.onload = () => {
          const { height, width } = image;
          if ((height === 1092 && width === 412) || isHelper) {
            uploadFunc(event.target.files[0], config).then((data) => {
              setImageFunc(data.location);
              setTextFunc(false);
              setDisabledFunc(false);
              showErrorFunc({
                isError: false,
                errorMsg: "",
              });
            });
            setTimeout(() => setSpinnerLoaderFunc(false), 5000);
          } else {
            showErrorFunc({
              isError: true,
              errorMsg: "Width and Height dimension must be 412x1092",
            });
            setSpinnerLoaderFunc(false);
          }
        };
      };
    } else {
      showErrorFunc({
        isError: true,
        errorMsg: "Please select a valid Image file.",
      });
      setSpinnerLoaderFunc(false);
      setDisabledFunc(true);
      return false;
    }
  };

  const [rules, setRules] = useState("");
  const setRulesData = (content, delta, source, editor) => {
    let rulesEdit = editor.getHTML();
    setRules(rulesEdit + "");
    setRuleFieldError("");
    setDisabled(false);
  };
  const goToListing = () => {
    props.history.push("/games");
  };
  async function editGameData(e) {
    e.preventDefault();
    if (apexTDMPreview?.length == 0 && game?.slug == "apex-legends") {
      setApexTDMImageFieldError("Please upload Apex TDM image");
    }
    if (
      rules?.length === 11 &&
      imagePreview?.length === 0 &&
      helperImage?.length === 0
    ) {
      setRuleFieldError("Please add some rules");
      setImageFieldError("Please upload image");
      setHelperImageFieldError("Please upload helper image");
    } else if (imagePreview?.length === 0) {
      setImageFieldError("Please upload image");
    } else if (rules?.length === 11) {
      setRuleFieldError("Please add some rules");
    } else if (helperImage?.length === 0) {
      setHelperImageFieldError("Please upload helper image");
    } else {
      const model = {
        background_image: imagePreview,
        game_rules: rules,
        helper_image: helperImage,
        game_demo_background_image: backgroundImage,
        traditional_image: apexTDMPreview,
        killrace_image: "",
      };
      await updateGameData(model, props.match.params.gameId)
        .then((res) => {
          toast.success("Game updated sucessfully", toastrOptions);
          goToListing();
          setShowRaw(false);
        })
        .catch((err) => {
          setShowRaw(false);
        });
    }
  }

  const [backgroundImage, setBackgroundImage] = useState(null);
  const [raw_html, setRawHTML] = useState("");
  const [show_raw, setShowRaw] = useState(false);
  const handleClickShowRaw = () => {
    setShowRaw(!show_raw);
    if (show_raw)
      setRawHTML(
        rules === "" ? props.location?.data?.rules || "" : rules || ""
      );
    syncViews(show_raw);
  };

  const syncViews = (fromRaw) => {
    if (fromRaw) {
      setRules(raw_html + "");
    } else {
      setRawHTML(
        rules === "" ? props.location?.data?.rules || "" : rules || ""
      );
    }
  };
  const handleChangeRaw = (html) => {
    setRawHTML(html + "");
    syncViews(show_raw);
    setDisabled(false);
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <Breadcrumbs breadcrumbItem={" Edit Game"} />
        {loader ? (
          <div class="spinner-grow transaction-spinner" role="status">
            <span class="sr-only">...Processing file</span>
          </div>
        ) : (
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <Row >
                    <Col>
                      <p>
                        <Link to="/games">
                          <i className="mdi mdi-arrow-left"></i> back
                        </Link>
                      </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="col-8">
                      <form onSubmit={editGameData}>
                        <FormGroup>
                          <div className="mb-3 chooseImage">
                            {" "}
                            <CustomInput
                              type="file"
                              id="exampleCustomFileBrowser"
                              name="customFile"
                              label={"Choose an image file"}
                              onChange={(event) =>
                                onUpload(
                                  event,
                                  setSpinnerLoader,
                                  uploadFile,
                                  setImagePreview,
                                  setText,
                                  showError,
                                  setDisabled
                                )
                              }
                            />
                            {errorMsg.isError ? null : (
                              <label>
                                Width and Height dimension must be 412x1092
                              </label>
                            )}
                            <label className="errorMsgGames">
                              {imageFieldError?.length === 0
                                ? errorMsg.errorMsg
                                : imageFieldError}
                            </label>
                          </div>
                        </FormGroup>
                        <div className="mb-3 preview-img">
                          <h2>{text ? "Image" : "Preview Image"}</h2>
                          <div class="upload-img">
                            {spinnerLoader ? (
                              <div
                                class="spinner-grow spinner-class"
                                role="status"
                                style={{ position: "inherit" }}
                              ></div>
                            ) : (
                              imagePreview && (
                                <img alt="Cropped" src={imagePreview} />
                              )
                            )}
                          </div>
                        </div>
                        <div className={show_raw ? "mb-3 showRaw" : "mb-3"}>
                          <label
                            className={
                              ruleFieldError.length === 0 ? "" : "errorMsgGames"
                            }
                          >
                            Rules
                          </label>
                          <EditorToolbar
                            toolbarId="toolbar-1"
                            onClickRaw={handleClickShowRaw}
                          />
                          <ReactQuill
                            theme="snow"
                            modules={{
                              toolbar: {
                                container: `#toolbar-1`,
                              },
                              clipboard: {
                                matchVisual: false,
                              },
                            }}
                            formats={formats}
                            onChange={setRulesData}
                            value={
                              rules === ""
                                ? props.location?.data?.rules || ""
                                : rules || ""
                            }
                            placeholder="Write something"
                          />
                          <textarea
                            className={"raw-editor"}
                            onChange={(e) => handleChangeRaw(e.target.value)}
                            value={raw_html}
                          />
                          <label
                            className="errorMsgGames invalid-feedback"
                          >
                            {ruleFieldError}
                          </label>
                        </div>
                        <FormGroup>
                          <div className="mb-3 chooseImage">
                            {" "}
                            <CustomInput
                              type="file"
                              id="exampleCustomFileBrowserHelper"
                              name="customFileHelper"
                              label={"Choose an Helper image file"}
                              onChange={(event) =>
                                onUpload(
                                  event,
                                  setSpinnerLoaderHelper,
                                  uploadFile,
                                  setHelperImage,
                                  setTextHelper,
                                  showErrorForHelperImage,
                                  setDisabled,
                                  true
                                )
                              }
                            />

                             {errorMsgForHelper.errorMsgForHelper.length ===0 ? null : (
                              <label 
                              className={
                                errorMsgForHelper.errorMsgForHelper.length === 0
                                  ? ""
                                  : "errorMsgGames invalid-feedback"
                              }>
                                {errorMsgForHelper.errorMsgForHelper}
                              </label>
                            )}
                              {helperImageFieldError?.length === 0 ? null : (
                            <label className={
                              helperImageFieldError?.length === 0
                                ? ""
                                : "errorMsgGames invalid-feedback"
                            }>
                              {helperImageFieldError?.length === 0
                                ? errorMsgForHelper.errorMsgForHelper
                                : helperImageFieldError}
                            </label>
                            )}
                          </div>
                        </FormGroup>
                        <div className="mb-3 preview-img">
                          <h2>
                            {textHelper
                              ? helperImage === null ||
                                helperImage === undefined
                                ? ""
                                : "Helper image"
                              : "Helper image preview"}
                          </h2>
                          <div class="upload-img">
                            {spinnerLoaderHelper ? (
                              <div
                                class="spinner-grow spinner-class"
                                role="status"
                                style={{ position: "inherit" }}
                              ></div>
                            ) : (
                              helperImage && (
                                <img alt="preview" src={helperImage} />
                              )
                            )}
                          </div>
                        </div>
                        {game?.slug == "apex-legends" ? (
                          <>
                            <FormGroup>
                              <div className="mb-3 chooseImage">
                                {" "}
                                <CustomInput
                                  type="file"
                                  id="exampleCustomFileBrowserApexTDM"
                                  name="customFileHelper"
                                  label={"Choose an Apex TDM image file"}
                                  onChange={(event) =>
                                    onUpload(
                                      event,
                                      setSpinnerLoaderApexTDM,
                                      uploadFile,
                                      setApexTDMPreview,
                                      setTextApexTDM,
                                      showErrorMsgForApexTDM,
                                      setDisabled
                                    )
                                  }
                                />
                                {errorMsgForApexTDM.isError ? null : (
                                  <label>
                                    Width and Height dimension must be 412x1092
                                  </label>
                                )}
                                <label className="errorMsgGames">
                                  {apexTDMImageFieldError?.length === 0
                                    ? errorMsgForApexTDM.errorMsg
                                    : apexTDMImageFieldError}
                                </label>
                              </div>
                            </FormGroup>
                            <div className="mb-3 preview-img">
                              <h2>
                                {textApexTDM
                                  ? apexTDMPreview === null ||
                                    apexTDMPreview === undefined
                                    ? ""
                                    : "Apex TDM Image"
                                  : "Apex TDM Image preview"}
                              </h2>
                              <div class="upload-img">
                                {spinnerLoaderApexTDM ? (
                                  <div
                                    class="spinner-grow spinner-class"
                                    role="status"
                                    style={{ position: "inherit" }}
                                  ></div>
                                ) : (
                                  apexTDMPreview && (
                                    <img alt="preview" src={apexTDMPreview} />
                                  )
                                )}
                              </div>
                            </div>
                          </>
                        ) : null}

                        <FormGroup style={{ marginTop: "7.5rem" }}>
                          <div>
                            <Button
                              type="submit"
                              color="primary"
                              className="ms-1"
                              disabled={disabled}
                            >
                              Submit
                            </Button>
                          </div>
                        </FormGroup>
                      </form>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        )}
      </div>
    </React.Fragment>
  );
};

export default EditGame;
