import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import { Card, CardBody, Col, FormGroup, Button, Modal } from "reactstrap";
import { Multiselect } from "multiselect-react-dropdown";
import { sendUpdateList } from "../../services/lobby_api_helper";
import { toast } from "react-toastify";
import toastrOptions from "../../helpers/toastr-options/toastr-options";
import { AvField, AvForm } from "availity-reactstrap-validation";
import RemovePlayerModule from "./RemovePlayerModule";
const EnrolledPlayersList = (props) => {
  const [loader, setLoader] = useState(false);
  const [playersObj, setPlayersObj] = useState({});
  const initialPlayersStateRef = useRef();
  const [playersOptions, setPlayersOption] = useState([]);
  const [manualSelect, setManualSelect] = useState([]);

  // refs for multidropdown to use for resetvalues
  const PlayersDropDownrefs = useRef();
  PlayersDropDownrefs.current = [];
  useEffect(() => {
    const tempPlayersObj = {};
    props?.playerList.forEach((element) => {
      // check if player already in ocr results
      const matchedPlayer = isMatched(element.id);
      // use value for ocr if matched name else use null for dropdown and zero for kills as default
      tempPlayersObj[element.id] = {
        id: element.id,
        name: matchedPlayer ? matchedPlayer?.account_name : null,
        kills: matchedPlayer ? matchedPlayer?.kills : 0,
        matched: matchedPlayer ? true : false,
        actual_name: matchedPlayer ? element?.gaming_account : null,
      };
    });
    //updating states
    setPlayersObj(tempPlayersObj);
    // store intial state of ocr result for reseting with cancel
    initialPlayersStateRef.current = tempPlayersObj;
  }, [props?.playerList]);
  //update dropdown player
  const checkMultiSelect = (e, id, index, gaming_account) => {
    const updatedPlayerObj = {
      id: id,
      name: e[0].account_name,
      kills: +e[0].kills,
      actual_name: gaming_account,
      matched: e[0].matched,
    };
    setManualSelect([...manualSelect, updatedPlayerObj]);

    const tempPlayersObj = { ...playersObj };
    tempPlayersObj[id] = updatedPlayerObj;
    setPlayersObj(tempPlayersObj);
  };

  const updateList = async () => {
    try {
      const manuallySelectedPlayersList = getPlayersList();

      if (manuallySelectedPlayersList.length !== 0) {
        const data = {
          users: manuallySelectedPlayersList,
          status: "update",
        };
        await sendUpdateList(data, props?.gameType?.id);
        toast.success("Players updated sucessfully", toastrOptions);
        props.callLobbyDetail();
      }
    } catch (error) {
      toast.error("Please try again later", toastrOptions);
    }
  };

  const publishList = async () => {
    const manuallySelectedPlayersList = getPlayersList();
    if (manuallySelectedPlayersList.length !== 0) {
      const data = {
        users: manuallySelectedPlayersList,
        status: "published",
      };
      setLoader(true);
      await sendUpdateList(data, props?.gameType?.id)
        .then((res) => {
          toast.success("Players updated sucessfully", toastrOptions);
          setLoader(false);
          props.callLobbyDetail();
        })
        .catch((err) => {
          toast.error("Please try again later", toastrOptions);
          setLoader(false);
        });
    } else {
      return;
    }
  };

  // adding to refs array
  const addToRefs = (el, id) => {
    if (el && !PlayersDropDownrefs.current.includes(el)) {
      PlayersDropDownrefs.current.push({ element: el, id });
    }
  };

  // reset to default ocr result
  const cancelList = () => {
    // reset dropDown to null
    PlayersDropDownrefs.current.map((multiselectRef) => {
      const isFound = manualSelect.findIndex((dropDown) => {
        return dropDown.id == multiselectRef.id;
      });
      if (isFound != -1) {
        multiselectRef?.element?.resetSelectedValues();
      }
    });
    // reset to intial state
    setPlayersObj({ ...initialPlayersStateRef.current });
    setManualSelect([]);
  };

  // filter already selected dropdown values
  const removeSelectedOptions = (playerObj = {}, playerList = []) => {
    return playerList
      ?.filter((player) => {
        let isFound = false;
        for (const [, value] of Object.entries(playerObj)) {
          if (value?.name == player.account_name) {
            isFound = true;
          }
        }
        return !isFound;
      })
      .map((player) => ({ ...player, name: player.account_name }));
  };
  const getPlayersList = () => {
    let manuallySelectedPlayersList = [];
    // playerid is required
    for (const [, player] of Object.entries(playersObj)) {
      // update payload new entry only if there is some name to prevent not selected dropdowns
      if (player.name) {
        manuallySelectedPlayersList.push({
          account_name: player.name,
          actual_account_name: player.actual_name,
          kills: player.kills,
          id: player.id,
        });
      }
    }
    return manuallySelectedPlayersList;
  };

  // options intially
  useEffect(() => {
    setPlayersOption(
      removeSelectedOptions(playersObj, props.gameType.pubg_results)
    );
    //  initialOptionsRef.current = props.gameType.pubg_results
  }, [props.gameType.pubg_results, playersObj]);

  const isMatched = (playerId = "") => {
    const isPlayerMatchedIndex = props?.gameType?.pubg_results?.findIndex(
      (player) => {
        return playerId === player?.id;
      }
    );
    return isPlayerMatchedIndex === -1
      ? false
      : props?.gameType?.pubg_results?.[isPlayerMatchedIndex];
  };
  const [removeId, setRemovalId] = useState("");
  const [openRemovalModal, setOpenRemovalModal] = useState(false);
  const handleRemove = (id) => {
    setRemovalId(id);
    setOpenRemovalModal(true);
  };
  const handleClose = () => {
    setOpenRemovalModal(false);
  };
  const handleRecallDetailApi = () => {
    props.callLobbyDetail();
  };
  return (
    <Col lg={12}>
      <Card>
        <CardBody className="valorantDetail">
          <AvForm
            onValidSubmit={(e, v) => {
              updateList();
            }}
          >
            <div className="table-responsive enrolled-players-table">
              <table className="table table-striped table-bordered lobbies-table responsiveTable">
                <thead>
                  <tr>
                    <td scope="col">Sr.No</td>
                    <td scope="col">Ryvals Username</td>
                    <td scope="col">Gamer ID</td>
                    {props.gameType.pubg_results !== null ? (
                      <td scope="col">Result Gamer ID</td>
                    ) : null}
                    {props.gameType.pubg_results !== null ? (
                      <td scope="col">Kills</td>
                    ) : null}
                    <td scope="col">Email</td>
                    <td scope="col">Discord ID</td>
                    <td scope="col">Phone no.</td>
                    {props.gameType.current_status == 1 ? (
                      <td scope="col">Action</td>
                    ) : null}
                  </tr>
                </thead>
                <tbody>
                  {props?.playerList?.length === 0 ? (
                    <tr>
                      <td colspan="5">
                        <h5 className="text-center my-5">
                          No enrolled players found
                        </h5>
                      </td>
                    </tr>
                  ) : (
                    props.playerList &&
                    props.playerList.map((item, index) => {
                      return (
                        <tr
                          key={index}
                          className={
                            props.gameType.pubg_results === null
                              ? ""
                              : !isMatched(item?.id) &&
                                !(
                                  props?.gameType?.stats_status === 4 ||
                                  props?.gameType?.stats_status === 5
                                )
                              ? "highlightRow"
                              : ""
                          }
                        >
                          <td>{index + 1}</td>
                          <td>{item.username || "N/A"}</td>
                          <td>{item.gaming_account || "N/A"}</td>

                          {props.gameType.pubg_results === null ? (
                            ""
                          ) : props.gameType.game?.has_upload &&
                            props.gameType.current_status != 1 ? (
                            <>
                              <td>
                                {props?.gameType?.stats_status === 4 ||
                                props?.gameType?.stats_status === 5 ? (
                                  isMatched(item?.id).account_name || "N/A"
                                ) : (
                                  <Multiselect
                                    name={"name" + item?.id}
                                    placeholder="Select Player"
                                    options={playersOptions}
                                    displayValue="name"
                                    onSelect={(e) =>
                                      checkMultiSelect(
                                        e,
                                        item?.id,
                                        index,
                                        item?.gaming_account
                                      )
                                    }
                                    selectedValues={
                                      isMatched(item?.id)
                                        ? [
                                            {
                                              ...isMatched(item?.id),
                                              name: isMatched(item?.id)
                                                .account_name,
                                            },
                                          ]
                                        : null
                                    }
                                    singleSelect="true"
                                    disable={
                                      props?.gameType?.stats_status === 4
                                        ? true
                                        : false
                                    }
                                    ref={(ref) => addToRefs(ref, item?.id)}
                                  />
                                )}
                              </td>
                            </>
                          ) : null}
                          {props.gameType.pubg_results === null ? (
                            ""
                          ) : props.gameType.game?.has_upload &&
                            props.gameType.current_status != 1 ? (
                            <td>
                              {props?.gameType?.stats_status === 4 ||
                              props?.gameType?.stats_status === 5 ? (
                                playersObj[item?.id]?.kills
                              ) : (
                                <AvField
                                  key={item?.id}
                                  name={"name" + item?.id}
                                  type="text"
                                  value={`${
                                    playersObj[item?.id]?.kills
                                  }`?.trim()}
                                  validate={{
                                    number: {
                                      value: true,
                                      errorMessage: "Please enter number only",
                                    },
                                    required: {
                                      value: true,
                                      errorMessage: "Kills are required",
                                    },
                                    min: {
                                      value: 0,
                                      errorMessage:
                                        "Kills can't be less than 0",
                                    },
                                    max: {
                                      value: 99,
                                      errorMessage:
                                        "Kills can't be more than 99",
                                    },
                                    pattern: {
                                      value: /^(0|[1-9]\d*)$/,
                                      errorMessage:
                                        "Kills can't be in decimals.",
                                    },
                                  }}
                                  onKeyPress={(event) => {
                                    if (!/[0-9]/.test(event.key)) {
                                      event.preventDefault();
                                    }
                                  }}
                                  onChange={(event) => {
                                    const tempObj = {
                                      ...playersObj[item?.id],
                                    };
                                    tempObj["kills"] =
                                      event.target.value?.trim();
                                    const tempPlayersObj = { ...playersObj };
                                    tempPlayersObj[item.id] = tempObj;
                                    setPlayersObj(tempPlayersObj);
                                  }}
                                />
                              )}
                            </td>
                          ) : null}
                          <td>{item.email || "N/A"}</td>
                          <td>{item.discord_id || "N/A"}</td>
                          <td>{item.phone || "N/A"}</td>
                          {props.gameType.current_status == 1 ? (
                            <td>
                              <Button
                                type="button"
                                color="primary"
                                className="ms-1"
                                disabled={
                                  props.gameType?.is_last_entry_time_ended ||
                                  props?.hideRemoveButton
                                }
                                onClick={() => handleRemove(item?.id)}
                              >
                                Remove
                              </Button>
                            </td>
                          ) : null}
                        </tr>
                      );
                    })
                  )}
                </tbody>
              </table>
            </div>
            {props.gameType.pubg_results === null ? null : (
              <Col className="col-12 mb-3">
                <FormGroup className="mt-4">
                  <div>
                    {props.gameType.game?.has_upload &&
                    props.gameType.current_status != 1 ? (
                      <>
                        <Button
                          type="submit"
                          color="primary"
                          className="ms-1"
                          disabled={
                            getPlayersList().length == 0 ||
                            props?.gameType?.stats_status === 4 ||
                            props?.gameType?.stats_status === 5
                              ? true
                              : false
                          }
                        >
                          Update
                        </Button>
                        <Button
                          type="button"
                          color="primary"
                          className="ms-1"
                          onClick={() => publishList()}
                          disabled={
                            getPlayersList().length == 0 ||
                            props?.gameType?.stats_status === 4 ||
                            props?.gameType?.stats_status === 5
                              ? true
                              : false
                          }
                        >
                          {loader ? (
                            <span className="spinner-border spinner-border-sm mr-4"></span>
                          ) : (
                            "Publish"
                          )}
                        </Button>{" "}
                        <Button
                          type="button"
                          color="primary"
                          className="ms-1"
                          onClick={() => cancelList()}
                          disabled={
                            manualSelect.length == 0 ||
                            props?.gameType?.stats_status === 4 ||
                            props?.gameType?.stats_status === 5
                              ? true
                              : false
                          }
                        >
                          Cancel
                        </Button>
                      </>
                    ) : null}
                  </div>
                </FormGroup>
              </Col>
            )}
          </AvForm>
          {openRemovalModal ? (
            <Modal
              isOpen={openRemovalModal}
              toggle={() => {
                handleClose();
              }}
              onHide={() => handleClose()}
            >
              <RemovePlayerModule
                OnClose={() => handleClose()}
                OnComplete={() => handleRecallDetailApi()}
                Mode={"solo"}
                PlayerId={removeId}
                LobbyId={props?.gameType?.id}
              />
            </Modal>
          ) : null}
        </CardBody>
      </Card>
    </Col>
  );
};
EnrolledPlayersList.propTypes = {
  callLobbyDetail: PropTypes.func,
};
export default EnrolledPlayersList;
