import React from "react";
import { Button, Modal } from "reactstrap";
import RemovePlayerModule from "../../LobbyList/RemovePlayerModule";
import { useState } from "react";
import { isEmpty } from "lodash";
import { inviteStatus } from "../../../helpers/util";
const TeamsTab = ({ details, changePermission, callTournamentDetail }) => {
  const comparator = (x) => (x.leader ? -1 : 1);
  const [removeId, setRemovalId] = useState("");
  const [openRemovalModal, setOpenRemovalModal] = useState(false);
  const handleRemove = (id) => {
    setRemovalId(id);
    setOpenRemovalModal(true);
  };
  const handleClose = () => {
    setOpenRemovalModal(false);
  };
  const handleRecallDetailApi = () => {
    callTournamentDetail();
  };
  return (
    <div className="table-responsive enrolled-players-table">
      <table className="table table-striped table-bordered lobbies-table responsiveTable">
        <thead>
          <tr>
            <td>Team Name</td>
            <td>Email</td>
            <td>Ryvals Username</td>
            <td>Team Creator\Member</td>
            <td>Invite Status</td>
            <td>Payment Status</td>
            {changePermission ? <td>Action</td> : null}
          </tr>
        </thead>
        <tbody>
          {isEmpty(details?.participants) ? (
            <tr>
              <td colspan="7">
                <h5 className="text-center my-5">No team enrolled yet</h5>
              </td>
            </tr>
          ) : (
            details?.participants.map((item, index) => {
              return item.players.sort(comparator).map((player, idx) => {
                return (
                  <tr key={index}>
                    {idx === 0 ? (
                      <td rowspan={item.players.length}>
                        {item?.name || "N/A"}
                      </td>
                    ) : null}
                    <td>{player?.email || "N/A"}</td>
                    <td>{player?.username || "N/A"}</td>
                    <td>{player?.leader ? "Creator" : "Member"}</td>
                    <td>{inviteStatus[player?.invite]}</td>
                    <td>{player?.has_paid ? "Completed" : "Pending"}</td>
                    {changePermission ? (
                      idx === 0 ? (
                        <td rowspan={item.players.length} className="teamName">
                          <Button
                            type="button"
                            color="primary"
                            className="ms-1"
                            onClick={() => handleRemove(player?.team)}
                            disabled={!details.is_team_removable}
                          >
                            Remove
                          </Button>
                        </td>
                      ) : null
                    ) : null}
                  </tr>
                );
              });
            })
          )}
        </tbody>
      </table>
      {openRemovalModal ? (
        <Modal
          isOpen={openRemovalModal}
          toggle={() => {
            handleClose();
          }}
          onHide={() => handleClose()}
        >
          <RemovePlayerModule
            OnClose={() => handleClose()}
            OnComplete={() => handleRecallDetailApi()}
            Mode={"tournament"}
            TeamId={removeId}
            TournamentId={details?.id}
          />
        </Modal>
      ) : null}
    </div>
  );
};

export default TeamsTab;
