import React, { useState } from "react";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import { removeSoloPlayer, removeTeam } from "../../services/lobby_api_helper";
import PropTypes from "prop-types";
import {
  AvForm,
  AvCheckboxGroup,
  AvCheckbox,
  AvGroup,
  AvInput,
  AvFeedback,
} from "availity-reactstrap-validation";
import { toast } from "react-toastify";
import toastrOptions from "../../helpers/toastr-options/toastr-options";
import { FormGroup, Button, ModalBody, ModalHeader } from "reactstrap";
import { removeTeamFromTournament } from "../../services/bracket_tournaments_api_helper";
const RemovePlayerModule = (props) => {
  const [showOtherBox, setShowOther] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const showOther = () => {
    setShowOther(!showOtherBox);
  };
  const handleClose = () => {
    props.OnClose();
  };
  const handleValidSubmit = async (event, values) => {
    setDisabled(true);
    const array =
      values.ManualReason.length != 0
        ? [...values.MultiReason, values.otherReason]
        : [...values.MultiReason];
    if (props.Mode === "tournament") {
      let data = {
        team_id: props.TeamId,
        reasons: array.toString(),
      };
      await removeTeamFromTournament(props.TournamentId, data)
        .then((res) => {
          props.OnClose();
          props.OnComplete();
          setDisabled(false);
          toast.success("Team removed successfully", toastrOptions);
        })
        .catch((err) => {
          setDisabled(false);
        });
    } else if (props.Mode === "solo") {
      let data = {
        player_id: props.PlayerId,
        lobby_id: props.LobbyId,
        reason: array.toString(),
      };
      await removeSoloPlayer(data)
        .then((res) => {
          props.OnClose();
          props.OnComplete();
          setDisabled(false);
          toast.success("Player removed successfully", toastrOptions);
        })
        .catch((err) => {
          setDisabled(false);
        });
    } else {
      let data = {
        team_id: props.TeamId,
        lobby_id: props.LobbyId,
        reason: array.toString(),
      };
      await removeTeam(data)
        .then((res) => {
          props.OnClose();
          props.OnComplete();
          toast.success("Team removed successfully", toastrOptions);
          setDisabled(false);
        })
        .catch((err) => {
          setDisabled(false);
        });
    }
  };
  return (
    <>
      <ModalHeader>
        <h5>Remove reason</h5>
        <button
          className="crossButton previewCrossIcon"
          onClick={() => {
            handleClose();
          }}
        >
          X
        </button>
      </ModalHeader>
      <ModalBody className="py-3 px-5">
        <AvForm
          onValidSubmit={(e, v) => {
            handleValidSubmit(e, v);
          }}
        >
          <AvCheckboxGroup
            inline
            name="MultiReason"
            label="Reasons"
            validate={{
              required: {
                value: showOtherBox ? false : true,
                errorMessage: "Please check any reason",
              },
            }}
          >
            <div className="d-grid removePLayer">
              <AvCheckbox label="Suspicious User" value="Suspicious User" />
              <AvCheckbox label="Cheating" value="Cheating" />
              <AvCheckbox
                label="Fraudulent Activity"
                value="Fraudulent Activity"
              />
            </div>
          </AvCheckboxGroup>
          <AvCheckboxGroup inline name="ManualReason">
            <AvCheckbox
              label="Other (Max 50 characters)"
              value="Other"
              onClick={() => showOther()}
            />
          </AvCheckboxGroup>
          {showOtherBox ? (
            <AvGroup>
              <AvInput
                type="textarea"
                name="otherReason"
                id="otherReason"
                placeholder="Type your reasons"
                required
                validate={{
                  maxLength: {
                    value: 50,
                    errorMessage: "Reason can have 50 characters max",
                  },
                }}
              />
              <AvFeedback>Please type your reason</AvFeedback>
            </AvGroup>
          ) : null}
          <FormGroup>
            <Button
              type="submit"
              color="primary"
              className="mt-4"
              disabled={disabled}
            >
              {disabled && (
                <span className="spinner-border spinner-border-sm mr-4"></span>
              )}{" "}
              Submit
            </Button>
          </FormGroup>
        </AvForm>
      </ModalBody>
    </>
  );
};
RemovePlayerModule.propTypes = {
  OnClose: PropTypes.func,
  OnComplete: PropTypes.func,
  callLobbyDetail: PropTypes.func,
};
export default RemovePlayerModule;
