import React, { useState } from "react";
import AddWinningPercentage from "./AddWinningPercentage";
import { Button } from "reactstrap";
import edit from "../../../assets/images/edit.svg";
import info from "../../../assets/images/info-icon.svg";
import disableEditIcon from "../../../assets/images/disableEditIcon.svg";
function TournamentsRoundHeading({ data, callApi, isPPK }) {
  const [winnerIndex, setWinnerIndex] = useState(null);
  const handleSave = () => {
    callApi();
    setWinnerIndex(null);
  };
  const handleClose = () => {
    setWinnerIndex(null);
  };
  return (
    <>
      <div
        className={
          winnerIndex === data.id
            ? "tournament-round-accordion-heading d-flex justify-content-between align-items-center w-85"
            : "tournament-round-accordion-heading d-flex justify-content-between align-items-center w-70"
        }
      >
        <h5 className="faq-heading text-capitalize">
          {data.round}{" "}
          {data.tour_bracket_style === 2 && data.round_type != null
            ? `[${data.round_type}]`
            : null}
        </h5>
        <span className="faq-heading text-capitalize font-18">
          Total Matches <br />
          <span>{data.total_matches}</span>
        </span>
        {isPPK ? (
          <span className="faq-heading text-capitalize font-18">
            {winnerIndex === null ? (
              <>
                {" "}
                Winning %{" "}
                <span className="info-icon stats-infoIcon">
                  <img
                    src={info}
                    alt="info"
                    className="info-image"
                    data-tip
                    data-for="loginTip"
                  />
                  <span class="tooltiptext winning-tooltip">
                    Winning percentage based upon enrollment amount.
                  </span>
                </span>
                <br />
                <span>{data.round_percent}</span>
                <Button
                  type="button"
                  color={"secondary"}
                  className="edit-icon info-icon"
                  onClick={(e) => {
                    if (!data.is_field_editable) {
                      e.preventDefault();
                    } else {
                      setWinnerIndex(data.id);
                    }
                  }}
                  disabled={!data.is_field_editable}
                >
                  <img
                    alt="edit-Admin-icon"
                    src={data.is_field_editable ? edit : disableEditIcon}
                    data-tip
                    data-for="loginTip"
                  />
                  <span class="tooltiptext edit-tooltip">Edit</span>
                </Button>
              </>
            ) : null}
            {winnerIndex === null ? null : winnerIndex === data.id ? (
              <AddWinningPercentage
                roundDetails={data}
                SaveWinningPercentageOfRound={handleSave}
                CloseWinningPercentageOfRound={handleClose}
              />
            ) : null}
          </span>
        ) : null}
      </div>
    </>
  );
}

export default TournamentsRoundHeading;
