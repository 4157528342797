import React from "react";
function StandingsTable({ standingsDetails }) {
  return (
    <div className="table-rep-plugin tournamentRound">
      <div className="table-responsive mb-0" data-pattern="priority-columns">
        <div className="table-responsrowspanive seize-wallet-table">
          <table className="table table-striped table-bordered manage-complaints-table lobbies-table add-schedule tounamnet-standing-tab">
            <thead>
              <tr>
                <th>Rank</th>
                <th>Team Name</th>
                <th>Number of Kills</th>
                <th>Score</th>
              </tr>
            </thead>
            <tbody>
              {standingsDetails &&
                standingsDetails.map((standings, idx) => {
                  return (
                    <tr key={idx}>
                      <td>{standings.position}</td>
                      <td>{standings.team_name}</td>
                      <td>{standings.kills}</td>
                      <td>{standings.points}</td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default StandingsTable;
