import React, { useState } from "react";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import { updateEarning } from "../services/user_api_helper";
import PropTypes from "prop-types";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { FormGroup, Button } from "reactstrap";
import { toast } from "react-toastify";
import toastrOptions from "../helpers/toastr-options/toastr-options";

import tickIcon from "../assets/images/user-tick-icon.svg";
import crossIcon from "../assets/images/user-cross-icon.svg";
import IconsToolTip from "../components/IconsToolTip";

const EarningTimeline = (props) => {
  const [value, setValue] = useState(props.days);
  const handleChange = (val) => {
    setValue(val);
  };
  const handleSubmit = async (event, values) => {
    let data = {
      days: values.earningTimeline,
    };
    if (value)
      await updateEarning(props.userId, data)
        .then((res) => {
          props.onClose();
          props.callApi();
          toast.success("Earning timeline successfully updated", toastrOptions);
        })
        .catch((err) => {
          console.log(err);
        });
  };
  return (
    <>
      <AvForm
        className="d-flex editEarning-timeline"
        onValidSubmit={(e, v) => {
          handleSubmit(e, v);
        }}
      >
        <AvField
          name="earningTimeline"
          label="Earning Timeline"
          placeholder="Earning Timeline"
          type="text"
          validate={{
            required: {
              value: true,
              errorMessage: "Earning Timeline is required",
            },
            number: {
              value: true,
              errorMessage: "Please enter number only",
            },
            max: {
              value: 365,
              errorMessage: "Earning Timeline  can't be greater than 365",
            },
            pattern: {
              value: /^[1-9][0-9]*$/,
              errorMessage: "Earning Timeline  can't be in decimals",
            },
          }}
          onChange={(e) => handleChange(e.target.value)}
          defaultValue={value}
        />
        <div className="daysBox editDaysBox">
          <span class="input-group-addon">Days</span>
        </div>
        <FormGroup>
          <div className="mt-4 d-flex">
            <Button
              type="submit"
              color="primary"
              className="edit-icon info-icon"
            >
              <IconsToolTip
                image={tickIcon}
                altImageText="user-tick-icon"
                text="Save"
              />
            </Button>
            <Button
              className="edit-icon mt-2 ms-0 px-0 info-icon"
              onClick={() => {
                props.onClose();
              }}
            >
              <IconsToolTip
                image={crossIcon}
                altImageText="user-cross-icon"
                text="Cancel"
              />
            </Button>
          </div>
        </FormGroup>
      </AvForm>
    </>
  );
};
EarningTimeline.propTypes = {
  onClose: PropTypes.func,
  callApi: PropTypes.func,
};
export default EarningTimeline;
