import React, { useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import InfiniteScrollDropDown from "../../components/InfiniteScrollDropDown";
import {
  getLeaderboardStandings,
  getTournamentsForLeaderBoard,
} from "../../services/bracket_tournaments_api_helper";
import Paginator from "../../helpers/paginator/paginator";
import leaderboard from "../../../src/assets/images/leaderboard.svg";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import {
  CardBody,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import { getFilterText, tournamentStatusOption } from "../../helpers/util";

function LeaderBoard() {
  const [selectedTournament, setSelectedTournament] = useState(null);
  const [leaderboardData, setLeaderboardData] = useState([]);
  const [showLoader, setShowLoader] = useState(false);
  const updateTournamentId = (id) => setSelectedTournament(id);
  const [pageNumber, setPageNumber] = useState(1);
  const [statusFilter, setStatusFilter] = useState(false);
  const [selectedDropdownByStatusFilter, setselectedDropdownByStatusFilter] =
    useState(null);
  const pageSize = 10;
  const [totalCount, setTotalCount] = useState(0);
  const handlePageClick = (newPageNum) => setPageNumber(newPageNum);
  const getLeaderBoardData = async () => {
    try {
      setShowLoader(true);
      const { results, total_records } = await getLeaderboardStandings({
        page_size: pageSize,
        page: pageNumber,
        tournament_id: selectedTournament,
      });
      setShowLoader(false);

      setTotalCount(total_records);
      setLeaderboardData(results);
    } catch (error) {
      setShowLoader(false);
    }
  };

  const dropdownChangeByStatusFilter = (value) => {
    setselectedDropdownByStatusFilter((prev) => (prev == value ? null : value));
    setPageNumber(1);
  };

  useEffect(() => {
    if (selectedTournament) {
      getLeaderBoardData();
    }
  }, [selectedTournament, pageNumber]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Breadcrumbs breadcrumbItem="Leaderboard" />
        <Row className="mb-4">
          <Col className="leaderboard-select">
            <div className="d-flex align-items-center mb-4">
              <img src={leaderboard} class="demoPage" />
              <h4 className="mt-3 ms-2">Leadership Board</h4>
            </div>
            <div className="d-flex">
              <Dropdown
                className="me-3"
                isOpen={statusFilter}
                toggle={() => setStatusFilter(!statusFilter)}
              >
                <DropdownToggle
                  caret
                  color="primary"
                  className="dropdown-toggle-split sort-by-btn"
                >
                  <span>
                    {selectedDropdownByStatusFilter === null
                      ? "Tournament Status"
                      : getFilterText(
                          tournamentStatusOption,
                          selectedDropdownByStatusFilter
                        )}
                  </span>
                  <i className="mdi mdi-chevron-down" />
                </DropdownToggle>
                <DropdownMenu>
                  {tournamentStatusOption.map((item, index) => {
                    return (
                      <DropdownItem
                        key={index}
                        onClick={() => dropdownChangeByStatusFilter(item.value)}
                        dropdownvalue={item.value}
                      >
                        {item.text}
                        &nbsp; &nbsp;
                        {selectedDropdownByStatusFilter === item.value ? (
                          <i className="mdi mdi-check-circle-outline"></i>
                        ) : null}
                      </DropdownItem>
                    );
                  })}
                </DropdownMenu>
              </Dropdown>
              <div className="flex-1  w-300">
                <InfiniteScrollDropDown
                  getList={getTournamentsForLeaderBoard}
                  updateSelectedOption={updateTournamentId}
                  type={selectedDropdownByStatusFilter}
                />
              </div>
            </div>
          </Col>
          <Col></Col>
        </Row>
        <Row>
          <Col className="col-12">
            <Card>
              <CardBody>
                <div className="table-rep-plugin">
                  <div
                    className="table-responsive mb-0"
                    data-pattern="priority-columns"
                  >
                    <Table className="table table-striped table-bordered lobbies-table">
                      <Thead className="border-3">
                        <Tr>
                          <Th scope="col" rowspan="2">
                            Team Name
                          </Th>
                          <Th scope="col" rowspan="2">
                            Score
                          </Th>
                          <Th scope="col" rowspan="2">
                            Kills
                          </Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {showLoader ? (
                          <div
                            class="spinner-grow transaction-spinner"
                            role="status"
                          >
                            <span class="sr-only">Loading...</span>
                          </div>
                        ) : leaderboardData.length === 0 ? (
                          <Tr>
                            <Td colspan="10" className="text-center mt-4">
                              No Leaderboard data found
                            </Td>
                          </Tr>
                        ) : (
                          leaderboardData.map(
                            ({ id, points, kills, team_name }) => (
                              <Tr key={id}>
                                <Td>{team_name}</Td>
                                <Td>{points}</Td>
                                <Td>{kills}</Td>
                              </Tr>
                            )
                          )
                        )}
                      </Tbody>
                    </Table>
                  </div>
                </div>
              </CardBody>
            </Card>
            <Paginator
              totalCount={totalCount}
              pageSize={pageSize}
              pageClick={handlePageClick}
              forcePage={pageNumber}
            />
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
}

export default LeaderBoard;
