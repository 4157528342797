import { AvField, AvForm } from "availity-reactstrap-validation";
import { useEffect, useState } from "react";
import { Button, FormGroup, Label } from "reactstrap";
import { IMG } from "../../components/Common/StatsCodeToolTip";
import {
  GAME_MAP_CHALLENGES,
  compareDatesDDMMYYYY,
  getGameFromId,
} from "../../helpers/util";
import { getAdminUserListLobby } from "../../services/admin_user_api_helper";
import { editChallenge } from "../../services/challenges_api_helper";
import Select from "react-select";
import toastrOptions from "../../helpers/toastr-options/toastr-options";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

const ApexCodesChallenge = ({
  slug,
  openAlert,
  challenges,
  canPerform,
  setisLoading,
}) => {
  const [adminList, setAdminList] = useState([]);
  const games = useSelector((state) => state.Games.game?.allGames);
  const [admin_id, setAdminId] = useState("");
  useEffect(() => {
    getAdminsList();
    isEditBufferExceeded();
  }, [challenges?.id]);

  useEffect(() => {
    const defaultAdmin = adminList.find(
      (admin) => admin.value == challenges.admin?.id
    );
    if (defaultAdmin) {
      setAdminId(defaultAdmin);
    }
  }, [adminList, challenges]);

  const handleValidSubmit = (event, values) => {
    event.preventDefault();
    updateChallenge(challenges.id, values);
  };

  const updateChallenge = async (challengeId, model) => {
    try {
      await editChallenge(challengeId, {
        ...model,
        admin_id: admin_id.value,
      });
      toast.success("Challenge successfully updated", toastrOptions);
    } catch (error) {}
  };

  const getAdminsList = async () => {
    try {
      const res = await getAdminUserListLobby();
      if (res?.length) {
        let data = res.map((item) => ({
          label: item.email,
          value: item.id,
        }));
        setAdminId(data[0]);
        setAdminList(data);
      }
    } catch (error) {}
  };
  const handleAdminSelectGroup = (selected) => {
    setAdminId(selected);
  };

  const isEditBufferExceeded = () => {
    // challenge already ended so cant edit
    if (challenges.end_time) return true;
    const TODAY = new Date();
    const TODAY_EST = TODAY.toLocaleDateString("en-US", {
      timeZone: "America/New_York",
    });
    const LAST_ENTRY_TIME = challenges.last_entry_time.split(" EDT")[0];
    const CURR_TIME = new Date().toLocaleString([], {
      hour: "2-digit",
      minute: "2-digit",
      timeZone: "America/New_York",
    });
    const Formatted_EST_DATE = TODAY_EST.replaceAll("/", "-");
    const dateResult = compareDatesDDMMYYYY(
      Formatted_EST_DATE,
      challenges.match_date
    );
    if (dateResult < 0) return false;
    else if (dateResult == 0) {
      return CURR_TIME > LAST_ENTRY_TIME;
    } else {
      return true;
    }
  };

  return (
    <AvForm
      onValidSubmit={(e, v) => {
        handleValidSubmit(e, v);
      }}
    >
      <div className="mb-3">
        <Label>Select Admin*</Label>
        <Select
          key={adminList.length}
          value={admin_id}
          onChange={(event) => {
            handleAdminSelectGroup(event);
          }}
          options={adminList}
          name="admin_id"
          classNamePrefix="select2-selection"
          isDisabled={
            isEditBufferExceeded() ||
            !canPerform(
              GAME_MAP_CHALLENGES[getGameFromId(games, challenges.game)?.slug]
                ?.permissionType,
              GAME_MAP_CHALLENGES[getGameFromId(games, challenges.game)?.slug]
                ?.permissionChangeType
            )
          }
        />
      </div>
      <div className="mb-3">
        <AvField
          name="admin_code"
          label="Admin Code*"
          placeholder="Enter admin code here"
          type="text"
          value={challenges.admin_code}
          validate={{
            required: {
              value: true,
              errorMessage: "Admin code is required",
            },
          }}
          disabled={
            isEditBufferExceeded() ||
            !canPerform(
              GAME_MAP_CHALLENGES[getGameFromId(games, challenges.game)?.slug]
                ?.permissionType,
              GAME_MAP_CHALLENGES[getGameFromId(games, challenges.game)?.slug]
                ?.permissionChangeType
            )
          }
        />
      </div>
      <div className="mb-3">
        <AvField
          name="participant_code"
          label="Participant Code*"
          placeholder="Enter participant code here"
          type="text"
          value={challenges.participant_code}
          validate={{
            required: {
              value: true,
              errorMessage: "Participant code is required",
            },
          }}
          disabled={
            isEditBufferExceeded() ||
            !canPerform(
              GAME_MAP_CHALLENGES[getGameFromId(games, challenges.game)?.slug]
                ?.permissionType,
              GAME_MAP_CHALLENGES[getGameFromId(games, challenges.game)?.slug]
                ?.permissionChangeType
            )
          }
        />
      </div>
      <div className="mb-3">
        <AvField
          name="stats_code"
          label={<IMG data={slug} />}
          placeholder="Enter statistic code here"
          type="text"
          value={challenges.stats_code}
          validate={{
            required: {
              value: true,
              errorMessage: "Statistic code is required",
            },
          }}
          disabled={
            isEditBufferExceeded() ||
            !canPerform(
              GAME_MAP_CHALLENGES[getGameFromId(games, challenges.game)?.slug]
                ?.permissionType,
              GAME_MAP_CHALLENGES[getGameFromId(games, challenges.game)?.slug]
                ?.permissionChangeType
            )
          }
        />
      </div>
      <div className="d-flex">
        <FormGroup className="mt-4 me-3">
          <div>
            <Button
              disabled={
                isEditBufferExceeded() ||
                !canPerform(
                  GAME_MAP_CHALLENGES[
                    getGameFromId(games, challenges.game)?.slug
                  ]?.permissionType,
                  GAME_MAP_CHALLENGES[
                    getGameFromId(games, challenges.game)?.slug
                  ]?.permissionChangeType
                )
              }
              type="submit"
              color="primary"
              className="ms-1"
            >
              Update
            </Button>
          </div>
        </FormGroup>

        <FormGroup className="mt-4">
          <div>
            <Button
              type="button"
              className="btn btn-danger"
              // onClick={() => handleDelete(item.id)}
              onClick={() => {
                openAlert(challenges.id, challenges.versus);
              }}
              disabled={
                !canPerform(
                  GAME_MAP_CHALLENGES[
                    getGameFromId(games, challenges.game)?.slug
                  ]?.permissionType,
                  GAME_MAP_CHALLENGES[
                    getGameFromId(games, challenges.game)?.slug
                  ]?.permissionDeleteType
                ) || !challenges.challenge_delete_buffer
              }
            >
              Delete
            </Button>
          </div>
        </FormGroup>
      </div>
    </AvForm>
  );
};

export default ApexCodesChallenge;
