import React from "react";
import PropTypes from "prop-types";
import { Route, Redirect } from "react-router-dom";
import { userRoutes } from "../allRoutes";
import PermissionPath from "../../helpers/PermissionPath";
import { getFromLocalStorage } from "../../helpers/util";
const Authmiddleware = ({
  component: Component,
  layout: Layout,
  isAuthProtected,
  permission,
  path,
  ...rest
}) => {
  const user = getFromLocalStorage("authUser");
  return (
    <Route
      {...rest}
      render={(props) => {
        const redirectPath = PermissionPath();
        if (isAuthProtected && !user) {
          return (
            <Redirect
              to={{
                pathname: "/login",
                state: { from: props.location },
              }}
            />
          );
        }
        if (!isAuthProtected && user) {
          return (
            <Redirect
              to={{
                pathname: user ? redirectPath[1]?.pathname : path,
                state: {
                  from: user ? redirectPath[1]?.pathname : props.location,
                },
              }}
            />
          );
        }

        if (isAuthProtected) {
          if (!user) {
            return (
              <Redirect
                to={{
                  pathname: "/login",
                  state: { from: props.location },
                }}
              />
            );
          } else {
            return (
              <Layout>
                <Component
                  {...props}
                  permission={
                    permission !== null ? permission :
                    user?.extras?.permissions?.length ? user.extras.permissions :
                    null
                  }
                />
              </Layout>
            );
          }
        } else {
          if (user) {
            return (
              <Redirect
                to={{
                  pathname: userRoutes[0].path,
                }}
              />
            );
          } else {
            return (
              <Layout>
                <Component {...props} />
              </Layout>
            );
          }
        }
      }}
    />
  );
};

Authmiddleware.propTypes = {
  isAuthProtected: PropTypes.bool,
  component: PropTypes.any,
  location: PropTypes.object,
  permission: PropTypes.any,
  layout: PropTypes.any,
};

export default Authmiddleware;
