import { Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
export const GoBack = ({ backUrl }) => {
  return (
    <Row className="mb-4">
      <Col>
        <p>
          <Link to={backUrl}>
            <i className="mdi mdi-arrow-left"></i> back
          </Link>
        </p>
      </Col>
    </Row>
  );
};
