import React, { useEffect, useState } from "react";

import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Button,
} from "reactstrap";
import classnames from "classnames";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

//Editor
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

import Loader from "../../components/Common/Loader";

import {
  getTermsAndCondition,
  addTermsCMS,
  editTermsCMS,
  getPrivacyPolicy,
  addPrivacyCMS,
  editPrivacyCMS,
  addCaliforniaPrivacyCMS,
  editCaliforniaPrivacyCMS,
  getCaliforniaPrivacyPolicy,
} from "../../services/cms_api_helper";
import { toast } from "react-toastify";
import toastrOptions from "../../helpers/toastr-options/toastr-options";
import PPK from "./PPK";
import FAQ from "./FAQ";
import FilterPermission from "../../helpers/FilterPermission";
import { filterOutPermissionToShowHide } from "../../helpers/PermissionUtils";
import EditorToolbar, { formats } from "./EditorToolbar";
import JoditEditorForm from "./JoditEditorForm";
import {
  CONSTANT_STRINGS,
  permissionsStrings,
  SUCCESS_MESSAGES,
} from "../../helpers/StringConstant";
import { isEmpty } from "lodash";

const CMS = (props) => {
  const [permissionPPKFAQ, setPermissionPPKFAQ] = useState([]);
  const [changePermission, setChange] = useState(false);
  const [viewPermission, setView] = useState(false);
  const [changePermissionPPKFAQ, setChangePPKFAQ] = useState(false);
  const [viewPermissionPPKFAQ, setViewPPKFAQ] = useState(false);
  const [deletePermissionPPKFAQ, setDeletePPKFAQ] = useState(false);
  const [addPermissionPPKFAQ, setAddPPKFAQ] = useState(false);
  useEffect(() => {
    if (isEmpty(props.permission)) {
      setChange(true);
      setView(true);
    } else {
      callSetPermission();
    }
  }, []);

  const callSetPermission = () => {
    const type = permissionsStrings.staticPage;
    const typePPKFAQ = permissionsStrings.PPKFAQ;
    const typeChange = permissionsStrings.typeStaticPageChange;
    const typeView = permissionsStrings.typeStaticPageView;
    const typePPKFAQChange = permissionsStrings.typePPKFAQChange;
    const typePPKFAQView = permissionsStrings.typePPKFAQView;
    const typePPKFAQAdd = permissionsStrings.typePPKFAQAdd;
    const typePPKFAQDelete = permissionsStrings.typePPKFAQDelete;
    const filteredPermission = FilterPermission(props.permission, type);
    const filteredPermissionPPKFAQ = FilterPermission(
      props.permission,
      typePPKFAQ
    );
    if (filteredPermission.length !== 0) {
      const setchange = filterOutPermissionToShowHide(
        filteredPermission[0].permissions,
        typeChange
      );
      const setview = filterOutPermissionToShowHide(
        filteredPermission[0].permissions,
        typeView
      );
      setChange(setchange);
      setView(setview);
      callTermsAndCondition();
    }
    if (filteredPermissionPPKFAQ.length !== 0) {
      const setchangePPKFAQ = filterOutPermissionToShowHide(
        filteredPermissionPPKFAQ[0]?.permissions,
        typePPKFAQChange
      );
      const setviewPPKFAQ = filterOutPermissionToShowHide(
        filteredPermissionPPKFAQ[0]?.permissions,
        typePPKFAQView
      );
      const setdeletePPKFAQ = filterOutPermissionToShowHide(
        filteredPermissionPPKFAQ[0]?.permissions,
        typePPKFAQDelete
      );
      const setaddPPKFAQ = filterOutPermissionToShowHide(
        filteredPermissionPPKFAQ[0]?.permissions,
        typePPKFAQAdd
      );

      setChangePPKFAQ(setchangePPKFAQ);
      setViewPPKFAQ(setviewPPKFAQ);
      setDeletePPKFAQ(setdeletePPKFAQ);
      setAddPPKFAQ(setaddPPKFAQ);
      setPermissionPPKFAQ(filteredPermissionPPKFAQ[0].permissions);
    }
    setactiveTab(filteredPermissionPPKFAQ.length !== 0 ? "1" : "3");
  };
  async function callTermsAndCondition() {
    showLoader(true);
    await getTermsAndCondition()
      .then((res) => {
        setTerms(res.content);
        showLoader(false);
      })

      .catch(() => showLoader(false));
  }
  async function callPrivacyPolicy() {
    setactiveTab("4");
    await getPrivacyPolicy()
      .then((res) => {
        setPolicy(res.content);
      })
      .catch();
  }

  function showLoader(load) {
    setisLoading(load);
  }
  const [isLoading, setisLoading] = useState(null);
  const [activeTab, setactiveTab] = useState("1");
  const [termsNdCondition, setTermsNdCondition] = useState("");
  const [terms, setTerms] = useState("");
  const [policy, setPolicy] = useState("");
  const setTermsData = (content, delta, source, editor) => {
    let updatedTermsNdCondition = editor.getHTML();
    setTermsNdCondition(updatedTermsNdCondition + "");
  };

  async function addTermsNdCondition(e) {
    e.preventDefault();
    let model = {
      title: CONSTANT_STRINGS.tANDc,
      content: termsNdCondition,
    };
    if (terms === "") {
      await addTermsCMS(model)
        .then((res) => {
          toast.success(SUCCESS_MESSAGES.tAndcAddSuccess, toastrOptions);
          setTerms(res);
          setShowRaw(false);
        })
        .catch();
    } else {
      await editTermsCMS(model)
        .then((res) => {
          toast.success(SUCCESS_MESSAGES.tAndcUpdatedSuccess, toastrOptions);
          setTerms(res);
          setShowRaw(false);
        })
        .catch();
    }
  }

  const [privacyPolicy, setPrivacyPolicy] = useState("");

  const setPrivacyPolicyData = (content, delta, source, editor) => {
    let updatedPrivacyPolicy = editor.getHTML();
    setPrivacyPolicy(updatedPrivacyPolicy + "");
  };

  async function addPrivacyPolicyData(e) {
    e.preventDefault();
    let model = {
      title: CONSTANT_STRINGS.PP,
      content: privacyPolicy,
    };
    if (policy === "") {
      await addPrivacyCMS(model)
        .then((res) => {
          toast.success(SUCCESS_MESSAGES.ppAddSuccess, toastrOptions);
          setPolicy(res);
          setShowRaw(false);
        })
        .catch();
    } else {
      await editPrivacyCMS(model)
        .then((res) => {
          toast.success(SUCCESS_MESSAGES.ppUpdatedSuccess, toastrOptions);
          setPolicy(res);
          setShowRaw(false);
        })
        .catch();
    }
  }

  async function callCaliforniaPrivacyPolicy({ setContent }) {
    await getCaliforniaPrivacyPolicy()
      .then((res) => {
        setContent(res.content);
      })
      .catch();
  }

  async function addTermsNdConditionCalifornia({ event, content, apiData }) {
    event.preventDefault();
    let model = {
      title: CONSTANT_STRINGS.CP,
      content: content,
    };
    if (apiData === "") {
      await addCaliforniaPrivacyCMS(model)
        .then((res) => {
          toast.success(SUCCESS_MESSAGES.cpAddSuccess, toastrOptions);
        })
        .catch();
    } else {
      await editCaliforniaPrivacyCMS(model)
        .then((res) => {
          toast.success(SUCCESS_MESSAGES.cpUpdatedSuccess, toastrOptions);
        })
        .catch();
    }
  }
  const [raw_html, setRawHTML] = useState("");
  const [show_raw, setShowRaw] = useState(false);
  const handleClickShowRaw = () => {
    setShowRaw(!show_raw);
    if (activeTab == "3") {
      if (show_raw)
        setRawHTML(termsNdCondition.length != 0 ? termsNdCondition : terms);
    }
    if (activeTab == "4") {
      if (show_raw)
        setRawHTML(privacyPolicy.length != 0 ? privacyPolicy : policy);
    }
    syncViews(show_raw);
  };

  const syncViews = (fromRaw) => {
    if (activeTab == "3") {
      if (fromRaw) {
        setTermsNdCondition(raw_html + "");
      } else {
        setRawHTML(termsNdCondition.length != 0 ? termsNdCondition : terms);
      }
    } else {
      if (fromRaw) {
        setPrivacyPolicy(raw_html + "");
      } else {
        setRawHTML(privacyPolicy.length != 0 ? privacyPolicy : policy);
      }
    }
  };
  const handleChangeRaw = (html) => {
    setRawHTML(html + "");
    syncViews(show_raw);
  };

  return (
    <React.Fragment>
      <Loader showLoader={isLoading} />
      <div className="page-content">
        <Breadcrumbs title="Pages" breadcrumbItem="CMS" />

        <div className="checkout-tabs">
          <Row>
            <Col lg="2">
              <Nav className="flex-column" pills>
                {permissionPPKFAQ.length === 0 ? null : (
                  <>
                    <NavItem>
                      <NavLink
                        className={classnames({ active: activeTab === "1" })}
                        onClick={() => {
                          setactiveTab("1");
                        }}
                      >
                        <i className="bx bx-question-mark d-block check-nav-icon mt-4 mb-2" />
                        <p className="fw-bold mb-4">What’s PPK?</p>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({ active: activeTab === "2" })}
                        onClick={() => {
                          setactiveTab("2");
                        }}
                      >
                        <i className="bx bx-question-mark d-block check-nav-icon mt-4 mb-2" />
                        <p className="fw-bold mb-4">FAQ</p>
                      </NavLink>
                    </NavItem>
                  </>
                )}

                {viewPermission ? (
                  <>
                    <NavItem>
                      <NavLink
                        className={classnames({ active: activeTab === "3" })}
                        onClick={() => {
                          setactiveTab("3");

                          setShowRaw(false);
                        }}
                      >
                        <i className="bx bx-question-mark d-block check-nav-icon mt-4 mb-2" />
                        <p className="fw-bold mb-4">Terms and Conditions</p>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({ active: activeTab === "4" })}
                        onClick={() => {
                          callPrivacyPolicy();
                          setactiveTab("4");
                          setShowRaw(false);
                        }}
                      >
                        <i className="bx bx-check-shield d-block check-nav-icon mt-4 mb-2" />
                        <p className="fw-bold mb-4">Privacy Policy</p>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({ active: activeTab === "5" })}
                        onClick={() => {
                          setactiveTab("5");
                        }}
                      >
                        <i className="bx bx-check-shield d-block check-nav-icon mt-4 mb-2" />
                        <p className="fw-bold mb-4">California Policy</p>
                      </NavLink>
                    </NavItem>
                  </>
                ) : null}
              </Nav>
            </Col>
            <Col lg="10">
              <Card>
                <CardBody>
                  <TabContent activeTab={activeTab}>
                    {viewPermission || viewPermissionPPKFAQ ? (
                      <>
                        <TabPane tabId="1">
                          <PPK
                            data={{
                              change: changePermissionPPKFAQ,
                              add: addPermissionPPKFAQ,
                              delete: deletePermissionPPKFAQ,
                              view: viewPermissionPPKFAQ,
                            }}
                          />
                        </TabPane>
                        <TabPane tabId="2">
                          <FAQ
                            data={{
                              change: changePermissionPPKFAQ,
                              add: addPermissionPPKFAQ,
                              delete: deletePermissionPPKFAQ,
                              view: viewPermissionPPKFAQ,
                            }}
                          />
                        </TabPane>
                        <TabPane tabId="3">
                          <CardTitle className="h4 mb-5">
                            Terms and Conditions
                          </CardTitle>
                          <div
                            className={
                              show_raw
                                ? "faq-box d-flex align-items-start mb-4 showRaw"
                                : "faq-box d-flex align-items-start mb-4"
                            }
                          >
                            <div className="flex-1">
                              <EditorToolbar
                                toolbarId="toolbar-1"
                                onClickRaw={handleClickShowRaw}
                              />
                              <form onSubmit={addTermsNdCondition}>
                                <ReactQuill
                                  theme="snow"
                                  modules={{
                                    toolbar: {
                                      container: `#toolbar-1`,
                                    },
                                    clipboard: {
                                      matchVisual: false,
                                    },
                                  }}
                                  formats={formats}
                                  onChange={setTermsData}
                                  value={
                                    termsNdCondition ? termsNdCondition : terms
                                  }
                                  placeholder="Write something"
                                  readOnly={!changePermission}
                                />
                                <textarea
                                  className={"raw-editor"}
                                  onChange={(e) =>
                                    handleChangeRaw(e.target.value)
                                  }
                                  value={raw_html}
                                  disabled={!changePermission}
                                />
                                <Button
                                  type="submit"
                                  color="primary"
                                  className="ms-1 cms-button terms-conditions-submit-btn"
                                  style={{ marginTop: "60px" }}
                                  disabled={!changePermission}
                                >
                                  Submit
                                </Button>
                              </form>
                            </div>
                          </div>
                        </TabPane>
                        <TabPane tabId="4">
                          <CardTitle className="h4 mb-5">
                            Privacy Policy
                          </CardTitle>

                          <div
                            className={
                              show_raw
                                ? "faq-box d-flex align-items-start mb-4 showRaw"
                                : "faq-box d-flex align-items-start mb-4"
                            }
                          >
                            <div className="flex-1 privacy-policy-box">
                              <EditorToolbar
                                toolbarId="toolbar-2"
                                onClickRaw={handleClickShowRaw}
                              />
                              <form onSubmit={addPrivacyPolicyData}>
                                <ReactQuill
                                  theme="snow"
                                  modules={{
                                    toolbar: {
                                      container: `#toolbar-2`,
                                    },
                                    clipboard: {
                                      matchVisual: false,
                                    },
                                  }}
                                  formats={formats}
                                  onChange={setPrivacyPolicyData}
                                  value={privacyPolicy ? privacyPolicy : policy}
                                  placeholder="Write something"
                                  readOnly={!changePermission}
                                />
                                <textarea
                                  className={"raw-editor"}
                                  onChange={(e) =>
                                    handleChangeRaw(e.target.value)
                                  }
                                  value={raw_html}
                                  disabled={!changePermission}
                                />
                                <Button
                                  type="submit"
                                  color="primary"
                                  className="ms-1 cms-button privacy-policy-submit-btn"
                                  style={{ marginTop: "60px" }}
                                  disabled={!changePermission}
                                >
                                  Submit
                                </Button>
                              </form>
                            </div>
                          </div>
                        </TabPane>
                        <TabPane tabId="5">
                          <CardTitle className="h4 mb-5">
                            California Policy
                          </CardTitle>
                          <div className="faq-box d-flex align-items-start mb-4">
                            <div className="flex-1 privacy-policy-box">
                              <JoditEditorForm
                                dataFromAPI={callCaliforniaPrivacyPolicy}
                                postDataToServer={addTermsNdConditionCalifornia}
                                changePermission={changePermission}
                              />
                            </div>
                          </div>
                        </TabPane>
                      </>
                    ) : null}
                  </TabContent>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};
export default CMS;
