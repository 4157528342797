const toastrOptions = {
  position: "top-right",
  autoClose: 3000,
  hideProgressBar: true,
  newestOnTop: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  pauseOnFocusLoss: false,
  theme: "colored",
};

export default toastrOptions;
