import React, { useEffect, useState } from "react";

import { Row, Col, Card, CardBody, Button } from "reactstrap";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { getBannerList, removeBanner } from "../../services/banner_api_helper";
import { Link } from "react-router-dom";

// Alert
import SweetAlert from "react-bootstrap-sweetalert";
import { toast } from "react-toastify";
import toastrOptions from "../../helpers/toastr-options/toastr-options";

const ImageSliderList = () => {
  const [banners, setBanners] = useState([]);
  const [openDeleteModal, setopenDeleteModal] = useState(false);
  const [selectedBanner, setselectedBanner] = useState(null);

  useEffect(() => {
    getList();
  }, []);

  const getList = async () => {
    await getBannerList().then((res) => {
      setBanners(res);
    });
  };

  const openModal = (banner) => {
    setselectedBanner(banner);
    setopenDeleteModal(true);
  };

  const closeModal = () => {
    setopenDeleteModal(false);
  };

  const deleteBanner = async () => {
    await removeBanner(selectedBanner.id)
      .then(() => {
        toast.success("Banner Removed Successfully", toastrOptions);
        closeModal();
        getList();
      })
      .catch((err) => {
        toast.error(err, toastrOptions);
        closeModal();
      });
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Breadcrumbs breadcrumbItem="Banners" />
        <Row>
          <Col className="col-12">
            <Card>
              <CardBody>
                <Row className="search-box">
                  <Col className="col-xl-2 col-lg-3 col-md-3 col-sm-3">
                    <Link to="/banner/add">
                      <button className="btn btn-primary">Add Banner</button>
                    </Link>
                  </Col>
                </Row>

                <div className="table-rep-plugin">
                  <div
                    className="table-responsive mb-0"
                    data-pattern="priority-columns"
                  >
                    <Table
                      id="tech-companies-1"
                      className="table table-striped table-bordered"
                    >
                      <Thead>
                        <Tr>
                          <Th>Image</Th>
                          <Th data-priority="3">Description</Th>
                          <Th data-priority="1">Button Link</Th>
                          <Th data-priority="1" colSpan="2">
                            Actions
                          </Th>
                        </Tr>
                      </Thead>
                      {banners && banners.length ? (
                        <Tbody>
                          {banners.map((item, index) => (
                            <Tr key={index}>
                              <Td>
                                <a
                                  href={item.image}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  <img
                                    height="100"
                                    src={item.image}
                                    alt="banner"
                                  />
                                </a>
                              </Td>
                              <Td>{item.description}</Td>
                              <Td>{item.link}</Td>
                              <Td>
                                <Link
                                  className="btn btn-secondary btn-sm"
                                  role="button"
                                  to={`/banner/edit/${item.id}`}
                                >
                                  Edit
                                </Link>
                              </Td>
                              <Td>
                                <Button
                                  className="btn btn-danger btn-sm"
                                  onClick={() => openModal(item)}
                                >
                                  Remove
                                </Button>
                              </Td>
                            </Tr>
                          ))}
                        </Tbody>
                      ) : (
                        <h5 className="text-center mt-4">No Banner(s) Found</h5>
                      )}
                    </Table>
                  </div>
                </div>
              </CardBody>
              {openDeleteModal ? (
                <SweetAlert
                  title="Remove Banner"
                  warning
                  showCancel
                  confirmButtonText="Yes"
                  confirmBtnBsStyle="success"
                  cancelButtonText="No"
                  cancelBtnBsStyle="danger"
                  onConfirm={() => deleteBanner()}
                  onCancel={() => closeModal()}
                >
                  Are you sure you want to remove the banner?
                </SweetAlert>
              ) : null}
            </Card>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};

export default ImageSliderList;
